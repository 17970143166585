import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import _ from 'lodash';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    drawer: string;
  }
}

export const defaultTheme = {
  palette: {
    mode: 'light',
    primary: {
      main: '#C00000',
      light: '#FF5C5C',
    },
    secondary: {
      main: '#355777',
    },
    background: {
      drawer: grey[100],
    },
  },
  typography: { fontSize: 16 },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: 'black',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 36,
        },
      },
    },
  },
};

const getTheme = (options: any) =>
  createTheme(_.merge(_.cloneDeep(defaultTheme), options));

export default getTheme;
