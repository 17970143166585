import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';

import {
  ChartTrace,
  ChartType,
  DataType,
  QueryResultColumn,
} from '../../../../utils/Types';
import { Chart, NoDataView } from '../../../../components';
import {
  getAxisTypeFromJsDataType,
  isDataCalculatable,
} from '../../../../utils/Helper';
import { HISTOGRAM_Y_AXIS_TITLE } from '../../../../utils/Constants';

const Analysis = ({
  columns,
  rows,
}: {
  columns: QueryResultColumn[] | undefined;
  rows: any[];
}) => {
  const [chartType, setChartType] = useState<ChartType>(ChartType.SCATTER_PLOT);
  const [x, setX] = useState('');
  const [y1, setY1] = useState('');
  const [y2, setY2] = useState('');

  if (!columns) {
    return <NoDataView bgcolor="grey.100" />;
  }

  const supportedChartTypes = [ChartType.BAR_CHART, ChartType.SCATTER_PLOT];

  const excludeColumns = [
    'VIDEO_URL',
    'LOCATION',
    'LOT',
    'SCAN_DATE',
    'NUM_FRAMES',
    'NOTES',
    'USER',
  ];
  const availableAttributes = columns.filter(
    i => !excludeColumns.includes(i.name)
  );

  const chartTracesToCreate: ChartTrace[] = [];
  if (x && y1) {
    chartTracesToCreate.push({ x, y: y1 });
  }
  if (x && y2) {
    chartTracesToCreate.push({ x, y: y2 });
  }

  const getChartTraces = () => {
    const chartTraces: (Plotly.Data | { nameX: string })[] = [];
    chartTracesToCreate
      .filter((t: ChartTrace) => t.x) // only check x axis exists because some charts don't hav y
      .forEach((trace: ChartTrace, index: number) => {
        // sort x axis
        let sortedData = [...rows];
        const xDataType = columns.find(i => i.name === trace.x)?.type;
        if (isDataCalculatable(xDataType as DataType)) {
          sortedData = _.sortBy(sortedData, trace.x!);
        }

        let x = sortedData.map(i => i[trace.x!]);
        let y =
          chartType === ChartType.HISTOGRAM
            ? ''
            : sortedData.map(i => i[trace.y!]);

        const mainTrace = {
          x,
          y,
          name: _.startCase(
            chartType === ChartType.HISTOGRAM ? trace.x! : trace.y!
          ),
          nameX: _.startCase(trace.x!),
          yaxis:
            index > 0 && chartType !== ChartType.HISTOGRAM
              ? `y${index + 1}`
              : 'y',
          offsetgroup: index,
          ...trace.settings,
        };
        chartTraces.push(mainTrace);
      });
    return chartTraces;
  };

  return (
    <Stack bgcolor="grey.50" minWidth={600}>
      <Stack direction="row" p={2} spacing={1}>
        <FormControl size="small" fullWidth>
          <InputLabel>Chart Type</InputLabel>
          <Select
            label="Chart Type"
            value={chartType}
            onChange={e => setChartType(e.target.value as ChartType)}
          >
            {supportedChartTypes.map(i => (
              <MenuItem key={`chartType_option_${i}`} value={i}>
                {_.startCase(i)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth>
          <InputLabel>X Axis</InputLabel>
          <Select label="X Axis" value={x} onChange={e => setX(e.target.value)}>
            {availableAttributes.map(i => (
              <MenuItem key={`x_option_${i.name}`} value={i.name}>
                {_.startCase(i.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth>
          <InputLabel>Y Axis 1</InputLabel>
          <Select
            label="Y Axis 1"
            value={y1}
            onChange={e => setY1(e.target.value)}
          >
            {availableAttributes.map(i => (
              <MenuItem
                key={`y1_option_${i.name}`}
                value={i.name}
                disabled={i.name === x || i.name === y2}
              >
                {_.startCase(i.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth>
          <InputLabel>Y Axis 2</InputLabel>
          <Select
            label="Y Axis 2"
            value={y2}
            onChange={e => setY2(e.target.value)}
          >
            {availableAttributes.map(i => (
              <MenuItem
                key={`y2_option_${i.name}`}
                value={i.name}
                disabled={i.name === x || i.name === y1}
              >
                {_.startCase(i.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {chartTracesToCreate.length > 0 && (
        <Chart
          type={chartType}
          title={
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography variant="h6" textAlign="center">
                Anaylysis
              </Typography>
            </Stack>
          }
          data={{
            xTitle:
              ((chartTracesToCreate.length === 1 ||
                chartType === ChartType.BAR_CHART) &&
                _.startCase(chartTracesToCreate[0].x)) ||
              '',
            xAxisType: getAxisTypeFromJsDataType(
              columns.find(c => c.name === chartTracesToCreate[0].x)?.type
            ),
            yTitle:
              chartType === ChartType.HISTOGRAM
                ? HISTOGRAM_Y_AXIS_TITLE
                : _.startCase(chartTracesToCreate[0].y) || '',
            yAxisType: getAxisTypeFromJsDataType(
              columns.find(c => c.name === chartTracesToCreate[0].y)?.type
            ),
            extraYaxes:
              chartType !== ChartType.HISTOGRAM &&
              chartTracesToCreate.length > 1
                ? chartTracesToCreate
                    .filter((i, index: number) => index > 0)
                    .map((i: ChartTrace) => ({
                      title: _.startCase(i.y!),
                      axisType: getAxisTypeFromJsDataType(
                        columns.find(c => c.name === i.y)?.type
                      ),
                    }))
                : [],
            data: getChartTraces(),
          }}
        />
      )}
    </Stack>
  );
};

export default Analysis;
