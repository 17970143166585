import { useEffect, useState } from 'react';

import { DialogWrapper, Form, Skeleton } from '../../../components';
import {
  FormInputCategory,
  FormInputItem,
  FormData,
  LooseObject,
  Org,
  ChartType,
  Trendline,
} from '../../../utils/Types';
import { useAppSelector } from '../../../redux/hooks';
import { selectDataPool } from '../../../redux/reducers/dataPoolSlice';
import { generateDataPoolId } from '../../../utils/Helper';

const EditInsightItem = ({
  availableQueries,
  insightItem,
  conditions,
  onCancel,
  handleSubmit,
  org,
}: {
  availableQueries: LooseObject[];
  insightItem: LooseObject;
  conditions: LooseObject;
  onCancel: () => void;
  handleSubmit: (v: FormData) => void;
  org: Org;
}) => {
  const [attribute, setAttribute] = useState<LooseObject>();
  const [formInputs, setFormInputs] = useState<FormInputItem[]>();

  const dataPool = useAppSelector(selectDataPool);

  useEffect(() => {
    loadAttribute();
  }, []);

  const loadAttribute = async () => {
    const query = availableQueries.find(
      i => i.id.toString() === insightItem.dataSourceKey
    );

    if (query) {
      const currentDataPoolItem = dataPool.find(i => {
        const insightItemId = generateDataPoolId({
          dataSourceKey: query.id,
          dataSourceParams: {},
        });
        return i.id === insightItemId;
      });
      if (currentDataPoolItem) {
        const attr = currentDataPoolItem.attributes.find(
          i => i.key === insightItem.chartTraces[0].x
        );
        setAttribute(attr);
      }
    }
  };

  useEffect(() => {
    if (attribute) {
      let inputs: FormInputItem[] = [
        {
          name: 'title',
          label: 'Title',
          category: FormInputCategory.TEXT_INPUT,
          defaultValue: insightItem.title,
        },
        {
          name: 'description',
          label: 'Description',
          category: FormInputCategory.TEXT_INPUT,
          defaultValue: insightItem.description,
          multiline: true,
        },
      ];

      if (insightItem.chartType === ChartType.HISTOGRAM) {
        inputs = inputs.concat([
          {
            name: 'xBinsSize',
            label: 'X Bins Size',
            category: FormInputCategory.TEXT_INPUT,
            type: attribute?.type === 'number' ? 'number' : '',
            defaultValue: insightItem.chartTraces[0].settings?.xbins?.size,
          },
        ]);
      }

      if (insightItem.chartType === ChartType.SCATTER_PLOT) {
        inputs = inputs.concat([
          {
            name: 'trendlines',
            label: 'Show Trendlines',
            category: FormInputCategory.SELECT,
            options: [{ label: 'R2 (R-Squared)', value: Trendline.R2 }],
            multiple: true,
            defaultValue: insightItem.chartTraces[0].settings?.trendlines,
          },
        ]);
      }
      setFormInputs(inputs);
    }
  }, [attribute]);

  return (
    <DialogWrapper
      title="Edit an insight item"
      onCancel={onCancel}
      hideCancelButton
    >
      {formInputs ? (
        <Form
          onSubmit={v => handleSubmit({ id: insightItem.id, attribute, ...v })}
          buttonText="Submit"
          inputs={formInputs}
        />
      ) : (
        <Skeleton minHeight={100} />
      )}
    </DialogWrapper>
  );
};

export default EditInsightItem;
