import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { User } from '../../utils/Types';

export interface UserState {
  value: User;
  status: 'idle' | 'loading' | 'failed';
}

let initialValue = {};
try {
  const initialValueString = localStorage.getItem('user');
  if (initialValueString) {
    initialValue = JSON.parse(initialValueString);
  }
} catch (error: any) {
  console.log(error.message + ' in localStorage user');
}

const initialState: UserState = {
  value: initialValue as User,
  status: 'idle',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.value;

export default userSlice.reducer;
