import { AlertColor } from '@mui/material';
import { AxisType, Datum } from 'plotly.js';
import { ReactNode } from 'react';
import {
  BarMode,
  DragMode,
  Orientation,
  SelectDirection,
} from '../components/Chart/SharedTypes';

export enum DateFormat {
  DEFAULT = 'DD/MM/YYYY HH:mm:ss',
  SHORT = 'DD/MM/YYYY',
  FULL = 'DD/MM/YYYY HH:mm:ss SSS Z',
  DAY_AND_TIME = 'MMM DD HH:mm',
  UNIX_TIMESTAMP = 'x',
}

export enum DateFormatUSA {
  DEFAULT = 'MM/DD/YYYY HH:mm:ss',
  SHORT = 'MM/DD/YYYY',
  FULL = 'MM/DD/YYYY HH:mm:ss SSS Z',
  DAY_AND_TIME = 'MMM DD HH:mm',
  UNIX_TIMESTAMP = 'x',
}

export enum DateFormatServer {
  DEFAULT = 'YYYY-MM-DD HH:mm:ss',
  SHORT = 'YYYY-MM-DD',
  FULL = 'YYYY-MM-DD HH:mm:ss SSS Z',
  DAY_AND_TIME = 'MM-DD HH:mm',
  UNIX_TIMESTAMP = 'x',
  TIMESTAMP = 'YYYY-MM-DD HH:mm:ss.SSS',
}

export type NavItem = {
  title: string;
  to: string;
  page: JSX.Element;
  category?: PluginName | 'Account' | 'Management' | 'Administration';
  icon?: any;
  hideNav?: boolean;
  hideFooter?: boolean;
  isPublic?: boolean;
  contentPadding?: number;
};

export enum FormInputCategory {
  COMPONENT = 'Component',
  TEXT_INPUT = 'TextInput',
  DATE_PICKER = 'DatePicker',
  DATE_TIME_PICKER = 'DateTimePicker',
  SELECT = 'Select',
  CHECK_BOX = 'CheckBox',
  SWITCH = 'Switch',
  DATE_RANGE_PICKER = 'DateRangePicker',
  NUMBER_RANGE_INPUT = 'NumberRangeInput',
  AUTO_COMPLETE = 'AutoComplete',
}

export type FormInputItem = {
  name: string;
  label?: string;
  category: FormInputCategory;
  defaultValue?: any;
  placeholder?: string;
  helperText?: string;
  inputProps?: {};
  textFieldProps?: {};
  validationParams?: {};
  exposeValue?: (value: any) => void;
  component?: JSX.Element;
  type?: string; // can be password, search, number
  required?: boolean;
  multiline?: boolean;
  size?: 'small' | 'medium' | undefined;
  avoidValidation?: boolean;
  options?: readonly LabelValuePair[];
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  multiple?: boolean;
  format?: string;
  loading?: boolean;
  selectAll?: boolean; // select all options of a select
};

export type LabelValuePair = { label: string; value: any; disabled?: boolean };

export type LooseObject = {
  [key: string]: any;
};

export enum MessageText {
  SUCCESS = 'Mission complete',
  INPUT_ERROR_REQUIRED = 'Required',
  INPUT_ERROR_EMAIL = 'Please type in a valid email address',
  INPUT_ERROR_POSITIVE_NUMBER = 'Should be a positive number',
  NUMBER_ONLY = 'Number only please',
  NETWORK_ERROR = 'Network error',
}

export enum PasswordLength {
  MIN = 4,
  MAX = 50,
}

export enum PluginName {
  GENETICS = 'Genetics',
  BREEDING = 'Breeding',
  FEEDLOT = 'Feedlot',
  FEEDYARD = 'Feedyard', // for Mishima only
  PRODUCER = 'Producer',
  PROCESSING = 'Processing',
  WHOLESALE = 'Wholesale',
  SCHEDULING = 'Scheduling',
  CAMERA = 'Camera',
  INSIGHTS = 'Insights',
  MACHINE_LEARNING = 'Machine Learning',
  LIVE_INTERNAL = 'Live Internal',
  BEEF_MSA = 'Beef MSA',
  SHEEP_PROBE = 'Sheep Probe',
}

export type Auth =
  | {
      id?: number;
      version?: number;
      role?: number;
      orgId?: number;
      orgOwner?: string;
      plugins?: string[];
      token?: string;
    }
  | null
  | undefined;

export interface Message {
  readonly text: string | null;
  readonly type: AlertColor;
}

export interface ServerRequest {
  action: string;
  params?: {};
  token?: string;
}

export enum ResultCode {
  SUCCESS = 700,
  FAILED = 701,
}

export interface Result {
  message: Message;
  serverData: LooseObject | LooseObject[] | null;
  auth?: Auth;
  statusCode?: number;
}

export interface FormData {
  [prop: string]: any;
}

export type Plugin = {
  id: number;
  name: string;
  description: string;
};

export type Org =
  | {
      id: number;
      name: string;
      owner: string;
      logo: string;
      plugins: Plugin[];
      theme?: {};
      updatedAt: Date;
      isWhiteLabel: boolean;
      isUSA: boolean;
      isTCRequired: boolean;
    }
  | null
  | undefined;

export type User =
  | {
      id: number;
      firstName: string;
      lastName: string;
      email: string;
      orgId: number;
      orgs?: Org[];
      plugins?: string[];
      role: number;
      roleConfig: any;
      version: number;
      is2FA: boolean;
      isDataMaintainer: boolean;
      isTCAccepted: boolean;
    }
  | null
  | undefined;

export type TableRowId = string | number;

export type TableRowProps = {
  id: TableRowId;
  [prop: string]: any;
};

export type TableColumnProps = {
  id: string;
  label: string;
  hidden?: boolean;
  isSortable?: boolean;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right' | 'left' | 'center' | 'inherit' | 'justify';
  disablePadding?: boolean;
  component?: Function;
  isDefalutOrderBy?: boolean;
};

export type TableGroupColumnProps = {
  label: string;
  colSpan: number;
};

export enum TableToolBarActionButtonCategory {
  SINGLE_SELECT_ONLY = 'SingleSelcetOnly',
  MULTIPLE_SELECT = 'MultipleSelect',
  NO_SELECT_ONLY = 'NoSelectOnly',
  ALWAYS = 'Always',
}

export enum LocationType {
  FEEDLOT = 'feedlot',
  SLAUGHTER = 'slaughter',
}

export enum UserRole {
  SUPER_ADMIN = 10,
  ORGANISATION_ADMIN = 9,
  // ORGANISATION_MANAGER = 8,
  ORGANISATION_USER = 7,
  DEFAULT = 0,
}

export enum Status {
  SUSPENDED = 'Suspended',
  ACTIVE = 'Active',
}

export type DataType =
  | 'string'
  | 'number'
  | 'date'
  | 'datetime'
  | 'unknown'
  | 'any';

export type Trait = {
  key: string;
  label: string;
  type?: DataType;
  isInArray?: boolean;
};

export type ChartData = {
  xTitle: string;
  yTitle: string;
  data: (Plotly.Data & { nameX?: string; x?: any; y?: any })[];
  xCategoryOrder?:
    | 'trace'
    | 'category ascending'
    | 'category descending'
    | 'array'
    | 'total ascending'
    | 'total descending'
    | 'min ascending'
    | 'min descending'
    | 'max ascending'
    | 'max descending'
    | 'sum ascending'
    | 'sum descending'
    | 'mean ascending'
    | 'mean descending'
    | 'median ascending'
    | 'median descending';
  xCategoryArray?: any[];
  xAxisType?: AxisType;
  yAxisType?: AxisType;
  yAxisRange?: any[];
  extraYaxes?: { title: string; axisType?: AxisType }[];
};

export type ChartPoint = {
  x: Datum;
  y: Datum;
};

export type ChartProps = {
  type: ChartType;
  title?: string;
  headerBeforeLoading?: ReactNode;
  header?: ReactNode;
  data: ChartData;
  loading?: boolean;
  minHeight?: number;
  updateFilter?: (v: any) => void;
  org?: Org;
  chartTraceColors?: string[];
  dragMode?: DragMode;
  selectDirection?: SelectDirection;
  style?: LooseObject;
  bgcolor?: string;
  backdrop?: ReactNode;
  handleClick?: (v: ChartPoint) => void;
  barMode?: BarMode;
  orientation?: Orientation;
  legendX?: number;
  legendY?: number;
};

export type SortableItem = { id: string | number; component: ReactNode };

export type DataArrayItem = { key: string; label: string; values: any[] };

export enum DataSourceKey {
  SUMMARY_OF_IMAGES = 'summary_of_images',
  LIST_OF_IMAGES = 'list_of_images',
}

export type DataSource = {
  key: string;
  label: string;
  endpoint: string;
  params?: LooseObject; // for external data sources
  query?: LooseObject; // for internal data sources
  isNoParamsPermitted?: boolean;
  excludeAttributes: string[];
};

export enum InsightItemType {
  CHART = 'Chart',
  DATA_TABLE = 'DataTable',
}

export type InsightItemProps = {
  type: InsightItemType;
  dataSourceKey: string;
  chartProps?: ChartProps;
};

export type DataPoolItem = {
  id: number | string;
  dataSource: DataSource;
  dataSourceParams?: LooseObject;
  data: LooseObject[];
  attributes: Trait[];
  updatedAt: number; // milliseconds
};

export enum ChartType {
  BAR_CHART = 'bar',
  SCATTER_PLOT = 'scatter',
  HISTOGRAM = 'histogram',
  LINE_CHART = 'line',
  PIE_CHART = 'pie',
  DONUT_CHART = 'donut',
  VIOLIN_PLOT = 'violin',
}

export enum SharedInsightScope {
  ENTIRE_ORG = 'Entire Organisation',
  TARGETED_USER = 'Targeted User',
}

export type ChartTrace = {
  x?: string;
  y?: string;
  yaxis?: string;
  settings?: LooseObject;
};

export type QueryResultColumn = {
  name: string;
  label: string;
  type: string;
  scale: number;
};

export type QueryResult = {
  err?: any;
  rows?: any[];
  columns?: QueryResultColumn[];
  numRows?: number;
  numUpdatedRows?: number;
  requestId?: string;
};

export enum QueryType {
  ALL_TABLES = 'ALL TABLES',
  ALL_VIEWS = 'All Views',
  ALL_COLUMNS = 'All Columns',
  ORDINARY_QUERY = 'Ordinary Query',
  ORIGINAL_QUERY = 'Original Query',
}

export type Condition = {
  value: any | any[];
  type: DataType;
};

export type Conditions = {
  [key: string]: Condition | null;
};

export enum Trendline {
  R2 = 'R2',
}

export type Coordinate = { longitude: number; latitude: number };

export type Boundary = { ne: Coordinate; sw: Coordinate };
