import { ListItemIcon, Stack, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import moment from 'moment';

import { Skeleton } from '../../../components';
import { LooseObject, Org } from '../../../utils/Types';
import { getDateFormat } from '../../../utils/Helper';

const UpcomingEventList = ({
  loading,
  title,
  lots,
  org,
}: {
  loading: boolean;
  title: string;
  lots: LooseObject[];
  org: Org;
}) => (
  <Stack p={2} spacing={1}>
    <Stack>
      <Typography fontWeight="bold">{title}</Typography>
      <Typography variant="body2">{`${moment()
        .startOf('week')
        .format(getDateFormat(org, 'short'))} - ${moment()
        .endOf('week')
        .format(getDateFormat(org, 'short'))}`}</Typography>
    </Stack>
    <Stack>
      {loading ? (
        <Skeleton minHeight={30} />
      ) : lots?.length > 0 ? (
        lots.map(i => (
          <Stack key={i.LOT} direction="row" color="grey.600">
            <ListItemIcon>
              <ChevronRight />
            </ListItemIcon>
            <Typography variant="body2">{`Lot No. ${i.LOT}`}</Typography>
          </Stack>
        ))
      ) : (
        <Typography variant="body2">No lots.</Typography>
      )}
    </Stack>
  </Stack>
);

export default UpcomingEventList;
