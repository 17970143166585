import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

import { TableColumnProps, TableRowProps } from '../utils/Types';

const BasicTable = ({
  columns,
  rows,
  borderColor = 'rgba(224, 224, 224, 1)',
  size = 'small',
  hideHead,
}: {
  columns: TableColumnProps[];
  rows: TableRowProps[];
  borderColor?: string;
  size?: 'small' | 'medium';
  hideHead?: boolean;
}) => (
  <Table size={size}>
    {!hideHead && (
      <TableHead sx={{ bgcolor: 'grey.200', border: 0 }}>
        <TableRow>
          {columns
            .filter(i => !i.hidden)
            .map(i => (
              <TableCell
                key={i.id}
                sx={{ border: 1, borderColor }}
                align="center"
              >
                {i.label}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    )}
    <TableBody>
      {rows.map((row, index) => (
        <TableRow key={row.id + '_' + index} sx={{ minHeight: 33 }}>
          {columns.map(
            i =>
              !i.hidden && (
                <TableCell
                  key={row.id + '_' + index + '_' + i.id}
                  sx={{
                    border: 1,
                    borderColor,
                    height: row[i.id] ? 'auto' : 33,
                  }}
                  align="center"
                >
                  {row[i.id]}
                </TableCell>
              )
          )}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default BasicTable;
