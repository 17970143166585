import { Link, Stack } from '@mui/material';

const Page = () => {
  return (
    <Stack spacing={3} py={3}>
      <Link
        href={process.env.REACT_APP_ZITADEL_AUTH_URL + '/ui/console/'}
        sx={{ cursor: 'pointer' }}
      >
        Please update your password here
      </Link>
    </Stack>
  );
};

export default Page;
