import { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import { DateFormatServer, LooseObject } from '../../../utils/Types';
import { postToServer } from '../../../utils/Helper';
import { DepthCameraComponentProps } from './GraphArea';
import FrameItem from './FrameItem';
import { Skeleton } from '../../../components';
import FrameSourceOfTruth from './FrameSourceOfTruth';

const DeepdiveFrames = ({
  item,
  date,
  trait,
  type,
  facility,
  auth,
  snackbar,
}: DepthCameraComponentProps & { item: LooseObject | undefined }) => {
  const [data, setData] = useState<LooseObject>();
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    if (item) {
      loadData(item);
    }
  }, []);

  const loadData = (item: LooseObject) => {
    if (item && date && trait && type && facility) {
      if (auth?.token) {
        setLoadingData(true);
        postToServer({
          action: 'machine-learning/depth-camera/GetDeepdiveFrames',
          params: {
            location: facility,
            date: date.format(DateFormatServer.SHORT),
            type,
            trait,
            barcode: item.barcode,
            video: item.video,
            frame: item.frame,
          },
          token: auth.token,
        }).then(response => {
          setLoadingData(false);
          if (response.message.type === 'success' && response.serverData) {
            setData(response.serverData as LooseObject);
          } else {
            snackbar.open(response.message);
          }
        });
      }
    }
  };

  return loadingData || !data ? (
    <Skeleton minWidth={300} minHeight={200} />
  ) : (
    <Stack>
      <Grid container p={2} bgcolor="grey.300" pt={1} justifyContent="center">
        <Typography variant="h5">{`Barcode: ${item?.barcode}`}</Typography>
      </Grid>
      <Grid container spacing={1} pt={1} bgcolor="grey.100" p={0.5}>
        <Grid item xs={8} bgcolor="grey.200">
          {Object.keys(data).map(i => (
            <FrameItem
              key={`frame_${i}`}
              id={i}
              data={data[i]}
              barcode={item?.barcode}
              date={date}
              trait={trait}
              type={type}
              facility={facility}
            />
          ))}
        </Grid>
        <Grid item xs={4}>
          {Object.keys(data)?.length > 0 && (
            <FrameSourceOfTruth
              data={data[Object.keys(data)[0]]}
              barcode={item?.barcode}
              date={date}
              trait={trait}
              type={type}
              facility={facility}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DeepdiveFrames;
