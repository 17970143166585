import {
  Button,
  ImageListItem,
  ImageListItemBar,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Moment } from 'moment';

import { Form } from '../../../components';
import { SnackbarContext } from '../../../utils/Contexts';
import { DateFormatServer, FormInputCategory } from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { logout, postToServer } from '../../../utils/Helper';
import { Trait, Type } from './GraphArea';
import { selectUser } from '../../../redux/reducers/userSlice';

export const getImageNameFromURL = (urlInput: string) => {
  const imageFullnameResult = urlInput.match(/.+(\/|%2F)(.+\?)/);
  if (!imageFullnameResult) return null;

  const imageFullname = imageFullnameResult[2];
  const imageNameResult = imageFullname.match(
    /.+(jpg|png|svg|jpeg|webp|bmp|gif)/
  );

  if (!imageNameResult) return imageFullname;

  const imageName = imageNameResult[0];
  if (!imageName) return null;

  return imageName;
};

export type FrameImageProps = {
  url: string;
  title: string;
  onClick?: (v: any) => void;
  height?: number | string;
  barcode: string;
  date: Moment | null;
  trait: Trait;
  type: Type;
  facility?: string;
};

const FrameImage = ({
  url,
  title,
  onClick,
  height,
  barcode,
  date,
  trait,
  type,
  facility,
}: FrameImageProps) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = ({
    issueType,
    note,
  }: {
    issueType: string;
    note: string;
  }) => {
    if (issueType) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'machine-learning/depth-camera/ReportIssue',
          params: {
            date: date?.format(DateFormatServer.SHORT),
            location: facility,
            trait,
            type,
            barcode,
            user_email: user?.email,
            image_name: getImageNameFromURL(url),
            issue_reported: issueType,
            note,
          },
          token: auth.token,
        }).then(response => {
          setLoading(false);
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            handleClose();
            snackbar.open(response.message);
          }
        });
      }
    }
  };

  return (
    <ImageListItem>
      <img
        src={url}
        alt={title}
        loading="lazy"
        onClick={() => onClick?.({ url, title })}
        style={{ height: height }}
      />
      <ImageListItemBar
        title={
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">{title}</Typography>
              <Button
                variant="contained"
                size="small"
                sx={{ width: 70, py: 0, fontSize: 10, textTransform: 'none' }}
                onClick={handleClick}
              >
                Report Issue
              </Button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Stack p={2}>
                  <Form
                    loading={loading}
                    onSubmit={data => onSubmit(data)}
                    buttonText="Submit"
                    inputs={[
                      {
                        name: 'issueType',
                        label: 'Issue Type',
                        category: FormInputCategory.SELECT,
                        options: [
                          { label: 'Segmentation', value: 'segmentation' },
                          { label: 'Exposure', value: 'exposure' },
                          { label: 'Blurriness', value: 'blurriness' },
                          { label: 'Distance', value: 'distance' },
                          { label: 'Shadow', value: 'shadow' },
                          { label: 'Other', value: 'other' },
                        ],
                      },
                      {
                        name: 'note',
                        label: 'Notes (optional)',
                        category: FormInputCategory.TEXT_INPUT,
                        placeholder: 'Please add notes ...',
                        multiline: true,
                      },
                    ]}
                    buttonFullWidth={false}
                    buttonSize="small"
                  />
                </Stack>
              </Popover>
            </Stack>
            <Stack overflow="auto">
              <Typography fontSize={12}>{getImageNameFromURL(url)}</Typography>
            </Stack>
          </Stack>
        }
      />
    </ImageListItem>
  );
};

export default FrameImage;
