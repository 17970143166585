import {
  ReactNode,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  CircularProgress,
  Slide,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { COPY_RIGHT, DEFAULT_MESSAGE } from '../utils/Constants';
import { useDocumentTitle } from '../hooks';
import NavBar from './Nav';
import { Message, Org } from '../utils/Types';
import { SnackbarContext, ThemeOveridesContext } from '../utils/Contexts';
import { useAppSelector } from '../redux/hooks';
import { selectOrg } from '../redux/reducers/orgSlice';
import { getPluginFromUrl } from '../utils/Helper';

const Footer = () => (
  <Stack component="footer" mt={2} textAlign="center">
    <Typography>{COPY_RIGHT}</Typography>
  </Stack>
);

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface PageProps {
  title: string;
  children?: ReactNode;
  hideNav?: boolean;
  hideFooter?: boolean;
  contentPadding?: number;
}

const PageContainer = ({
  title,
  children,
  hideNav,
  hideFooter,
  contentPadding,
}: PageProps) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState(DEFAULT_MESSAGE);
  const [org, setOrg] = useState<Org>();

  const location = useLocation();
  const plugin = getPluginFromUrl(location.pathname);

  useDocumentTitle(plugin ? `${title} | ${plugin}` : title);

  const themeOverides = useContext(ThemeOveridesContext);
  const orgFromRedux = useAppSelector(selectOrg);

  useEffect(() => {
    loadTheme();
  }, []);

  const loadTheme = () => {
    const orgFromStorageString = localStorage.getItem('org');

    if (orgFromStorageString) {
      const orgFromStorage = JSON.parse(orgFromStorageString) as Org;

      themeOverides.apply(orgFromStorage?.theme);
      setOrg(orgFromStorage);
    } else if (orgFromRedux) {
      setOrg(orgFromRedux);
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const snackbar = useMemo(
    () => ({
      open: (m: Message) => {
        setMessage(m);
        setOpenSnackbar(true);
      },
      close: () => setOpenSnackbar(false),
    }),
    []
  );

  return (
    <>
      <SnackbarContext.Provider value={snackbar}>
        <Box sx={{ bgcolor: 'text.primary' }}>
          {hideNav ? (
            <Box>
              {children}
              {!hideFooter && <Footer />}
            </Box>
          ) : org ? (
            <NavBar title={title} contentPadding={contentPadding}>
              {children}
            </NavBar>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                height: '50vh',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size={60} />
            </Box>
          )}
          {message?.text && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              TransitionComponent={Slide}
              autoHideDuration={5000}
              open={openSnackbar}
              onClose={handleCloseSnackbar}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={message.type}
                sx={{ width: '100%' }}
              >
                {message.text}
              </Alert>
            </Snackbar>
          )}
        </Box>
      </SnackbarContext.Provider>
    </>
  );
};

export default PageContainer;
