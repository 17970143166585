import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { Org } from '../../utils/Types';

export interface OrgState {
  value: Org;
  status: 'idle' | 'loading' | 'failed';
}

let initialValue = {};
try {
  const initialValueString = localStorage.getItem('org');
  if (initialValueString) {
    initialValue = JSON.parse(initialValueString);
  }
} catch (error: any) {
  console.log(error.message + ' in localStorage org');
}

const initialState: OrgState = {
  value: initialValue as Org,
  status: 'idle',
};

export const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    setOrg: (state, action) => {
      state.value = action.payload.payload;
    },
  },
});

export const { setOrg } = orgSlice.actions;

export const selectOrg = (state: RootState) => state.org.value;

export default orgSlice.reducer;
