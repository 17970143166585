import { useEffect, useState } from 'react';
import { Popover, Stack, Typography } from '@mui/material';
import moment, { Moment } from 'moment';

import {
  Auth,
  ChartType,
  DateFormatServer,
  LooseObject,
  Org,
} from '../../../utils/Types';
import { Chart } from '../../../components';
import { assignIds, getDateFormat, postToServer } from '../../../utils/Helper';
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { HISTOGRAM_Y_AXIS_TITLE } from '../../../utils/Constants';

const GraderHistogram = ({
  data,
  loading,
  minHeight,
  auth,
  org,
  snackbar,
}: {
  data: LooseObject[];
  loading: boolean;
  minHeight?: number;
  auth: Auth;
  org: Org;
  snackbar: any;
}) => {
  const allDateStrings = data
    ?.map(i => i.photo_date)
    ?.sort(
      (a: string, b: string) =>
        moment(b, DateFormatServer.SHORT).valueOf() -
        moment(a, DateFormatServer.SHORT).valueOf()
    );

  const [loadingImages, setLoadingImages] = useState(false);

  const [date, setDate] = useState<Moment | null>(moment());
  const [allImages, setAllImages] = useState<LooseObject[]>([]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    let defaultDate = moment();

    if (allDateStrings?.[0]) {
      defaultDate = moment(allDateStrings?.[0], DateFormatServer.SHORT);
      setDate(defaultDate);

      loadAllImages(defaultDate);
    }
  }, [data]);

  const loadAllImages = (forDate: Moment | null) => {
    if (forDate && auth?.token) {
      setLoadingImages(true);
      postToServer({
        action: 'camera/ListOfImages',
        params: {
          dateFrom: forDate.format(DateFormatServer.SHORT),
          includeImage: true,
        },
        token: auth.token,
      }).then(response => {
        setLoadingImages(false);
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const imagesFromServer: LooseObject[] = assignIds(
              response.serverData as LooseObject[]
            );

            if (org?.isUSA) {
              imagesFromServer.forEach(i => {
                i.traits?.forEach((t: any) => {
                  t.label = t.label?.replaceAll('AUS', 'BMS');
                });
              });
            }

            if (imagesFromServer?.length > 0) {
              setAllImages(imagesFromServer);
            }
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const disableDates = (date: Moment) => {
    const currentDateString = date.format(DateFormatServer.SHORT);
    const shouldDisable = !allDateStrings?.includes(currentDateString);
    return shouldDisable;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Chart
      type={ChartType.HISTOGRAM}
      title="Number Of Bodies Collected Daily"
      headerBeforeLoading={
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">{`Show data for ${date?.format(
            getDateFormat(org, 'short')
          )}`}</Typography>
          <Stack>
            <Typography
              onClick={handleClick}
              variant="body2"
              sx={{
                textDecoration: 'underline',
                m: 1,
                ':hover': { color: 'grey.700', cursor: 'pointer' },
              }}
            >
              Change Date
            </Typography>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateCalendar
                  disableFuture
                  value={date}
                  onChange={(v: Moment | null) => {
                    setDate(v);
                    loadAllImages(v);
                  }}
                  shouldDisableDate={disableDates}
                  slots={{
                    day: ({ day, ...rest }) => (
                      <PickersDay
                        day={day}
                        {...rest}
                        selected={
                          day.format(getDateFormat(org, 'short')) ===
                          date?.format(getDateFormat(org, 'short'))
                        }
                        sx={{
                          border: allDateStrings.includes(
                            day.format(DateFormatServer.SHORT)
                          )
                            ? 1
                            : 0,
                          borderColor: theme => theme.palette.primary.main,
                        }}
                      />
                    ),
                  }}
                />
              </LocalizationProvider>
            </Popover>
          </Stack>
        </Stack>
      }
      data={{
        xTitle: 'Grader',
        yTitle: HISTOGRAM_Y_AXIS_TITLE,
        data: [
          {
            x: allImages?.map(
              i => i.traits?.find((j: any) => j.key === 'user')?.value
            ),
            type: 'histogram',
          },
        ],
      }}
      loading={loading || loadingImages}
    />
  );
};

export default GraderHistogram;
