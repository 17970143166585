import { useContext, useEffect, useState } from 'react';
import { Dialog, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add, Edit } from '@mui/icons-material';
import moment from 'moment';

import { Table } from '../../../components';
import { FormData } from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { getDateFormat, logout, postToServer } from '../../../utils/Helper';
import { LooseObject } from '../../../utils/Types';
import EditPlugin from './EditPlugin';
import AddPlugin from './AddPlugin';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [plugins, setPlugins] = useState<LooseObject[]>([]);
  const [tableRows, setTableRows] = useState<LooseObject[]>([]);
  const [itemForDialog, setItemForDialog] = useState<LooseObject>({});
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'administration/GetPlugins',
        params: {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            setPlugins(response.serverData as LooseObject[]);
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const handleAddPlugin = ({ name, description }: FormData) => {
    if (name && description) {
      setLoading(true);
      if (auth?.token) {
        const params = { name, description };
        postToServer({
          action: 'administration/AddPlugin',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogAdd(!openDialogAdd);
              setPlugins(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setLoading(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const handleEditPlugin = ({ name, description }: FormData) => {
    if (itemForDialog.id && name && description) {
      setLoading(true);
      if (auth?.token) {
        const params = {
          id: itemForDialog.id,
          name,
          description,
        };
        postToServer({
          action: 'administration/EditPlugin',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogEdit(!openDialogEdit);
              setPlugins(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setLoading(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const columns = [
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      sortingFn: 'datetime',
      size: 120,
    },
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'description', header: 'Description' },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      sortingFn: 'datetime',
      size: 120,
    },
    { accessorKey: 'actions', header: 'Actions' },
  ];

  useEffect(() => {
    const rows = plugins.map(i => ({
      ...i,
      createdAt: moment(i.createdAt).format(getDateFormat(org, 'default')),
      updatedAt: moment(i.updatedAt).format(getDateFormat(org, 'default')),
      actions: (
        <Stack direction="row" spacing={1}>
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              color="info"
              onClick={() => {
                setItemForDialog(i);
                setOpenDialogEdit(true);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    }));
    setTableRows(rows);
  }, [plugins]);

  return (
    <Stack>
      <Stack alignItems="flex-end">
        <Tooltip arrow title="Create New Plugin">
          <IconButton onClick={() => setOpenDialogAdd(true)} size="large">
            <Add color="primary" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Table
        renderTopToolbarCustomActions={() => (
          <Typography variant="h6">All Plugins in The System</Typography>
        )}
        columns={columns}
        data={tableRows}
        state={{ isLoading: loading }}
        enableColumnActions={false}
      />
      <Dialog open={openDialogAdd}>
        <AddPlugin
          onConfirm={handleAddPlugin}
          onCancel={() => setOpenDialogAdd(false)}
          loading={loading}
          existings={plugins.map(i => i.name)}
        />
      </Dialog>
      <Dialog open={openDialogEdit}>
        <EditPlugin
          onCancel={() => setOpenDialogEdit(false)}
          onConfirm={handleEditPlugin}
          loading={loading}
          item={itemForDialog}
        />
      </Dialog>
    </Stack>
  );
};

export default Page;
