import moment from 'moment';
import { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import _ from 'lodash';

import {
  Chart,
  Dialog,
  DialogWrapperInfo,
  NoDataView,
} from '../../../components';
import {
  Auth,
  ChartPoint,
  ChartType,
  DateFormatServer,
  LooseObject,
  Org,
} from '../../../utils/Types';
import { isNotEmpty } from '../../../utils/Helper';
import { MISHIMA_GRADES } from '.';
import LotDetails from './LotDetails';

type Props = {
  lots: LooseObject[];
  averageUSDAScoreCompanyPast: number | undefined;
  loadLots: () => void;
  averageDailyIncreaseInUSDAScoreCompanyOverall: number | undefined;
  loading: boolean;
  auth: Auth;
  org: Org;
  snackbar: any;
};

const LotsInsights = ({
  lots,
  averageUSDAScoreCompanyPast,
  loadLots,
  averageDailyIncreaseInUSDAScoreCompanyOverall,
  loading,
  auth,
  org,
  snackbar,
}: Props) => {
  const [openDialogLotDetails, setOpenDialogLotDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>();

  lots.forEach(lot => {
    let sumOfGrades = 0;
    MISHIMA_GRADES.forEach(k => {
      if (isNotEmpty(lot['COUNT_OF_' + k])) {
        sumOfGrades += lot['COUNT_OF_' + k];
      }
    });
    if (sumOfGrades > 0) {
      MISHIMA_GRADES.forEach(k => {
        lot['PERCENTAGE_OF_' + k] = (
          ((lot['COUNT_OF_' + k] || 0) * 100) /
          sumOfGrades
        ).toFixed(2);
      });
    }
  });

  // const lotsPast = lots.filter(
  //   i =>
  //     isNotEmpty(i.AVG_USDA_SCORE) &&
  //     isNotEmpty(i.HEADCOUNT_SCANNED) &&
  //     isNotEmpty(i.SHIPPING_DATE) &&
  //     moment(i.SHIPPING_DATE, DateFormatServer.SHORT) < moment().startOf('day')
  // );
  // const usdaAveragePast =
  //   lotsPast.length > 0
  //     ? Number.parseFloat(
  //         (
  //           _.sum(lotsPast.map(i => i.AVG_USDA_SCORE * i.HEADCOUNT_SCANNED)) /
  //           _.sumBy(lotsPast, 'HEADCOUNT_SCANNED')
  //         ).toFixed(2)
  //       )
  //     : undefined;

  const lotsOpen = lots.filter(
    i =>
      isNotEmpty(i.AVG_USDA_SCORE) &&
      isNotEmpty(i.HEADCOUNT_SCANNED) &&
      (!isNotEmpty(i.SHIPPING_DATE) ||
        (isNotEmpty(i.SHIPPING_DATE) &&
          moment(i.SHIPPING_DATE, DateFormatServer.SHORT) >=
            moment().startOf('day')))
  );
  // const usdaAverageOpen =
  //   lotsOpen.length > 0
  //     ? Number.parseFloat(
  //         (
  //           _.sum(lotsOpen.map(i => i.AVG_USDA_SCORE * i.HEADCOUNT_SCANNED)) /
  //           _.sumBy(lotsOpen, 'HEADCOUNT_SCANNED')
  //         ).toFixed(2)
  //       )
  //     : undefined;

  const handleClickPoint = async ({ x, y }: ChartPoint) => {
    const item = lotsOpen.find(i => i.LOT === x && i.AVG_USDA_SCORE === y);
    if (item) {
      setCurrentItem(item);
      setOpenDialogLotDetails(true);
    }
  };

  return (
    <Stack p={2} bgcolor="grey.100" minWidth="50vw">
      {lotsOpen.length > 0 ? (
        <Stack bgcolor="white" py={2} spacing={2}>
          <Chart
            type={ChartType.SCATTER_PLOT}
            title={
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h6" textAlign="center">
                  Average USDA Score of Open Lots
                </Typography>
              </Stack>
            }
            data={{
              xTitle: 'Lot',
              yTitle: 'Average USDA Score',
              xAxisType: 'category',
              data: [
                {
                  x: lotsOpen.map(i => i.LOT),
                  y: lotsOpen.map(i => i.AVG_USDA_SCORE),
                  name: 'Lot',
                  hovertemplate: `<i>%{xaxis.title.text}</i>: %{x} <br /><i>%{yaxis.title.text}</i>: %{y} <extra></extra>`,
                },
                // {
                //   x: lotsOpen.map(i => i.LOT),
                //   y: Array.from(Array(lotsOpen.length).keys()).map(
                //     () => averageUSDAScoreCompanyPast
                //   ),
                //   name: 'Company Past Average',
                //   mode: 'lines',
                //   line: { dash: 'dash' },
                //   hovertemplate: `<i>Company Past Average USDA Score</i>: %{y} <extra></extra>`,
                // },
                // {
                //   x: lotsOpen.map(i => i.LOT),
                //   y: Array.from(Array(lotsOpen.length).keys()).map(
                //     () => usdaAveragePast
                //   ),
                //   name: 'Current Yard Past Average',
                //   mode: 'lines',
                //   line: { dash: 'dash' },
                //   hovertemplate: `<i>Current Yard Past Average USDA Score</i>: %{y} <extra></extra>`,
                // },
                // {
                //   x: lotsOpen.map(i => i.LOT),
                //   y: Array.from(Array(lotsOpen.length).keys()).map(
                //     () => usdaAverageOpen
                //   ),
                //   name: 'Current Yard Open Average',
                //   mode: 'lines',
                //   line: { dash: 'dash' },
                //   hovertemplate: `<i>Current Yard Open Average USDA Score</i>: %{y} <extra></extra>`,
                // },
              ],
            }}
            loading={loading}
            handleClick={handleClickPoint}
          />
          {auth?.orgOwner === 'mishima' && (
            <Chart
              type={ChartType.BAR_CHART}
              title={
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" textAlign="center">
                    Composition of Lot
                  </Typography>
                </Stack>
              }
              data={{
                xTitle: 'Lot',
                yTitle: '% Of Grade',
                xAxisType: 'category',
                data: _.reverse([...MISHIMA_GRADES]).map(k => ({
                  x: lotsOpen.map(i => i.LOT),
                  y: lotsOpen.map(i => i['PERCENTAGE_OF_' + k]),
                  customdata: lotsOpen.map(i => i['COUNT_OF_' + k]),
                  name: _.startCase(k.toLowerCase()),
                  nameX: 'Lot',
                  hovertemplate: `<i>%{xaxis.title.text}</i>: %{x} <br /><i>%{data.name}</i>: %{customdata} (%{y}%) <extra></extra>`,
                })),
              }}
              loading={loading}
              barMode="stack"
              chartTraceColors={[]}
            />
          )}

          <Dialog
            open={openDialogLotDetails}
            onClose={() => setOpenDialogLotDetails(false)}
            fullScreen
            isTransparent
          >
            <DialogWrapperInfo onClose={() => setOpenDialogLotDetails(false)}>
              {currentItem && (
                <LotDetails
                  lot={currentItem}
                  loadLots={loadLots}
                  averageDailyIncreaseInUSDAScoreCompanyOverall={
                    averageDailyIncreaseInUSDAScoreCompanyOverall
                  }
                  auth={auth}
                  snackbar={snackbar}
                  org={org}
                />
              )}
            </DialogWrapperInfo>
          </Dialog>
        </Stack>
      ) : (
        <NoDataView />
      )}
    </Stack>
  );
};

export default LotsInsights;
