import { LooseObject, Org, User } from '../../../utils/Types';
import { DialogWrapperInfo } from '../../../components';
import ImageItem from './ImageItem';

type Props = {
  user?: User;
  org: Org;
  onClose: () => void;
  data: LooseObject;
  hideReportImageIssue?: boolean;
  minWidth?: any;
  maxWidth?: any;
  isImageUrlProvided?: boolean;
};

const ImageInfo = ({
  user,
  org,
  onClose,
  data,
  hideReportImageIssue,
  minWidth,
  maxWidth,
  isImageUrlProvided,
}: Props) => {
  return (
    <DialogWrapperInfo onClose={onClose}>
      <ImageItem
        user={user}
        item={data}
        org={org}
        hideReportImageIssue={hideReportImageIssue}
        isImageUrlProvided={isImageUrlProvided}
        minWidth={minWidth || `min(calc(100vw - 32px), calc(87vh) * ${9 / 16})`}
        maxWidth={maxWidth || `min(calc(100vw - 28px), calc(89vh) * ${9 / 16})`}
        traitsOnSide
      />
    </DialogWrapperInfo>
  );
};

export default ImageInfo;
