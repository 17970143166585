import { Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { Dialog, Table } from '../../../components';
import ImageInfo from '../../camera/SummaryOfImages/ImageInfo';
import { Auth, LooseObject, Org } from '../../../utils/Types';
import { postToServer } from '../../../utils/Helper';

const CarcaseTable = ({
  date,
  columns,
  rows,
  loading,
  auth,
  org,
}: {
  date: string;
  columns: any[];
  rows: any[];
  loading: boolean;
  auth: Auth;
  org: Org;
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>({});

  const [images, setImages] = useState<LooseObject[]>([]);

  useEffect(() => {
    if (date === '2023-06-29') {
      fetchData();
    }
  }, [date]);

  const fetchData = _.debounce(async () => {
    if (auth?.token) {
      postToServer({
        action: 'camera/ListOfImages',
        params: {
          dateFrom: '2023-06-30',
          includeImage: true,
        },
        token: auth.token,
      }).then(response => {
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setImages(serverData);
          }
        }
      });
    }
  }, 500);

  return (
    <Stack>
      <Table
        title="List Of Carcases"
        columns={
          columns.map(i => ({
            accessorKey: i.name,
            header: i.label,
          }))!
        }
        columnDataTypes={columns}
        data={rows}
        state={{
          isLoading: loading || (date === '2023-06-29' && images.length === 0),
        }}
        enableColumnActions={false}
        // enableBottomToolbar={false}
        // enablePagination={false}
        muiTableContainerProps={{
          sx: { maxHeight: '820px' },
        }}
        // enableRowNumbers
        // rowNumberDisplayMode="static"
        initialState={{
          density: 'compact',
          pagination: { pageSize: 20, pageIndex: 0 },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            if (images && images.length > 0) {
              const image = images.find(
                i => i.barcode === row.original.MEQCAMERA_BARCODE
              );
              if (image) {
                setCurrentItem(image);
              } else {
                setCurrentItem({});
              }

              setOpenDialog(true);
            }
          },
        })}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: 'rgba(0,0,0,0.5)',
        }}
        fullScreen
        isTransparent
      >
        <ImageInfo
          org={org}
          onClose={() => {
            setOpenDialog(false);
          }}
          data={currentItem}
        />
      </Dialog>
    </Stack>
  );
};

export default CarcaseTable;
