import { useContext, useEffect, useRef, useState } from 'react';
import { Chip, Popover, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  FullscreenControl,
  GeoJSONSource,
  // GeolocateControl,
  Layer,
  MapRef,
  NavigationControl,
  ScaleControl,
  Source,
  ViewStateChangeEvent,
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import type { FeatureCollection, Point } from 'geojson';
import { Map } from 'react-map-gl';
import _ from 'lodash';

import { SnackbarContext } from '../../../utils/Contexts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import {
  logout,
  optimiseQueryResult,
  postToServer,
  parseArrayToFeatureCollection,
  isNotEmpty,
  applyUpperCaseTerms,
  conditionValueToString,
  filterByConditions,
  // getGeoSqlConditionsWithBounds,
} from '../../../utils/Helper';
import {
  EMPTY_FEATURE_COLLECTION,
  MAP_STYLE,
  QUERY_DATA_SOURCE_ENDPOINT,
} from '../../../utils/Constants';
import {
  Boundary,
  Conditions,
  Coordinate,
  LooseObject,
  QueryResult,
  QueryType,
} from '../../../utils/Types';
import { Button, Dialog, DialogWrapperInfo } from '../../../components';
import {
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
} from './Layers';
import Analysis from './Analysis';
import Filter from './Filter';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false); // eslint-disable-line

  const [data, setData] = useState<QueryResult>();
  const [dataSelected, setDataSelected] = useState<LooseObject[]>([]);
  const [conditions, setConditions] = useState<Conditions>({});
  const [mapView, setMapView] = useState<{
    center: Coordinate;
    bounds: Boundary;
  }>({
    center: {
      longitude: 148.327059977,
      latitude: -34.81287779,
    },
    bounds: {
      ne: { longitude: 146.95926904030517, latitude: -34.67879798613632 },
      sw: { longitude: 146.02680444069506, latitude: -35.62021105795605 },
    },
  });

  const [openDialog, setOpenDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const org = useAppSelector(selectOrg); // eslint-disable-line
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    debouncedFetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => debouncedFetchData.cancel();
    // }, [mapView]);
  }, []);

  const fetchData = async () => {
    if (auth?.token) {
      setLoading(true);
      await postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ORDINARY_QUERY,
          view: 'GMP_INSIGHTS_CARCASE',
          sqlConditions: '1 = 1 LIMIT 10000;',
          // sqlConditions: getGeoSqlConditions({
          //   center: centerOfMap,
          //   distance_km: 100,
          // }),
          // sqlConditions: getGeoSqlConditionsWithBounds({
          //   bounds: mapView.bounds,
          // }),
        },
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            setData(optimiseQueryResult(serverData));
          } else {
            snackbar.open(response.message);
          }
        }
      });
      setLoading(false);
    }
  };

  const debouncedFetchData = _.debounce(fetchData, 500); // Debounce getData function with a delay of 500ms

  const rowsToDisplay = filterByConditions({ data: data?.rows, conditions });

  const geojson: FeatureCollection<Point> =
    rowsToDisplay.length > 0
      ? parseArrayToFeatureCollection(rowsToDisplay, {
          longitudeKey: 'AVGLON',
          latitudeKey: 'AVGLAT',
        })
      : EMPTY_FEATURE_COLLECTION;

  const onClick = (event: any) => {
    const feature = event.features[0];
    const clusterId = feature?.properties.cluster_id;
    const mapboxSource = mapRef.current?.getSource('my-data') as GeoJSONSource;

    if (isNotEmpty(clusterId) && mapboxSource) {
      mapboxSource.getClusterLeaves(
        clusterId,
        Number.MAX_VALUE,
        0,
        (err, features) => {
          if (!err && features) {
            setDataSelected(features.map(i => i.properties) as LooseObject[]);
          }
          setOpenDialog(true);
        }
      );
    }
  };

  const handleFilter = (v: any) => {
    if (v) {
      const newConditions: LooseObject = {};

      data?.columns?.forEach(column => {
        if (
          isNotEmpty(v[`${column.name}From`]) ||
          isNotEmpty(v[`${column.name}To`])
        ) {
          newConditions[column.name] = {
            value: {
              from: v[`${column.name}From`],
              to: v[`${column.name}To`],
            },
            type: column.type,
          };
        } else if (isNotEmpty(v[column.name])) {
          if (Array.isArray(v[column.name])) {
            if (v[column.name].length > 0) {
              newConditions[column.name] = {
                value: v[column.name],
                type: column.type,
              };
            }
          } else {
            newConditions[column.name] = {
              value: v[column.name],
              type: column.type,
            };
          }
        }

        setConditions(newConditions);
      });
    }
    handleClose();
  };

  const handleDeleteCondition = (k: string) => {
    setConditions(prev => {
      const newConditions = { ...prev };
      delete newConditions[k];
      return newConditions;
    });
  };

  const handleOnMove = (e: ViewStateChangeEvent) => {
    const currentMap = mapRef.current;

    const newMapCenter = mapRef.current?.getCenter();
    const newMapBounds = currentMap?.getBounds();
    if (newMapCenter && newMapBounds) {
      setMapView({
        center: {
          longitude: newMapCenter.lng,
          latitude: newMapCenter.lat,
        },
        bounds: {
          ne: {
            longitude: newMapBounds._ne.lng,
            latitude: newMapBounds._ne.lat,
          },
          sw: {
            longitude: newMapBounds._sw.lng,
            latitude: newMapBounds._sw.lat,
          },
        },
      });
    }
  };

  return (
    <Stack width="100%" height="100%">
      <Stack
        py={2}
        alignItems="flex-start"
        justifyContent="space-between"
        direction="row"
        spacing={1}
      >
        <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
          {Object.keys(conditions).map(k => (
            <Stack key={k}>
              <Chip
                label={`${applyUpperCaseTerms(
                  _.startCase(k.toLowerCase())
                )}: ${conditionValueToString(conditions[k]!)}`}
                onDelete={() => handleDeleteCondition(k)}
                size="small"
              />
            </Stack>
          ))}
        </Stack>
        <Button
          title="Filter"
          onClick={handleClick}
          sx={{ width: 80 }}
          size="small"
          variant="outlined"
        />
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Filter
            conditions={conditions}
            columns={data?.columns}
            excludeColumns={['AVGLON', 'AVGLAT']}
            rows={data?.rows}
            handleSubmit={handleFilter}
          />
        </Popover>
      </Stack>
      <Map
        mapStyle={MAP_STYLE}
        attributionControl={false}
        initialViewState={{
          longitude: mapView.center.longitude,
          latitude: mapView.center.latitude,
          zoom: 7,
        }}
        interactiveLayerIds={[clusterLayer.id!]}
        onClick={onClick}
        ref={mapRef}
        onMove={handleOnMove}
        minZoom={3}
      >
        <Source id="my-data" type="geojson" data={geojson} cluster>
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
        <NavigationControl position="bottom-right" />
        <FullscreenControl />
        {/* <GeolocateControl /> */}
        <ScaleControl />
      </Map>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        isTransparent
      >
        <DialogWrapperInfo onClose={() => setOpenDialog(false)}>
          <Analysis columns={data?.columns} rows={dataSelected} />
        </DialogWrapperInfo>
      </Dialog>
    </Stack>
  );
};

export default Page;
