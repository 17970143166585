import { useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  Auth,
  ChartTrace,
  ChartType,
  Conditions,
  FormData,
  InsightItemType,
  LooseObject,
  Org,
  SortableItem,
} from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  generateDataPoolId,
  isNotEmpty,
  logout,
  postToServer,
  sortArrayByAnotherArray,
} from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import {
  SortableContainer,
  Dialog,
  FilterConditions2,
  Skeleton,
  DialogWrapper,
} from '../../../components';
import AddInsightItem from './AddInsightItem';
import EditInsightItem from './EditInsightItem';
import InsightItemChart from './InsightItemChart';
import InsightItemTable from './InsightItemTable';
import { selectDataPool } from '../../../redux/reducers/dataPoolSlice';
import { updateDataPool } from '../../../utils/Helper2';
import {
  AVAILABEL_DATA_SOURCE,
  QUERY_DATA_SOURCE_ENDPOINT,
} from '../../../utils/Constants';

const ViewInsight = ({
  initialInsight,
  updateInsights,
  availableQueries,
  onCancel,
  isWritable,
  auth,
  org,
}: {
  initialInsight: LooseObject;
  updateInsights: (v: LooseObject[]) => void;
  availableQueries: LooseObject[];
  onCancel: () => void;
  isWritable?: boolean;
  auth: Auth;
  org: Org;
}) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [insight, setInsight] = useState<LooseObject>(initialInsight);
  const [insightItems, setInsightItems] = useState<LooseObject[]>([]);
  const [conditions, setConditions] = useState<Conditions>({});
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [currentInsightItem, setCurrentInsightItem] = useState<LooseObject>();

  const dataPool = useAppSelector(selectDataPool);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setInsightItems(
      sortArrayByAnotherArray(insight.items, 'id', insight.sortedIds || [])
    );
  }, [insight]);

  const handleAdd = ({
    title,
    description,
    itemType,
    dataSourceKey,
    dataSourceParams,
    chartType,
    chartTraces,
    isQuery,
  }: FormData) => {
    if (insight && title && itemType && dataSourceKey) {
      // add missing x axis
      if (chartTraces && chartTraces.length > 1) {
        chartTraces.forEach((trace: ChartTrace) => {
          if (!trace.x) {
            trace.x = chartTraces[0].x;
          }
        });
      }
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights2/AddInsightItem',
          params: {
            title,
            description,
            insightId: insight.id,
            itemType,
            dataSourceKey,
            dataSourceParams,
            chartType,
            chartTraces,
            isQuery,
          },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            if (response.message.type === 'success' && response.serverData) {
              const serverData = response.serverData as LooseObject[];
              updateInsights(serverData);
              const updatedInsight = serverData.find(i => i.id === insight.id);
              if (updatedInsight) {
                setInsight(updatedInsight);
              }
              setOpenDialogAdd(false);
            } else {
              snackbar.open(response.message);
            }
            setLoading(false);
          }
        });
      }
    }
  };

  const handleDone = (sortedIds: (string | number)[]) => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'insights2/UpdateInsight',
        params: { id: insight.id, sortedIds },
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            updateInsights(serverData);
            const updatedInsight = serverData.find(i => i.id === insight.id);
            if (updatedInsight) {
              setInsight(updatedInsight);
            }
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  const handleEdit = ({
    id,
    attribute,
    title,
    description,
    xBinsSize,
    trendlines,
  }: FormData) => {
    if (id && insight && title && attribute) {
      // add trace settings
      if (currentInsightItem?.chartType === ChartType.HISTOGRAM) {
        currentInsightItem.chartTraces.forEach((trace: ChartTrace) => {
          trace.settings = {
            xbins: {
              size: xBinsSize,
            },
          };
        });
      }
      if (currentInsightItem?.chartType === ChartType.SCATTER_PLOT) {
        currentInsightItem.chartTraces.forEach((trace: ChartTrace) => {
          trace.settings = {
            trendlines,
          };
        });
      }
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights2/EditInsightItem',
          params: {
            id,
            title,
            description,
            insightId: insight.id,
            chartTraces: currentInsightItem?.chartTraces,
          },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            if (response.message.type === 'success' && response.serverData) {
              const serverData = response.serverData as LooseObject[];
              updateInsights(serverData);
              const updatedInsight = serverData.find(i => i.id === insight.id);
              if (updatedInsight) {
                setInsight(updatedInsight);
              }
              setOpenDialogEdit(false);
            } else {
              snackbar.open(response.message);
            }
            setLoading(false);
          }
        });
      }
    }
  };

  const sortableItems: SortableItem[] = [];

  insightItems.forEach(async item => {
    let dataPoolItem = dataPool.find(
      i =>
        i.id ===
        generateDataPoolId({
          dataSourceKey: item.dataSourceKey,
          dataSourceParams: item.dataSourceParams,
        })
    );

    sortableItems.push({
      id: item.id,
      component: dataPoolItem ? (
        item.itemType === InsightItemType.CHART ? (
          <InsightItemChart
            loading={loading}
            item={item}
            dataPoolItem={dataPoolItem}
            conditions={conditions}
            updateConditions={setConditions}
            handleSettings={insightItem => {
              setCurrentInsightItem(insightItem);
              setOpenDialogEdit(true);
            }}
          />
        ) : item.itemType === InsightItemType.DATA_TABLE ? (
          <InsightItemTable
            loading={loading}
            item={item}
            dataPoolItem={dataPoolItem}
            conditions={conditions}
            updateConditions={setConditions}
            org={org}
          />
        ) : (
          <Typography>No item type matched.</Typography>
        )
      ) : (
        <Skeleton />
      ),
    });

    if (!dataPoolItem) {
      const query = availableQueries.find(
        i => i.id.toString() === item.dataSourceKey
      );
      const dataSource = AVAILABEL_DATA_SOURCE.find(
        i => i.key === item.dataSourceKey
      );
      await updateDataPool({
        dataPool,
        dataSource: {
          key: item.dataSourceKey,
          label: query?.name || dataSource?.label,
          endpoint: dataSource?.endpoint || QUERY_DATA_SOURCE_ENDPOINT,
          query,
          isNoParamsPermitted: true,
          excludeAttributes: [],
        },
        auth,
        dispatch,
      }).then(newDataPool => {
        if (newDataPool) {
          dataPoolItem = newDataPool.find(
            i =>
              i.id ===
              generateDataPoolId({
                dataSourceKey: item.dataSourceKey,
                dataSourceParams: item.dataSourceParams,
              })
          );
        }
      });
    }
  });

  return (
    <DialogWrapper
      title={insight.title}
      onCancel={onCancel}
      hideCancelButton
      hideConfirmButton
    >
      {loading ? (
        <Skeleton />
      ) : (
        <Stack>
          <Stack spacing={1}>
            <Stack>
              <Typography>{insight.description}</Typography>
            </Stack>
            <FilterConditions2
              conditions={conditions}
              onUpdate={v => {
                if (v.key) {
                  setConditions(prev => {
                    const newConditions = { ...prev };
                    if (
                      isNotEmpty(v[`${v.key}From`]) &&
                      isNotEmpty(v[`${v.key}To`])
                    ) {
                      newConditions[v.key] = {
                        value: {
                          from: v[`${v.key}From`],
                          to: v[`${v.key}To`],
                        },
                        type: v.type,
                      };
                    } else {
                      newConditions[v.key] = v[`${v.key}`];
                    }
                    return newConditions;
                  });
                }
              }}
              onClear={(k: string) =>
                setConditions(prev => {
                  const newConditions = { ...prev };
                  newConditions[k] = null;
                  return newConditions;
                })
              }
              onClearAll={() => setConditions({})}
              org={org}
            />
            <SortableContainer
              items={sortableItems}
              handleAdd={() => setOpenDialogAdd(true)}
              handleDone={handleDone}
              isWritable={isWritable}
            />
            <Dialog
              open={openDialogAdd}
              onClose={() => setOpenDialogAdd(false)}
            >
              <AddInsightItem
                availableQueries={availableQueries}
                onCancel={() => setOpenDialogAdd(false)}
                handleSubmit={handleAdd}
                org={org}
                conditions={conditions}
              />
            </Dialog>
            <Dialog
              open={openDialogEdit}
              onClose={() => setOpenDialogEdit(false)}
            >
              {currentInsightItem && (
                <EditInsightItem
                  availableQueries={availableQueries}
                  insightItem={currentInsightItem}
                  onCancel={() => setOpenDialogEdit(false)}
                  handleSubmit={handleEdit}
                  org={org}
                  conditions={conditions}
                />
              )}
            </Dialog>
          </Stack>
        </Stack>
      )}
    </DialogWrapper>
  );
};

export default ViewInsight;
