import { useEffect, useState } from 'react';
import { ButtonGroup, Popover, Stack, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import _ from 'lodash';
import moment from 'moment';

import {
  Auth,
  LooseObject,
  FormData,
  FormInputCategory,
  Org,
  QueryType,
  QueryResult,
  DateFormatServer,
} from '../../../utils/Types';
import { getDateFormat, postToServer } from '../../../utils/Helper';
import LotDetailsTables from './LotDetailsTables';
import { Button, Form, Skeleton } from '../../../components';
import ButtonTab, { ButtonTabTitle } from './ButtonTab';
import LotDetailsCharts from './LotDetailsCharts';
import { QUERY_DATA_SOURCE_ENDPOINT } from '../../../utils/Constants';

type Props = {
  lot: LooseObject;
  loadLots: () => void;
  averageDailyIncreaseInUSDAScoreCompanyOverall: number | undefined;
  auth: Auth;
  snackbar: any;
  org: Org;
};

const LotDetails = ({
  lot,
  loadLots,
  averageDailyIncreaseInUSDAScoreCompanyOverall,
  auth,
  snackbar,
  org,
}: Props) => {
  const [loadingAnimals, setLoadingAnimals] = useState(false);
  const [loadingScans, setLoadingScans] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const [animals, setAnimals] = useState<LooseObject>({});
  const [scans, setScans] = useState<QueryResult>();

  const [currentView, setCurrentView] = useState<ButtonTabTitle>('Tables');

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickShippingButton = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    fetchAnimals();
    fetchScans();
  }, [lot]);

  const fetchAnimals = async () => {
    if (auth?.token) {
      setLoadingAnimals(true);
      await postToServer({
        action: 'feedyard/GetAnimalsWithGroupedScans',
        params: { yard: lot.FEEDLOT, lot: lot.LOT },
        token: auth.token,
      }).then(async response => {
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject;

            setAnimals(serverData);
          } else {
            snackbar.open(response.message);
          }
        }
      });
      setLoadingAnimals(false);
    }
  };

  const fetchScans = async () => {
    if (auth?.token) {
      setLoadingScans(true);
      await postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ORDINARY_QUERY,
          view: 'MISHIMA_INSIGHTS_LOTS',
          sqlConditions: `FEEDLOT = '${lot.FEEDLOT}' AND LOT = '${lot.LOT}'`,
        },
        token: auth.token,
      }).then(async response => {
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            setScans(serverData);
          } else {
            snackbar.open(response.message);
          }
        }
      });
      setLoadingScans(false);
    }
  };

  const handleSubmitShipping = async ({ date, location }: FormData) => {
    if (date && location) {
      setLoadingForm(true);
      if (auth?.token) {
        await postToServer({
          action: 'feedyard/UpdateShipment',
          params: {
            feedlot: lot.FEEDLOT,
            lot: lot.LOT,
            date: date.format(DateFormatServer.SHORT),
            location,
          },
          token: auth.token,
        }).then(response => {
          if (response.statusCode !== 401) {
            if (response.message.type === 'success') {
              setAnchorEl(null);
              loadLots();
            }
            snackbar.open(response.message);
          }
        });
      }
      setLoadingForm(false);
    }
  };

  return loadingAnimals || loadingScans ? (
    <Skeleton minWidth="50vw" />
  ) : (
    <Stack p={2} bgcolor="grey.100" spacing={2} minWidth="50vw">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">{`Lot ${lot.LOT}`}</Typography>
        <Stack direction="row" spacing={2}>
          <ButtonGroup variant="outlined">
            <ButtonTab
              title="Tables"
              selected={currentView}
              onClick={setCurrentView}
            />
            <ButtonTab
              title="Charts"
              selected={currentView}
              onClick={setCurrentView}
            />
          </ButtonGroup>
          <Button
            title="Add Shipping Details"
            variant="outlined"
            endIcon={<ChevronRight />}
            onClick={handleClickShippingButton}
          />
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <Stack minWidth={280} p={2}>
              <Form
                loading={loadingForm}
                onSubmit={handleSubmitShipping}
                buttonText="Submit"
                size="small"
                format={getDateFormat(org, 'short')}
                inputs={[
                  {
                    name: 'location',
                    label: 'Shipping Location',
                    category: FormInputCategory.SELECT,
                    options: [
                      {
                        label:
                          auth?.orgOwner === 'mishima' ? 'OWB' : 'Location 1',
                        value: 'OWB',
                      },
                      { label: 'Other', value: 'Other' },
                    ],
                    defaultValue: lot.SHIPPING_LOCATION,
                  },
                  {
                    name: 'date',
                    label: 'Shipping Date',
                    category: FormInputCategory.DATE_PICKER,
                    defaultValue: lot.SHIPPING_DATE
                      ? moment(lot.SHIPPING_DATE, getDateFormat(org, 'short'))
                      : moment().add(4, 'weeks'),
                  },
                ]}
              />
            </Stack>
          </Popover>
        </Stack>
      </Stack>
      {currentView === 'Charts' ? (
        <LotDetailsCharts
          scans={_.orderBy(scans?.rows || [], 'SEQUENCE_OF_SCAN')}
          animals={animals.animalsWithScans || []}
          loadingAnimals={loadingAnimals}
          loadingScans={loadingScans}
          auth={auth}
        />
      ) : (
        <LotDetailsTables
          animals={animals.animalsWithGroupedScans || []}
          scans={_.orderBy(scans?.rows || [], 'SEQUENCE_OF_SCAN')}
          averageDailyIncreaseInUSDAScoreCompanyOverall={
            averageDailyIncreaseInUSDAScoreCompanyOverall
          }
          loadingAnimals={loadingAnimals}
          loadingScans={loadingScans}
          auth={auth}
        />
      )}
    </Stack>
  );
};

export default LotDetails;
