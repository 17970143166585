import { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Popover,
} from '@mui/material';
import moment, { Moment } from 'moment';
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
  Auth,
  ChartType,
  DateFormatServer,
  LooseObject,
  Org,
  QueryResult,
  QueryType,
} from '../../../utils/Types';
import { Chart } from '../../../components';
import {
  delay,
  getDateFormat,
  optimiseQueryResult,
  postToServer,
} from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import {
  HISTOGRAM_Y_AXIS_TITLE,
  QUERY_DATA_SOURCE_ENDPOINT,
} from '../../../utils/Constants';

const getBrand = ({ imf, hcdw }: { imf: number; hcdw: number }) => {
  let brand = 'All Others';
  if (imf) {
    if (imf >= 4 && imf < 6) {
      if (hcdw >= 20 && hcdw < 28) {
        brand = 'PREM';
      } else if (hcdw >= 28) {
        brand = 'PREM+';
      }
    } else if (imf >= 6) {
      if (hcdw >= 22 && hcdw < 28) {
        brand = 'UPREM';
      } else if (hcdw >= 28) {
        brand = 'UPREM+';
      }
    }
  } else {
    brand = 'No IMF';
  }

  return brand;
};

const BrandsHistogram = ({
  data,
  loading,
  auth,
  org,
}: {
  data: LooseObject[];
  loading: boolean;
  auth: Auth;
  org: Org;
}) => {
  const snackbar = useContext(SnackbarContext);
  const [loadingCarcases, setLoadingCarcases] = useState(false);

  const allDateStrings = data
    ?.map(i => i.id)
    ?.sort(
      (a: string, b: string) =>
        moment(b, DateFormatServer.SHORT).valueOf() -
        moment(a, DateFormatServer.SHORT).valueOf()
    );

  type XAxis = 'All' | 'IMF Only';
  const [xAxis, setXAxis] = useState<XAxis>('All');

  type YAxis = 'Count' | 'Percent';
  const [yAxis, setYAxis] = useState<YAxis>('Percent');

  const [date, setDate] = useState<Moment | null>(moment());
  const [carcases, setCarcases] = useState<QueryResult>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    let defaultDate = moment();

    if (allDateStrings?.[0]) {
      defaultDate = moment(allDateStrings?.[0], DateFormatServer.SHORT);
      setDate(defaultDate);

      loadCarcases(defaultDate);
    }
  }, [data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadCarcases = async (forDate: Moment | null) => {
    if (forDate && auth?.token) {
      setLoadingCarcases(true);
      await delay(500);
      await postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ORDINARY_QUERY,
          view: 'FREWS_INSIGHTS_CARCASE',
          sqlConditions: `SLAUGHTER_DATE = '${forDate.format(
            DateFormatServer.SHORT
          )}'`,
        },
        token: auth.token,
      }).then(response => {
        setLoadingCarcases(false);
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            const optimisedServerData = optimiseQueryResult(serverData);

            setCarcases(optimisedServerData);
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const disableDates = (date: Moment) => {
    const currentDateString = date.format(DateFormatServer.SHORT);
    const shouldDisable = !allDateStrings?.includes(currentDateString);
    return shouldDisable;
  };

  const handleChangeXAxis = (event: SelectChangeEvent) => {
    if (event?.target?.value) {
      setXAxis(event.target.value as XAxis);
    }
  };

  const handleChangeYAxis = (event: SelectChangeEvent) => {
    if (event?.target?.value) {
      setYAxis(event.target.value as YAxis);
    }
  };

  const dataToDisplay = carcases?.rows
    ?.map(i => getBrand({ imf: i.MEQ_IMF, hcdw: i.WEIGHT_HDW }))
    .filter(i => (xAxis === 'IMF Only' ? i !== 'No IMF' : true));

  return (
    <Chart
      type={ChartType.HISTOGRAM}
      histnorm={yAxis === 'Count' ? '' : 'percent'}
      title={`Population Spread`}
      headerBeforeLoading={
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">{`Show data for ${date?.format(
            getDateFormat(org, 'short')
          )}`}</Typography>
          <Stack>
            <Typography
              onClick={handleClick}
              variant="body2"
              sx={{
                textDecoration: 'underline',
                m: 1,
                ':hover': { color: 'grey.700', cursor: 'pointer' },
              }}
            >
              Change Date
            </Typography>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateCalendar
                  disableFuture
                  value={date}
                  onChange={(v: Moment | null) => {
                    setDate(v);
                    loadCarcases(v);
                  }}
                  shouldDisableDate={disableDates}
                  slots={{
                    day: ({ day, ...rest }) => (
                      <PickersDay
                        day={day}
                        {...rest}
                        selected={
                          day.format(getDateFormat(org, 'short')) ===
                          date?.format(getDateFormat(org, 'short'))
                        }
                        sx={{
                          border: allDateStrings.includes(
                            day.format(DateFormatServer.SHORT)
                          )
                            ? 1
                            : 0,
                          borderColor: theme => theme.palette.primary.main,
                        }}
                      />
                    ),
                  }}
                />
              </LocalizationProvider>
            </Popover>
          </Stack>
        </Stack>
      }
      header={
        <Stack
          direction="row"
          spacing={1}
          mt={1}
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          <FormControl size="small" sx={{ bgcolor: 'white' }}>
            <InputLabel>X axis</InputLabel>
            <Select value={xAxis} label="X axis" onChange={handleChangeXAxis}>
              <MenuItem key={'x_axis_all'} value="All">
                All
              </MenuItem>
              <MenuItem key={'x_axis_imf_only'} value="IMF Only">
                IMF Only
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ bgcolor: 'white' }}>
            <InputLabel>Y axis</InputLabel>
            <Select value={yAxis} label="Y axis" onChange={handleChangeYAxis}>
              <MenuItem key={'category_count'} value="Count">
                Count
              </MenuItem>
              <MenuItem key={'category_percent'} value="Percent">
                Percent
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      }
      data={{
        xTitle: 'Brand',
        yTitle: yAxis || HISTOGRAM_Y_AXIS_TITLE,
        data: [
          {
            x: dataToDisplay,
          },
        ],
        xCategoryOrder: 'array',
        xCategoryArray: [
          'No IMF',
          'All Others',
          'PREM',
          'PREM+',
          'UPREM',
          'UPREM+',
        ],
      }}
      loading={loading || loadingCarcases}
    />
  );
};

export default BrandsHistogram;
