import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

import { DialogWrapper, Form, ToolButton } from '../../../components';
import {
  DataSource,
  FormInputCategory,
  FormInputItem,
  FormData,
  InsightItemType,
  LooseObject,
  Org,
  ChartTrace,
  ChartType,
} from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import {
  ChartTypes,
  InsightItemTypes,
  QUERY_DATA_SOURCE_ENDPOINT,
} from '../../../utils/Constants';
import { selectDataPool } from '../../../redux/reducers/dataPoolSlice';
import { generateDataPoolId } from '../../../utils/Helper';
import { updateDataPool } from '../../../utils/Helper2';

const AddInsightItem = ({
  availableQueries,
  conditions,
  onCancel,
  handleSubmit,
  org,
}: {
  availableQueries: LooseObject[];
  conditions: LooseObject;
  onCancel: () => void;
  handleSubmit: (v: FormData) => void;
  org: Org;
}) => {
  const [loading, setLoading] = useState(false);

  const [dataSource, setDataSource] = useState<DataSource>();
  const [itemType, setItemType] = useState<string>();
  const [chartType, setChartType] = useState<string>();
  const [chartTraces, setChartTraces] = useState<ChartTrace[]>([]);
  const [chartCount, setChartCount] = useState(1);

  const auth = useAppSelector(selectAuth);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  let existingDataPool = [...dataPool];

  useEffect(() => {
    loadData();
  }, [dataSource]);

  const loadData = async () => {
    if (auth?.token && dataSource) {
      setLoading(true);
      existingDataPool = await updateDataPool({
        dataPool,
        dataSource,
        auth,
        dispatch,
      });
      setLoading(false);
    }
  };

  let inputs: FormInputItem[] = [
    {
      name: 'title',
      label: 'Title',
      category: FormInputCategory.TEXT_INPUT,
    },
    {
      name: 'description',
      label: 'Description',
      category: FormInputCategory.TEXT_INPUT,
      multiline: true,
    },
    {
      name: 'itemType',
      label: 'Item Type',
      category: FormInputCategory.SELECT,
      options: InsightItemTypes,
      exposeValue: v => setItemType(v),
    },
    {
      name: 'dataSourceKey',
      label: 'Data Source',
      category: FormInputCategory.SELECT,
      options: availableQueries.map(i => ({
        label: i.name,
        value: i.id,
      })),
      exposeValue: v => {
        const availableDataSource = availableQueries.find(i => i.id === v);
        if (availableDataSource) {
          setDataSource({
            key: availableDataSource.id,
            label: availableDataSource.name,
            endpoint: QUERY_DATA_SOURCE_ENDPOINT,
            query: availableDataSource,
            isNoParamsPermitted: true,
            excludeAttributes: [],
          });
        }
      },
    },
  ];

  const currentDataPoolItem = existingDataPool.find(i => {
    if (dataSource) {
      const insightItemId = generateDataPoolId({
        dataSourceKey: dataSource.key,
        dataSourceParams: {},
      });

      return i.id === insightItemId;
    } else {
      return false;
    }
  });
  if (itemType === InsightItemType.CHART && currentDataPoolItem) {
    inputs = inputs.concat([
      {
        name: 'chartType',
        label: 'Chart Type',
        category: FormInputCategory.SELECT,
        options: ChartTypes,
        exposeValue: v => setChartType(v),
      },
      {
        name: 'addOneMoreChart',
        category: FormInputCategory.COMPONENT,
        component:
          chartType === ChartType.PIE_CHART ||
          chartType === ChartType.DONUT_CHART ? (
            <Stack />
          ) : (
            <Stack direction="row" justifyContent="flex-end">
              {chartCount < 3 && (
                <ToolButton
                  title="Add one more chart"
                  icon={<Add />}
                  onClick={() => setChartCount(prev => prev + 1)}
                />
              )}
              {chartCount > 1 && (
                <ToolButton
                  title="Remove one chart"
                  icon={<Remove />}
                  onClick={() => setChartCount(prev => prev - 1)}
                />
              )}
            </Stack>
          ),
      },
    ]);
  }

  if (itemType === InsightItemType.CHART && currentDataPoolItem && chartType) {
    const getAxes = (count: number) => {
      const axes: FormInputItem[] = [
        {
          name: 'xAxis' + count,
          label:
            chartType === ChartType.PIE_CHART ||
            chartType === ChartType.DONUT_CHART
              ? 'Labels'
              : 'X Axis' + (chartCount > 1 ? ` ${count + 1}` : ''),
          category: FormInputCategory.SELECT,
          options: currentDataPoolItem?.attributes.map(i => ({
            label: i.label,
            value: i.key,
            disabled: chartTraces[count]?.y === i.key,
          })),
          defaultValue:
            count > 0 && chartType === ChartType.BAR_CHART
              ? chartTraces[0].x
              : '',
          disabled: count > 0 && chartType === ChartType.BAR_CHART,
          exposeValue: v => {
            const trace: ChartTrace = { x: v };
            if (chartTraces.length <= count) {
              // no such a trace yet
              setChartTraces(prev => [...prev, trace]);
            } else {
              setChartTraces(prev => [
                ...prev.filter((t, index) => index !== count),
                { ...chartTraces[count], x: v },
              ]);
            }
          },
        },
      ];
      if (chartType !== ChartType.HISTOGRAM) {
        axes.push({
          name: 'yAxis' + count,
          label:
            chartType === ChartType.PIE_CHART ||
            chartType === ChartType.DONUT_CHART
              ? 'Values'
              : 'Y Axis' + (chartCount > 1 ? ` ${count + 1}` : ''),
          category: FormInputCategory.SELECT,
          options: currentDataPoolItem?.attributes.map(i => ({
            label: i.label,
            value: i.key,
            disabled: chartTraces[count]?.x === i.key,
          })),
          exposeValue: v => {
            const trace: ChartTrace = { y: v };
            if (chartTraces.length <= count) {
              // no such a trace yet
              setChartTraces(prev => [...prev, trace]);
            } else {
              setChartTraces(prev => [
                ...prev.filter((t, index) => index !== count),
                { ...chartTraces[count], y: v },
              ]);
            }
          },
        });
      }
      return axes;
    };

    for (let count = 0; count < chartCount; count++) {
      inputs.splice(inputs.length - 1, 0, ...getAxes(count));
    }
  }

  return (
    <DialogWrapper
      title="Add a new insight item"
      onCancel={onCancel}
      hideCancelButton
    >
      <Form
        loading={loading}
        onSubmit={v =>
          handleSubmit({
            ...v,
            chartTraces: chartTraces.filter((t, index) => index < chartCount),
            isQuery: !!dataSource?.query,
          })
        }
        buttonText="Submit"
        inputs={inputs}
      />
    </DialogWrapper>
  );
};

export default AddInsightItem;
