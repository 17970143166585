import { Routes as ReactRouter, Route, BrowserRouter } from 'react-router-dom';

import { NAV_ITEMS, OTHER_PAGES } from '../utils/Constants';
import PageContainer from './PageContainer';
import PrivateRoutes from './PrivateRoutes';

const Routes = () => {
  const allRoutes = NAV_ITEMS.concat(OTHER_PAGES);

  const publicRoutes = allRoutes.filter(i => i.isPublic);
  const privateRoutes = allRoutes.filter(i => !i.isPublic);

  return (
    <BrowserRouter>
      <ReactRouter>
        {publicRoutes.map(item => (
          <Route
            key={item.title}
            path={item.to}
            element={
              <PageContainer
                title={`${item.title}`}
                children={item.page}
                hideNav={item.hideNav}
                hideFooter={item.hideFooter}
                contentPadding={item.contentPadding}
              />
            }
          />
        ))}
        <Route element={<PrivateRoutes />}>
          {privateRoutes.map(item => (
            <Route
              key={item.title}
              path={item.to}
              element={
                <PageContainer
                  title={item.title}
                  children={item.page}
                  hideNav={item.hideNav}
                  hideFooter={item.hideFooter}
                  contentPadding={item.contentPadding}
                />
              }
            />
          ))}
        </Route>
      </ReactRouter>
    </BrowserRouter>
  );
};

export default Routes;
