import { useContext, useEffect, useState } from 'react';
import { Dialog, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add, Edit, Group, Power } from '@mui/icons-material';
import moment from 'moment';
import _ from 'lodash';

import { CopyButton, Status, Table } from '../../../components';
import { FormData, Status as StatusType } from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { getDateFormat, logout, postToServer } from '../../../utils/Helper';
import { LooseObject } from '../../../utils/Types';
import EditOrg from './EditOrg';
import AddOrg from './AddOrg';
import AsignPlugins from './AsignPlugins';
import AsignUsers from './AsignUsers';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [orgs, setOrgs] = useState<LooseObject[]>([]);
  const [tableRows, setTableRows] = useState<LooseObject[]>([]);
  const [itemForDialog, setItemForDialog] = useState<LooseObject>({});
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogAssignPlugins, setOpenDialogAssignPlugins] = useState(false);
  const [openDialogAssignUsers, setOpenDialogAssignUsers] = useState(false);

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'administration/GetOrgs',
        params: {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            setOrgs(response.serverData as LooseObject[]);
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const handleAddOrg = ({
    name,
    owner,
    hostname,
    logo,
    theme,
    isWhiteLabel,
    isUSA,
    isTCRequired,
    isValid,
  }: FormData) => {
    if (name && owner && logo && theme) {
      setLoading(true);
      if (auth?.token) {
        const params = {
          name,
          owner,
          hostname,
          logo,
          theme,
          isWhiteLabel,
          isUSA,
          isTCRequired,
          isValid,
        };
        postToServer({
          action: 'administration/AddOrg',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogAdd(!openDialogAdd);
              setOrgs(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setLoading(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const handleEditOrg = ({
    name,
    hostname,
    logo,
    theme,
    isWhiteLabel,
    isUSA,
    isTCRequired,
    isValid,
  }: FormData) => {
    if (itemForDialog.id && name && logo && theme) {
      setLoading(true);
      if (auth?.token) {
        const params = {
          id: itemForDialog.id,
          name,
          hostname,
          logo,
          theme,
          isWhiteLabel,
          isUSA,
          isTCRequired,
          isValid,
        };
        postToServer({
          action: 'administration/EditOrg',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogEdit(!openDialogEdit);
              setOrgs(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setLoading(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const handleAssignPlugins = ({ plugins }: FormData) => {
    if (itemForDialog.id && plugins) {
      setLoading(true);
      if (auth?.token) {
        const params = {
          id: itemForDialog.id,
          plugins,
        };
        postToServer({
          action: 'administration/AssignPlugins',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogAssignPlugins(!openDialogAssignPlugins);
              setOrgs(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setLoading(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const columns = [
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      sortingFn: 'datetime',
      size: 120,
    },
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'owner', header: 'Owner' },
    { accessorKey: 'hostname', header: 'Hostname' },
    { accessorKey: 'plugins', header: 'Plugins' },
    { accessorKey: 'logo', header: 'Logo' },
    { accessorKey: 'theme', header: 'Theme', size: 100 },
    { accessorKey: 'status', header: 'Status', size: 100 },
    { accessorKey: 'actions', header: 'Actions', size: 150 },
  ];

  useEffect(() => {
    const rows = orgs.map(i => ({
      ...i,
      createdAt: moment(i.createdAt).format(getDateFormat(org, 'default')),
      plugins: (
        <Stack>
          {i.plugins &&
            _.sortBy(i.plugins, 'name').map((p: LooseObject) => (
              <Typography key={p.id}>{p.name}</Typography>
            ))}
        </Stack>
      ),
      theme: <CopyButton text={JSON.stringify(i.theme)} />,
      status: (
        <Stack alignItems="center">
          <Status
            status={i.isValid ? StatusType.ACTIVE : StatusType.SUSPENDED}
          />
          {i.isUSA && <Typography fontSize={9}>US Client</Typography>}
          {i.isWhiteLabel && <Typography fontSize={9}>White Label</Typography>}
          {i.isTCRequired && <Typography fontSize={9}>TC Required</Typography>}
        </Stack>
      ),
      actions: (
        <Stack direction="row" spacing={1}>
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              color="info"
              onClick={() => {
                setItemForDialog(i);
                setOpenDialogEdit(true);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Assign Plugins">
            <IconButton
              aria-label="assign plugins"
              color="info"
              onClick={() => {
                setItemForDialog(i);
                setOpenDialogAssignPlugins(true);
              }}
            >
              <Power />
            </IconButton>
          </Tooltip>
          <Tooltip title="Assign Users">
            <IconButton
              aria-label="assign users"
              color="info"
              onClick={() => {
                setItemForDialog(i);
                setOpenDialogAssignUsers(true);
              }}
            >
              <Group />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    }));
    setTableRows(rows);
  }, [orgs]);

  return (
    <Stack>
      <Stack alignItems="flex-end">
        <Tooltip arrow title="Create New Organisation">
          <IconButton onClick={() => setOpenDialogAdd(true)} size="large">
            <Add color="primary" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Table
        renderTopToolbarCustomActions={() => (
          <Typography variant="h6">All Orgnisations in The System</Typography>
        )}
        columns={columns}
        data={tableRows}
        state={{ isLoading: loading }}
        enableColumnActions={false}
      />
      <Dialog open={openDialogAdd}>
        <AddOrg
          onConfirm={handleAddOrg}
          onCancel={() => setOpenDialogAdd(!openDialogAdd)}
          loading={loading}
        />
      </Dialog>
      <Dialog open={openDialogEdit}>
        <EditOrg
          onCancel={() => setOpenDialogEdit(!openDialogEdit)}
          onConfirm={handleEditOrg}
          loading={loading}
          item={itemForDialog}
        />
      </Dialog>
      <Dialog open={openDialogAssignPlugins}>
        <AsignPlugins
          onCancel={() => setOpenDialogAssignPlugins(!openDialogAssignPlugins)}
          onConfirm={handleAssignPlugins}
          loading={loading}
          item={itemForDialog}
        />
      </Dialog>
      <Dialog open={openDialogAssignUsers} maxWidth="xl">
        <AsignUsers
          onCancel={() => setOpenDialogAssignUsers(!openDialogAssignUsers)}
          item={itemForDialog}
        />
      </Dialog>
    </Stack>
  );
};

export default Page;
