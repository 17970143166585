import { Stack, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SlotComponentProps } from '@mui/base/utils';
import moment from 'moment';
import type { ValueEditorProps } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';

import { DateFormatServer } from '../../../utils/Types';
import { getDateFormat } from '../../../utils/Helper';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import { useAppSelector } from '../../../redux/hooks';

const CustomValueEditor = ({
  operator,
  value,
  handleOnChange,
  fieldData,
}: ValueEditorProps) => {
  const org = useAppSelector(selectOrg);
  const dateFormat = getDateFormat(org, 'short');

  if (operator === 'isnull' || operator === 'isnotnull') {
    return null;
  }

  const inputProps: SlotComponentProps<
    typeof TextField,
    {},
    Record<string, any>
  > = { size: 'small', variant: 'standard' };

  const width = 140;
  const height = 26;

  let valueEditor = (
    <TextField
      onChange={v => handleOnChange(v.target.value)}
      value={value}
      type={fieldData.inputType === 'number' ? 'number' : 'text'}
      {...inputProps}
      sx={{ width, height }}
    />
  );

  if (operator === 'between' || operator === 'notbetween') {
    switch (fieldData.inputType) {
      case 'number':
        const [value1, value2] = value === '' ? ['', ''] : value;
        valueEditor = (
          <Stack direction="row" spacing={1}>
            <TextField
              onChange={v => handleOnChange([v.target.value, value2])}
              value={value1 || ''}
              type={fieldData.inputType === 'number' ? 'number' : 'text'}
              {...inputProps}
              sx={{ height }}
            />
            <TextField
              onChange={v => handleOnChange([value1, v.target.value])}
              value={value2 || ''}
              type={fieldData.inputType === 'number' ? 'number' : 'text'}
              {...inputProps}
              sx={{ height }}
            />
          </Stack>
        );
        break;
      case 'date':
        const [date1, date2] = value === '' ? [null, null] : value;
        valueEditor = (
          <Stack direction="row" spacing={1}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                onChange={(v: any) =>
                  handleOnChange([v.format(DateFormatServer.TIMESTAMP), date2])
                }
                value={date1 ? moment(date1, DateFormatServer.TIMESTAMP) : null}
                format={dateFormat}
                slotProps={{ textField: inputProps }}
                sx={{ height }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                onChange={(v: any) =>
                  handleOnChange([date1, v.format(DateFormatServer.TIMESTAMP)])
                }
                value={date2 ? moment(date2, DateFormatServer.TIMESTAMP) : null}
                format={dateFormat}
                slotProps={{ textField: inputProps }}
                sx={{ height }}
              />
            </LocalizationProvider>
          </Stack>
        );
        break;
    }
  } else {
    switch (fieldData.inputType) {
      case 'date':
        valueEditor = (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              onChange={(v: any) =>
                handleOnChange(v.format(DateFormatServer.TIMESTAMP))
              }
              value={value ? moment(value, DateFormatServer.TIMESTAMP) : null}
              format={dateFormat}
              sx={{ width, height }}
              slotProps={{
                textField: inputProps,
              }}
            />
          </LocalizationProvider>
        );
        break;
    }
  }

  return valueEditor;
};

export default CustomValueEditor;
