import { useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  Dialog,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { LoadingButton } from '@mui/lab';

import {
  DialogWrapper,
  OtpInput,
  PublicPageContainner,
} from '../../../components';
import { FormData, Org, Auth, User, LooseObject } from '../../../utils/Types';
import {
  asyEncrypt,
  isNotEmpty,
  loadOrg,
  postToServer,
  signInToOrg,
} from '../../../utils/Helper';
import {
  // CURRENT_FRONTEND_VERSION,
  RSA_PUBLIC_KEY,
} from '../../../utils/Constants';
import { useAppDispatch } from '../../../redux/hooks';
import { SnackbarContext, ThemeOveridesContext } from '../../../utils/Contexts';
import SelectOrg from './SelectOrg';
import LoginForm, { HelperTextLogin } from './LoginForm';
import Terms from './Terms';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [orgs, setOrgs] = useState<Org[]>([]);
  const [is2FA, setIs2FA] = useState<boolean>();
  const [otpToken, setOtpToken] = useState('');
  const [formData, setFormData] = useState<FormData>({});
  const [authTemp, setAuthTemp] = useState<Auth>();

  const [openDialogForLost2FA, setOpenDialogForLost2FA] = useState(false);
  const [openDialogForAcceptTC, setOpenDialogForAcceptTC] = useState(false);
  const [currentOrgIdForAcceptTC, setCurrentOrgIdForAcceptTC] =
    useState<number>();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const themeOverides = useContext(ThemeOveridesContext);

  useEffect(() => {
    clearCacheData();
    loadOrg({ hostname: window.location.hostname, themeOverides, dispatch });
    refreshSnowflake();
    // checkLatestFrontendVersion();
  }, []);

  const clearCacheData = () => {
    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name);
      });
    });
  };

  // const checkLatestFrontendVersion = () =>
  //   postToServer({
  //     action: 'GetLatestFrontendVersion',
  //     params: {},
  //     token: '',
  //   }).then(response => {
  //     if (response.message.type === 'success' && response.serverData) {
  //       const serverData = response.serverData as LooseObject;
  //       if (serverData.version > CURRENT_FRONTEND_VERSION) {
  //         window.location.reload();
  //       }
  //     }
  //   });

  const refreshSnowflake = () =>
    postToServer({ action: 'RefreshSnowflake', params: {}, token: '' });

  const handleSubmit = async ({ email, password, otpToken }: FormData) => {
    setFormData({ email, password });

    if (email && validator.isEmail(email) && password) {
      setLoading(true);
      const params = {
        email,
        password: await asyEncrypt({ text: password, pubKey: RSA_PUBLIC_KEY }),
        otpToken,
      };

      await postToServer({ action: 'SignIn', params, token: '' }).then(
        async response => {
          setLoading(false);
          if (response.message.type === 'success') {
            const auth = response.auth as Auth;
            const serverData = response.serverData as LooseObject;

            if (serverData.is2FARequired) {
              setIs2FA(true);
            } else {
              setIs2FA(false);
              const user = response.serverData as User;

              setAuthTemp(auth);

              if (user && user.orgs) {
                if (user.orgs.length > 1) {
                  // user belongs to multiple orgs
                  setOrgs(user.orgs);
                } else if (user.orgs.length === 1) {
                  const isUserAgreedTerms = await IsUserAgreedTerms({
                    orgId: user.orgs[0]?.id,
                    auth,
                  });
                  if (isUserAgreedTerms) {
                    setOpenBackdrop(true);
                    await signInToOrg({
                      orgId: user.orgs[0]?.id,
                      auth,
                      themeOverides,
                      dispatch,
                      navigate,
                      snackbar,
                    });
                    setOpenBackdrop(false);
                  } else {
                    setCurrentOrgIdForAcceptTC(user.orgs[0]?.id);
                    setOpenDialogForAcceptTC(true);
                  }
                }
              }
            }
          } else {
            snackbar.open(response.message);
          }
        }
      );
    }
  };

  const lost2FA = async () => {
    if (
      formData.email &&
      validator.isEmail(formData.email) &&
      formData.password
    ) {
      setLoading(true);
      await postToServer({
        action: 'Lost2FA',
        params: {
          email: formData.email,
          password: await asyEncrypt({
            text: formData.password,
            pubKey: RSA_PUBLIC_KEY,
          }),
          origin: window.location.protocol + '//' + window.location.host,
        },
        token: '',
      }).then(response => {
        if (response.message.type === 'success') {
          setOpenDialogForLost2FA(false);
        }
        snackbar.open(response.message);
      });
      setLoading(false);
    }
  };

  const IsUserAgreedTerms = async ({
    orgId,
    auth,
  }: {
    orgId?: number;
    auth?: Auth;
  }) => {
    let isUserAgreedTerms = false;

    if (isNotEmpty(orgId)) {
      if (auth?.token) {
        await postToServer({
          action: 'IsUserAgreedTerms',
          params: { orgId },
          token: auth?.token,
        }).then(response => {
          if (response.message.type === 'success' && response.serverData) {
            isUserAgreedTerms = (response.serverData as LooseObject)
              .isTCAccepted;
          }
        });
      }
    }
    return isUserAgreedTerms;
  };

  const acceptTerms = async () => {
    setLoading(true);
    await postToServer({
      action: 'AcceptTerms',
      params: {
        orgId: currentOrgIdForAcceptTC,
        isAccepted: true,
      },
      token: authTemp?.token,
    }).then(async response => {
      if (response.message.type === 'success' && response.serverData) {
        if ((response.serverData as LooseObject).isTCAccepted) {
          setOpenDialogForAcceptTC(false);
        }
        setOpenBackdrop(true);
        await signInToOrg({
          orgId: currentOrgIdForAcceptTC,
          auth: authTemp,
          themeOverides,
          dispatch,
          navigate,
          snackbar,
        });
        setOpenBackdrop(false);
      } else {
        snackbar.open(response.message);
      }
    });
    setLoading(false);
  };

  return (
    <PublicPageContainner>
      {orgs?.length > 1 ? (
        <SelectOrg
          orgs={orgs}
          onSelectOrg={async id => {
            const isUserAgreedTerms = await IsUserAgreedTerms({
              orgId: id,
              auth: authTemp,
            });
            if (isUserAgreedTerms) {
              setOpenBackdrop(true);
              await signInToOrg({
                orgId: id,
                auth: authTemp,
                themeOverides,
                dispatch,
                navigate,
                snackbar,
              });
              setOpenBackdrop(false);
            } else {
              setCurrentOrgIdForAcceptTC(id);
              setOpenDialogForAcceptTC(true);
            }
          }}
        />
      ) : is2FA ? (
        <Stack
          spacing={3}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Typography color="grey.100" fontSize={18}>
            Please enter your one-time password
          </Typography>
          <OtpInput
            onChange={v => v && setOtpToken(v)}
            handleSubmit={() => handleSubmit({ ...formData, otpToken })}
          />
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() => handleSubmit({ ...formData, otpToken })}
          >
            Submit
          </LoadingButton>
          <HelperTextLogin
            text="Lost your one-time password?"
            to="#"
            onClickLink={() => setOpenDialogForLost2FA(true)}
          />
          <Dialog open={openDialogForLost2FA}>
            <DialogWrapper
              title="Confirm to reset 2FA"
              onCancel={() => setOpenDialogForLost2FA(false)}
              loading={loading}
              onConfirm={lost2FA}
            >
              <Typography>
                Are you sure to reset your 2-factor authentication?
              </Typography>
            </DialogWrapper>
          </Dialog>
        </Stack>
      ) : (
        <LoginForm loading={loading} handleSubmit={handleSubmit} />
      )}
      <Dialog open={openDialogForAcceptTC} maxWidth="lg">
        <Terms
          isUSA={true}
          onCancel={() => setOpenDialogForAcceptTC(false)}
          onConfirm={acceptTerms}
          loading={loading}
        />
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </PublicPageContainner>
  );
};

export default Page;
