import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { DialogWrapper } from '../../../components';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Terms = ({
  isUSA,
  onCancel,
  onConfirm,
  loading,
}: {
  isUSA: boolean;
  onCancel: () => void;
  loading: boolean;
  onConfirm: () => void;
}) => {
  const [numPages, setNumPages] = useState<number>();

  return (
    <DialogWrapper
      title="Terms of Service"
      onCancel={onCancel}
      loading={loading}
      onConfirm={onConfirm}
      confirmText="Accept"
      cancelText="Deny"
    >
      <Stack spacing={1}>
        <Typography>
          Please confirm you have read the Terms of Service.
        </Typography>
        <Stack bgcolor="grey.300" p={2} pr={1} borderRadius={2}>
          <Stack maxHeight="70vh" overflow="scroll">
            <Document
              file={`${
                process.env.REACT_APP_API_ENDPOINT
              }/files/terms/Terms-of-Service-${isUSA ? 'US' : 'AU'}.pdf`}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </Stack>
        </Stack>
      </Stack>
    </DialogWrapper>
  );
};

export default Terms;
