import { useState } from 'react';
import { Button, FormControl, MenuItem, Select, Stack } from '@mui/material';
import { BarChart, CheckCircle } from '@mui/icons-material';
import { DemoLocations } from '../Overview';
import { Auth } from '../../../utils/Types';

const InsightsButton = ({
  onClick,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => (
  <Button
    variant="contained"
    startIcon={<BarChart />}
    sx={{
      textTransform: 'none',
      bgcolor: 'grey.900',
      justifyContent: { xs: 'flex-start', lg: 'center' },
    }}
    onClick={onClick}
  >
    Insights
  </Button>
);

const LocationButton = ({
  title,
  selected,
  onClick,
  auth,
}: {
  title: string;
  selected: string;
  onClick: (v: string) => void;
  auth: Auth;
}) => (
  <Button
    variant="outlined"
    startIcon={selected === title && <CheckCircle color="primary" />}
    sx={{
      textTransform: 'none',
      color: 'grey.800',
      borderColor: selected === title ? 'primary' : 'grey.300',
      bgcolor: 'grey.50',
    }}
    onClick={() => onClick(title)}
  >
    {auth?.orgOwner === 'smithfield' ? title : DemoLocations[title]}
  </Button>
);

const LocationSwitch = ({
  onChange,
  onClickShowInsight,
  auth,
}: {
  onChange: (value: string) => void;
  onClickShowInsight: () => void;
  auth: Auth;
}) => {
  const [selected, setSelected] = useState<string>('Mesquite');

  const onSwitch = (v: string) => {
    setSelected(v);
    onChange(v);
  };

  return (
    <Stack>
      <Stack
        direction="row"
        bgcolor="grey.200"
        p={1.5}
        spacing={1}
        justifyContent="space-between"
        display={{ xs: 'none', lg: 'flex' }}
      >
        <Stack direction="row" spacing={1}>
          <LocationButton
            title="Mesquite"
            selected={selected}
            onClick={onSwitch}
            auth={auth}
          />
          <LocationButton
            title="Horton"
            selected={selected}
            onClick={onSwitch}
            auth={auth}
          />
          <LocationButton
            title="Yellowstone"
            selected={selected}
            onClick={onSwitch}
            auth={auth}
          />
          <LocationButton
            title="Bovina"
            selected={selected}
            onClick={onSwitch}
            auth={auth}
          />
        </Stack>
        <InsightsButton onClick={onClickShowInsight} />
      </Stack>
      <Stack display={{ xs: 'flex', lg: 'none' }} spacing={1}>
        <InsightsButton onClick={onClickShowInsight} />
        <FormControl size="small">
          <Select value={selected} onChange={e => onSwitch(e.target.value)}>
            <MenuItem value="Mesquite">
              {auth?.orgOwner === 'smithfield'
                ? 'Mesquite'
                : DemoLocations['Mesquite']}
            </MenuItem>
            <MenuItem value="Horton">
              {auth?.orgOwner === 'smithfield'
                ? 'Horton'
                : DemoLocations['Horton']}
            </MenuItem>
            <MenuItem value="Yellowstone">
              {auth?.orgOwner === 'smithfield'
                ? 'Yellowstone'
                : DemoLocations['Yellowstone']}
            </MenuItem>
            <MenuItem value="Bovina">
              {auth?.orgOwner === 'smithfield'
                ? 'Bovina'
                : DemoLocations['Bovina']}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
};
export default LocationSwitch;
