import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { ChartType, LooseObject } from '../../../utils/Types';
import { Chart } from '../../../components';
import { HISTOGRAM_Y_AXIS_TITLE } from '../../../utils/Constants';

const TraitsHistogram = ({
  data,
  allTraits,
  loading,
}: {
  data: LooseObject[];
  allTraits: LooseObject[];
  loading: boolean;
}) => {
  const [histogramTrait, setHistogramTrait] = useState<
    LooseObject | undefined
  >();

  useEffect(() => {
    setHistogramTrait(allTraits?.[0]);
  }, [allTraits]);

  const handleChangeHistogramTrait = (event: SelectChangeEvent) => {
    if (event?.target?.value) {
      setHistogramTrait(allTraits.find(i => i.name === event.target.value));
    }
  };

  return (
    <Chart
      type={ChartType.HISTOGRAM}
      title={'Histogram Of  ' + histogramTrait?.label || 'Trait'}
      header={
        allTraits.length > 1 && (
          <FormControl size="small" sx={{ bgcolor: 'white', mt: 2 }}>
            <InputLabel>Trait</InputLabel>
            <Select
              value={histogramTrait?.name || ''}
              label="Trait"
              onChange={handleChangeHistogramTrait}
            >
              {allTraits?.map(i => (
                <MenuItem key={'trait_' + i.name} value={i.name || ''}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      }
      data={{
        xTitle: histogramTrait?.label,
        yTitle: HISTOGRAM_Y_AXIS_TITLE,
        data: [
          {
            x: data.map(i => i[histogramTrait?.name]),
            type: 'histogram',
          },
        ],
      }}
      loading={loading}
    />
  );
};

export default TraitsHistogram;
