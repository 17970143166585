import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

import { FormData } from '../../../utils/Types';
import { delay, postToServer } from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import ForgotPasswordForm from './ForgotPasswordForm';
import { PublicPageContainner } from '../../../components';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = ({ email }: FormData) => {
    if (email && validator.isEmail(email)) {
      setLoading(true);
      const params = {
        email,
        origin: window.location.protocol + '//' + window.location.host,
      };

      postToServer({ action: 'ForgotPassword', params, token: '' }).then(
        async response => {
          setLoading(false);
          if (response.message.type === 'success') {
            snackbar.open(response.message);
            await delay(2000);
            navigate('/');
          } else {
            snackbar.open(response.message);
          }
        }
      );
    }
  };

  return (
    <PublicPageContainner>
      <ForgotPasswordForm loading={loading} handleSubmit={handleSubmit} />
    </PublicPageContainner>
  );
};

export default Page;
