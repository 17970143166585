import { Close } from '@mui/icons-material';
import { Stack, Tooltip, IconButton } from '@mui/material';

type Props = {
  children: React.ReactNode;
  minWidth?: number | string;
  maxWidth?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  onClose: () => void;
};
const DialogWrapperInfo = ({
  children,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  onClose,
}: Props) => (
  <Stack height="100%" justifyContent="center">
    <Stack position="relative">
      <Tooltip title="close">
        <IconButton
          aria-label="Close"
          onClick={onClose}
          sx={{
            width: 26,
            height: 26,
            bgcolor: 'grey.100',
            alignSelf: 'flex-end',
            zIndex: theme => theme.zIndex.drawer + 1,
            '&:hover': {
              bgcolor: 'grey.300',
            },
            mb: -3,
            position: 'absolute',
          }}
        >
          <Close />
        </IconButton>
      </Tooltip>
    </Stack>
    <Stack
      m={1}
      border={'1px solid #fff'}
      borderRadius={1.2}
      overflow="auto"
      minWidth={minWidth}
      maxWidth={maxWidth || 'calc(100vw - 28px)'}
      minHeight={minHeight}
      maxHeight={maxHeight || '90vh'}
    >
      {children}
    </Stack>
  </Stack>
);

export default DialogWrapperInfo;
