import moment from 'moment';
import { faker } from '@faker-js/faker';

import { getRandomInt } from './utils/Helper';
import { DateFormat } from './utils/Types';

export const mockLotSummary = Array.from(Array(10).keys()).map(i => ({
  id: i,
  numberOfHead: getRandomInt(0, 100),
  dateofInduction: moment()
    .add(-getRandomInt(0, 60 * 60 * 24 * 30), 'seconds')
    .format(DateFormat.SHORT),
  daysOnFeed: getRandomInt(0, 100),
  estShippingDate: moment()
    .add(getRandomInt(0, 60 * 60 * 24 * 50), 'seconds')
    .format(DateFormat.SHORT),
  midpointWeight: getRandomInt(0, 100),
  numberOfDeaths: getRandomInt(0, 20),
  ultra: getRandomInt(0, 100),
  fiveStar: getRandomInt(0, 100),
  fourStar: getRandomInt(0, 100),
  choice: getRandomInt(0, 100),
}));

export const mockGroupAnimalsAnalysis = Array.from(Array(25).keys()).map(i => ({
  id: i,
  dateofInduction: moment()
    .add(-getRandomInt(0, 60 * 60 * 24 * 30), 'seconds')
    .format(DateFormat.SHORT),
  daysOnFeed: getRandomInt(0, 100),
  estDaysToGo: getRandomInt(0, 100),
  ultra: getRandomInt(0, 100),
  fiveStar: getRandomInt(0, 100),
  fourStar: getRandomInt(0, 100),
  choice: getRandomInt(0, 100),
}));

export const mockUserList = Array.from(Array(25).keys()).map(i => ({
  id: i,
  createdAt: moment()
    .add(-getRandomInt(0, 60 * 60 * 24 * 30), 'seconds')
    .format(DateFormat.SHORT),
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  email: faker.internet.email(),
  isValid: getRandomInt(0, 5) === 0 ? false : true,
  lastLogin: moment(
    faker.datatype.datetime({ max: 1681700000000, min: 1681000000000 })
  ).format(DateFormat.DEFAULT),
}));

export const mockSummaries = Array.from(Array(25).keys()).map(i => ({
  id: i,
  createdAt: moment()
    .add(-i - 1, 'days')
    .format(DateFormat.SHORT),
  numberOfImages: faker.datatype.number({ min: 10, max: 100 }),
  avarageMb: faker.datatype.number({ min: 50, max: 100, precision: 1 }) + '%',
}));

export const mockListOfImages = Array.from(Array(25).keys()).map(i => ({
  id: i,
  barcode: faker.random.numeric(10),
  photoTime: moment()
    .add(-getRandomInt(0, 60 * 60 * 24 * 30), 'seconds')
    .format(DateFormat.DEFAULT),
  trait_1: faker.random.word(),
  trait_2: faker.random.word(),
}));

export const mockListOfLots = Array.from(Array(25).keys()).map(i => ({
  id: i,
  date: moment()
    .add(-getRandomInt(0, 60 * 60 * 24 * 30), 'seconds')
    .format(DateFormat.SHORT),
  pic: faker.random.numeric(3),
  lotNumber: faker.random.numeric(3),
  numberProcessed: faker.random.numeric(3),
  avgPricePerHead: '$' + faker.random.numeric(3),
  lotPriceTotal: '$' + faker.random.numeric(5),
  dateOfInduction: moment()
    .add(-getRandomInt(0, 60 * 60 * 24 * 30), 'seconds')
    .format(DateFormat.SHORT),
  processDate: moment()
    .add(-getRandomInt(0, 60 * 60 * 24 * 30), 'seconds')
    .format(DateFormat.SHORT),
  minHSCW: faker.random.numeric(2),
  maxHSCW: faker.random.numeric(2),
  avgHSCW: faker.random.numeric(2),
  avgLMY: faker.random.numeric(2),
  avgIMF: faker.random.numeric(2),
  avgBrandScore: faker.random.numeric(2),
  waterUse: faker.random.numeric(2),
  energyUse: faker.random.numeric(2),
}));

export const mockGridReport = Array.from(Array(7).keys()).map(i => ({
  id: i,
  range: `${18 + i * 2}-${18 + i * 2 + 2}`,
  class1: faker.random.numeric(2),
  class2: faker.random.numeric(2),
  class3: faker.random.numeric(2),
  class4: faker.random.numeric(2),
  class5: faker.random.numeric(2),
  total: faker.random.numeric(2),
}));

export const mockYards = Array.from(Array(10).keys()).map(i => ({
  id: i,
  timeBeforeShipping: `${(i + 1) * 7} Days`,
  numberOfHead: getRandomInt(0, 100),
  ultra: getRandomInt(0, 100),
  fiveStar: getRandomInt(0, 100),
  fourStar: getRandomInt(0, 100),
  choice: getRandomInt(0, 100),
  wagyu: getRandomInt(0, 100),
  estShippingDate: moment()
    .add(getRandomInt(0, 60 * 60 * 24 * 50), 'seconds')
    .format(DateFormat.SHORT),
  dateofInduction: moment()
    .add(-getRandomInt(0, 60 * 60 * 24 * 30), 'seconds')
    .format(DateFormat.SHORT),
  averageWeight: getRandomInt(0, 100),
  usdaScore: getRandomInt(0, 20),
  shippingTo: 'OWB',
}));
