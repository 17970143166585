import * as React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Skeleton,
} from '@mui/material';
import moment from 'moment';

import {
  TableRowProps,
  TableColumnProps,
  TableGroupColumnProps,
  TableRowId,
  Message,
} from '../../utils/Types';
import TablePaginationActions from './TablePaginationActions';
import EnhancedTableToolbar, { ActionButton } from './EnhancedTableToolbar';
import EnhancedTableHead, { Order } from './EnhancedTableHead';
import Row from './Row';
import ServerMessage from '../ServerMessage';

const emptyValueComparator = <T,>(a: T, b: T, orderBy: keyof T) => {
  // treat null as the smallest value
  if (b[orderBy] === null || b[orderBy] === undefined || b[orderBy] === '') {
    return -1;
  }
  if (a[orderBy] === null || a[orderBy] === undefined || a[orderBy] === '') {
    return 1;
  }

  return 0;
};

const descendingComparator = <T,>(
  a: T,
  b: T,
  orderBy: keyof T,
  dateFormat: string
) => {
  // to sort date, the column id should contain one of special strings as below
  if (
    orderBy.toString().includes('date') ||
    orderBy.toString().includes('Date') ||
    orderBy.toString().includes('createdAt') ||
    orderBy.toString().includes('updatedAt')
  ) {
    const comparingEmptyValues = emptyValueComparator(a, b, orderBy);
    if (comparingEmptyValues !== 0) {
      return comparingEmptyValues;
    }

    return (
      moment(b[orderBy] as string, dateFormat).valueOf() -
      moment(a[orderBy] as string, dateFormat).valueOf()
    );
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return emptyValueComparator(a, b, orderBy);
};

const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key,
  dateFormat: string
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, dateFormat)
    : (a, b) => -descendingComparator(a, b, orderBy, dateFormat);
};

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
const stableSort = <T,>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

type DataTableProps = {
  loading?: boolean;
  message?: Message;
  title?: string;
  columns: TableColumnProps[];
  rows: TableRowProps[];
  minWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  collapsedColumns?: string[]; // should not be used with collapsedComponent
  columnGroups?: TableGroupColumnProps[];
  size?: 'small' | 'medium' | undefined;
  background?: string;
  isSelectable?: boolean;
  isSingleSelect?: boolean;
  handleSelect?: Function;
  onClickRow?: Function;
  actionButtons?: ActionButton[];
  borderColor?: string;
  showAllBorder?: boolean;
  searchAttributes?: string[];
  sxSearchTextField?: {};
  collapsedComponent?: Function; // should not be used with collapsedColumns
  sx?: {};
  sxHead?: {};
  sxTitle?: {};
  dateFormat: string;
  defaultRowsPerPage?: number;
};

const DataTable = ({
  loading,
  message,
  title,
  columns,
  rows,
  minWidth,
  minHeight,
  maxHeight,
  collapsedColumns,
  columnGroups,
  size = 'small',
  background,
  isSelectable,
  isSingleSelect,
  handleSelect,
  onClickRow,
  actionButtons,
  borderColor = 'rgba(224, 224, 224, 1)',
  showAllBorder,
  searchAttributes,
  sxSearchTextField,
  collapsedComponent,
  sx,
  sxHead,
  sxTitle,
  dateFormat,
  defaultRowsPerPage,
}: DataTableProps) => {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof TableRowProps>(
    columns.find(i => i.isDefalutOrderBy)?.id || columns[0]?.id
  );
  const [selected, setSelected] = React.useState<readonly TableRowId[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    defaultRowsPerPage || 15
  );
  const [keywords, setKeywords] = React.useState('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TableRowProps
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (searchAttributes) {
    if (searchAttributes.length === 0) {
      throw new Error('Search attributes should contain at least column id.');
    } else {
      const allColumnIds = columns.map(i => i.id);
      searchAttributes.forEach(i => {
        if (!allColumnIds.includes(i)) {
          throw new Error('Search attributes contain id not in columns.');
        }
      });
    }
  }

  const rowsToDisplay: TableRowProps[] = rows.filter(i => {
    let contains = false;
    if (keywords) {
      searchAttributes?.forEach(j => {
        if (i[j]?.toLowerCase().includes(keywords.toLowerCase())) {
          contains = true;
        }
      });
    } else {
      contains = true;
    }
    return contains;
  });

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rowsToDisplay.map(n => n.id);
      setSelected(newSelected);
      handleSelect?.(newSelected);
      return;
    }
    setSelected([]);
    handleSelect?.([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: TableRowId) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly TableRowId[] = [];

    if (isSingleSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat([], name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    }

    setSelected(newSelected);
    handleSelect?.(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: TableRowId) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsToDisplay.length) : 0;

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        background,
      }}
    >
      {title && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          sxTitle={sxTitle}
          actionButtons={actionButtons}
          searchAttributes={searchAttributes}
          sxSearchTextField={sxSearchTextField}
          handleSearch={v => setKeywords(v.target.value)}
        />
      )}
      {message && message.text && <ServerMessage message={message} />}
      <TableContainer sx={{ minWidth, minHeight, maxHeight }}>
        <Table stickyHeader aria-label="sticky table" size={size} sx={sx}>
          <EnhancedTableHead
            columns={columns}
            collapsedColumns={collapsedColumns}
            columnGroups={columnGroups}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rowsToDisplay.length}
            isSelectable={isSelectable}
            isSingleSelect={isSingleSelect}
            borderColor={borderColor}
            showAllBorder={showAllBorder}
            collapsedComponent={collapsedComponent}
            sxHead={sxHead}
          />
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={columns.length - (collapsedColumns?.length || 0)}
                >
                  <Skeleton
                    key="skeleton"
                    variant="rounded"
                    height={40}
                    sx={{ mt: 1 }}
                  />
                </TableCell>
              </TableRow>
            ) : (
              (rowsPerPage > 0 || rowsPerPage === -1
                ? stableSort(
                    rowsToDisplay,
                    getComparator(order, orderBy, dateFormat)
                  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rowsToDisplay
              ).map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Row
                    key={index}
                    index={index}
                    columns={columns}
                    row={row as TableRowProps}
                    collapsedColumns={collapsedColumns}
                    labelId={labelId}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    isSelectable={isSelectable}
                    onClickRow={onClickRow}
                    borderColor={borderColor}
                    showAllBorder={showAllBorder}
                    collapsedComponent={collapsedComponent}
                    allRows={
                      stableSort(
                        rowsToDisplay,
                        getComparator(order, orderBy, dateFormat)
                      ) as TableRowProps[]
                    }
                  />
                );
              })
            )}
            {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }} />}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25, { label: 'All', value: -1 }]}
        component="div"
        count={rowsToDisplay.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
  );
};

export default DataTable;
