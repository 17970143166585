import { Typography, Stack } from '@mui/material';

const NoDataView = ({
  minHeight,
  bgcolor,
}: {
  minHeight?: number;
  bgcolor?: string;
}) => (
  <Stack
    display="flex"
    justifyContent="center"
    alignItems="center"
    bgcolor={bgcolor}
    minHeight={minHeight || 300}
    width="100%"
  >
    <Typography>No data available.</Typography>
  </Stack>
);

export default NoDataView;
