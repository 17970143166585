import axios from 'axios';
import _ from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import * as openpgp from 'openpgp';
import ReactGA from 'react-ga4';
import { bytesToHex as toHex } from '@noble/hashes/utils';
import type { Feature, FeatureCollection, Point } from 'geojson';
import moment from 'moment';
import { AxisType } from 'plotly.js';
import { sha256 } from '@noble/hashes/sha256';

import {
  AVAILABEL_DATA_SOURCE,
  DATA_POOL_ITEM_EXPIRED_IN_MILLISECONDS,
  DEFAULT_MESSAGE,
  NAV_ITEMS,
  UPPER_CASE_TERMS,
} from './Constants';
import {
  ServerRequest,
  Result,
  MessageText,
  ResultCode,
  UserRole,
  Org,
  DateFormat,
  DateFormatUSA,
  Auth,
  User,
  PluginName,
  Trait,
  LooseObject,
  DataPoolItem,
  DateFormatServer,
  DataType,
  QueryResult,
  Condition,
  Conditions,
  Coordinate,
  Boundary,
} from './Types';
import { AuthState, setAuth } from '../redux/reducers/authSlice';
import { OrgState, setOrg } from '../redux/reducers/orgSlice';
import { UserState, setUser } from '../redux/reducers/userSlice';
import { setDataPool } from '../redux/reducers/dataPoolSlice';

export const postToServer = async ({
  action,
  params,
  token,
}: ServerRequest) => {
  const result: Result = {
    message: DEFAULT_MESSAGE,
    serverData: null,
  };

  await axios
    .post(process.env.REACT_APP_API_ENDPOINT + '/user/' + action, params, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token ? `Bearer ${token}` : null,
      },
    })
    .then(async response => {
      result.statusCode = response.status;

      const { data } = response;

      if (data && data.auth) {
        // update token
        result.auth = JSON.parse(data.auth);
      }
      result.message = {
        text: data.msg,
        type: data.code === ResultCode.SUCCESS ? 'success' : 'error',
      };

      if (data.code === ResultCode.SUCCESS && !_.isEmpty(data.data)) {
        result.serverData = JSON.parse(data.data);
      }
    })
    .catch(error => {
      if (error.response?.data) {
        result.message = {
          text: error.response.data.msg,
          type: 'error',
        };
        result.statusCode = error.response?.status;
      } else {
        result.message = {
          text: MessageText.NETWORK_ERROR,
          type: 'error',
        };
      }
    });

  return result;
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
};

export const getPrecisionOfNumber = (a: number) => {
  if (!isFinite(a)) return 0;
  var e = 1,
    p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
};

export const isNotEmpty = (data: any) =>
  data !== undefined &&
  data !== null &&
  data !== '' &&
  JSON.stringify(data) !== '{}' &&
  JSON.stringify(data) !== '()' &&
  JSON.stringify(data) !== '"()"';

export const isNumber = (value: any, acceptScientificNotation?: boolean) => {
  if (!acceptScientificNotation) {
    return /^-{0,1}\d+(\.\d+)?$/.test(value);
  }
  if (Array.isArray(value)) {
    return false;
  }
  return !isNaN(parseInt(value, 10));
};

export const getRandomPassword = (length = 18) => {
  const sample = '23456789ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz';
  return Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map(x => sample[x % sample.length])
    .join('');
};

export const sortArrayByAnotherArray = (
  arrayToBeSorted: any[],
  key: string,
  arrayBy: (string | number)[]
) =>
  arrayToBeSorted.sort(
    (a, b) => arrayBy.indexOf(a[key]) - arrayBy.indexOf(b[key])
  );

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const asyEncrypt = async ({
  text,
  pubKey,
}: {
  text: string;
  pubKey: string;
}) => {
  const publicKey = await openpgp.readKey({ armoredKey: pubKey });
  const encrypted = await openpgp.encrypt({
    message: await openpgp.createMessage({ text }), // input as Message object
    encryptionKeys: publicKey,
  });
  return encrypted;
};

export const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
};

export const getUserRoleName = (value: number) =>
  _.startCase(
    Object.keys(UserRole)[Object.values(UserRole).indexOf(value)].toLowerCase()
  );

export const getUserRoleNames = ({
  min,
  max,
}: {
  min?: UserRole;
  max?: UserRole;
}) =>
  Object.keys(UserRole)
    .filter(
      k =>
        (Number(UserRole[k]) || Number(UserRole[k]) === 0) &&
        UserRole[k] >= (min || UserRole.DEFAULT) &&
        UserRole[k] <= (max || UserRole.SUPER_ADMIN)
    )
    .map(key => ({
      label: _.startCase(key.toLowerCase()),
      value: UserRole[key],
    }));

type logoutProps = {
  dispatch: ThunkDispatch<
    {
      auth: AuthState;
      org: OrgState;
      user: UserState;
    },
    undefined,
    AnyAction
  >;
  // themeOverides: {
  //   apply: (overides: any) => void;
  // };
  navigate: NavigateFunction;
};

export const logout = ({ dispatch, navigate }: logoutProps) => {
  dispatch(
    setAuth({
      type: 'auth/remove',
      payload: null,
    })
  );
  dispatch(
    setOrg({
      type: 'org/set',
      payload: {},
    })
  );
  dispatch(
    setUser({
      type: 'user/remove',
      payload: null,
    })
  );
  dispatch(
    setUser({
      type: 'dataPool/remove',
      payload: null,
    })
  );
  localStorage.removeItem('auth');
  localStorage.removeItem('org');
  localStorage.removeItem('user');

  // navigate('/', { replace: true });
  if (process.env.REACT_APP_INSIGHTS_V2) {
    window.location.href = `${process.env.REACT_APP_INSIGHTS_V2}/logout`;
  }
};

export const getDateFormat = (
  org: Org,
  type: 'default' | 'short' | 'full' | 'day_and_time' | 'unix_timestamp'
) => {
  let dateFormat = DateFormat.DEFAULT as string;
  switch (type) {
    case 'default':
      dateFormat = org?.isUSA ? DateFormatUSA.DEFAULT : DateFormat.DEFAULT;
      break;
    case 'short':
      dateFormat = org?.isUSA ? DateFormatUSA.SHORT : DateFormat.SHORT;
      break;
    case 'full':
      dateFormat = org?.isUSA ? DateFormatUSA.FULL : DateFormat.FULL;
      break;
    case 'day_and_time':
      dateFormat = org?.isUSA
        ? DateFormatUSA.DAY_AND_TIME
        : DateFormat.DAY_AND_TIME;
      break;
    case 'unix_timestamp':
      dateFormat = org?.isUSA
        ? DateFormatUSA.UNIX_TIMESTAMP
        : DateFormat.UNIX_TIMESTAMP;
      break;
  }
  return dateFormat;
};

// load org with id or hostname
export const loadOrg = async ({
  orgId,
  hostname,
  themeOverides,
  dispatch,
}: {
  orgId?: number;
  hostname?: string;
  themeOverides: any;
  dispatch: any;
}) => {
  if (orgId || hostname) {
    await postToServer({
      action: 'GetOrg',
      params: { orgId, hostname },
      token: '',
    }).then(response => {
      if (response.message.type === 'success') {
        const org = response.serverData as Org;

        ReactGA.gtag('set', 'user_properties', {
          org_name: org?.name,
        });

        themeOverides.apply(org?.theme);

        localStorage.setItem('org', JSON.stringify(org));
        dispatch(
          setOrg({
            type: 'org/set',
            payload: response.serverData,
          })
        );
      }
    });
  }
};

export const signInToOrg = async ({
  orgId,
  auth,
  themeOverides,
  dispatch,
  navigate,
  snackbar,
}: {
  orgId?: number;
  auth?: Auth;
  themeOverides: any;
  dispatch: any;
  navigate: NavigateFunction;
  snackbar: any;
}) => {
  if (orgId !== null && orgId !== undefined) {
    if (auth?.token) {
      await postToServer({
        action: 'SignInToOrg',
        params: { orgId },
        token: auth?.token,
      }).then(response => {
        if (response.message.type === 'success') {
          const user = response.serverData as User;

          ReactGA.set({ userId: user?.email });

          loadOrg({ orgId: user?.orgId, themeOverides, dispatch });

          // save auth to current browser
          localStorage.setItem('auth', JSON.stringify(response.auth));
          dispatch(
            setAuth({
              type: 'auth/set',
              payload: response.auth,
            })
          );

          // save user to current browser
          localStorage.setItem('user', JSON.stringify(response.serverData));
          dispatch(
            setUser({
              type: 'user/set',
              payload: response.serverData,
            })
          );

          const sortedPlugins = user?.plugins
            ? [...user?.plugins].sort()
            : null;

          // navigate to private pages
          // first avalable plugin or Account
          const toPage = sortedPlugins?.[0]
            ? NAV_ITEMS.find(i => i.category === sortedPlugins?.[0])
            : NAV_ITEMS.find(i => i.category === 'Account');

          navigate(toPage!.to);
        } else {
          snackbar.open(response.message);
        }
      });
    }
  }
};

export const getTraitType = ({
  key,
  value,
  type,
}: {
  key: string;
  value: any;
  type?: string;
}) => {
  let traitType: Trait['type'] = 'unknown';
  if (type) {
    switch (type) {
      case 'string':
        traitType = 'string';
        break;
      case 'integer':
        traitType = 'number';
        break;
      case 'float':
        traitType = 'number';
        break;
    }
  } else if (key?.includes('date')) {
    traitType = 'date';
  } else {
    switch (typeof value) {
      case 'string':
        traitType = 'string';
        break;

      case 'number':
        traitType = 'number';
        break;
    }
  }
  return traitType;
};

export const getPluginFromUrl = (url: string) => {
  let plugin: string | undefined = '';
  if (url) {
    plugin = Object.values(PluginName).find(
      i => url.toLowerCase().indexOf(i.toLocaleLowerCase()) >= 0
    );
  }
  return plugin;
};

export const getUniqTraits = (allTraits: Trait[][]) => {
  const uniqTraits: Trait[] = [];
  for (let i = 0; i < allTraits.length; i++) {
    const traits = allTraits[i];
    traits.forEach(t => {
      if (!uniqTraits.map(o => o.key).includes(t.key)) {
        uniqTraits.push(t);
      }
    });
  }
  return uniqTraits;
};

export const padLeft = (nr: number, n: number, str?: string) =>
  Array(n - String(nr).length + 1).join(str || '0') + nr;

export const assignIds = (data: LooseObject[]) => {
  if (data && Array.isArray(data)) {
    const prefix = getRandomPassword(5);
    const sequenceLength = data.length.toString().length;

    return data.map(
      (i, index) =>
        ({
          id: i.id || `${prefix}_${padLeft(index, sequenceLength)}`,
          ...i,
        } as LooseObject)
    );
  }

  return data;
};

export const getAllAtributesOfAnObject = (obj: LooseObject) => {
  const attributes: Trait[] = [];
  Object.keys(obj).forEach(k => {
    if (Array.isArray(obj[k])) {
      obj[k].forEach((i: LooseObject) =>
        attributes.push({
          key: i.key,
          label: i.label,
          type: getTraitType({ key: i.key, value: i.value, type: i.type }),
          isInArray: true,
        })
      );
    } else {
      attributes.push({
        key: k,
        label: _.startCase(k),
        type: getTraitType({ key: k, value: obj[k] }),
      });
    }
  });
  return attributes;
};

export const getAllAtributesOfAList = (data: LooseObject[]) => {
  const allTraits: Trait[][] = [];
  data.forEach(i => allTraits.push(getAllAtributesOfAnObject(i)));
  return getUniqTraits(allTraits);
};

export const objectWithKeySorted = (obj: LooseObject) => {
  const objWithKeySorted: LooseObject = {};
  const keys = Object.keys(obj).sort();
  for (let i = 0; i < keys.length; i++) {
    const value = obj[keys[i]];
    if (
      (value && JSON.stringify(value) !== JSON.stringify({})) ||
      value === 0
    ) {
      objWithKeySorted[keys[i]] = value;
    }
  }
  return objWithKeySorted;
};

// assume only one attribute is array
export const getDataFromObjectArray = (
  array: LooseObject[],
  key: string,
  allAttributes?: Trait[]
) => {
  let data = [];
  if (array && array.length > 0 && key) {
    if (!allAttributes) {
      allAttributes = getAllAtributesOfAList(array);
    }
    const attribute = allAttributes.find(i => i.key === key);
    if (attribute?.isInArray) {
      array.forEach(i => {
        for (let j = 0; j < Object.keys(i).length; j++) {
          const k = Object.keys(i)[j];
          if (Array.isArray(i[k])) {
            const trait = i[k].find((t: any) => t.key === key);
            data.push(trait ? trait.value : null);
            break;
          }
        }
      });
    } else {
      data = array.map(i => i[key]);
    }
  }
  return data;
};

export const filterData = (data: LooseObject[], conditions: LooseObject) => {
  const attributes = getAllAtributesOfAList(data);
  const dataToDisplay = data.filter(i => {
    let display = true;
    Object.keys(conditions).forEach(key => {
      const attribute = attributes.find(a => a.key === key);
      if (conditions[key] !== null && conditions[key] !== undefined) {
        if (i[key] || i[key] === 0) {
          // is a direct key
          if (Array.isArray(conditions[key])) {
            if (!conditions[key].includes(i[key])) {
              display = false;
            }
          } else {
            switch (attribute?.type) {
              case 'date':
                if (
                  isDateGreater(conditions[key].from, i[key]) ||
                  isDateGreater(i[key], conditions[key].to)
                ) {
                  display = false;
                }
                break;
              case 'number':
                if (
                  conditions[key].from > i[key] ||
                  i[key] > conditions[key].to
                ) {
                  display = false;
                }
                break;
            }
          }
        } else {
          // is in traits
          for (let m = 0; m < Object.keys(data[0]).length; m++) {
            const k = Object.keys(data[0])[m];
            if (Array.isArray(data[0][k])) {
              const trait = data[0][k].find((t: any) => t.key === key);
              if (trait) {
                if (Array.isArray(conditions[key])) {
                  if (
                    !conditions[key].includes(
                      i[k].find((t: any) => t.key === key)?.value
                    )
                  ) {
                    display = false;
                  }
                } else {
                  switch (attribute?.type) {
                    case 'date':
                      if (
                        isDateGreater(
                          conditions[key].from,
                          i[k].find((t: any) => t.key === key)?.value
                        ) ||
                        isDateGreater(
                          i[k].find((t: any) => t.key === key)?.value,
                          conditions[key].to
                        )
                      ) {
                        display = false;
                      }
                      break;
                    case 'number':
                      if (
                        conditions[key].from >
                          i[k].find((t: any) => t.key === key)?.value ||
                        i[k].find((t: any) => t.key === key)?.value >
                          conditions[key].to
                      ) {
                        display = false;
                      }
                      break;
                  }
                }
                break;
              }
            }
          }
        }
      }
    });
    return display;
  });
  return dataToDisplay;
};

export const getFlatObject = (obj: LooseObject) => {
  const flatObj: LooseObject = {};
  Object.keys(obj).forEach(k => {
    if (Array.isArray(obj[k])) {
      obj[k].forEach((o: any) => {
        flatObj[o.key] = obj[k].find((t: any) => t.key === o.key)?.value;
      });
    } else {
      flatObj[k] = obj[k];
    }
  });
  return flatObj;
};

export const getFlatObjectArray = (
  array: LooseObject[],
  allAttributes?: Trait[]
) => {
  let data: LooseObject[] = [];
  if (array && array.length > 0) {
    if (!allAttributes) {
      allAttributes = getAllAtributesOfAList(array);
    }

    array.forEach(i => {
      const obj: LooseObject = {};
      allAttributes?.forEach(attribute => {
        if (attribute?.isInArray) {
          let hasValue = false;
          for (let j = 0; j < Object.keys(i).length; j++) {
            const k = Object.keys(i)[j];
            if (Array.isArray(i[k])) {
              const trait = i[k].find((t: any) => t.key === attribute.key);
              if (trait) {
                obj[attribute.key] = trait?.value || null;
                hasValue = true;
                break;
              }
            }
          }
          if (!hasValue) {
            obj[attribute.key] = null;
          }
        } else {
          obj[attribute.key] = i[attribute.key];
        }
      });
      data.push(obj);
    });
  }

  return data;
};

export const generateDataPoolId = ({
  dataSourceKey,
  dataSourceParams,
}: {
  dataSourceKey: string;
  dataSourceParams: LooseObject;
}) =>
  toHex(
    sha256(
      dataSourceKey +
        (isNotEmpty(dataSourceParams)
          ? JSON.stringify(objectWithKeySorted(dataSourceParams))
          : '')
    )
  );

export const getDataPoolItemFromDataSource = async ({
  auth,
  dataSourceKey,
  dataSourceParams,
}: {
  auth: Auth;
  dataSourceKey: string;
  dataSourceParams: LooseObject;
}) => {
  if (auth?.token && dataSourceKey) {
    const dataSource = AVAILABEL_DATA_SOURCE.find(i => i.key === dataSourceKey);
    if (
      dataSource &&
      (dataSource.isNoParamsPermitted || !_.isEmpty(dataSourceParams))
    ) {
      const dataPoolItem: DataPoolItem = {
        id: generateDataPoolId({ dataSourceKey, dataSourceParams }),
        dataSource,
        dataSourceParams,
        data: [],
        attributes: [],
        updatedAt: Date.now(),
      };
      await postToServer({
        action: dataSource.endpoint,
        params: dataSourceParams || {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            const attributes = getAllAtributesOfAList(serverData).filter(
              i => !dataSource.excludeAttributes.includes(i.key)
            );
            dataPoolItem.data = serverData;
            dataPoolItem.attributes = attributes;
          }
        }
      });

      return dataPoolItem;
    }
  }

  return undefined;
};

export const updateDataPool = async ({
  dataPool,
  dataSourceKey,
  dataSourceParams,
  auth,
  dispatch,
}: {
  dataPool: DataPoolItem[];
  dataSourceKey: string;
  dataSourceParams: LooseObject;
  auth: Auth;
  dispatch: any;
}) => {
  let existingDataPool = [...dataPool];

  const dataPoolId = generateDataPoolId({
    dataSourceKey,
    dataSourceParams,
  });

  const existingDataPoolItem = existingDataPool.find(i => i.id === dataPoolId);

  if (
    !existingDataPoolItem ||
    Date.now() - existingDataPoolItem.updatedAt >
      DATA_POOL_ITEM_EXPIRED_IN_MILLISECONDS
  ) {
    const dataPoolItem = await getDataPoolItemFromDataSource({
      auth,
      dataSourceKey,
      dataSourceParams,
    });

    if (dataPoolItem) {
      existingDataPool = [
        ...existingDataPool.filter(i => i.id !== dataPoolItem.id),
        dataPoolItem,
      ];
      dispatch(
        setDataPool({
          type: 'dataPool/set',
          payload: existingDataPool,
        })
      );
    }

    return existingDataPool;
  }
  return dataPool;
};

export const compareServerDatesDesc = ({
  a,
  b,
  dateFormat,
}: {
  a: string;
  b: string;
  dateFormat: string;
}) => {
  const aDate = moment(a, dateFormat);
  const bDate = moment(b, dateFormat);
  if (aDate > bDate) {
    return -1;
  }
  if (aDate < bDate) {
    return 1;
  }
  return 0;
};

export const getFilterCondition = ({
  values,
  type,
  data,
}: {
  values: any[];
  type?: string;
  data: any[];
}) => {
  if (values && Array.isArray(values) && values.length > 0) {
    switch (type) {
      case 'date':
      case 'datetime':
        values.sort(
          (a, b) =>
            -compareServerDatesDesc({
              a,
              b,
              dateFormat: DateFormatServer.SHORT,
            })
        );
        data.sort(
          (a, b) =>
            -compareServerDatesDesc({
              a,
              b,
              dateFormat: DateFormatServer.SHORT,
            })
        );
        // if date, find the closest from and to
        const dateWithoutSmall = data.filter(i =>
          isDateGreater(i, values[0], true)
        );
        const fromDate = dateWithoutSmall?.[0];
        const dateWithoutBig = data.filter(
          i => !isDateGreater(i, values[values.length - 1])
        );
        const toDate = dateWithoutBig[dateWithoutBig.length - 1];

        return {
          from: fromDate,
          to: isDateGreater(fromDate, toDate) ? fromDate : toDate,
        };

      case 'number':
        values.sort((a, b) => a - b);
        data.sort((a, b) => a - b);
        // if number, find the closest from and to
        const numberWithoutSmall = data.filter(i => i >= values[0]);
        const fromNumber = numberWithoutSmall?.[0];
        const numberWithoutBig = data.filter(
          i => i <= values[values.length - 1]
        );
        const toNumber = numberWithoutBig[numberWithoutBig.length - 1];
        return {
          from: fromNumber,
          to: fromNumber > toNumber ? fromNumber : toNumber,
        };
    }
  }
  return values;
};

export const isDataCalculatable = (dataType?: DataType) =>
  dataType &&
  (dataType === 'date' || dataType === 'datetime' || dataType === 'number');

export const isDateGreater = (
  a: string,
  b: string,
  includeEqual?: boolean,
  dateFormat?: string
) =>
  includeEqual
    ? moment(a, dateFormat || DateFormatServer.SHORT) >=
      moment(b, dateFormat || DateFormatServer.SHORT)
    : moment(a, dateFormat || DateFormatServer.SHORT) >
      moment(b, dateFormat || DateFormatServer.SHORT);

export const getJsDataTypeFromServerDataType = (serverDataType: string) => {
  let type = '';
  switch (serverDataType) {
    case 'text':
      type = 'string';
      break;
    case 'fixed':
    case 'real':
      type = 'number';
      break;
    case 'date':
      type = 'date';
      break;
    case 'timestamp_ntz':
      type = 'datetime';
      break;
  }
  return type;
};

export const getAxisTypeFromJsDataType = (jsDataType?: string) => {
  let type: AxisType = '-';
  switch (jsDataType) {
    case 'string':
      type = 'category';
      break;
    case 'date':
    case 'datetime':
      type = 'date';
      break;
  }
  return type;
};

export const getQueryBuilderInputTypeFromJsDataType = (jsDataType: string) => {
  let type = 'text';
  switch (jsDataType) {
    case 'string':
      type = 'text';
      break;
    case 'number':
      type = 'number';
      break;
    case 'date':
    case 'datetime':
      type = 'date';
      break;
  }
  return type;
};

export const linearRegression = (x: number[], y: number[]) => {
  const lr = {};
  const n = y.length;
  let sum_x = 0;
  let sum_y = 0;
  let sum_xy = 0;
  let sum_xx = 0;
  let sum_yy = 0;

  for (let i = 0; i < y.length; i++) {
    sum_x += x[i];
    sum_y += y[i];
    sum_xy += x[i] * y[i];
    sum_xx += x[i] * x[i];
    sum_yy += y[i] * y[i];
  }

  lr['sl'] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
  lr['off'] = (sum_y - lr['sl'] * sum_x) / n;
  lr['r2'] = Math.pow(
    (n * sum_xy - sum_x * sum_y) /
      Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
    2
  );

  return lr;
};

// transfer db data type to js data type
export const optimiseQueryResult = ({ columns, ...rest }: QueryResult) => ({
  columns:
    columns && columns.length > 0
      ? columns.map(i => ({
          name: i.name,
          label: applyUpperCaseTerms(
            _.startCase(i.name.toLowerCase()).replace('Percent Of', '%')
          ),
          scale: i.scale,
          type: getJsDataTypeFromServerDataType(i.type),
        }))
      : [],
  ...rest,
});

// for MapBox to use
export const parseArrayToFeatureCollection = (
  arr: { [key: string]: any }[],
  point: { longitudeKey: string; latitudeKey: string }
): FeatureCollection<Point> => {
  const features: Feature<Point>[] = [];

  for (const obj of arr) {
    if (
      obj &&
      typeof obj[point.latitudeKey] === 'number' &&
      typeof obj[point.longitudeKey] === 'number'
    ) {
      const latitude = obj[point.latitudeKey];
      const longitude = obj[point.longitudeKey];

      const pointGeometry: Point = {
        type: 'Point',
        coordinates: [longitude, latitude], // GeoJSON uses [longitude, latitude] order
      };

      const feature: Feature<Point> = {
        type: 'Feature',
        geometry: pointGeometry,
        properties: obj, // Assigning the object as properties, you can modify this as per your requirements
      };

      features.push(feature);
    }
  }

  const featureCollection: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features,
  };

  return featureCollection;
};

export const showAllAttributesWhenHoverChart = ({
  item,
  excludeAttributes = [],
}: {
  item: LooseObject;
  excludeAttributes?: string[];
}) => {
  return Object.keys(item)
    .filter(i => !excludeAttributes.includes(i))
    .sort()
    .map(
      i => `${applyUpperCaseTerms(_.startCase(i.toLowerCase()))}: ${item[i]}`
    )
    .join('<br />');
};

export const applyUpperCaseTerms = (str: string) => {
  let updatedStr = str;
  UPPER_CASE_TERMS.forEach(i => {
    updatedStr = updatedStr.replaceAll(i.toLowerCase(), i);
    updatedStr = updatedStr.replaceAll(_.startCase(i.toLowerCase()), i);
  });
  return updatedStr;
};

export const conditionValueToString = (condition: Condition) => {
  let str = condition?.value;
  if (isNotEmpty(condition?.value?.from) && isNotEmpty(condition?.value?.to)) {
    str = `${condition.value.from} - ${condition.value.to}`;
  } else {
    if (isNotEmpty(condition?.value?.from)) {
      str = `>= ${condition.value.from}`;
    }
    if (isNotEmpty(condition?.value?.to)) {
      str = `<= ${condition.value.to}`;
    }
  }
  if (Array.isArray(condition?.value)) {
    str = condition.value.join(', ');
    if (str.length > 15) {
      str = str.substring(0, 15) + '...';
    }
  }

  return str;
};

export const filterByConditions = ({
  data,
  conditions,
}: {
  data: LooseObject[] | undefined;
  conditions: Conditions;
}) => {
  const result: LooseObject[] = [];
  if (data) {
    if (Object.keys(conditions).length === 0) {
      return data;
    } else {
      data.forEach(d => {
        let flag = true;
        Object.keys(conditions).forEach(k => {
          if (conditions[k]) {
            if (isNotEmpty(conditions[k]?.value)) {
              if (
                isNotEmpty(conditions[k]?.value?.from) ||
                isNotEmpty(conditions[k]?.value?.to)
              ) {
                if (isNotEmpty(conditions[k]?.value?.from)) {
                  if (d[k] < conditions[k]?.value?.from) {
                    flag = false;
                  }
                }
                if (isNotEmpty(conditions[k]?.value?.to)) {
                  if (d[k] > conditions[k]?.value?.to) {
                    flag = false;
                  }
                }
              } else if (Array.isArray(conditions[k]?.value)) {
                if (!conditions[k]?.value.includes(d[k])) {
                  flag = false;
                }
              } else {
                if (!conditions[k]?.value !== d[k]) {
                  flag = false;
                }
              }
            }
          }
        });
        if (flag) {
          result.push(d);
        }
      });
    }
  }
  return result;
};

export const getGeoSqlConditionsWithDistance = ({
  center,
  distance_km,
}: {
  center: Coordinate;
  distance_km: number;
}) =>
  `ST_DISTANCE(ST_MAKEPOINT(AVGLON, AVGLAT), ST_MAKEPOINT(${center.longitude}, ${center.latitude})) <= ${distance_km} * 1000;`;

export const getGeoSqlConditionsWithBounds = ({
  bounds,
}: {
  bounds: Boundary;
}) =>
  `ST_WITHIN(ST_POINT(AVGLON, AVGLAT), ST_GEOGFROMTEXT('POLYGON((${bounds.ne.longitude} ${bounds.ne.latitude}, ${bounds.sw.longitude} ${bounds.ne.latitude}, ${bounds.sw.longitude} ${bounds.sw.latitude}, ${bounds.ne.longitude} ${bounds.sw.latitude}, ${bounds.ne.longitude} ${bounds.ne.latitude}))'));`;
