import { Stack } from '@mui/material';
import _ from 'lodash';

import {
  Conditions,
  FormInputCategory,
  FormInputItem,
  LabelValuePair,
  LooseObject,
  QueryResultColumn,
} from '../../../utils/Types';
import { Form } from '../../../components';

const Filter = ({
  conditions,
  columns,
  excludeColumns = [],
  rows = [],
  handleSubmit,
}: {
  conditions: Conditions;
  columns: QueryResultColumn[] | undefined;
  excludeColumns?: string[];
  rows?: LooseObject[];
  handleSubmit: (v: any) => void;
}) => {
  const inputs = columns
    ?.filter(i => !excludeColumns.includes(i.name))
    .map(
      i =>
        ({
          name: i.name,
          label: i.label,
          size: 'small',
          options: _.uniqBy(
            rows.map(
              m => ({ label: m[i.name], value: m[i.name] } as LabelValuePair)
            ),
            'label'
          ),
          category:
            i.type === 'number'
              ? FormInputCategory.NUMBER_RANGE_INPUT
              : i.type === 'date' || i.type === 'datetime'
              ? FormInputCategory.DATE_RANGE_PICKER
              : FormInputCategory.AUTO_COMPLETE,
          multiple: true,
          defaultValue: Array.isArray(conditions[i.name]?.value)
            ? conditions[i.name]?.value.map((v: LabelValuePair) => ({
                label: v,
                value: v,
              }))
            : conditions[i.name]?.value,
        } as FormInputItem)
    );

  return (
    <Stack direction="row" spacing={1}>
      <Stack
        spacing={1}
        borderRight={1}
        borderColor={theme => theme.palette.divider}
        p={2}
        width={520}
        height="100%"
      >
        {inputs && (
          <Form
            onSubmit={handleSubmit}
            buttonText="Update"
            inputs={inputs}
            size="small"
            buttonSize="medium"
          />
        )}
      </Stack>
    </Stack>
  );
};

export default Filter;
