import {
  TextField,
  Stack,
  Autocomplete as MuiAutoComplete,
} from '@mui/material';
import { FormInputItem, LooseObject } from '../../utils/Types';
import _ from 'lodash';

type Props = {
  item: FormInputItem;
  textFieldProps: LooseObject;
  values: {};
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  inputProps?: {};
  loading?: boolean;
};

const AutoComplete = ({
  item,
  values,
  setFieldValue,
  textFieldProps,
  loading,
}: Props) => {
  return (
    <Stack key={item.name} alignItems="flex-start" width="100%">
      <MuiAutoComplete
        {..._.omit(textFieldProps, ['autoComplete'])}
        renderInput={params => (
          <TextField
            {...params}
            label={item.label}
            placeholder={item.placeholder}
          />
        )}
        value={values[item.name]}
        onChange={(e, v) => {
          setFieldValue(item.name, v);
          item.exposeValue?.(v);
        }}
        options={_.orderBy(item.options as readonly any[], 'label')}
        freeSolo
        forcePopupIcon
        loading={loading}
        limitTags={2}
        multiple={item.multiple}
        disableCloseOnSelect={item.multiple}
      />
    </Stack>
  );
};

export default AutoComplete;
