import { useEffect, useState } from 'react';
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
} from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getDateFormat, logout, postToServer } from '../../../utils/Helper';
import { Auth, DateFormatServer, LooseObject } from '../../../utils/Types';
import { Trait, Type } from './GraphArea';
import { CONTENT_PADDING } from '../../../utils/Constants';
import { selectOrg } from '../../../redux/reducers/orgSlice';

type Props = {
  snackbar: any;
  auth: Auth;
  setLoading: (loading: boolean) => void;
  setDateForGraph: (date: Moment | null) => void;
  setTraitForGraph: (trait: Trait) => void;
  setTypeForGraph: (type: Type) => void;
  setFacilityForGraph: (facility: string) => void;
  setDataForGraph: (data: LooseObject | undefined) => void;
  setDataForGraphAdjusted: (data: LooseObject | undefined) => void;
};

const Filter = ({
  snackbar,
  auth,
  setLoading,
  setDateForGraph,
  setTraitForGraph,
  setTypeForGraph,
  setFacilityForGraph,
  setDataForGraph,
  setDataForGraphAdjusted,
}: Props) => {
  const [allFacilities, setAllFacilities] = useState<string[]>([]);
  const [date, setDate] = useState<Moment | null>(null);
  const [trait, setTrait] = useState<Trait>(Trait.EYE_MUSCLE);
  const [type, setType] = useState<Type>(Type.FRAMES);
  const [facility, setFacility] = useState<string>('');
  const [validDates, setValidDates] = useState<Moment[]>([]);
  const [loadingFacilities, setLoadingFacilities] = useState(false);
  const [loadingDates, setLoadingDates] = useState(false);

  const org = useAppSelector(selectOrg);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadFacilities();
  }, []);

  const loadFacilities = () => {
    if (auth?.token) {
      setLoading(true);
      setLoadingFacilities(true);
      postToServer({
        action: 'machine-learning/depth-camera/GetLocations',
        params: {},
        token: auth.token,
      }).then(response => {
        setLoadingFacilities(false);
        setLoading(false);
        if (response.message.type === 'success' && response.serverData) {
          const data = (response.serverData as string[]).sort();
          setAllFacilities(data);
          if (data.length > 0) {
            // default facility to the first location
            const defaultFacility = data[0];
            setFacility(defaultFacility);
            setFacilityForGraph(defaultFacility);
            loadDates(defaultFacility);
          }
        } else {
          snackbar.open(response.message);
        }
      });
    }
  };

  const loadDates = (updatedFacility: string) => {
    if (auth?.token && updatedFacility) {
      setLoading(true);
      setLoadingDates(true);
      postToServer({
        action: 'machine-learning/depth-camera/GetDates',
        params: { location: updatedFacility },
        token: auth.token,
      }).then(response => {
        setLoadingDates(false);
        setLoading(false);
        if (response.message.type === 'success' && response.serverData) {
          const data = response.serverData as LooseObject[];
          if (data.length > 0) {
            const optimisedData = data
              .map(i => moment(i, DateFormatServer.SHORT))
              .sort((a, b) => (a > b ? -1 : a < b ? 1 : 0));

            setValidDates(optimisedData);
            // show graph for default facility and latest available date
            const defaultDate = optimisedData[0];
            setDate(defaultDate);
            setDateForGraph(defaultDate);
            fetchDataForGraph({
              faci: updatedFacility,
              dat: defaultDate,
            });
            fetchDataForGraphAdjusted({
              faci: updatedFacility,
              dat: defaultDate,
            });
          }
        } else {
          snackbar.open(response.message);
        }
      });
    }
  };

  const fetchDataForGraph = ({
    dat,
    tra,
    typ,
    faci,
  }: {
    dat?: Moment | null;
    tra?: Trait;
    typ?: Type;
    faci?: string;
  }) => {
    if (auth?.token) {
      setLoading(true);
      snackbar.close();
      postToServer({
        action: 'machine-learning/depth-camera/GetData',
        params: {
          location: faci || facility,
          date:
            dat?.format(DateFormatServer.SHORT) ||
            date?.format(DateFormatServer.SHORT),
          trait: tra || trait,
          type: typ || type,
        },
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            if (JSON.stringify(response.serverData) === '{}') {
              setDataForGraph(undefined);
            } else {
              setDataForGraph(response.serverData as LooseObject);
            }
          } else {
            setDataForGraph(undefined);
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const fetchDataForGraphAdjusted = ({
    dat,
    tra,
    typ,
    faci,
  }: {
    dat?: Moment | null;
    tra?: Trait;
    typ?: Type;
    faci?: string;
  }) => {
    if (auth?.token) {
      setLoading(true);
      snackbar.close();
      postToServer({
        action: 'machine-learning/depth-camera/GetAdjustedData',
        params: {
          location: faci || facility,
          date:
            dat?.format(DateFormatServer.SHORT) ||
            date?.format(DateFormatServer.SHORT),
          trait: tra || trait,
          type: typ || type,
        },
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            if (JSON.stringify(response.serverData) === '{}') {
              setDataForGraphAdjusted(undefined);
            } else {
              setDataForGraphAdjusted(response.serverData as LooseObject);
            }
          } else {
            setDataForGraphAdjusted(undefined);
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const validDateStrings = validDates.map(i =>
    i.format(getDateFormat(org, 'short'))
  );
  const disableDates = (date: Moment) => {
    const currentDateString = date.format(getDateFormat(org, 'short'));
    const shouldDisable = !validDateStrings.includes(currentDateString);
    return shouldDisable;
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1} minHeight={`calc(100vh - ${190}px)`}>
        <Stack
          bgcolor={grey[100]}
          spacing={1}
          borderRight={1}
          borderColor={theme => theme.palette.divider}
        >
          <Stack>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateCalendar
                loading={loadingDates}
                renderLoading={() => <DayCalendarSkeleton />}
                disableFuture
                value={date}
                onChange={(v: Moment | null) => {
                  setDate(v);
                  setDateForGraph(v);
                  fetchDataForGraph({ dat: v });
                  fetchDataForGraphAdjusted({ dat: v });
                }}
                shouldDisableDate={disableDates}
                slots={{
                  day: ({ day, ...rest }) => (
                    <PickersDay
                      day={day}
                      {...rest}
                      selected={
                        day.format(getDateFormat(org, 'short')) ===
                        date?.format(getDateFormat(org, 'short'))
                      }
                      sx={{
                        border: validDateStrings.includes(
                          day.format(getDateFormat(org, 'short'))
                        )
                          ? 1
                          : 0,
                        borderColor: theme => theme.palette.primary.main,
                      }}
                    />
                  ),
                }}
              />
            </LocalizationProvider>
          </Stack>
          <Divider />
          <Stack p={CONTENT_PADDING}>
            <Typography variant="h6">Locations</Typography>
            <RadioGroup
              value={facility}
              onChange={(e, v) => {
                setFacility(v);
                setFacilityForGraph(v);
                loadDates(v);
              }}
            >
              {loadingFacilities ? (
                <Skeleton variant="rounded" height={84} />
              ) : (
                allFacilities.map(item => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={_.startCase(item)}
                  />
                ))
              )}
            </RadioGroup>
          </Stack>
          <Divider />
          <Stack p={CONTENT_PADDING}>
            <Typography variant="h6">Trait</Typography>
            <RadioGroup
              defaultValue={Trait.EYE_MUSCLE}
              onChange={(e, v) => {
                setTrait(v as Trait);
                setTraitForGraph(v as Trait);
                fetchDataForGraph({ tra: v as Trait });
                fetchDataForGraphAdjusted({ tra: v as Trait });
              }}
            >
              {Object.values(Trait).map(item => (
                <FormControlLabel
                  key={item}
                  value={item}
                  control={<Radio />}
                  label={_.startCase(item)}
                />
              ))}
            </RadioGroup>
          </Stack>
          <Divider />
          <Stack p={CONTENT_PADDING}>
            <Typography variant="h6">Type</Typography>
            <RadioGroup
              defaultValue={Type.FRAMES}
              onChange={(e, v) => {
                setType(v as Type);
                setTypeForGraph(v as Type);
                fetchDataForGraph({ typ: v as Type });
                fetchDataForGraphAdjusted({ typ: v as Type });
              }}
            >
              {Object.values(Type).map(item => (
                <FormControlLabel
                  key={item}
                  value={item}
                  control={<Radio />}
                  label={_.startCase(item)}
                />
              ))}
            </RadioGroup>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Filter;
