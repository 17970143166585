import { useState } from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';

import { CONTENT_PADDING } from '../../../../utils/Constants';
import { Skeleton } from '../../../../components';
import { DateFormatServer, LooseObject, Org } from '../../../../utils/Types';
import { getDateFormat } from '../../../../utils/Helper';

type Props = {
  loading: boolean;
  data: LooseObject[] | undefined;
  changeLocation: (value: string) => void;
  changeDate: (value: string) => void;
  org: Org;
};

const Filter = ({ loading, data, changeLocation, changeDate, org }: Props) => {
  const [location, setLocation] = useState<string>('');
  const [date, setDate] = useState<string>('');

  return (
    <Stack direction="row" spacing={1} minHeight={`calc(100vh - ${190}px)`}>
      <Stack
        bgcolor="grey.100"
        spacing={1}
        borderRight={1}
        borderColor={theme => theme.palette.divider}
        minWidth={220}
      >
        <Stack p={CONTENT_PADDING}>
          <Typography variant="h6">Locations</Typography>
          {loading ? (
            <Skeleton minHeight={84} />
          ) : (
            <RadioGroup
              value={location}
              onChange={(e, v) => {
                setLocation(v);
                setDate('');
                changeLocation(v);
              }}
            >
              {_.uniq(data?.map(i => i.LOCATION))
                .sort()
                .map(item => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={_.startCase(item)}
                  />
                ))}
            </RadioGroup>
          )}
        </Stack>
        <Divider />
        <Stack p={CONTENT_PADDING}>
          <Typography variant="h6">Scan Date</Typography>
          {loading ? (
            <Skeleton minHeight={84} />
          ) : (
            <FormControl size="small">
              <Select
                value={date}
                onChange={e => {
                  setDate(e.target.value);
                  changeDate(e.target.value);
                }}
              >
                {_.orderBy(
                  _.uniq(
                    data
                      ?.filter(i => i.LOCATION === location)
                      .map(i => i.SCAN_DATE)
                  ),
                  o => moment(o, DateFormatServer.SHORT),
                  'desc'
                ).map(i => (
                  <MenuItem key={i} value={i}>
                    {moment(i, DateFormatServer.SHORT).format(
                      getDateFormat(org, 'short')
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Filter;
