import { Stack } from '@mui/material';
import {
  Cached,
  Fullscreen,
  FullscreenExit,
  SettingsOutlined,
} from '@mui/icons-material';

import ToolButton from '../Button/ToolButton';

const Toolbar = ({
  toggleFullScreen,
  handleReset,
  isFullScreen,
  handleSettings,
}: {
  toggleFullScreen?: () => void;
  handleReset?: () => void;
  isFullScreen?: boolean;
  handleSettings?: () => void;
}) => (
  <Stack direction="row" width="95%" justifyContent="flex-end">
    {handleReset && (
      <ToolButton title="Reset" icon={<Cached />} onClick={handleReset} />
    )}
    {toggleFullScreen && (
      <ToolButton
        title="Toggle full screen"
        icon={isFullScreen ? <FullscreenExit /> : <Fullscreen />}
        onClick={toggleFullScreen}
      />
    )}
    {handleSettings && (
      <ToolButton
        title="Settings"
        icon={<SettingsOutlined />}
        onClick={handleSettings}
      />
    )}
  </Stack>
);

export default Toolbar;
