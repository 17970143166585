import { useState } from 'react';
import _ from 'lodash';
import { IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import {
  ChartPoint,
  ChartTrace,
  ChartType,
  DataPoolItem,
  DataSourceKey,
  LooseObject,
  Trendline,
} from '../../../utils/Types';
import { useAppSelector } from '../../../redux/hooks';
import {
  applyUpperCaseTerms,
  getDataFromObjectArray,
  isDataCalculatable,
  linearRegression,
} from '../../../utils/Helper';
import { Chart, Dialog } from '../../../components';
import ImageInfo from '../../camera/SummaryOfImages/ImageInfo';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import { HISTOGRAM_Y_AXIS_TITLE } from '../../../utils/Constants';
import { filterData, getFilterCondition } from '../../../utils/Helper2';
import DataSourceDetails from './DataSourceDetails';

const InsightItemChart = ({
  loading,
  item,
  dataPoolItem,
  conditions,
  updateConditions,
  handleSettings,
}: {
  loading: boolean;
  item: LooseObject;
  dataPoolItem: DataPoolItem;
  conditions: LooseObject;
  updateConditions: (newConditions: LooseObject) => void;
  handleSettings: (insightItem: LooseObject) => void;
}) => {
  const [openDialogImageInfo, setOpenDialogImageInfo] = useState(false);
  const [currentDataPoint, setCurrentDataPoint] = useState<LooseObject>({});
  const [openDialogDataSource, setOpenDialogDataSource] = useState(false);

  const org = useAppSelector(selectOrg);

  const handleClickPoint = async ({ x, y }: ChartPoint) => {
    const attributeX = dataPoolItem.attributes.find(
      i => i.key === item.chartTraces[0].x
    );
    const attributeY = dataPoolItem.attributes.find(
      i => i.key === item.chartTraces[0].y
    );

    if (attributeX && attributeY) {
      const dataPoint = dataPoolItem.data.find(i => {
        let foundX = false;
        if (attributeX?.isInArray) {
          for (let j = 0; j < Object.keys(i).length; j++) {
            const k = Object.keys(i)[j];
            if (Array.isArray(i[k])) {
              const trait = i[k].find((t: any) => t.key === attributeX.key);
              if (
                trait &&
                (trait.value === x || trait.value === x?.toString())
              ) {
                foundX = true;
              }
              break;
            }
          }
        } else {
          foundX =
            i[attributeX.key] === x || i[attributeX.key] === x?.toString();
        }

        let foundY = false;
        if (attributeY?.isInArray) {
          for (let j = 0; j < Object.keys(i).length; j++) {
            const k = Object.keys(i)[j];
            if (Array.isArray(i[k])) {
              const trait = i[k].find((t: any) => t.key === attributeY.key);
              if (
                trait &&
                (trait.value === y || trait.value === y?.toString())
              ) {
                foundY = true;
              }
              break;
            }
          }
        } else {
          foundY =
            i[attributeY.key] === y || i[attributeY.key] === y?.toString();
        }

        return foundX && foundY;
      });
      if (dataPoint) {
        setCurrentDataPoint(dataPoint);
        setOpenDialogImageInfo(true);
      }
    }
  };

  const getLabel = (key: string) =>
    dataPoolItem.attributes.find(a => a.key === key)?.label;

  const getChartTraces = () => {
    const chartTraces: (Plotly.Data | { nameX: string })[] = [];
    item.chartTraces
      .filter((t: ChartTrace) => t.x) // only check x axis exists because some charts don't hav y
      .forEach((trace: ChartTrace, index: number) => {
        // sort x axis
        let sortedData = dataPoolItem.data;
        const xDataType = dataPoolItem.attributes.find(
          i => i.key === trace.x
        )?.type;
        if (isDataCalculatable(xDataType)) {
          sortedData = _.sortBy(dataPoolItem.data, trace.x!);
        }
        const filteredData = filterData(sortedData, conditions);

        let x = getDataFromObjectArray(
          filteredData,
          trace.x!,
          dataPoolItem.attributes
        );
        let y =
          item.chartType === ChartType.HISTOGRAM
            ? ''
            : getDataFromObjectArray(
                filteredData,
                trace.y!,
                dataPoolItem.attributes
              );

        const mainTrace = {
          x,
          y,
          name: applyUpperCaseTerms(
            _.startCase(
              (item.chartType === ChartType.HISTOGRAM
                ? trace.x!
                : trace.y!
              ).toLowerCase()
            )
          ),
          nameX: applyUpperCaseTerms(_.startCase(trace.x!.toLowerCase())),
          yaxis:
            index > 0 && item.chartType !== ChartType.HISTOGRAM
              ? `y${index + 1}`
              : 'y',
          offsetgroup: index,
          ...trace.settings,
        };
        chartTraces.push(mainTrace);

        if (
          item.chartType === ChartType.SCATTER_PLOT &&
          trace.settings?.trendlines?.includes(Trendline.R2)
        ) {
          let lr = linearRegression(x, y as any[]);
          let fit_from = Math.min(...x);
          let fit_to = Math.max(...x);

          let fit = {
            x: [fit_from, fit_to],
            y: [fit_from * lr['sl'] + lr['off'], fit_to * lr['sl'] + lr['off']],
            mode: 'lines',
            line: {
              dash: 'dot',
            },
            name: 'R2='.concat(
              (Math.round(lr['r2'] * 10000) / 10000).toString()
            ),
          };

          chartTraces.push(fit);
        }
      });
    return chartTraces;
  };

  return (
    <Stack>
      <Chart
        type={item.chartType}
        title={
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Typography variant="h6" textAlign="center">
              {item.title}
            </Typography>
            {item.description && (
              <Tooltip title={item.description}>
                <IconButton sx={{ width: 20, height: 20 }}>
                  <InfoOutlined sx={{ width: 20, height: 20 }} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        }
        subtitle={
          <Stack direction="row" spacing={1}>
            <Typography variant="body2">Data Source: </Typography>
            <Link
              variant="body2"
              color="secondary"
              onClick={() => setOpenDialogDataSource(true)}
            >
              {dataPoolItem.dataSource.label}
            </Link>
          </Stack>
        }
        data={{
          xTitle:
            ((item.chartTraces.length === 1 ||
              item.chartType === ChartType.BAR_CHART) &&
              getLabel(item.chartTraces[0].x)) ||
            '',
          yTitle:
            item.chartType === ChartType.HISTOGRAM
              ? HISTOGRAM_Y_AXIS_TITLE
              : getLabel(item.chartTraces[0].y) || '',
          extraYaxes:
            item.chartType !== ChartType.HISTOGRAM &&
            item.chartTraces.length > 1
              ? item.chartTraces.map(
                  (i: ChartTrace, index: number) =>
                    index > 0 && {
                      title: getLabel(i.y!),
                    }
                )
              : [],
          data: getChartTraces(),
        }}
        loading={loading}
        updateFilter={(v: any) => {
          const newConditions = { ...conditions };
          //only filter the first trace
          const traitX = dataPoolItem.attributes.find(
            i => i.key === item.chartTraces[0].x
          );
          const conditionX = getFilterCondition({
            values: v.x,
            type: traitX?.type,
            data: getDataFromObjectArray(
              dataPoolItem.data,
              item.chartTraces[0].x,
              dataPoolItem.attributes
            ),
          });
          newConditions[item.chartTraces[0].x] = conditionX;

          if (item.chartType === ChartType.SCATTER_PLOT) {
            //only filter the first trace
            const traitY = dataPoolItem.attributes.find(
              i => i.key === item.chartTraces[0].y
            );
            const conditionY = getFilterCondition({
              values: v.y,
              type: traitY?.type,
              data: getDataFromObjectArray(
                dataPoolItem.data,
                item.chartTraces[0].y,
                dataPoolItem.attributes
              ),
            });
            newConditions[item.chartTraces[0].y] = conditionY;
          }
          updateConditions(newConditions);
        }}
        handleSettings={() => handleSettings(item)}
        dragMode={
          item.chartType === ChartType.PIE_CHART ||
          item.chartType === ChartType.DONUT_CHART
            ? false
            : 'select'
        }
        backdrop={
          item.dataSourceKey === DataSourceKey.LIST_OF_IMAGES &&
          item.chartType === ChartType.SCATTER_PLOT ? (
            <Dialog
              open={openDialogImageInfo}
              onClose={() => setOpenDialogImageInfo(false)}
              isTransparent
            >
              <ImageInfo
                org={org}
                onClose={() => {
                  setOpenDialogImageInfo(false);
                }}
                data={currentDataPoint}
              />
            </Dialog>
          ) : undefined
        }
        handleClick={
          item.dataSourceKey === DataSourceKey.LIST_OF_IMAGES &&
          item.chartType === ChartType.SCATTER_PLOT
            ? handleClickPoint
            : undefined
        }
      />
      <Dialog
        open={openDialogDataSource}
        onClose={() => setOpenDialogDataSource(false)}
        maxWidth="sm"
      >
        <DataSourceDetails
          dataSource={dataPoolItem.dataSource}
          onCancel={() => setOpenDialogDataSource(false)}
          org={org}
        />
      </Dialog>
    </Stack>
  );
};

export default InsightItemChart;
