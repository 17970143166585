import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';

import { selectAuth } from '../redux/reducers/authSlice';

const PrivateRoutes = () => {
  const auth = useAppSelector(selectAuth);

  if (!auth?.token) return <Navigate to="/" replace />;

  return <Outlet />;
};

export default PrivateRoutes;
