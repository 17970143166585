import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default ToggleButton;
