import { Stack, Typography } from '@mui/material';

import { LooseObject } from '../../../utils/Types';
import { DialogWrapper } from '../../../components';
import CloseIcon from '../../../assets/icons/circle-xmark.png';

type Props = {
  onConfirm: (values: any) => void;
  onCancel: (values: any) => void;
  loading: boolean;
  item: LooseObject;
};

const Delete = ({ onConfirm, onCancel, loading, item }: Props) => (
  <DialogWrapper
    onCancel={onCancel}
    onConfirm={() => onConfirm(item.id)}
    loading={loading}
  >
    <Stack alignItems="center" spacing={3}>
      <img src={CloseIcon} alt="icon" width={90} height={90} />
      <Stack alignItems="center" spacing={1}>
        <Typography variant="h5">Are you sure?</Typography>
        <Typography>{`Are you sure to delete user "${item.firstName} ${item.lastName}"?`}</Typography>
      </Stack>
    </Stack>
  </DialogWrapper>
);

export default Delete;
