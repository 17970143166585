import { Box, Grid } from '@mui/material';
import { ReactNode } from 'react';

type CollapseRowProps = {
  label: ReactNode;
  value: any;
};

const CollapsedRow = ({ label, value }: CollapseRowProps) => (
  <Grid
    xs={12}
    container
    spacing={0.5}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Grid item xs={2}>
      <Box
        sx={{
          height: { xs: 25, md: 35 },
          fontSize: { xs: '0.8rem', md: '1rem' },
          display: 'flex',
          alignItems: 'center',
          p: { xs: 1, md: 2 },
        }}
      >
        {label}
      </Box>
    </Grid>
    <Grid item xs={10}>
      <Box
        sx={{
          height: { xs: 25, md: 35 },
          fontSize: { xs: '0.8rem', md: '1rem' },
          display: 'flex',
          alignItems: 'center',
          p: { xs: 1, md: 2 },
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {value}
      </Box>
    </Grid>
  </Grid>
);

export default CollapsedRow;
