import { useContext, useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  DateFormatServer,
  LooseObject,
  QueryResult,
  QueryType,
} from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import {
  logout,
  optimiseQueryResult,
  postToServer,
} from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import { QUERY_DATA_SOURCE_ENDPOINT } from '../../../utils/Constants';
import NotProbedHistogram from './NotProbedHistogram';
import TraitsHistogram from './TraitsHistogram';
import TraitsScatter from './TraitsScatter';
import BrandsHistogram from './BrandsHistogram';
import ImfHistogram from './ImfHistogram';
import LotHistogram from './LotHistogram';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [summaries, setSummaries] = useState<QueryResult>();

  const [criteria, setCriteria] = useState<LooseObject>({});

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (auth?.token) {
      setLoading(true);
      await postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ORDINARY_QUERY,
          view: 'FREWS_INSIGHTS_CARCASE_AGG',
          sqlConditions: '1 = 1',
        },
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            const optimisedServerData = optimiseQueryResult(serverData);

            setSummaries(optimisedServerData);
          } else {
            snackbar.open(response.message);
          }
        }
      });
      setLoading(false);
    }
  };

  const dataToDisplay =
    summaries?.rows
      ?.filter(i =>
        criteria.months
          ? criteria.months.includes(
              moment(i.SLAUGHTER_DATE, DateFormatServer.SHORT).format(
                DateFormatServer.SHORT
              )
            )
          : true
      )
      .map(i => ({
        ...i,
        id: moment(i.SLAUGHTER_DATE, DateFormatServer.SHORT).format(
          DateFormatServer.SHORT
        ),
      })) || [];

  const uniqTraits =
    summaries?.columns?.map(i => ({ ...i, key: i.name })) || [];

  return (
    <Stack mt={2} ml={-2} mr={-3} mb={-3}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6} mt={1}>
          <TraitsHistogram
            data={dataToDisplay}
            allTraits={uniqTraits.filter(i =>
              [
                // 'TOTAL_CARCASES',
                'AVG_MEQ_IMF',
                'AVG_WEIGHT_HDW',
                'AVG_FAT_DEPTH',
              ].includes(i.name)
            )}
            loading={loading}
            updateCriteria={v => setCriteria({ ...criteria, months: v.x })}
          />
        </Grid>
        <Grid item xs={12} lg={6} mt={1}>
          <TraitsScatter
            data={dataToDisplay}
            loading={loading}
            auth={auth}
            org={org}
          />
        </Grid>
        <Grid item xs={12} lg={6} mt={1}>
          <ImfHistogram
            data={dataToDisplay}
            loading={loading}
            auth={auth}
            org={org}
          />
        </Grid>
        <Grid item xs={12} lg={6} mt={1}>
          <BrandsHistogram
            data={dataToDisplay}
            loading={loading}
            auth={auth}
            org={org}
          />
        </Grid>
        <Grid item xs={12} lg={6} mt={1}>
          <NotProbedHistogram
            data={dataToDisplay}
            loading={loading}
            updateCriteria={v => setCriteria({ ...criteria, months: v.x })}
          />
        </Grid>
        <Grid item xs={12} lg={6} mt={1}>
          <LotHistogram
            data={dataToDisplay}
            loading={loading}
            auth={auth}
            org={org}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Page;
