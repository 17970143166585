import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';

import { Auth, ChartPoint, ChartType, LooseObject } from '../../../utils/Types';
import { Chart } from '../../../components';
import { MISHIMA_GRADES } from '.';

const LotDetailsCharts = ({
  scans,
  animals,
  loadingAnimals,
  loadingScans,
  auth,
}: {
  scans: LooseObject[];
  animals: LooseObject[];
  loadingAnimals: boolean;
  loadingScans: boolean;
  auth: Auth;
}) => {
  const [hoveredAnimals, setHoveredAnimals] = useState<LooseObject[]>();
  const handleHoverPoint = async ({ x, y }: ChartPoint) => {
    const item = animals.find(i => i.USDA === x && i.DAYS_ON_FEED === y);
    if (item) {
      setHoveredAnimals(animals.filter(i => i.EID === item.EID));
    }
  };

  return (
    <Stack spacing={2}>
      <Stack bgcolor="white" pt={2}>
        <Chart
          type={ChartType.SCATTER_PLOT}
          title={
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="h6" textAlign="center">
                USDA Score VS Days On Feed
              </Typography>
            </Stack>
          }
          data={{
            xTitle: 'USDA Score',
            yTitle: 'Days On Feed',
            data: [
              {
                x: animals.map(i => i.USDA),
                y: animals.map(i => i.DAYS_ON_FEED),
                customdata: animals.map(i => i.EID),
                hovertemplate: `<i>EID</i>: %{customdata} <br /><i>%{xaxis.title.text}</i>: %{x} <br /><i>%{yaxis.title.text}</i>: %{y} <extra></extra>`,
                showlegend: false,
              },
              hoveredAnimals
                ? {
                    x: hoveredAnimals.map(i => i.USDA),
                    y: hoveredAnimals.map(i => i.DAYS_ON_FEED),
                    customdata: animals.map(i => i.EID),
                    hovertemplate: `<i>EID</i>: %{customdata} <br /><i>%{xaxis.title.text}</i>: %{x} <br /><i>%{yaxis.title.text}</i>: %{y} <extra></extra>`,
                    marker: { size: 16 },
                    showlegend: false,
                  }
                : {},
            ],
          }}
          loading={loadingAnimals}
          handleHover={handleHoverPoint}
          handleUnhover={() => setHoveredAnimals(undefined)}
        />
      </Stack>
      {auth?.orgOwner === 'mishima' && (
        <Stack bgcolor="white" pt={2}>
          <Chart
            type={ChartType.BAR_CHART}
            data={{
              xTitle: 'Sessions',
              yTitle: 'Count Of Grade',
              data: _.reverse([...MISHIMA_GRADES]).map(i => ({
                x: Array.from(Array(scans.length).keys()).map(
                  o => ['50 - 130 DOF', '130 - 220 DOF', '>= 220 DOF'][o]
                ),
                y: scans.map(s => s[`COUNT_OF_${i}`]),
                name: _.startCase(i.toLowerCase()),
                hovertemplate: `<i>%{x} <br /><i>%{data.name}</i>: %{y} <extra></extra>`,
                type: 'bar',
              })),
            }}
            traceorder="reversed"
            loading={loadingScans}
            barMode="group"
            chartTraceColors={[]}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default LotDetailsCharts;
