import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Button,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { Message } from '../utils/Types';
import ServerMessage from './ServerMessage';

type Props = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  cancelText?: string;
  onCancel?: (values: any) => void;
  hideCancelButton?: boolean;
  confirmText?: string;
  onConfirm?: (values: any) => void;
  hideConfirmButton?: boolean;
  loading?: boolean;
  message?: Message;
};

const DialogWrapper = ({
  title,
  description,
  children,
  cancelText,
  onCancel,
  hideCancelButton,
  confirmText,
  onConfirm,
  hideConfirmButton,
  loading,
  message,
}: Props) => (
  <Stack minWidth={{ xs: 300, md: 400 }} width="100%">
    {title && (
      <Stack
        bgcolor="#E5E5E7"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <DialogTitle minWidth={240}>{title}</DialogTitle>
        {onCancel && (
          <IconButton
            aria-label="Close"
            onClick={onCancel}
            sx={{ width: 40, height: 40, mr: 1.5 }}
          >
            <Close />
          </IconButton>
        )}
      </Stack>
    )}
    <DialogContent>
      <Stack pt={1} spacing={2}>
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
        {message && message.text && <ServerMessage message={message} />}
      </Stack>
    </DialogContent>
    <DialogActions>
      {!hideCancelButton && onCancel && (
        <Button onClick={onCancel} variant="outlined">
          {cancelText || 'Cancel'}
        </Button>
      )}
      {!hideConfirmButton && onConfirm && (
        <LoadingButton
          onClick={onConfirm}
          loading={loading}
          variant="contained"
        >
          {confirmText || 'Confirm'}
        </LoadingButton>
      )}
    </DialogActions>
  </Stack>
);

export default DialogWrapper;
