import { Stack, Typography, Grid } from '@mui/material';

import TextInput, { TextInputProps } from './TextInput';

const NumberRangeInput = ({
  item,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  textFieldProps,
}: TextInputProps) => (
  <Stack sx={textFieldProps.sx}>
    <Stack mb={1}>
      <Typography align="left">{item.label}</Typography>
    </Stack>
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextInput
          label="From"
          name={`${item.name}From`}
          type="number"
          item={item}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          textFieldProps={textFieldProps}
          inputProps={{
            inputProps: {
              max: values[`${item.name}To`],
              step: 'any',
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="To"
          name={`${item.name}To`}
          type="number"
          item={item}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          textFieldProps={textFieldProps}
          inputProps={{
            inputProps: {
              min: values[`${item.name}From`],
              step: 'any',
            },
          }}
        />
      </Grid>
    </Grid>
  </Stack>
);

export default NumberRangeInput;
