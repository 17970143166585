import { Box, Divider, Typography, Stack } from '@mui/material';
import {
  AppRegistration,
  CorporateFare,
  ManageAccounts,
} from '@mui/icons-material';

import { PLUGINS, NAV_ITEMS } from '../../utils/Constants';
import { selectAuth } from '../../redux/reducers/authSlice';
import { selectOrg } from '../../redux/reducers/orgSlice';
import { selectUser } from '../../redux/reducers/userSlice';
import { Plugin, UserRole } from '../../utils/Types';
import { useAppSelector } from '../../redux/hooks';
import MeqLogoImg from '../../assets/logos/meq.png';
import SignOutIcon from '../../assets/icons/sign-out.png';
import { MARGIN, SIDEBAR_WIDTH } from './index';
import SidebarItem from './SidebarItem';
import Logo from './Logo';
import SwichOrg from './SwitchOrg';

const Sidebar = ({ mobileOpen }: { mobileOpen: boolean }) => {
  const auth = useAppSelector(selectAuth);
  const org = useAppSelector(selectOrg);
  const user = useAppSelector(selectUser);

  const allPluginsForThisUser = org?.plugins?.filter(i =>
    auth?.plugins?.includes(i.name)
  );

  return (
    <Stack
      width={SIDEBAR_WIDTH}
      display={{ xs: mobileOpen ? 'flex' : 'none', md: 'flex' }}
    >
      <Stack m={MARGIN} width={SIDEBAR_WIDTH - MARGIN * 8 * 2}>
        <Box display={{ xs: 'none', md: 'flex' }}>
          <Logo maxWidth={220} maxHeight={60} img={org?.logo} />
        </Box>
        <Typography
          fontSize={24}
          display={{ xs: 'flex', md: 'none' }}
          color="background.default"
        >
          {`Hello, ${user?.firstName}`}
        </Typography>
      </Stack>
      {(!org?.isWhiteLabel || (user?.orgs?.length || 0) > 1) && (
        <Stack
          bgcolor="grey.900"
          px={6}
          py={2}
          direction="row"
          alignItems="center"
          display={{ xs: 'flex', md: 'none' }}
          spacing={0.5}
        >
          <SwichOrg org={org} user={user} />
        </Stack>
      )}
      <Stack display="flex" justifyContent="space-between" flex={1}>
        <Stack spacing={MARGIN / 2}>
          <Divider sx={{ bgcolor: 'grey.900' }} />

          {PLUGINS.filter(i => {
            const allPluginNamesForThisUser = allPluginsForThisUser?.map(
              (j: Plugin) => j.name
            );
            return allPluginNamesForThisUser?.includes(i.name);
          }).map(plugin => {
            const subNav = NAV_ITEMS.filter(i => i.category === plugin.name);
            return (
              <SidebarItem
                key={plugin.name}
                title={plugin.name}
                icon={plugin.icon}
                items={subNav}
              />
            );
          })}
          {user?.role !== undefined &&
            user?.role >= UserRole.ORGANISATION_ADMIN && (
              <SidebarItem
                key="management"
                title="Management"
                icon={<AppRegistration />}
                items={NAV_ITEMS.filter(i => i.category === 'Management')}
              />
            )}
          {user?.role !== undefined &&
            user?.role >= UserRole.SUPER_ADMIN &&
            auth?.orgOwner?.toLocaleLowerCase() === 'meq' && (
              <SidebarItem
                key="administration"
                title="Administration"
                icon={<CorporateFare />}
                items={NAV_ITEMS.filter(i => i.category === 'Administration')}
              />
            )}
          <SidebarItem
            key="account"
            title="Account"
            icon={<ManageAccounts />}
            items={NAV_ITEMS.filter(i => i.category === 'Account')}
          />
          <Divider />
          <SidebarItem
            title="Logout"
            icon={<img src={SignOutIcon} alt="icon" />}
            items={[]}
            sx={{ color: '#797979' }}
          />
        </Stack>

        {org?.isWhiteLabel && (
          <Stack
            direction="row"
            spacing={2}
            px={MARGIN}
            py={MARGIN / 2}
            bgcolor="black"
          >
            <Typography fontSize={18} color="background.default">
              Powered by:
            </Typography>
            <img src={MeqLogoImg} alt="icon" width={78} height={24} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Sidebar;
