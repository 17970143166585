import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { LooseObject } from '../../../utils/Types';

type Props = {
  item: LooseObject;
  traitKey: any;
  onSubmit: (value: any) => void;
};

const InlineTextField = ({ item, traitKey, onSubmit }: Props) => {
  const currentTrait = item.traits.find((i: any) => i.key === traitKey);

  const [isBlured, setIsBlured] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [value, setValue] = useState(currentTrait?.value || '');

  useEffect(() => {
    setValue(currentTrait?.value || '');
  }, [currentTrait]);

  return (
    <TextField
      autoComplete="off"
      variant="standard"
      value={value}
      size="small"
      sx={{
        '&& .MuiInputBase-input': {
          pb: 0,
        },
        '&& .MuiInputBase-root': {
          color: 'white',
          fontSize: '0.75rem',
          '&.MuiInput-root': {
            ':hover': {
              ':not(.Mui-disabled, .Mui-error)': {
                ':before': {
                  borderBottomColor: isChanged
                    ? isBlured
                      ? 'green'
                      : 'red'
                    : 'grey.100',
                },
              },
            },
          },
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: isChanged
            ? isBlured
              ? 'green'
              : 'red'
            : 'grey.100',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: isChanged
            ? isBlured
              ? 'green'
              : 'red'
            : 'grey.500',
        },
      }}
      onFocus={() => {
        setIsBlured(false);
        setIsChanged(false);
      }}
      onBlur={() => {
        setIsBlured(true);
        isChanged && onSubmit({ trait: { ...currentTrait, value } });
      }}
      onChange={(v: any) => {
        setIsChanged(true);
        setValue(v.target.value);
      }}
    />
  );
};

export default InlineTextField;
