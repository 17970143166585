import { Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import {
  FormData,
  FormInputCategory,
  FormInputItem,
  LooseObject,
  QueryResult,
  QueryResultColumn,
  QueryType,
} from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { logout, postToServer, isNotEmpty } from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import { Dialog, DialogWrapper, Form } from '../../../components';
import QueryGenerator from './QueryGenerator';
import QueryResultDialog from './QueryResultDialog';
import { QUERY_DATA_SOURCE_ENDPOINT } from '../../../utils/Constants';
import { SELECT_ALL_VALUE } from '../../../components/Form/Select';

const DataSourceForm = ({
  title,
  item = {},
  onCancel,
  handleSubmit,
}: {
  title: string;
  item?: LooseObject;
  onCancel: () => void;
  handleSubmit: (v: FormData) => void;
}) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [dataView, setDataView] = useState<string>(item.view);
  const [availableDataViews, setAvailableDataViews] = useState<string[]>([]);
  const [dataViewColumns, setDataViewColumns] = useState<QueryResultColumn[]>(
    []
  );
  const [columnsInterested, setColumnsInterested] = useState<string[]>();
  console.log(columnsInterested);

  const [sqlConditions, setSqlConditions] = useState<string>(
    item.sqlConditions
  );

  const [openDialogQueryResult, setOpenDialogQueryResult] = useState(false);

  const auth = useAppSelector(selectAuth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadAvailableDataViews();
  }, []);

  useEffect(() => {
    if (isNotEmpty(dataView)) {
      loadDataViewColumns();
    }
  }, [dataView]);

  const loadAvailableDataViews = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: { id: auth.orgOwner, type: QueryType.ALL_TABLES },
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            setAvailableDataViews(
              serverData.rows?.map((i: any) => i.name) || []
            );
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  const loadDataViewColumns = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ALL_COLUMNS,
          view: dataView,
        },
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            setDataViewColumns(serverData.columns || []);
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  let inputs: FormInputItem[] = [
    {
      name: 'name',
      label: 'Name',
      category: FormInputCategory.TEXT_INPUT,
      defaultValue: item.name,
      textFieldProps: { autoComplete: 'off' },
    },
    {
      name: 'view',
      label: 'Data View',
      category: FormInputCategory.SELECT,
      options: availableDataViews.map(i => ({
        label: _.startCase(i.toLowerCase()),
        value: i,
      })),
      defaultValue: item.view,
      exposeValue: v => setDataView(v),
    },
  ];

  if (dataView && !loading) {
    inputs = inputs.concat([
      {
        name: 'columnsInterested',
        label: 'Columns Interested In',
        category: FormInputCategory.SELECT,
        options: dataViewColumns.map(i => ({
          label: _.startCase(i.name.toLowerCase()),
          value: i.name,
        })),
        defaultValue: item.columnsInterested,
        exposeValue: v =>
          setColumnsInterested(v.filter((i: string) => i !== SELECT_ALL_VALUE)),
        multiple: true,
        selectAll: true,
      },
      {
        name: 'sqlGenerator',
        label: 'Conditions',
        category: FormInputCategory.COMPONENT,
        component: dataView ? (
          <Stack>
            <Typography sx={{ alignSelf: 'flex-start' }}>
              Query Conditions
            </Typography>
            <QueryGenerator
              initialSql={sqlConditions}
              columns={dataViewColumns}
              onPreview={() => setOpenDialogQueryResult(true)}
              loading={loading}
              onQueryChange={sql => setSqlConditions(sql)}
            />
          </Stack>
        ) : (
          <Stack />
        ),
      },
    ]);
  }

  return (
    <DialogWrapper title={title} onCancel={onCancel} hideCancelButton>
      <Stack width={{ xs: '75vw', sm: '60vw', lg: '50vw', xl: '40vw' }}>
        <Stack spacing={3}>
          <Form
            loading={loading}
            onSubmit={v => handleSubmit({ ...v, id: item.id, sqlConditions })}
            buttonText="Submit"
            inputs={inputs}
            maxWidth="xl"
          />
        </Stack>
        <Dialog
          open={openDialogQueryResult}
          onClose={() => setOpenDialogQueryResult(false)}
          maxWidth="xl"
        >
          {dataView && sqlConditions && (
            <QueryResultDialog
              view={dataView}
              sqlConditions={sqlConditions}
              columnsInterested={columnsInterested}
              onCancel={() => setOpenDialogQueryResult(false)}
              auth={auth}
              snackbar={snackbar}
            />
          )}
        </Dialog>
      </Stack>
    </DialogWrapper>
  );
};

export default DataSourceForm;
