import { Stack, Typography, useTheme } from '@mui/material';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import { ReactNode, useState } from 'react';

import {
  ChartData,
  ChartPoint,
  ChartType,
  LooseObject,
  Org,
} from '../../utils/Types';
import { NoDataView, Skeleton } from '..';
import { BarMode, DragMode, Orientation, SelectDirection } from './SharedTypes';
import Toolbar from './Toolbar';
import ChartContainer from './ChartContainer';
import { generateChartTraceColors } from './Utils';
import {
  HISTOGRAM_Y_AXIS_TITLE,
  HISTOGRAM_Y_AXIS_TITLE_PERCENT,
} from '../../utils/Constants';

const getPieSettings = (
  type: ChartType,
  trace: Plotly.Data & { nameX?: string; x?: any; y?: any }
) => ({
  values: trace.y,
  labels: trace.x,
  sort: false,
  hole: type === ChartType.DONUT_CHART ? 0.4 : undefined,
});

const getViolinSettings = (
  trace: Plotly.Data & { nameX?: string; x?: any; y?: any }
) => ({
  box: {
    visible: true,
  },
  meanline: {
    visible: true,
  },
  transforms: [
    {
      type: 'groupby',
      groups: trace.x,
    },
  ],
});

const Chart = ({
  type,
  histnorm,
  title,
  subtitle,
  headerBeforeLoading,
  header,
  data,
  loading,
  minHeight,
  maxHeight,
  updateFilter,
  handleSettings,
  org,
  chartTraceColors,
  orientation = 'h',
  legendX,
  legendY,
  barMode,
  dragMode,
  traceorder,
  selectDirection,
  style,
  bgcolor,
  backdrop,
  handleClick,
  handleHover,
  handleUnhover,
}: {
  type: ChartType;
  histnorm?: '' | 'percent' | 'probability' | 'density' | 'probability density'; // for show histogram
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  headerBeforeLoading?: ReactNode;
  header?: ReactNode;
  data: ChartData;
  loading?: boolean;
  minHeight?: number;
  maxHeight?: number;
  updateFilter?: (v: any) => void;
  handleSettings?: () => void;
  org?: Org;
  chartTraceColors?: string[];
  orientation?: Orientation;
  legendX?: number;
  legendY?: number;
  barMode?: BarMode;
  dragMode?: DragMode;
  traceorder?: 'grouped' | 'normal' | 'reversed' | 'reversed+grouped';
  selectDirection?: SelectDirection;
  style?: LooseObject;
  bgcolor?: string;
  backdrop?: ReactNode;
  handleClick?: (v: ChartPoint) => void;
  handleHover?: (v: ChartPoint) => void;
  handleUnhover?: (v: ChartPoint) => void;
}) => {
  data = { ...data }; // make sure not change the original dataset

  if (
    type === ChartType.PIE_CHART ||
    type === ChartType.DONUT_CHART ||
    type === ChartType.VIOLIN_PLOT
  ) {
    orientation = 'v';
  }

  const [isFullScreen, setIsFullScreen] = useState(false);

  const theme = useTheme();

  const onSelected: (
    event: Readonly<Plotly.PlotSelectionEvent>
  ) => void = dataSelected => {
    if (type === ChartType.LINE_CHART || type === ChartType.VIOLIN_PLOT) {
      updateFilter?.(dataSelected?.range);
    } else {
      const xLabels = dataSelected?.points.map(o => o.x);
      const yLabels = dataSelected?.points.map(o => o.y);
      const xUnique = [...new Set(xLabels)];
      const yUnique = [...new Set(yLabels)];
      if (xUnique.length > 0 || yUnique.length > 0) {
        updateFilter?.({ x: xUnique, y: yUnique });
      }
    }
  };

  const handleClickPoint = (event: Readonly<Plotly.PlotMouseEvent>) => {
    // get the closest point
    const point: ChartPoint = { x: null, y: null };
    point.x = 0;
    point.y = 0;
    for (let i = 0; i < event.points.length; i++) {
      point.x = event.points[i].x;
      point.y = event.points[i].y;
    }

    handleClick?.(point);
  };

  const handleHoverPoint = (event: Readonly<Plotly.PlotMouseEvent>) => {
    // get the closest point
    const point: ChartPoint = { x: null, y: null };
    point.x = 0;
    point.y = 0;
    for (let i = 0; i < event.points.length; i++) {
      point.x = event.points[i].x;
      point.y = event.points[i].y;
    }

    handleHover?.(point);
  };

  const handleUnhoverPoint = (event: Readonly<Plotly.PlotMouseEvent>) => {
    // get the closest point
    const point: ChartPoint = { x: null, y: null };
    point.x = 0;
    point.y = 0;
    for (let i = 0; i < event.points.length; i++) {
      point.x = event.points[i].x;
      point.y = event.points[i].y;
    }

    handleUnhover?.(point);
  };

  const extraYAxes = () => {
    const yAxes: LooseObject = {};
    data.extraYaxes
      ?.filter(i => i && i.title)
      .forEach((i, index) => {
        yAxes[`yaxis${index + 2}`] = {
          title: i.title,
          overlaying: 'y',
          anchor: 'free',
          side: index === 0 ? 'right' : 'left',
          position: index === 0 ? 1 : 0,
          type: i.axisType,
        };
      });
    return yAxes;
  };

  return (
    <ChartContainer
      isFullScreen={isFullScreen}
      exitFullScreen={() => setIsFullScreen(false)}
    >
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {title && typeof title === 'string' ? (
          <Typography variant="h6" textAlign="center">
            {title}
          </Typography>
        ) : (
          title
        )}
        {subtitle && typeof subtitle === 'string' ? (
          <Typography variant="body2" textAlign="center">
            {subtitle}
          </Typography>
        ) : (
          subtitle
        )}
        {headerBeforeLoading}
        {loading ? (
          <Skeleton minHeight={minHeight} />
        ) : _.isEmpty(data?.data) ? (
          <NoDataView minHeight={minHeight} />
        ) : (
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            {header}
            <Toolbar
              handleReset={
                updateFilter && (() => updateFilter({ x: null, y: null }))
              }
              toggleFullScreen={() => setIsFullScreen(!isFullScreen)}
              handleSettings={handleSettings}
              isFullScreen={isFullScreen}
            />
            <Plot
              data={data.data.map(i => {
                // group dataset based on x axis for some charts
                if (
                  type === ChartType.LINE_CHART ||
                  type === ChartType.BAR_CHART ||
                  type === ChartType.PIE_CHART ||
                  type === ChartType.DONUT_CHART
                ) {
                  i.y = _.uniq(i.x).map(uniqX => {
                    let sumY = 0;
                    for (let ix = 0; ix < i.x.length; ix++) {
                      if (uniqX === i.x[ix]) {
                        sumY += i.y[ix];
                      }
                    }
                    return sumY;
                  });
                  i.x = _.uniq(i.x); // only change x after change y because y has to use old x
                }

                return {
                  type: type === ChartType.DONUT_CHART ? 'pie' : type,
                  histnorm,
                  mode:
                    type === ChartType.LINE_CHART ? 'lines+markers' : 'markers',
                  hovertemplate:
                    type === ChartType.PIE_CHART ||
                    type === ChartType.DONUT_CHART
                      ? `<i>%{data.nameX}</i>: %{label} <br /><i>%{data.name}</i>: %{value} <br /><i>Percent</i>: %{percent} <extra></extra>`
                      : `<i>%{${
                          data.data.length === 1
                            ? 'xaxis.title.text'
                            : 'data.nameX'
                        }}</i>: %{x} <br /><i>${
                          type === ChartType.HISTOGRAM
                            ? histnorm === 'percent'
                              ? HISTOGRAM_Y_AXIS_TITLE_PERCENT
                              : HISTOGRAM_Y_AXIS_TITLE
                            : `%{${
                                data.data.length === 1
                                  ? 'yaxis.title.text'
                                  : 'data.name'
                              }}`
                        }</i>: %{y} <extra></extra>`,
                  ...(type === ChartType.PIE_CHART ||
                  type === ChartType.DONUT_CHART
                    ? getPieSettings(type, i)
                    : {}),

                  ...(type === ChartType.VIOLIN_PLOT
                    ? getViolinSettings(i)
                    : {}),

                  ...i,
                } as Plotly.Data;
              })}
              layout={{
                xaxis: {
                  domain: [
                    (data.extraYaxes?.filter(i => i && i.title).length || 0) > 1
                      ? 0.2
                      : 0,
                    1,
                  ],
                  title: data.xTitle,
                  automargin: true,
                  categoryorder: data.xCategoryOrder,
                  categoryarray: data.xCategoryArray,
                  type: data.xAxisType,
                  // tickformat: org?.isUSA ? '%m/%d/%Y' : '%d/%m/%Y',
                },
                yaxis: {
                  title: data.yTitle,
                  automargin: true,
                  type: data.yAxisType,
                  range: data.yAxisRange,
                },
                ...extraYAxes(),
                autosize: true,
                margin: { t: 0 },
                barmode: barMode,
                bargap: type === ChartType.HISTOGRAM ? 0.1 : 0.2,
                dragmode: dragMode || false,
                selectdirection:
                  selectDirection ||
                  (type === ChartType.SCATTER_PLOT ? 'd' : 'h'),
                colorway:
                  chartTraceColors ||
                  generateChartTraceColors({
                    quantity: data.data.length,
                    seedColorHex: theme.palette.primary.main,
                  }),
                legend: {
                  itemdoubleclick: false,
                  itemclick: false,
                  orientation,
                  traceorder,
                  xanchor: orientation === 'h' ? 'center' : undefined,
                  x: legendX || (orientation === 'h' ? 0.5 : 1),
                  y: legendY || (orientation === 'h' ? 1.15 : 1),
                },

                // hoverlabel: { bgcolor: 'white' },
                plot_bgcolor: bgcolor,
                paper_bgcolor: bgcolor,
              }}
              style={{
                width: '100%',
                maxHeight: isFullScreen ? undefined : maxHeight,
                ...style,
              }}
              useResizeHandler
              config={{ displaylogo: false, displayModeBar: false }}
              onSelected={onSelected}
              onClick={
                handleClick
                  ? handleClickPoint
                  : dragMode === 'select'
                  ? onSelected
                  : undefined
              }
              onHover={handleHover ? handleHoverPoint : undefined}
              onUnhover={handleUnhover ? handleUnhoverPoint : undefined}
            />
            {backdrop}
          </Stack>
        )}
      </Stack>
    </ChartContainer>
  );
};

export default Chart;
