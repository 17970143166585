import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { CalendarMonth } from '@mui/icons-material';

import { DataTable, Form } from '../../../components';
import {
  TableRowProps,
  TableColumnProps,
  DateFormatServer,
  TableToolBarActionButtonCategory,
  FormData,
  FormInputCategory,
  QueryType,
  QueryResult,
} from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import {
  getDateFormat,
  isNotEmpty,
  logout,
  optimiseQueryResult,
  postToServer,
} from '../../../utils/Helper';
import ListOfData from './ListOfData';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import { MONTHS, QUERY_DATA_SOURCE_ENDPOINT } from '../../../utils/Constants';
import ExportButton from './ExportButton';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [summaries, setSummaries] = useState<QueryResult>();
  const [dateRange, setDateRange] = useState<{
    from: Moment | undefined;
    to: Moment | undefined;
  }>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const columns: TableColumnProps[] = [
    { id: 'id', label: 'ID', isSortable: true, hidden: true },
    ...(summaries?.columns?.map(i => ({
      id: i.name,
      label: i.label,
      isSortable: true,
      isDefalutOrderBy: i.name === 'SLAUGHTER_DATE',
    })) || []),
  ];

  columns.push({
    id: 'actions',
    label: 'Actions',
    component: (item: TableRowProps) => (
      <ExportButton
        auth={auth}
        dispatch={dispatch}
        navigate={navigate}
        dateFrom={item.row.id}
        org={org}
      />
    ),
  });

  const getSummariesInDateRange = (from: Moment, to: Moment) =>
    summaries?.rows?.filter(i => {
      const slaughterDate = moment(i.SLAUGHTER_DATE, DateFormatServer.SHORT);
      return slaughterDate >= from && slaughterDate <= to;
    }) || [];

  let summariesToDisplay = summaries?.rows || [];

  if (dateRange?.from && dateRange?.to) {
    summariesToDisplay = getSummariesInDateRange(dateRange.from, dateRange.to);
  }

  const rows: TableRowProps[] = [];

  const columnsContainPercentOf = columns
    .map(c => c.id)
    .filter(d => d.includes('PERCENT_OF_'));

  summariesToDisplay.forEach(item => {
    const row: TableRowProps = {
      ...item,
      ...columnsContainPercentOf.map(i => (item[i] = `${item[i]}%`)), // check if columns include 'PERCERNT_OF_"
      id: moment(item.SLAUGHTER_DATE, DateFormatServer.SHORT).format(
        DateFormatServer.SHORT
      ),
      SLAUGHTER_DATE: moment(
        item.SLAUGHTER_DATE,
        DateFormatServer.SHORT
      ).format(getDateFormat(org, 'short')),
      actions: { isDependent: true },
    };
    rows.push(row);
  });

  const rowsToDisplay: TableRowProps[] = rows.filter(i => true);

  const fetchData = async () => {
    if (auth?.token) {
      setLoading(true);
      await postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ORDINARY_QUERY,
          view: 'FREWS_INSIGHTS_CARCASE_AGG',
          sqlConditions: '1 = 1',
        },
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            const optimisedServerData = optimiseQueryResult(serverData);

            setSummaries(optimisedServerData);
          } else {
            snackbar.open(response.message);
          }
        }
      });
      setLoading(false);
    }
  };

  const getListOfData = (row: TableRowProps) => <ListOfData data={row} />;

  const years: number[] = [];
  summaries?.rows?.forEach(i => {
    const y = moment(i.SLAUGHTER_DATE, DateFormatServer.SHORT).year();
    if (!years.includes(y)) {
      years.push(y);
    }
  });

  const handleSubmit = ({
    year,
    month,
    slaughterDateFrom,
    slaughterDateTo,
  }: FormData) => {
    if (year && isNotEmpty(month)) {
      setDateRange({
        from: moment().set('year', year).set('month', month).startOf('month'),
        to: moment().set('year', year).set('month', month).endOf('month'),
      });
    }
    if (slaughterDateFrom && slaughterDateTo) {
      setDateRange({ from: slaughterDateFrom, to: slaughterDateTo });
    }
    handleClose();
  };

  return (
    <Stack mt={2}>
      {dateRange?.from && dateRange?.to && (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            variant="h6"
            fontSize={16}
          >{`Show data between ${dateRange.from.format(
            getDateFormat(org, 'short')
          )} and ${dateRange.to.format(
            getDateFormat(org, 'short')
          )}`}</Typography>
          <Button onClick={() => setDateRange(undefined)}>Clear</Button>
        </Stack>
      )}
      <DataTable
        dateFormat={getDateFormat(org, 'short')}
        loading={loading}
        title="Summary of Data by Slaughter Date"
        columns={columns}
        rows={rowsToDisplay}
        searchAttributes={['id']}
        collapsedComponent={getListOfData}
        defaultRowsPerPage={25}
        actionButtons={[
          {
            category: TableToolBarActionButtonCategory.ALWAYS,
            component: (
              <Stack>
                <Tooltip title="Filter by Slaughter Date">
                  <IconButton onClick={handleClick}>
                    <CalendarMonth />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Stack m={2} spacing={1} maxWidth={340}>
                    <Form
                      fullWidth
                      loading={loading}
                      onSubmit={handleSubmit}
                      buttonText="Submit"
                      inputs={[
                        {
                          name: 'filterDataByMonth',
                          category: FormInputCategory.COMPONENT,
                          component: (
                            <Typography variant="h6" my={1}>
                              Filter data by month
                            </Typography>
                          ),
                        },
                        {
                          name: 'year',
                          label: 'Year',
                          category: FormInputCategory.SELECT,
                          options: years.map(i => ({
                            label: i.toString(),
                            value: i,
                          })),
                        },
                        {
                          name: 'month',
                          label: 'Month',
                          category: FormInputCategory.SELECT,
                          options: MONTHS,
                        },
                        {
                          name: 'divider',
                          category: FormInputCategory.COMPONENT,
                          component: <Divider>Or</Divider>,
                        },
                        {
                          name: 'findDataInADateRange',
                          category: FormInputCategory.COMPONENT,
                          component: (
                            <Typography variant="h6" my={1}>
                              Find data in a date range
                            </Typography>
                          ),
                        },
                        {
                          name: 'slaughterDate',
                          category: FormInputCategory.DATE_RANGE_PICKER,
                          disableFuture: true,
                          format: getDateFormat(org, 'short'),
                        },
                      ]}
                      maxWidth="sm"
                      itemSx={{ size: 'small', mb: 1 }}
                    />
                  </Stack>
                </Popover>
              </Stack>
            ),
          },
          {
            category: TableToolBarActionButtonCategory.ALWAYS,
            component:
              summariesToDisplay.length > 0 ? (
                <ExportButton
                  auth={auth}
                  dispatch={dispatch}
                  navigate={navigate}
                  dateFrom={
                    dateRange?.from?.format(DateFormatServer.SHORT) ||
                    moment(
                      summariesToDisplay[0].SLAUGHTER_DATE,
                      DateFormatServer.SHORT
                    ).format(DateFormatServer.SHORT)
                  }
                  dateTo={
                    dateRange?.to?.format(DateFormatServer.SHORT) ||
                    moment(
                      summariesToDisplay[summariesToDisplay.length - 1]
                        .SLAUGHTER_DATE,
                      DateFormatServer.SHORT
                    ).format(DateFormatServer.SHORT)
                  }
                  org={org}
                />
              ) : (
                <Stack />
              ),
          },
        ]}
      />
    </Stack>
  );
};

export default Page;
