import { Stack, Typography, Button } from '@mui/material';
import _ from 'lodash';

import { Org } from '../../../utils/Types';

type Props = {
  orgs: Org[];
  onSelectOrg: (orgId?: number) => void;
};

const SelectOrg = ({ orgs, onSelectOrg }: Props) => {
  return (
    <Stack spacing={3} alignItems="center">
      <Stack spacing={1} alignItems="center">
        <Typography variant="h4" fontWeight="bold" color="primary">
          Thank you
        </Typography>
        <Typography color="grey.500" textAlign="center">
          Please select an organisation to continue!
        </Typography>
      </Stack>
      <Stack
        spacing={2}
        alignItems="center"
        width="100%"
        maxWidth={300}
        maxHeight="80vh"
        overflow="scroll"
      >
        {_.orderBy(orgs, 'name').map((o: Org, index: number) => (
          <Button
            key={o?.name}
            variant="contained"
            fullWidth
            sx={{
              bgcolor: index % 2 === 0 ? 'white' : 'primary.main',
              color: index % 2 === 0 ? 'primary.main' : 'white',
              '&&:hover': {
                bgcolor: index % 2 === 0 ? 'grey.500' : 'primary.dark',
              },
            }}
            onClick={() => onSelectOrg(o?.id)}
          >
            {o?.name}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};

export default SelectOrg;
