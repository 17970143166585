import { createContext } from 'react';
import { Message } from './Types';

export const ThemeOveridesContext = createContext({
  apply: (overides: any) => {},
});

export const SnackbarContext = createContext({
  open: (m: Message) => {},
  close: () => {},
});
