import _ from 'lodash';

import {
  AVAILABEL_DATA_SOURCE,
  DATA_POOL_ITEM_EXPIRED_IN_MILLISECONDS,
} from './Constants';
import {
  Auth,
  Trait,
  LooseObject,
  DataPoolItem,
  DataSource,
  QueryType,
  DateFormatServer,
} from './Types';
import { setDataPool } from '../redux/reducers/dataPoolSlice';
import {
  applyUpperCaseTerms,
  compareServerDatesDesc,
  generateDataPoolId,
  getAllAtributesOfAList,
  getJsDataTypeFromServerDataType,
  isDateGreater,
  isNotEmpty,
  postToServer,
} from './Helper';

export const filterData = (data: LooseObject[], conditions: LooseObject) => {
  const dataToDisplay = data.filter(i => {
    let display = true;

    Object.keys(conditions)
      .filter(i => conditions[i] && isNotEmpty(conditions[i]?.value))
      .forEach(key => {
        // is a direct key
        if (i[key] || i[key] === 0) {
          if (Array.isArray(conditions[key].value)) {
            if (!conditions[key].value.includes(i[key])) {
              display = false;
            }
          } else {
            switch (conditions[key].type) {
              case 'date':
              case 'datetime':
                if (
                  isDateGreater(conditions[key].value.from, i[key]) ||
                  isDateGreater(i[key], conditions[key].value.to)
                ) {
                  display = false;
                }
                break;
              case 'number':
                if (
                  conditions[key].value.from > i[key] ||
                  i[key] > conditions[key].value.to
                ) {
                  display = false;
                }
                break;
            }
          }
        } else {
          // is in traits
          for (let m = 0; m < Object.keys(data[0]).length; m++) {
            const k = Object.keys(data[0])[m];
            if (Array.isArray(data[0][k])) {
              const trait = data[0][k].find((t: any) => t.key === key);
              if (trait) {
                if (Array.isArray(conditions[key].value)) {
                  if (
                    !conditions[key].value.includes(
                      i[k].find((t: any) => t.key === key)?.value
                    )
                  ) {
                    display = false;
                  }
                } else {
                  switch (conditions[key].type) {
                    case 'date':
                      if (
                        isDateGreater(
                          conditions[key].value.from,
                          i[k].find((t: any) => t.key === key)?.value
                        ) ||
                        isDateGreater(
                          i[k].find((t: any) => t.key === key)?.value,
                          conditions[key].value.to
                        )
                      ) {
                        display = false;
                      }
                      break;
                    case 'number':
                      if (
                        conditions[key].value.from >
                          i[k].find((t: any) => t.key === key)?.value ||
                        i[k].find((t: any) => t.key === key)?.value >
                          conditions[key].value.to
                      ) {
                        display = false;
                      }
                      break;
                  }
                }
                break;
              }
            }
          }
        }
      });
    return display;
  });
  return dataToDisplay;
};

export const getFilterCondition = ({
  values,
  type,
  data,
}: {
  values: any[];
  type?: string;
  data: any[];
}) => {
  if (values && Array.isArray(values) && values.length > 0) {
    switch (type) {
      case 'date':
      case 'datetime':
        values.sort(
          (a, b) =>
            -compareServerDatesDesc({
              a,
              b,
              dateFormat: DateFormatServer.SHORT,
            })
        );
        data.sort(
          (a, b) =>
            -compareServerDatesDesc({
              a,
              b,
              dateFormat: DateFormatServer.SHORT,
            })
        );
        // if date, find the closest from and to
        const dateWithoutSmall = data.filter(i =>
          isDateGreater(i, values[0], true)
        );
        const fromDate = dateWithoutSmall?.[0];
        const dateWithoutBig = data.filter(
          i => !isDateGreater(i, values[values.length - 1])
        );
        const toDate = dateWithoutBig[dateWithoutBig.length - 1];

        return {
          value: {
            from: fromDate,
            to: isDateGreater(fromDate, toDate) ? fromDate : toDate,
          },
          type,
        };

      case 'number':
        values.sort((a, b) => a - b);
        data.sort((a, b) => a - b);
        // if number, find the closest from and to
        const numberWithoutSmall = data.filter(i => i >= values[0]);
        const fromNumber = numberWithoutSmall?.[0];
        const numberWithoutBig = data.filter(
          i => i <= values[values.length - 1]
        );
        const toNumber = numberWithoutBig[numberWithoutBig.length - 1];
        return {
          value: {
            from: fromNumber,
            to: fromNumber > toNumber ? fromNumber : toNumber,
          },
          type,
        };
    }
  }

  return { value: values, type };
};

export const getDataPoolItemFromDataSource = async ({
  auth,
  dataSource,
}: {
  auth: Auth;
  dataSource: DataSource;
}) => {
  if (auth?.token && dataSource) {
    if (isNotEmpty(dataSource.query)) {
      // is external data source
      const availableDataSource = AVAILABEL_DATA_SOURCE.find(
        i => i.key === dataSource.key
      );
      if (availableDataSource) {
        dataSource = { ...dataSource, ...availableDataSource };
      }
    }

    if (
      isNotEmpty(dataSource.query) ||
      dataSource.isNoParamsPermitted ||
      isNotEmpty(dataSource.params)
    ) {
      const dataPoolItem: DataPoolItem = {
        id: generateDataPoolId({
          dataSourceKey: dataSource.key,
          dataSourceParams: dataSource.params || {},
        }),
        dataSource,
        data: [],
        attributes: [],
        updatedAt: Date.now(),
      };
      await postToServer({
        action: dataSource.endpoint,
        params: dataSource.query
          ? {
              id: auth.orgOwner,
              type: QueryType.ORDINARY_QUERY,
              view: dataSource.query.view,
              columnsInterested: dataSource.query.columnsInterested,
              sqlConditions: dataSource.query.sqlConditions,
            }
          : dataSource.params || {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            if (isNotEmpty(dataSource.query)) {
              const serverData = response.serverData as LooseObject;

              const attributes: Trait[] = serverData.columns
                .filter(
                  (i: LooseObject) =>
                    !dataSource.excludeAttributes.includes(i.name)
                )
                .map((i: LooseObject) => ({
                  key: i.name,
                  label: applyUpperCaseTerms(_.startCase(i.name.toLowerCase())),
                  type: getJsDataTypeFromServerDataType(i.type),
                }));

              dataPoolItem.data = serverData.rows;
              dataPoolItem.attributes = attributes;
            } else {
              const serverData = response.serverData as LooseObject[];

              const attributes = getAllAtributesOfAList(serverData).filter(
                i => !dataSource.excludeAttributes.includes(i.key)
              );
              dataPoolItem.data = serverData;
              dataPoolItem.attributes = attributes;
            }
          }
        }
      });

      return dataPoolItem;
    }
  }

  return undefined;
};

export const updateDataPool = async ({
  dataPool,
  dataSource,
  auth,
  dispatch,
}: {
  dataPool: DataPoolItem[];
  dataSource: DataSource;
  auth: Auth;
  dispatch: any;
}) => {
  let existingDataPool = [...dataPool];

  const dataPoolId = generateDataPoolId({
    dataSourceKey: dataSource.key,
    dataSourceParams: dataSource.params || {},
  });

  const existingDataPoolItem = existingDataPool.find(i => i.id === dataPoolId);

  if (
    !existingDataPoolItem ||
    Date.now() - existingDataPoolItem.updatedAt >
      DATA_POOL_ITEM_EXPIRED_IN_MILLISECONDS
  ) {
    const dataPoolItem = await getDataPoolItemFromDataSource({
      auth,
      dataSource,
    });

    if (dataPoolItem) {
      existingDataPool = [
        ...existingDataPool.filter(i => i.id !== dataPoolItem.id),
        dataPoolItem,
      ];
      dispatch(
        setDataPool({
          type: 'dataPool/set',
          payload: existingDataPool,
        })
      );
    }

    return existingDataPool;
  }
  return dataPool;
};
