import _ from 'lodash';

import { FormInputCategory, PluginName } from '../../../utils/Types';
import { Form, DialogWrapper } from '../../../components';

type Props = {
  onConfirm: (values: any) => void;
  onCancel: (values: any) => void;
  loading: boolean;
  existings: string[];
};

const Add = ({ onConfirm, onCancel, loading, existings }: Props) => (
  <DialogWrapper
    title="Add a New Plugin"
    description="Before adding any plugin, please make sure it is listed in the enum PluginName of types.ts file from both the frontend and backend code. Otherwise, the backend will reject the request. This is to ensure the source code is lined up with the database."
    onCancel={onCancel}
    hideCancelButton
    hideConfirmButton
  >
    <Form
      loading={loading}
      onSubmit={onConfirm}
      buttonText="Submit"
      inputs={[
        {
          name: 'name',
          label: 'Name',
          category: FormInputCategory.SELECT,
          options: _.difference(Object.values(PluginName), existings).map(
            o => ({ label: o, value: o })
          ),
        },
        {
          name: 'description',
          label: 'Description',
          category: FormInputCategory.TEXT_INPUT,
        },
      ]}
    />
  </DialogWrapper>
);

export default Add;
