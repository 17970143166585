import { Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Moment } from 'moment';

import FrameImage, { FrameImageProps } from './FrameImage';
import { Dialog, DialogWrapperInfo } from '../../../components';
import { Trait, Type } from './GraphArea';
import { LooseObject } from '../../../utils/Types';
import { isNotEmpty } from '../../../utils/Helper';

const FrameItem = ({
  id,
  data,
  date,
  trait,
  type,
  facility,
  barcode,
}: {
  id: string;
  data: LooseObject;
  date: Moment | null;
  trait: Trait;
  type: Type;
  facility?: string;
  barcode: string;
}) => {
  const [currentItem, setCurrentItem] = useState<FrameImageProps>();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Grid
      item
      container
      xs={12}
      bgcolor="grey.300"
      border={1}
      borderColor="grey.500"
      mb={1}
    >
      <Stack direction="row" spacing={2} justifyContent="center" width="100%">
        <Typography fontWeight="bold">{`Frame #: ${id}`}</Typography>
        {isNotEmpty(data.android_meq) && (
          <Typography>{`Android MEQ: ${data.android_meq}`}</Typography>
        )}
        {isNotEmpty(data.gpu_meq) && (
          <Typography>{`GPU MEQ: ${data.gpu_meq}`}</Typography>
        )}
        {isNotEmpty(data.truth) && (
          <Typography>{`Truth: ${data.truth}`}</Typography>
        )}
      </Stack>
      {data.contour_frame && (
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={6}>
            <FrameImage
              url={data.contour_frame}
              title={`Contour Frame #${id}`}
              onClick={image => {
                setCurrentItem(image);
                setOpenDialog(true);
              }}
              barcode={barcode}
              date={date}
              trait={trait}
              type={type}
              facility={facility}
            />
          </Grid>
          <Grid item xs={6}>
            {data.depth_frame ? (
              data.depth_frame.map((i: string) => (
                <FrameImage
                  key={`depth_frame_${id}_${i}`}
                  url={i}
                  title={`Depth Frame #${id}`}
                  onClick={image => {
                    setCurrentItem(image);
                    setOpenDialog(true);
                  }}
                  barcode={barcode}
                  date={date}
                  trait={trait}
                  type={type}
                  facility={facility}
                />
              ))
            ) : (
              <Stack
                flex={1}
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%', height: '100%' }}
              >
                <Typography>No image available.</Typography>
              </Stack>
            )}
          </Grid>
        </Grid>
      )}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        isTransparent
      >
        <DialogWrapperInfo
          onClose={() => setOpenDialog(false)}
          maxHeight="100vh"
        >
          {currentItem?.title && currentItem?.url && (
            <FrameImage
              url={currentItem.url}
              title={currentItem.title}
              height="90vh"
              barcode={barcode}
              date={date}
              trait={trait}
              type={type}
              facility={facility}
            />
          )}
        </DialogWrapperInfo>
      </Dialog>
    </Grid>
  );
};

export default FrameItem;
