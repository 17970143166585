import { Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';

import { DateFormat, FormInputItem, LooseObject } from '../../utils/Types';

export type DatePickerProps = {
  label?: string;
  name?: string;
  item: FormInputItem;
  textFieldProps: LooseObject;
  values: {};
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  minDate?: any;
  maxDate?: any;
  inputProps?: {};
  format?: string;
};

const DatePicker = ({
  label,
  name,
  item,
  values,
  setFieldValue,
  minDate,
  maxDate,
  textFieldProps,
  format,
}: DatePickerProps) => (
  <Stack alignItems="flex-start" width="100%">
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        {...textFieldProps}
        label={label || item.label}
        disablePast={item.disablePast}
        disableFuture={item.disableFuture}
        value={
          values[name || item.name] === undefined
            ? null
            : values[name || item.name]
        }
        onChange={v => {
          setFieldValue(name || item.name, v);
          item.exposeValue?.(v);
        }}
        minDate={minDate}
        maxDate={maxDate}
        format={format || DateFormat.SHORT}
        sx={{ width: '100%', ...textFieldProps?.sx }}
      />
    </LocalizationProvider>
  </Stack>
);

export default DatePicker;
