import { useContext, useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import { SnackbarContext } from '../../../utils/Contexts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import {
  getDateFormat,
  isNotEmpty,
  logout,
  optimiseQueryResult,
  postToServer,
} from '../../../utils/Helper';
import {
  HISTOGRAM_Y_AXIS_TITLE,
  QUERY_DATA_SOURCE_ENDPOINT,
} from '../../../utils/Constants';
import {
  ChartType,
  DateFormatServer,
  QueryResult,
  QueryType,
} from '../../../utils/Types';
import {
  Button,
  Chart,
  Dialog,
  DialogWrapperInfo,
  Skeleton,
} from '../../../components';
import TraitsHistogram from './TraitsHistogram';
import CarcaseTable from './CarcaseTable';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<QueryResult>();
  const [uniqDates, setUniqDates] = useState<string[]>([]);
  const [date, setDate] = useState<string>('');
  const [openDialog, setOpenDialog] = useState(false);

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, []);

  const fetchData = _.debounce(async () => {
    if (auth?.token) {
      setLoading(true);
      await postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ORDINARY_QUERY,
          view: 'AMG_INSIGHTS_CARCASE',
          sqlConditions: '1 = 1',
        },
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            setData(optimiseQueryResult(serverData));
            if (serverData.rows && serverData.rows.length > 0) {
              const uniqDates = _.uniq(serverData.rows.map(i => i.KILLDATE));
              const sortedDates = _.orderBy(
                uniqDates,
                o => moment(o, DateFormatServer.SHORT),
                'desc'
              );
              setUniqDates(sortedDates);
              setDate(sortedDates[0]);
            }
          } else {
            snackbar.open(response.message);
          }
        }
      });
      setLoading(false);
    }
  }, 500);

  const EXCLUDED_TRAITS = [
    'BARCODE',
    'KILLDATE',
    'LOT_NUMBER',
    'BODY_NO',
    'HANG',
    'MFV',
    'SY',
    'HCP',
    'FATDIST',
    'HIDEPE',
    'HSCW',
    'MEQPROBE_MSA',
    'MEQPROBE_AUS',
    'MSAMB',
    'AUSMB',
    'MEQCAMERA_BARCODE',
    'MEQCAMERA_MSA',
    'MEQCAMERA_AUS',
  ];

  const handleChangeDate = (event: SelectChangeEvent) => {
    if (event?.target?.value) {
      setDate(event.target.value);
    }
  };

  const dataToDisplay = data?.rows?.filter(i => i.KILLDATE === date);

  const dataToDisplayForChart = data?.rows?.filter(
    i => i.KILLDATE === date && isNotEmpty(i.MEQPROBE_MSA)
  );

  return loading || !data?.columns ? (
    <Skeleton />
  ) : (
    <Stack width="100%" height="100%" spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControl size="small" sx={{ bgcolor: 'white', mt: 2, width: 200 }}>
          <InputLabel>Date</InputLabel>
          <Select value={date} label="Date" onChange={handleChangeDate}>
            {uniqDates?.map(i => (
              <MenuItem key={'date_' + i} value={i}>
                {moment(i, DateFormatServer.SHORT).format(
                  getDateFormat(org, 'short')
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack>
          <Stack direction="row">
            <Typography>{`Bodies Processed: ${dataToDisplay?.length}`}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography>{`Bodies Probed: ${dataToDisplayForChart?.length}`}</Typography>
          </Stack>
        </Stack>
        <Button
          variant="outlined"
          title="Datatable"
          onClick={() => setOpenDialog(true)}
        />
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          isTransparent
        >
          <DialogWrapperInfo
            onClose={() => setOpenDialog(false)}
            maxHeight="100vh"
            minWidth={300}
          >
            {dataToDisplay && (
              <CarcaseTable
                date={date}
                columns={data?.columns}
                rows={dataToDisplay}
                loading={loading}
                auth={auth}
                org={org}
              />
            )}
          </DialogWrapperInfo>
        </Dialog>
      </Stack>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Chart
            type={ChartType.HISTOGRAM}
            title="MEQProbe MSA vs. AMG MSA"
            data={{
              xTitle: 'Value',
              yTitle: HISTOGRAM_Y_AXIS_TITLE,
              data: [
                {
                  x: dataToDisplayForChart?.map(i => i.MEQPROBE_MSA),
                  name: 'MEQProbe MSA',
                  nameX: 'MEQProbe MSA',
                  type: 'histogram',
                },
                {
                  x: dataToDisplayForChart?.map(i => i.MSAMB),
                  name: 'AMG MSA',
                  nameX: 'AMG MSA',
                  type: 'histogram',
                },
              ],
            }}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Chart
            type={ChartType.HISTOGRAM}
            title="MEQProbe AUS vs. AMG AUS"
            data={{
              xTitle: 'Value',
              yTitle: HISTOGRAM_Y_AXIS_TITLE,
              data: [
                {
                  x: dataToDisplayForChart?.map(i => i.MEQPROBE_AUS),
                  name: 'MEQProbe AUS',
                  nameX: 'MEQProbe AUS',
                  type: 'histogram',
                  histfunc: 'count',
                },
                {
                  x: dataToDisplayForChart?.map(i => i.AUSMB),
                  name: 'AMG AUS',
                  nameX: 'AMG AUS',
                  type: 'histogram',
                  histfunc: 'count',
                },
              ],
            }}
            loading={loading}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Chart
            type={ChartType.SCATTER_PLOT}
            title="MEQProbe MSA vs. AMG MSA"
            data={{
              xTitle: 'MEQProbe MSA',
              yTitle: 'AMG MSA',
              data: [
                {
                  x: dataToDisplayForChart?.map(i => i.MEQPROBE_MSA),
                  y: dataToDisplayForChart?.map(i => i.MSAMB),
                },
              ],
            }}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Chart
            type={ChartType.SCATTER_PLOT}
            title="MEQProbe AUS vs. AMG AUS"
            data={{
              xTitle: 'MEQProbe AUS',
              yTitle: 'AMG AUS',
              data: [
                {
                  x: dataToDisplayForChart?.map(i => i.MEQPROBE_AUS),
                  y: dataToDisplayForChart?.map(i => i.AUSMB),
                },
              ],
            }}
            loading={loading}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <Chart
            type={ChartType.HISTOGRAM}
            title="Histogram Of HSCW"
            data={{
              xTitle: 'HSCW',
              yTitle: HISTOGRAM_Y_AXIS_TITLE,
              data: [
                {
                  x: dataToDisplayForChart?.map(i => i.HSCW),
                  type: 'histogram',
                  histfunc: 'count',
                },
              ],
            }}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TraitsHistogram
            data={dataToDisplayForChart || []}
            allTraits={
              data?.columns?.filter(i => !EXCLUDED_TRAITS.includes(i.name)) ||
              []
            }
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Chart
            type={ChartType.PIE_CHART}
            title="Pie Chart Of Sex"
            data={{
              xTitle: 'Sex',
              yTitle: 'Count',
              data: [
                {
                  x: dataToDisplayForChart?.map(i => i.SEX),
                  y: dataToDisplayForChart?.map(() => 1),
                  name: 'Count',
                  nameX: 'Sex',
                  type: 'pie',
                  textinfo: 'label+percent',
                },
              ],
            }}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Page;
