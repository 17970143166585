import { Box, Stack } from '@mui/material';

import { useImage } from '../../hooks';

type Props = {
  img?: string;
  maxWidth?: number;
  maxHeight?: number;
  mb?: number;
};

const Logo = ({ img, maxWidth, maxHeight, mb }: Props) => {
  const { image } = useImage(`logos/${img}`);

  return (
    <Stack
      mb={mb}
      width={maxWidth}
      height={maxHeight}
      justifyContent="center"
      alignItems="center"
    >
      {image ? (
        <img
          src={image}
          alt="logo"
          style={{ maxWidth, maxHeight, objectFit: 'contain' }}
        />
      ) : (
        <Box height={60} />
      )}
    </Stack>
  );
};

export default Logo;
