import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import {
  authReducer,
  orgReducer,
  userReducer,
  dataPoolReducer,
} from './reducers';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    org: orgReducer,
    user: userReducer,
    dataPool: dataPoolReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
