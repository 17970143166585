import { ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Grid, Stack } from '@mui/material';
import { DeleteOutline, DragIndicator } from '@mui/icons-material';

import { LooseObject } from '../utils/Types';
import ToolButton from './Button/ToolButton';

const SortableItem = ({
  id,
  children,
  isEditMode,
  handleRemove,
  sx,
}: {
  id: number | string;
  children: ReactNode;
  isEditMode: boolean;
  handleRemove: (id: string | number) => void;
  sx?: LooseObject;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid item xs={12} lg={6} xl={4} sx={style}>
      <Stack
        width="100%"
        height="100%"
        p={1}
        border={isEditMode ? 0.5 : 0}
        flexDirection="column"
        alignItems="flex-end"
        sx={sx}
      >
        {isEditMode && (
          <Stack direction="row" spacing={1}>
            <ToolButton
              title="Remove"
              icon={<DeleteOutline />}
              buttonProps={{
                sx: { width: 30, height: 30 },
              }}
              onClick={() => handleRemove(id)}
            />
            <ToolButton
              title="Drag and Move"
              icon={<DragIndicator />}
              buttonProps={{
                sx: { width: 30, height: 30 },
                ref: setNodeRef,
                ...attributes,
                ...listeners,
              }}
            />
          </Stack>
        )}
        <Stack width="100%" height="100%">
          {children}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default SortableItem;
