import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  Addchart,
  AppRegistration,
  Clear,
  DoneOutline,
} from '@mui/icons-material';

import { SortableItem as SortableItemType } from '../utils/Types';
import SortableItem from './SortableItem';
import ToolButton from './Button/ToolButton';

const SortableContainer = ({
  items,
  updateIndexes,
  handleAdd,
  handleDone,
  isWritable,
}: {
  items: SortableItemType[];
  updateIndexes?: (newIds: (number | string)[]) => void;
  handleAdd: () => void;
  handleDone: (v: (string | number)[]) => void;
  isWritable?: boolean;
}) => {
  const [sortedItems, setSortedItems] = useState(items);
  const [isEditMode, setIsEditMode] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setSortedItems(items);
  }, [items, isEditMode]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setSortedItems(sortedItems => {
        const ids = sortedItems.map(i => i.id);
        const oldIndex = ids.indexOf(active.id);
        const newIndex = ids.indexOf(over.id);

        const newSortedItems = arrayMove(sortedItems, oldIndex, newIndex);
        updateIndexes?.(newSortedItems.map(i => i.id));
        return newSortedItems;
      });
    }
  };

  const handleRemove = (id: string | number) => {
    if (id !== null && id !== undefined) {
      setSortedItems(sortedItems => {
        const newSortedItems = sortedItems.filter(i => i.id !== id);
        updateIndexes?.(newSortedItems.map(i => i.id));
        return newSortedItems;
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToWindowEdges]}
    >
      <Stack width="100%">
        {isWritable && (
          <Stack
            width="100%"
            bgcolor="grey.100"
            alignItems="flex-end"
            my={0.5}
            p={0.5}
          >
            {isEditMode ? (
              <Stack direction="row" spacing={1}>
                <ToolButton
                  title="Cancel"
                  icon={<Clear sx={{ fontSize: 30 }} />}
                  onClick={() => {
                    setIsEditMode(false);
                  }}
                />
                <ToolButton
                  title="Done"
                  icon={<DoneOutline sx={{ fontSize: 30 }} />}
                  onClick={() => {
                    handleDone(sortedItems.map(i => i.id));
                    setIsEditMode(false);
                  }}
                />
              </Stack>
            ) : (
              <Stack direction="row">
                <ToolButton
                  title="Add a new item"
                  icon={<Addchart sx={{ fontSize: 30 }} />}
                  onClick={handleAdd}
                />
                <ToolButton
                  title="Edit"
                  icon={<AppRegistration sx={{ fontSize: 30 }} />}
                  onClick={() => setIsEditMode(true)}
                />
              </Stack>
            )}
          </Stack>
        )}
        <SortableContext items={sortedItems}>
          <Grid container spacing={1}>
            {sortedItems.map(item => (
              <SortableItem
                key={item.id}
                id={item.id}
                isEditMode={isEditMode}
                handleRemove={handleRemove}
              >
                {item.component}
              </SortableItem>
            ))}
          </Grid>
        </SortableContext>
      </Stack>
    </DndContext>
  );
};

export default SortableContainer;
