import { Stack, Typography } from '@mui/material';

import { PublicPageContainner } from '../components';
import { HelperTextLogin } from './auth/Login/LoginForm';

const Page = () => (
  <PublicPageContainner>
    <Stack spacing={3} alignItems="center" textAlign="center">
      <Typography variant="h2" fontWeight="bold" color="primary">
        &#128519; Oops!
      </Typography>
      <Typography variant="h4" fontWeight="bold" color="primary">
        404 Page Not Found
      </Typography>
      <HelperTextLogin text="Get lost?" to="/" linkTitle="Return to home" />
    </Stack>
  </PublicPageContainner>
);

export default Page;
