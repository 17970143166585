import { useContext, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectAuth } from '../../redux/reducers/authSlice';
import { selectUser, setUser } from '../../redux/reducers/userSlice';
import { FormInputCategory, FormData } from '../../utils/Types';
import { Form } from '../../components';
import { logout, postToServer } from '../../utils/Helper';
import { SnackbarContext } from '../../utils/Contexts';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = ({ firstName, lastName }: FormData) => {
    if (firstName && lastName) {
      setLoading(true);
      if (auth?.token) {
        const params = { firstName, lastName };
        postToServer({
          action: 'UpdateProfile',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              // save user to current browser
              localStorage.setItem('user', JSON.stringify(response.serverData));
              dispatch(
                setUser({
                  type: 'user/set',
                  payload: response.serverData,
                })
              );
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setLoading(false);
        logout({ dispatch, navigate });
      }
    }
  };

  return (
    <Stack spacing={3}>
      <Typography mt={2}>Update your personal information.</Typography>
      <Form
        loading={loading}
        onSubmit={handleSubmit}
        buttonText="Update"
        maxWidth={500}
        inputs={[
          {
            name: 'firstName',
            label: 'First Name',
            category: FormInputCategory.TEXT_INPUT,
            defaultValue: user?.firstName,
          },
          {
            name: 'lastName',
            label: 'Last Name',
            category: FormInputCategory.TEXT_INPUT,
            defaultValue: user?.lastName,
          },
        ]}
      />
    </Stack>
  );
};

export default Page;
