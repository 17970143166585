import { DownloadForOfflineOutlined } from '@mui/icons-material';
import { Tooltip, IconButton, Stack, CircularProgress } from '@mui/material';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import {
  delay,
  getDateFormat,
  logout,
  optimiseQueryResult,
  postToServer,
} from '../../../utils/Helper';
import {
  Auth,
  DateFormatServer,
  LooseObject,
  Org,
  QueryResult,
  QueryType,
} from '../../../utils/Types';
import { SnackbarContext } from '../../../utils/Contexts';
import { QUERY_DATA_SOURCE_ENDPOINT } from '../../../utils/Constants';

const ExportButton = ({
  auth,
  dispatch,
  navigate,
  dateFrom,
  dateTo,
  org,
}: {
  auth: Auth;
  dispatch: any;
  navigate: any;
  dateFrom: string;
  dateTo?: string;
  org: Org;
}) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [csvFilename, setCsvFilename] = useState<string>(
    'sheep_probe_data.csv'
  );
  const [allData, setAllData] = useState<LooseObject[]>([]);

  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  // make sure dateFrom < dateTo
  if (
    dateFrom &&
    dateTo &&
    moment(dateFrom, DateFormatServer.SHORT) >
      moment(dateTo, DateFormatServer.SHORT)
  ) {
    const temp = dateFrom;
    dateFrom = dateTo;
    dateTo = temp;
  }

  const downloadCsvFile = ({
    dateFrom,
    dateTo,
  }: {
    dateFrom: string;
    dateTo?: string;
  }) => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ORDINARY_QUERY,
          view: 'FREWS_INSIGHTS_CARCASE',
          sqlConditions: dateTo
            ? `SLAUGHTER_DATE BETWEEN '${dateFrom}' AND '${dateTo}'`
            : `SLAUGHTER_DATE = '${dateFrom}'`,
        },
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            const optimisedServerData = optimiseQueryResult(serverData);

            setCsvFilename(
              dateTo
                ? `sheep_probe_data_${dateFrom}_to_${dateTo}.csv`
                : `sheep_probe_data_${dateFrom}.csv`
            );

            setAllData(optimisedServerData.rows || []);
            await delay(1000);
            csvLink.current?.link.click();
          } else {
            snackbar.open(response.message);
          }

          setLoading(false);
        }
      });
    }
  };

  return (
    <Stack>
      <Tooltip
        placement="left"
        title={
          dateTo
            ? `Export data from ${moment(
                dateFrom,
                DateFormatServer.SHORT
              ).format(getDateFormat(org, 'short'))} to ${moment(
                dateTo,
                DateFormatServer.SHORT
              ).format(getDateFormat(org, 'short'))} as CSV file`
            : dateFrom
            ? `Export data as CSV file for ${moment(
                dateFrom,
                DateFormatServer.SHORT
              ).format(getDateFormat(org, 'short'))}`
            : 'Export data as CSV file'
        }
      >
        <IconButton
          onClick={() =>
            downloadCsvFile({
              dateFrom,
              dateTo,
            })
          }
          disabled={loading}
          sx={{ width: 40, height: 40 }}
        >
          {loading ? (
            <CircularProgress size={22} />
          ) : (
            <DownloadForOfflineOutlined />
          )}
        </IconButton>
      </Tooltip>
      <CSVLink
        data={allData}
        filename={csvFilename}
        // headers={[{ key: 'body', label: 'Body' }]}
        ref={csvLink}
      ></CSVLink>
    </Stack>
  );
};

export default ExportButton;
