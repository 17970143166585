import { useEffect, useState } from 'react';

import {
  Auth,
  DateFormatServer,
  FormInputCategory,
  FormInputItem,
  LooseObject,
  Org,
  UserRole,
} from '../../../utils/Types';
import { Form, DialogWrapper } from '../../../components';
import {
  compareServerDatesDesc,
  getUserRoleNames,
  postToServer,
} from '../../../utils/Helper';
import { DEMO_ORGS } from '../../../utils/Constants';

type Props = {
  onConfirm: (values: any) => void;
  onCancel: (values: any) => void;
  loading: boolean;
  item: LooseObject;
  auth?: Auth;
  org?: Org;
  isAdministration?: boolean;
};

const Edit = ({
  onConfirm,
  onCancel,
  loading,
  item,
  auth,
  org,
  isAdministration,
}: Props) => {
  const [summaries, setSummaries] = useState<LooseObject[]>([]);

  useEffect(() => {
    fetchAvailableDatesInCamera();
  }, []);

  const fetchAvailableDatesInCamera = () => {
    if (org && DEMO_ORGS.includes(org.owner) && auth?.token) {
      postToServer({
        action: 'camera/SummaryImages',
        params: {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            if (
              Array.isArray(response.serverData) &&
              response.serverData.length > 0
            ) {
              setSummaries(
                (response.serverData as LooseObject[]).sort((a, b) =>
                  compareServerDatesDesc({
                    a: a.photo_date,
                    b: b.photo_date,
                    dateFormat: DateFormatServer.SHORT,
                  })
                )
              );
            }
          }
        }
      });
    }
  };

  const inputs: FormInputItem[] = [
    {
      name: 'firstName',
      label: 'First Name',
      category: FormInputCategory.TEXT_INPUT,
      defaultValue: item?.firstName,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      category: FormInputCategory.TEXT_INPUT,
      defaultValue: item?.lastName,
    },
    {
      name: 'role',
      label: 'Role',
      category: FormInputCategory.SELECT,
      options: getUserRoleNames({
        min: UserRole.ORGANISATION_USER,
        max: UserRole.ORGANISATION_ADMIN,
      }),
      defaultValue: item.role.params.role,
    },
    {
      name: 'isValid',
      label: 'Active',
      category: FormInputCategory.SWITCH,
      defaultValue: item.isValid,
    },
  ];

  if (org && DEMO_ORGS.includes(org.owner)) {
    inputs.splice(inputs.length - 1, 0, {
      name: 'photoDates',
      label: 'Photo Dates To Show In Camera',
      helperText: 'If empty, the user will see all dates available after login',
      category: FormInputCategory.SELECT,
      options: summaries.map(i => ({
        label: i.photo_date,
        value: i.photo_date,
      })),
      defaultValue: item.roleConfig?.photoDates,
      multiple: true,
      selectAll: true,
    });
  }

  return (
    <DialogWrapper
      title={`Edit User ${item.firstName} ${item.lastName}`}
      onCancel={onCancel}
      hideCancelButton
      hideConfirmButton
    >
      <Form
        loading={loading}
        onSubmit={onConfirm}
        buttonText="Submit"
        inputs={inputs}
      />
    </DialogWrapper>
  );
};

export default Edit;
