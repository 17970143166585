import { Stack, Alert } from '@mui/material';
import { Message } from '../utils/Types';

interface props {
  message: Message;
}

const ServerMessage = ({ message }: props) => (
  <Stack my={1} width="100%">
    <Alert severity={message.type}>{message.text}</Alert>
  </Stack>
);
export default ServerMessage;
