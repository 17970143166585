import * as React from 'react';
import {
  IconButton,
  TableCell,
  TableRow,
  Collapse,
  Checkbox,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';

import { TableRowProps, TableColumnProps, TableRowId } from '../../utils/Types';
import CollapsedRow from './CollapsedRow';

type RowProps = {
  index: number;
  columns: TableColumnProps[];
  row: TableRowProps;
  collapsedColumns?: string[]; // should not be used with collapsedComponent
  labelId: string;
  handleClick: (event: React.MouseEvent<unknown>, name: TableRowId) => void;
  isItemSelected: boolean;
  isSelectable?: boolean;
  onClickRow?: Function;
  borderColor?: string;
  showAllBorder?: boolean;
  collapsedComponent?: Function; // should not be used with collapsedColumns
  allRows: TableRowProps[]; // pass all current (sorted) rows to onClickRow
};

const Row = ({
  index,
  columns,
  row,
  collapsedColumns,
  labelId,
  handleClick,
  isItemSelected,
  isSelectable,
  onClickRow,
  borderColor,
  showAllBorder,
  collapsedComponent,
  allRows,
}: RowProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={`table-row-checkbox-${index}`}
        selected={isItemSelected}
        sx={{
          cursor: onClickRow ? 'pointer' : 'default',
          '& > *': onClickRow ? { borderBottom: 'unset' } : {},
          bgcolor: open ? 'grey.300' : row.color,
          '&&:hover': {
            bgcolor: open ? 'grey.300' : row.color,
          },
          ...row.sx,
        }}
      >
        {isSelectable && (
          <TableCell
            padding="checkbox"
            sx={{ borderRight: showAllBorder ? 1 : 0, borderColor }}
          >
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId,
              }}
              onClick={event => handleClick(event, row.id)}
            />
          </TableCell>
        )}
        {(collapsedColumns || collapsedComponent) && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              onClick={() => setOpen(!open)}
              sx={{
                width: 22,
                height: 22,
                m: 0.5,
                bgcolor: open ? 'grey.900' : 'grey.200',
                color: open ? 'grey.100' : 'grey.500',
                '&&:hover': {
                  bgcolor: open ? 'grey.600' : 'grey.400',
                  color: open ? 'grey.200' : 'grey.200',
                },
              }}
            >
              {open ? (
                <KeyboardArrowDown fontSize="small" />
              ) : (
                <KeyboardArrowRight fontSize="small" />
              )}
            </IconButton>
          </TableCell>
        )}
        {columns.map((column, index) => {
          const value = row[column.id];
          return (
            (!collapsedColumns || !collapsedColumns.includes(column.id)) &&
            !column.hidden && (
              <TableCell
                key={column.id}
                align={column.align}
                sx={{
                  maxWidth: column.maxWidth,
                  borderLeft: index !== 0 && showAllBorder ? 1 : 0,
                  borderColor,
                }}
                id={index === 0 ? labelId : undefined}
                scope={index === 0 ? 'row' : undefined}
                padding={column.disablePadding ? 'none' : 'normal'}
                onClick={() => onClickRow?.(row, allRows)}
              >
                {column.component && typeof column.component === 'function'
                  ? column.component(
                      value?.isDependent
                        ? { index, row, params: value.params }
                        : value
                    )
                  : value}
              </TableCell>
            )
          );
        })}
      </TableRow>
      {collapsedComponent && (
        <TableRow>
          <TableCell sx={{ p: 0, maxWidth: 0 }} colSpan={columns.length + 1}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {collapsedComponent(row)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {collapsedColumns && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              {collapsedColumns.map(collapsedColumn => {
                const value = row[collapsedColumn];
                const column = columns.find(i => i.id === collapsedColumn);
                return column ? (
                  <CollapsedRow
                    key={column.id}
                    label={column.label}
                    value={
                      column.component && typeof column.component === 'function'
                        ? column.component(
                            value.isDependent
                              ? { index, row, params: value.params }
                              : value
                          )
                        : value
                    }
                  />
                ) : null;
              })}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default Row;
