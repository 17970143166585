import { Stack } from '@mui/material';
import { useState } from 'react';
import Otp from 'react-otp-input';

type Props = {
  onChange: (v: string) => void;
  handleSubmit?: () => void;
  isSmallScreen?: boolean;
};
const OtpInput = ({ onChange, handleSubmit, isSmallScreen }: Props) => {
  const [otpToken, setOtpToken] = useState('');

  return (
    <Stack>
      <form onKeyDown={e => e.key === 'Enter' && handleSubmit?.()}>
        <Otp
          value={otpToken}
          onChange={v => {
            setOtpToken(v);
            onChange(v);
          }}
          numInputs={6}
          // inputType="number"
          renderSeparator={<span>-</span>}
          renderInput={props => <input {...props} />}
          inputStyle={{
            width: isSmallScreen ? 35 : 50,
            height: isSmallScreen ? 35 : 50,
            fontSize: isSmallScreen ? 20 : 26,
          }}
        />
      </form>
    </Stack>
  );
};

export default OtpInput;
