import { Button } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';

type Props = {
  tag: string;
  defaultState: boolean | undefined;
  onSubmit: (value: any) => void;
};

const TagButton = ({ tag, defaultState, onSubmit }: Props) => {
  const [isTag, setIsTag] = useState(defaultState);
  useEffect(() => {
    setIsTag(defaultState);
  }, [defaultState]);

  return (
    <Button
      variant="contained"
      size="small"
      color={isTag ? 'secondary' : 'primary'}
      sx={{ px: 0.2, py: 0, fontSize: 10, textTransform: 'none' }}
      onClick={() => {
        onSubmit(JSON.parse(`{"${tag}":${!isTag}}`));
        setIsTag(!isTag);
      }}
    >
      {(isTag ? 'Untag ' : 'Tag ') + _.startCase(tag)}
    </Button>
  );
};

export default TagButton;
