import { useEffect, useState } from 'react';

import { DialogWrapper, Skeleton, Table } from '../../../components';
import { Auth, QueryResult, QueryType } from '../../../utils/Types';
import { postToServer } from '../../../utils/Helper';
import { QUERY_DATA_SOURCE_ENDPOINT } from '../../../utils/Constants';

const QueryResultDialog = ({
  view,
  columnsInterested,
  sqlConditions,
  onCancel,
  auth,
  snackbar,
}: {
  view: string;
  columnsInterested?: string[];
  sqlConditions: string;
  onCancel: () => void;
  auth: Auth;
  snackbar: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [queryResult, setQueryResult] = useState<QueryResult>();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ORDINARY_QUERY,
          view,
          columnsInterested,
          sqlConditions,
        },
        token: auth.token,
      }).then(async response => {
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            setQueryResult(serverData);
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  return (
    <DialogWrapper
      title="Data Source Result"
      onCancel={onCancel}
      hideCancelButton
    >
      {!loading && queryResult && queryResult.columns ? (
        <Table
          columns={queryResult.columns?.map(i => ({
            accessorKey: i.name,
            header: i.name,
          }))}
          data={queryResult.rows || []}
        />
      ) : (
        <Skeleton />
      )}
    </DialogWrapper>
  );
};

export default QueryResultDialog;
