import { FormInputCategory, LooseObject } from '../../../utils/Types';
import { Form, DialogWrapper } from '../../../components';

type Props = {
  title: string;
  item?: LooseObject;
  onConfirm: (values: any) => void;
  onCancel: (values: any) => void;
  loading: boolean;
};

const TermsForm = ({
  title,
  item = {},
  onConfirm,
  onCancel,
  loading,
}: Props) => {
  return (
    <DialogWrapper
      title={title}
      description="Only the terms with latest version number will be used for users to accept. If you change the version number and the version number is higher than last version, it will trigger the users to accept the terms."
      onCancel={onCancel}
      hideCancelButton
      hideConfirmButton
    >
      <Form
        fullWidth
        loading={loading}
        onSubmit={onConfirm}
        buttonText="Submit"
        inputs={[
          {
            name: 'title',
            label: 'Title',
            category: FormInputCategory.TEXT_INPUT,
            defaultValue: item.title,
          },
          {
            name: 'version',
            label: 'Version Number',
            category: FormInputCategory.TEXT_INPUT,
            type: 'number',
            defaultValue: item.version,
          },
          {
            name: 'country',
            label: 'Country',
            category: FormInputCategory.SELECT,
            options: [
              { label: 'AU', value: 'AU' },
              { label: 'US', value: 'US' },
            ],
            defaultValue: item.country,
          },
        ]}
      />
    </DialogWrapper>
  );
};

export default TermsForm;
