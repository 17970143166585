import { useContext, useEffect, useState } from 'react';

import { postToServer } from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import { PublicPageContainner } from '../../../components';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { HelperTextLogin } from '../Login/LoginForm';
import { Message } from '../../../utils/Types';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState<Message>();

  useEffect(() => {
    reset2FA();
  }, []);

  const reset2FA = () => {
    const paramsFromUrl = new URLSearchParams(window.location.search);
    const params = { code: paramsFromUrl.get('code') };

    postToServer({ action: 'Reset2FA', params, token: '' }).then(response => {
      setLoading(false);
      setMessage(response.message);
      snackbar.open(response.message);
    });
  };

  return (
    <PublicPageContainner>
      <Stack spacing={3} alignItems="center">
        <Stack spacing={1} alignItems="center" textAlign="center">
          <Typography variant="h4" fontWeight="bold" color="primary">
            Reset Your 2FA
          </Typography>
          {loading ? (
            <CircularProgress sx={{ color: 'grey.100' }} />
          ) : (
            <Typography color="grey.200">{message?.text}</Typography>
          )}
        </Stack>
        <HelperTextLogin text="Want to login?" to="/" />
      </Stack>
    </PublicPageContainner>
  );
};

export default Page;
