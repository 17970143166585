const hsl2Hex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const hexToHSL = (H: string) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = parseInt('0x' + H[1] + H[1]);
    g = parseInt('0x' + H[2] + H[2]);
    b = parseInt('0x' + H[3] + H[3]);
  } else if (H.length === 7) {
    r = parseInt('0x' + H[1] + H[2]);
    g = parseInt('0x' + H[3] + H[4]);
    b = parseInt('0x' + H[5] + H[6]);
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  // return 'hsl(' + h + ',' + s + '%,' + l + '%)';
  return [h, s, l];
};

export const generateChartTraceColors = ({
  quantity,
  seedColorHex,
}: {
  quantity: number;
  seedColorHex: string;
}) => {
  const [h, s, l] = hexToHSL(seedColorHex);

  const colors = [];
  const hRange = 40;
  const sRange = { min: 50, max: 100 };
  const lRange = { min: 15, max: 80 };

  const hStep = Math.trunc((hRange * 2) / quantity);
  const sStep = Math.trunc((sRange.max - sRange.min) / quantity);
  const lStep = Math.trunc((lRange.max - lRange.min) / quantity);

  for (let i = 0; i < quantity; i++) {
    let hLocal = h + i * hStep;
    if (hLocal > h + hRange) {
      hLocal = h - i * hStep + hRange;
    }

    let sLocal = s + i * sStep;
    if (sLocal > sRange.max) {
      sLocal = (sLocal % sRange.max) + sRange.min;
    }

    let lLocal = l + i * lStep * 2;
    if (lLocal > lRange.max) {
      lLocal = (lLocal % lRange.max) + lRange.min;
    }

    colors.push(hsl2Hex(hLocal, sLocal, lLocal));
  }

  return colors;
};
