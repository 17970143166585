import { useState } from 'react';
import {
  Stack,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  alpha,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { NavItem } from '../../utils/Types';
import { MARGIN } from './index';
import { useAppDispatch } from '../../redux/hooks';
import { logout } from '../../utils/Helper';

type SectionProps = {
  title: string;
  icon: any;
  items: NavItem[];
  to?: string;
  sx?: {};
};

const SidebarItem = ({ title, icon, items, to, sx }: SectionProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (to) {
      navigate(to);
    }
    if (title === 'Logout') {
      logout({ dispatch, navigate });
    }
    setOpen(!open);
  };

  return (
    <Stack>
      {items.length > 0 ? (
        <List>
          <Typography sx={{ px: MARGIN, mb: 1 }} color="primary.light">
            {title.toUpperCase()}
          </Typography>
          {items.map(item => (
            <ListItemButton
              key={item.title}
              component={Link}
              to={item.to}
              selected={item.to === location.pathname}
              sx={{
                px: MARGIN,
                '&.Mui-selected': {
                  backgroundColor: theme =>
                    alpha(theme.palette.primary.main, 0.4),
                  '&:hover': {
                    backgroundColor: theme =>
                      alpha(theme.palette.primary.main, 0.3),
                  },
                },
                '&:hover': {
                  backgroundColor: theme =>
                    alpha(theme.palette.primary.main, 0.3),
                },
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{ color: 'background.default' }}
              />
            </ListItemButton>
          ))}
        </List>
      ) : (
        <ListItemButton
          onClick={handleClick}
          selected={location.pathname
            .split('/')
            .includes(title.toLowerCase().replace(/\s/g, '-'))}
          sx={{
            px: MARGIN,
            '&.Mui-selected': {
              backgroundColor: theme => alpha(theme.palette.primary.main, 0.4),
            },
            '&:hover': {
              backgroundColor: theme => alpha(theme.palette.primary.main, 0.3),
            },
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={title}
            sx={{ color: 'background.default', ...sx }}
          />
        </ListItemButton>
      )}
    </Stack>
  );
};

export default SidebarItem;
