import { Stack } from '@mui/material';
import { Moment } from 'moment';

import { LooseObject, Org } from '../../../utils/Types';
import SimpleView from './SimpleView';
import HotboxView from './HotboxView';
import { NoDataView, Skeleton } from '../../../components';

export enum Shift {
  DAY = 'day',
  NIGHT = 'night',
}

export enum DataView {
  SIMPLE = 'simple',
  PROBABILISTIC = 'probabilistic',
  COMPARISON = 'comparison',
  HOTBOX = 'hotbox',
}

export const MIN_HEIGHT = 300;
export const MIN_WIDTH = 600;

type Props = {
  dataView: DataView;
  date?: Moment | null;
  shift?: Shift;
  facilities?: string[];
  data?: LooseObject;
  loading: boolean;
  org: Org;
};

const GraphArea = ({
  dataView,
  date,
  shift,
  facilities,
  data,
  loading,
  org,
}: Props) => {
  return (
    <Stack sx={{ minWidth: { xs: MIN_WIDTH * 0.7, md: MIN_WIDTH } }}>
      <Stack>
        {loading ? (
          <Skeleton />
        ) : dataView === DataView.SIMPLE ? (
          <SimpleView
            dataView={dataView}
            date={date}
            shift={shift}
            facilities={facilities}
            data={data}
            loading={loading}
            org={org}
          />
        ) : dataView === DataView.HOTBOX && Array.isArray(data) ? (
          <HotboxView
            dataView={dataView}
            date={date}
            shift={shift}
            facilities={facilities}
            data={data}
            loading={loading}
          />
        ) : (
          <NoDataView />
        )}
      </Stack>
    </Stack>
  );
};

export default GraphArea;
