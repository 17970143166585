import { useContext, useState } from 'react';
import { IconButton, Stack, Typography, Grid } from '@mui/material';
import { Moment } from 'moment';
import {
  ChevronLeft,
  ChevronRight,
  FilterAltOutlined,
} from '@mui/icons-material';

import { useAppSelector } from '../../../redux/hooks';
import { LooseObject } from '../../../utils/Types';
import GraphArea, { Type, Trait } from './GraphArea';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import Filter from './Filter';
import MetricResults from './MetricResults';
import RunParams from './RunParams';
import { selectAuth } from '../../../redux/reducers/authSlice';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [date, setDate] = useState<Moment | null>(null);
  const [trait, setTrait] = useState<Trait>(Trait.EYE_MUSCLE);
  const [type, setType] = useState<Type>(Type.FRAMES);
  const [facility, setFacility] = useState<string>();
  const [dataForGraph, setDataForGraph] = useState<LooseObject>();
  const [dataForGraphAdjusted, setDataForGraphAdjusted] =
    useState<LooseObject>();

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        display={{ xs: 'flex', md: 'none' }}
        px={2}
        py={1}
        bgcolor="grey.100"
      >
        <Stack direction="row" color="grey.700">
          <FilterAltOutlined />
          <Typography>Filters</Typography>
        </Stack>
        <IconButton
          onClick={() => setShowFilter(!showFilter)}
          sx={{
            borderRadius: 1,
            bgcolor: 'grey.400',
            width: 26,
            height: 26,
            ':hover': {
              bgcolor: 'grey.300',
            },
          }}
        >
          {showFilter ? (
            <ChevronLeft sx={{ color: 'white' }} />
          ) : (
            <ChevronRight sx={{ color: 'white' }} />
          )}
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        minHeight={`calc(100vh - ${190}px)`}
        overflow="auto"
      >
        <Stack display={{ xs: showFilter ? 'flex' : 'none', md: 'flex' }}>
          <Filter
            snackbar={snackbar}
            auth={auth}
            setLoading={setLoading}
            setDateForGraph={setDate}
            setTraitForGraph={setTrait}
            setTypeForGraph={setType}
            setFacilityForGraph={setFacility}
            setDataForGraph={setDataForGraph}
            setDataForGraphAdjusted={setDataForGraphAdjusted}
          />
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={8}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <GraphArea
                  date={date}
                  trait={trait}
                  type={type}
                  facility={facility}
                  data={dataForGraph}
                  loading={loading}
                  auth={auth}
                  org={org}
                  snackbar={snackbar}
                />
              </Grid>
              <Grid item xs={12} p={0.5}>
                <MetricResults
                  date={date}
                  trait={trait}
                  type={type}
                  facility={facility}
                  data={dataForGraph}
                  loading={loading}
                  auth={auth}
                  org={org}
                  snackbar={snackbar}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={2}>
              <Grid item xs={12}>
                <GraphArea
                  date={date}
                  trait={trait}
                  type={type}
                  facility={facility}
                  data={dataForGraphAdjusted}
                  loading={loading}
                  auth={auth}
                  org={org}
                  snackbar={snackbar}
                  isAdjusted
                />
              </Grid>
              <Grid item xs={12} p={0.5}>
                <MetricResults
                  date={date}
                  trait={trait}
                  type={type}
                  facility={facility}
                  data={dataForGraphAdjusted}
                  loading={loading}
                  auth={auth}
                  org={org}
                  snackbar={snackbar}
                  isAdjusted
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={4} p={0.5}>
            <RunParams
              date={date}
              trait={trait}
              type={type}
              facility={facility}
              data={dataForGraph}
              loading={loading}
              auth={auth}
              org={org}
              snackbar={snackbar}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default Page;
