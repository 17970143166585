import { useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { MRT_ColumnDef } from 'material-react-table';

import { Table } from '../../../../components';
import {
  DateFormatServer,
  QueryResult,
  QueryType,
} from '../../../../utils/Types';
import { LooseObject } from '../../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { selectAuth } from '../../../../redux/reducers/authSlice';
import {
  getDateFormat,
  logout,
  optimiseQueryResult,
  postToServer,
} from '../../../../utils/Helper';
import { SnackbarContext } from '../../../../utils/Contexts';
import { selectOrg } from '../../../../redux/reducers/orgSlice';
import { QUERY_DATA_SOURCE_ENDPOINT } from '../../../../utils/Constants';

const ListOfCarcases = ({ data }: { data: LooseObject }) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [carcases, setCarcases] = useState<QueryResult>();

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (auth?.token) {
      setLoading(true);
      await postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ORDINARY_QUERY,
          view: 'FREWS_INSIGHTS_CARCASE',
          sqlConditions: `SLAUGHTER_DATE = '${data.id}'`,
        },
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            const optimisedServerData = optimiseQueryResult(serverData);

            setCarcases(optimisedServerData);
          } else {
            snackbar.open(response.message);
          }
        }
      });
      setLoading(false);
    }
  };

  const columns: MRT_ColumnDef<any, any>[] =
    carcases?.columns?.map(i => ({ accessorKey: i.name, header: i.label })) ||
    [];

  const rows: LooseObject[] =
    carcases?.rows?.map(i => ({
      ...i,
      SLAUGHTER_DATE: moment(i.SLAUGHTER_DATE, DateFormatServer.SHORT).format(
        getDateFormat(org, 'short')
      ),
    })) || [];

  return (
    <Stack px={2} pb={2} bgcolor={grey[300]}>
      <Table
        title={`List of Carcases for ${data.id}`}
        columns={columns}
        data={rows}
        enableColumnActions={false}
        enableHiding={false}
        enableColumnResizing
        enableDensityToggle={false}
        // enableBottomToolbar={false}
        // enablePagination={false}
        // enableStickyHeader
        // muiTableContainerProps={{ sx: { maxHeight: '430px' } }}
        // enableRowNumbers
        // rowNumberDisplayMode="static"
        state={{ isLoading: loading }}
        initialState={{ density: 'compact' }}
        layoutMode="grid"
        defaultColumn={{
          minSize: 20,
          maxSize: 1000,
          size: 150,
        }}
      />
    </Stack>
  );
};

export default ListOfCarcases;
