import { IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { East, West } from '@mui/icons-material';

import { LooseObject, Org, User } from '../../../utils/Types';
import ImageInfo from './ImageInfo';

const NavButton = ({
  icon,
  onClick,
  enable,
}: {
  icon: any;
  onClick: any;
  enable: boolean;
}) => (
  <IconButton
    onClick={onClick}
    disabled={!enable}
    sx={{
      borderRadius: 1,
      width: 30,
      height: 30,
      bgcolor: 'grey.500',
      '&:hover': {
        bgcolor: 'grey.300',
      },
    }}
  >
    {icon}
  </IconButton>
);

type Props = {
  user?: User;
  org: Org;
  onClose: () => void;
  initialImage: LooseObject;
  allImages: LooseObject[];
  hideReportImageIssue?: boolean;
  isImageUrlProvided?: boolean;
};

const ImageInfoWithNavi = ({
  user,
  org,
  onClose,
  initialImage,
  allImages,
  hideReportImageIssue,
  isImageUrlProvided,
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  useEffect(() => {
    const initalIndex = allImages
      ?.map(i => i.barcode)
      .indexOf(initialImage?.barcode);

    if (initalIndex !== undefined && initalIndex >= 0) {
      setCurrentIndex(initalIndex);
    }
  }, [initialImage, allImages]);

  useHotkeys(
    'ArrowLeft',
    () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    },
    [currentIndex]
  );

  useHotkeys(
    'ArrowRight',
    () => {
      if (currentIndex < (allImages?.length || 0) - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    },
    [currentIndex]
  );

  return (
    <Stack
      direction="row"
      minWidth="90vw"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
    >
      <NavButton
        icon={<West />}
        onClick={() => {
          if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
          }
        }}
        enable={currentIndex > 0}
      />
      <ImageInfo
        user={user}
        org={org}
        onClose={onClose}
        data={allImages?.[currentIndex] || { initializing: true }}
        hideReportImageIssue={hideReportImageIssue}
        minWidth={`min(calc(100vw - 100px), calc(82vh) * ${9 / 16})`}
        maxWidth={`min(calc(100vw - 80px), calc(86vh) * ${9 / 16})`}
        isImageUrlProvided={isImageUrlProvided}
      />
      <NavButton
        icon={<East />}
        onClick={() => {
          if (currentIndex < (allImages?.length || 0) - 1) {
            setCurrentIndex(currentIndex + 1);
          }
        }}
        enable={currentIndex < (allImages?.length || 0) - 1}
      />
    </Stack>
  );
};

export default ImageInfoWithNavi;
