import { useEffect, useState } from 'react';

import { Skeleton, Table } from '../../../components';
import { DepthCameraComponentProps } from './GraphArea';
import { DateFormatServer, LooseObject } from '../../../utils/Types';
import { postToServer } from '../../../utils/Helper';

const RunParams = ({
  date,
  trait,
  type,
  facility,
  loading,
  auth,
  org,
  snackbar,
}: DepthCameraComponentProps) => {
  const [data, setData] = useState<LooseObject>({});
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    loadData();
  }, [facility, date, type, trait]);

  const loadData = () => {
    if (facility && date && type && trait) {
      if (auth?.token) {
        setLoadingData(true);
        postToServer({
          action: 'machine-learning/depth-camera/GetRunParams',
          params: {
            location: facility,
            date: date.format(DateFormatServer.SHORT),
          },
          token: auth.token,
        }).then(response => {
          setLoadingData(false);
          if (!(response.message.type === 'success' && response.serverData)) {
            snackbar.open(response.message);
          }
          setData(
            response.serverData ? (response.serverData as LooseObject) : {}
          );
        });
      }
    }
  };

  // eslint-disable-next-line
  const handleSaveCell = (cell: any, value: any) => {
    console.log(cell, value);

    //@ts-ignore
    // tableData[cell.row.index][cell.column.id] = value;
    // setTableData([...tableData]);
  };

  const columns = [
    {
      accessorKey: 'param',
      header: 'Param',
      enableEditing: false,
    },
    {
      accessorKey: 'value',
      header: 'Value',
    },
  ];

  return loading || loadingData ? (
    <Skeleton />
  ) : (
    <Table
      columns={columns || []}
      data={Object.keys(data).map(k => ({
        param: k,
        value: data[k]?.toString(),
      }))}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      layoutMode="grid"
      enablePagination={false}
      initialState={{ density: 'compact' }}
      // editingMode="cell"
      // enableEditing
      // muiTableBodyCellEditTextFieldProps={({ cell }) => ({
      //   onBlur: event => {
      //     handleSaveCell(cell, event.target.value);
      //   },
      // })}
    />
  );
};

export default RunParams;
