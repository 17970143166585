import { useContext, useState } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import {
  ChevronLeft,
  ChevronRight,
  FilterAltOutlined,
} from '@mui/icons-material';

import { useAppSelector } from '../../../redux/hooks';
import { LooseObject } from '../../../utils/Types';
import GraphArea, { DataView, Shift } from './GraphArea';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import Filter from './Filter';

export type FetchDataForGraphParams = {
  dat?: Moment | null;
  shif?: string;
  faci?: string[];
  dataV?: string;
};

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [date, setDate] = useState<Moment | null>(moment());
  const [shift, setShift] = useState<Shift>(Shift.DAY);
  const [facilities, setFacilities] = useState<string[]>([]);
  const [dataView, setDataView] = useState<DataView>(DataView.SIMPLE);
  const [dataForGraph, setDataForGraph] = useState<LooseObject>();

  const org = useAppSelector(selectOrg);

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        display={{ xs: 'flex', md: 'none' }}
        px={2}
        py={1}
        bgcolor="grey.100"
      >
        <Stack direction="row" color="grey.700">
          <FilterAltOutlined />
          <Typography>Filters</Typography>
        </Stack>
        <IconButton
          onClick={() => setShowFilter(!showFilter)}
          sx={{
            borderRadius: 1,
            bgcolor: 'grey.400',
            width: 26,
            height: 26,
            ':hover': {
              bgcolor: 'grey.300',
            },
          }}
        >
          {showFilter ? (
            <ChevronLeft sx={{ color: 'white' }} />
          ) : (
            <ChevronRight sx={{ color: 'white' }} />
          )}
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        minHeight={`calc(100vh - ${190}px)`}
        overflow="auto"
      >
        <Stack display={{ xs: showFilter ? 'flex' : 'none', md: 'flex' }}>
          <Filter
            snackbar={snackbar}
            setLoading={setLoading}
            setDateForGraph={setDate}
            setShiftForGraph={setShift}
            setFacilitiesForGraph={setFacilities}
            setDataViewForGraph={setDataView}
            setDataForGraph={setDataForGraph}
          />
        </Stack>
        <Stack width="100%" minWidth={300} p={2} pl={1}>
          <GraphArea
            dataView={dataView}
            date={date}
            shift={shift}
            facilities={facilities}
            data={dataForGraph}
            loading={loading}
            org={org}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Page;
