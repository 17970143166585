import { Box, Stack } from '@mui/material';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';

import ValidationSchema from './ValidationSchema';
import {
  FormInputCategory,
  FormInputItem,
  LabelValuePair,
} from '../../utils/Types';
import ServerMessage from '../ServerMessage';
import { Message } from '../../utils/Types';
import TextInput from './TextInput';
import NumberRangeInput from './NumberRangeInput';
import Autocomplete from './Autocomplete';
import DateRangePicker from './DateRangePicker';
import DatePicker from './DatePicker';
import Switch from './Swtich';
import Select, { SELECT_ALL_VALUE } from './Select';
import { isNotEmpty } from '../../utils/Helper';

type FormProps = {
  loading?: boolean;
  message?: Message;
  onSubmit: (values: any) => void;
  inputs: FormInputItem[];
  buttonText?: string;
  itemSx?: {};
  buttonSx?: {};
  isHorizontal?: boolean;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  maxWidth?: number | string;
  format?: string;
  buttonFullWidth?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
};

const Form = ({
  loading,
  message,
  onSubmit,
  inputs,
  buttonText,
  itemSx = { mb: 1, textAlign: 'left' },
  buttonSx,
  isHorizontal,
  size,
  fullWidth = true,
  maxWidth,
  format,
  buttonFullWidth = true,
  buttonSize = 'large',
}: FormProps) => {
  const getInitialValues = () => {
    const values = {};
    if (inputs.length > 0) {
      for (let i = 0; i < inputs.length; i += 1) {
        switch (inputs[i].category) {
          case FormInputCategory.CHECK_BOX:
            values[inputs[i].name] = inputs[i].defaultValue || false;
            break;
          case FormInputCategory.SWITCH:
            values[inputs[i].name] = inputs[i].defaultValue || false;
            break;
          case FormInputCategory.NUMBER_RANGE_INPUT:
            values[`${inputs[i].name}From`] = isNotEmpty(
              inputs[i].defaultValue?.from
            )
              ? inputs[i].defaultValue?.from
              : '';
            values[`${inputs[i].name}To`] = isNotEmpty(
              inputs[i].defaultValue?.to
            )
              ? inputs[i].defaultValue?.to
              : '';
            break;
          case FormInputCategory.DATE_PICKER:
            values[inputs[i].name] = inputs[i].defaultValue || null;
            break;
          case FormInputCategory.DATE_RANGE_PICKER:
            values[`${inputs[i].name}From`] =
              inputs[i].defaultValue?.from || null;
            values[`${inputs[i].name}To`] = inputs[i].defaultValue?.to || null;
            break;
          case FormInputCategory.SELECT:
            values[inputs[i].name] =
              inputs[i].defaultValue === 0
                ? 0
                : inputs[i].defaultValue || (inputs[i].multiple ? [] : '');
            break;
          case FormInputCategory.AUTO_COMPLETE:
            values[inputs[i].name] = inputs[i].defaultValue || [];
            break;
          default:
            values[inputs[i].name] = inputs[i].defaultValue || '';
            break;
        }
      }
    }
    return values;
  };

  const textFieldProps = {
    autoComplete: 'new-password',
    fullWidth,
    size,
    sx: itemSx,
  };

  return (
    <Stack maxWidth={{ md: maxWidth }} width="100%">
      <Formik
        initialValues={getInitialValues()}
        onSubmit={values => {
          const optimisedValues = { ...values };
          Object.keys(optimisedValues).forEach(k => {
            const input = inputs.find(i => i.name === k);
            // remove SELECT_ALL_VALUE
            if (input?.multiple && input?.selectAll) {
              optimisedValues[k] = optimisedValues[k].filter(
                (i: string) => i !== SELECT_ALL_VALUE
              );
            }

            // show only values for autocomplete
            if (input?.category === FormInputCategory.AUTO_COMPLETE) {
              optimisedValues[k] = optimisedValues[k].map(
                (i: LabelValuePair) => i.value
              );
            }
          });
          onSubmit(optimisedValues);
        }}
        validationSchema={ValidationSchema(inputs)}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <form
              onSubmit={handleSubmit}
              style={
                isHorizontal
                  ? { display: 'flex', flexDirection: 'row' }
                  : { textAlign: 'center' }
              }
            >
              {inputs &&
                Array.isArray(inputs) &&
                inputs.length > 0 &&
                inputs.map(item => {
                  let input;
                  switch (item.category) {
                    case FormInputCategory.COMPONENT:
                      input = <Box key={item.name}>{item.component}</Box>;
                      break;

                    case FormInputCategory.TEXT_INPUT:
                      input = (
                        <TextInput
                          key={item.name}
                          item={item}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          textFieldProps={{
                            ...textFieldProps,
                            ...item.textFieldProps,
                          }}
                        />
                      );
                      break;

                    case FormInputCategory.NUMBER_RANGE_INPUT:
                      input = (
                        <NumberRangeInput
                          key={item.name}
                          item={item}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          textFieldProps={{
                            ...textFieldProps,
                            ...item.textFieldProps,
                          }}
                        />
                      );
                      break;

                    case FormInputCategory.SELECT:
                      input = (
                        <Select
                          key={item.name}
                          item={item}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          textFieldProps={{
                            ...textFieldProps,
                            ...item.textFieldProps,
                          }}
                        />
                      );
                      break;

                    case FormInputCategory.AUTO_COMPLETE:
                      input = (
                        <Autocomplete
                          key={item.name}
                          item={item}
                          values={values}
                          setFieldValue={setFieldValue}
                          textFieldProps={{
                            ...textFieldProps,
                            ...item.textFieldProps,
                          }}
                          loading={item.loading}
                        />
                      );
                      break;

                    case FormInputCategory.DATE_PICKER:
                      input = (
                        <DatePicker
                          key={item.name}
                          item={item}
                          values={values}
                          setFieldValue={setFieldValue}
                          textFieldProps={{
                            ...textFieldProps,
                            ...item.textFieldProps,
                          }}
                          format={format}
                        />
                      );
                      break;

                    case FormInputCategory.DATE_RANGE_PICKER:
                      input = (
                        <DateRangePicker
                          key={item.name}
                          item={item}
                          values={values}
                          setFieldValue={setFieldValue}
                          textFieldProps={{
                            ...textFieldProps,
                            ...item.textFieldProps,
                          }}
                          format={format}
                        />
                      );
                      break;

                    case FormInputCategory.SWITCH:
                      input = (
                        <Switch
                          key={item.name}
                          item={item}
                          values={values}
                          setFieldValue={setFieldValue}
                          textFieldProps={{
                            ...textFieldProps,
                            ...item.textFieldProps,
                          }}
                        />
                      );
                      break;

                    default:
                      input = <Box key={item.name} />;
                  }
                  return input;
                })}

              {message && message.text && <ServerMessage message={message} />}

              {buttonText && (
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  disabled={!isValid}
                  fullWidth={buttonFullWidth}
                  sx={{
                    mt: 2,
                    ...buttonSx,
                  }}
                  size={buttonSize}
                >
                  {buttonText}
                </LoadingButton>
              )}
            </form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default Form;
