import { useContext, useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

import {
  FormData,
  LooseObject,
  SharedInsightScope,
} from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { logout, postToServer } from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import {
  Skeleton,
  Dialog,
  Table,
  SmallButton,
  DialogWrapper,
} from '../../../components';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import InsightForm from './InsightForm';
import Insight from './Insight';
import Share from './Share';
// import UITest from './UITest';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [insights, setInsights] = useState<LooseObject[]>([]);
  const [availableQueries, setAvailableQueries] = useState<LooseObject[]>([]);
  const [currentItem, setCurrentItem] = useState<LooseObject>();
  const [openDialogView, setOpenDialogView] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchInsights();
    fetchAvailableQueries();
  }, []);

  const fetchInsights = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'insights2/GetInsights',
        params: {},
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setInsights(serverData);
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  const fetchAvailableQueries = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'insights/GetQueries',
        params: {},
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setAvailableQueries(serverData);
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  const handleAdd = ({ title, description }: FormData) => {
    if (title) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights2/AddInsight',
          params: { title, description },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setInsights(response.serverData as LooseObject[]);
              setOpenDialogAdd(false);
            } else {
              snackbar.open(response.message);
            }
          }
        });
      }
    }
  };

  const handleEdit = ({ id, title, description }: FormData) => {
    if (id && title) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights2/UpdateInsight',
          params: { id, title, description },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setInsights(response.serverData as LooseObject[]);
              setOpenDialogEdit(false);
            } else {
              snackbar.open(response.message);
            }
          }
        });
      }
    }
  };

  const handleRemove = (id: string | number) => {
    if (id) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights2/RemoveInsight',
          params: { id },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setInsights(response.serverData as LooseObject[]);
              setOpenDialogDelete(false);
            } else {
              snackbar.open(response.message);
            }
          }
        });
      }
    }
  };

  const handleSharing = ({
    id,
    scope,
    email,
    isToUserWritable,
    isEntireOrgWritable,
  }: FormData) => {
    if (
      id &&
      scope &&
      (scope === SharedInsightScope.ENTIRE_ORG
        ? true
        : email && validator.isEmail(email))
    ) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights2/ShareInsight',
          params: { id, scope, email, isToUserWritable, isEntireOrgWritable },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setInsights(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      }
    }
  };

  const handleRetrieveSharing = (id: string | number) => {
    if (id) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights2/RemoveInsightSharing',
          params: { id },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setInsights(response.serverData as LooseObject[]);
            } else {
              snackbar.open(response.message);
            }
          }
        });
      }
    }
  };

  const columns = [
    { accessorKey: 'title', header: 'Title' },
    { accessorKey: 'description', header: 'Description' },
    { accessorKey: 'actions', header: 'Actions' },
  ];

  const rows = insights.map(i => ({
    ...i,
    actions: (
      <Stack spacing={1} direction="row">
        <SmallButton
          variant="outlined"
          title="View"
          onClick={() => {
            setCurrentItem(i);
            setOpenDialogView(true);
          }}
        />
        <SmallButton
          variant="outlined"
          title="Edit"
          onClick={() => {
            setCurrentItem(i);
            setOpenDialogEdit(true);
          }}
        />
        <SmallButton
          variant="outlined"
          title="Delete"
          onClick={() => {
            setCurrentItem(i);
            setOpenDialogDelete(true);
          }}
        />
        <Share
          insight={i}
          loading={loading}
          handleSharing={handleSharing}
          handleRetrieveSharing={handleRetrieveSharing}
        />
      </Stack>
    ),
  }));

  return loading ? (
    <Skeleton />
  ) : (
    <Stack>
      {/* <UITest /> */}
      <Stack width="100%" alignItems="flex-end" my={2}>
        <Button
          variant="contained"
          sx={{ width: 120 }}
          onClick={() => setOpenDialogAdd(true)}
        >
          + Insight
        </Button>
      </Stack>
      <Dialog
        open={openDialogAdd}
        onClose={() => setOpenDialogAdd(false)}
        maxWidth="xl"
      >
        <InsightForm
          title="Add a new insight"
          loading={loading}
          onCancel={() => setOpenDialogAdd(false)}
          handleSubmit={handleAdd}
        />
      </Dialog>
      <Stack spacing={2}>
        <Table
          columns={columns}
          data={rows}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          layoutMode="grid"
        />
      </Stack>
      <Dialog
        open={openDialogView}
        onClose={() => setOpenDialogView(false)}
        fullScreen
      >
        {currentItem && (
          <Insight
            initialInsight={currentItem}
            updateInsights={value => setInsights(value)}
            availableQueries={availableQueries}
            onCancel={() => setOpenDialogView(false)}
            isWritable
            auth={auth}
            org={org}
          />
        )}
      </Dialog>
      <Dialog
        open={openDialogEdit}
        onClose={() => setOpenDialogEdit(false)}
        maxWidth="xl"
      >
        {currentItem && (
          <InsightForm
            title="Edit insight"
            item={currentItem}
            loading={loading}
            onCancel={() => setOpenDialogEdit(false)}
            handleSubmit={v => handleEdit({ id: currentItem.id, ...v })}
          />
        )}
      </Dialog>
      <Dialog
        open={openDialogDelete}
        onClose={() => setOpenDialogDelete(false)}
        maxWidth="xl"
      >
        {currentItem && (
          <DialogWrapper
            title="Delete an insight"
            onCancel={() => setOpenDialogDelete(false)}
            onConfirm={() => handleRemove(currentItem.id)}
          >
            <Typography>{`Are you sure to delete ${currentItem.title}?`}</Typography>
          </DialogWrapper>
        )}
      </Dialog>
    </Stack>
  );
};

export default Page;
