import { Skeleton as MuiSkeleton } from '@mui/material';

const Skeleton = ({
  minWidth,
  minHeight = 300,
  bgcolor = 'grey.100',
}: {
  minWidth?: number | string;
  minHeight?: number | string;
  bgcolor?: string;
}) => (
  <MuiSkeleton
    variant="rounded"
    height="100%"
    width="100%"
    sx={{
      bgcolor: bgcolor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth,
      minHeight,
    }}
  >
    Loading...
  </MuiSkeleton>
);

export default Skeleton;
