import { Stack } from '@mui/material';
import { DialogWrapper, Form } from '../../../components';
import { FormInputCategory, FormData, LooseObject } from '../../../utils/Types';

const InsightForm = ({
  title,
  item = {},
  loading,
  onCancel,
  handleSubmit,
}: {
  title: string;
  item?: LooseObject;
  loading: boolean;
  onCancel: () => void;
  handleSubmit: (v: FormData) => void;
}) => {
  return (
    <DialogWrapper title={title} onCancel={onCancel} hideCancelButton>
      <Stack spacing={3}>
        <Form
          loading={loading}
          onSubmit={handleSubmit}
          buttonText="Submit"
          inputs={[
            {
              name: 'title',
              label: 'Title',
              category: FormInputCategory.TEXT_INPUT,
              defaultValue: item.title,
            },
            {
              name: 'description',
              label: 'Description',
              category: FormInputCategory.TEXT_INPUT,
              defaultValue: item.description,
              multiline: true,
            },
          ]}
          maxWidth="xl"
        />
      </Stack>
    </DialogWrapper>
  );
};

export default InsightForm;
