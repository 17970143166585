import { FormInputCategory, LooseObject } from '../../../utils/Types';
import { Form, DialogWrapper } from '../../../components';

type Props = {
  onConfirm: (values: any) => void;
  onCancel: (values: any) => void;
  loading: boolean;
  item: LooseObject;
};

const Edit = ({ onConfirm, onCancel, loading, item }: Props) => (
  <DialogWrapper
    title="Edit Plugin"
    description="Before editing any plugin, please make sure the new name is listed in the enum PluginName of types.ts file from both the frontend and backend code. Otherwise, the backend will reject the request. This is to ensure the source code is lined up with the database."
    onCancel={onCancel}
    hideCancelButton
    hideConfirmButton
  >
    <Form
      loading={loading}
      onSubmit={onConfirm}
      buttonText="Submit"
      inputs={[
        {
          name: 'name',
          label: 'Name',
          category: FormInputCategory.TEXT_INPUT,
          defaultValue: item?.name,
        },
        {
          name: 'description',
          label: 'Description',
          category: FormInputCategory.TEXT_INPUT,
          defaultValue: item?.description,
        },
      ]}
    />
  </DialogWrapper>
);

export default Edit;
