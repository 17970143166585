import { useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ReactGA from 'react-ga4';

import getTheme from './utils/Theme';
import { Routes } from './components';
import { store } from './redux/store';
import { ThemeOveridesContext } from './utils/Contexts';

// for analytics
ReactGA.initialize(
  process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID ||
    'Please Provide A Measurement ID'
);

const App = () => {
  const [themeOptions, setThemeOptions] = useState();

  const themeOverides = useMemo(
    () => ({ apply: (overides: any) => setThemeOptions(overides) }),
    []
  );

  return (
    <Provider store={store}>
      <ThemeOveridesContext.Provider value={themeOverides}>
        <ThemeProvider theme={getTheme(themeOptions)}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </ThemeOveridesContext.Provider>
    </Provider>
  );
};

export default App;
