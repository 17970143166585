import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormData } from '../../../utils/Types';
import { asyEncrypt, delay, postToServer } from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import ForgotPasswordForm from './ResetPasswordForm';
import { RSA_PUBLIC_KEY } from '../../../utils/Constants';
import { PublicPageContainner } from '../../../components';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async ({ password, passwordAgain }: FormData) => {
    if (password && passwordAgain && password === passwordAgain) {
      setLoading(true);
      const paramsFromUrl = new URLSearchParams(window.location.search);

      const params = {
        password: await asyEncrypt({ text: password, pubKey: RSA_PUBLIC_KEY }),
        code: paramsFromUrl.get('code'),
      };

      postToServer({ action: 'ResetPassword', params, token: '' }).then(
        async response => {
          setLoading(false);
          if (response.message.type === 'success') {
            snackbar.open(response.message);
            await delay(2000);
            navigate('/');
          } else {
            snackbar.open(response.message);
          }
        }
      );
    }
  };

  return (
    <PublicPageContainner>
      <ForgotPasswordForm loading={loading} handleSubmit={handleSubmit} />
    </PublicPageContainner>
  );
};

export default Page;
