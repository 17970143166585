import { useEffect, useState } from 'react';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
} from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { getDateFormat, logout, postToServer } from '../../../utils/Helper';
import {
  DateFormatServer,
  LocationType,
  LooseObject,
} from '../../../utils/Types';
import { AntSwitch } from '../../../components';
import { DataView, Shift } from './GraphArea';
import { CONTENT_PADDING } from '../../../utils/Constants';
import { selectOrg } from '../../../redux/reducers/orgSlice';

type Props = {
  snackbar: any;
  setLoading: (loading: boolean) => void;
  setDateForGraph: (date: Moment | null) => void;
  setShiftForGraph: (shift: Shift) => void;
  setFacilitiesForGraph: (facilities: string[]) => void;
  setDataViewForGraph: (dataView: DataView) => void;
  setDataForGraph: (data: LooseObject | undefined) => void;
};

const Filter = ({
  snackbar,
  setLoading,
  setDateForGraph,
  setShiftForGraph,
  setFacilitiesForGraph,
  setDataViewForGraph,
  setDataForGraph,
}: Props) => {
  const [allFacilities, setAllFacilities] = useState<string[]>([]);
  const [date, setDate] = useState<Moment | null>(moment());
  const [shift, setShift] = useState<Shift>(Shift.DAY);
  const [facilities, setFacilities] = useState<string[]>([]);
  const [dataView, setDataView] = useState<DataView>(DataView.SIMPLE);
  const [validDates, setValidDates] = useState<Moment[]>([]);
  const [loadingFacilities, setLoadingFacilities] = useState(false);
  const [loadingDates, setLoadingDates] = useState(false);

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadFacilities();
  }, []);

  const loadFacilities = () => {
    if (auth?.token) {
      setLoading(true);
      setLoadingFacilities(true);
      postToServer({
        action: 'GetLocations',
        params: { locationType: LocationType.SLAUGHTER },
        token: auth.token,
      }).then(response => {
        setLoadingFacilities(false);
        setLoading(false);
        if (response.message.type === 'success' && response.serverData) {
          const data = response.serverData as LooseObject[];
          const allFacilityNames = data.map(i => i.name_str);
          setAllFacilities(allFacilityNames);
          if (data.length > 0) {
            // default facility to the first location
            const defaultFacilities = allFacilityNames.slice(0, 1);
            setFacilities(defaultFacilities);
            setFacilitiesForGraph(defaultFacilities);
            loadDates(defaultFacilities);
          }
        } else {
          snackbar.open(response.message);
        }
      });
    }
  };

  const loadDates = (updatedFacilities: string[]) => {
    if (auth?.token && updatedFacilities.length > 0) {
      setLoading(true);
      setLoadingDates(true);
      postToServer({
        action: 'GetSlaughterDates',
        params: { facilities: updatedFacilities },
        token: auth.token,
      }).then(response => {
        setLoadingDates(false);
        setLoading(false);
        if (response.message.type === 'success' && response.serverData) {
          const data = response.serverData as LooseObject[];
          if (data.length > 0) {
            const optimisedData = data.map(i =>
              moment(i, DateFormatServer.SHORT)
            );
            setValidDates(optimisedData);
            // show graph for default facility and latest available date
            const defaultDate = optimisedData[optimisedData.length - 1];
            setDate(defaultDate);
            setDateForGraph(defaultDate);
            fetchDataForGraph({
              faci: updatedFacilities,
              dat: defaultDate,
            });
          }
        } else {
          snackbar.open(response.message);
        }
      });
    }
  };

  const onChangeFacilities = (name: string, checked: boolean) => {
    if (checked) {
      if (dataView === DataView.COMPARISON) {
        // multiple select
        const updatedFacilities = _.union(facilities, [name]);
        setFacilities(updatedFacilities);
        setFacilitiesForGraph(updatedFacilities);
        loadDates(updatedFacilities);
      } else {
        const updatedFacilities = [name];
        setFacilities(updatedFacilities); // single select
        setFacilitiesForGraph(updatedFacilities);
        loadDates(updatedFacilities);
      }
    } else if (facilities.length > 1) {
      // must have at least 1 facility selected
      const updatedFacilities = _.remove(facilities, n => n !== name);
      setFacilities(updatedFacilities);
      setFacilitiesForGraph(updatedFacilities);
      loadDates(updatedFacilities);
    }
  };

  const onChangeDataView = (v: DataView) => {
    if (v !== DataView.COMPARISON && facilities.length > 1) {
      // keep only one facility
      setFacilities(facilities.slice(0, 1));
      setFacilitiesForGraph(facilities.slice(0, 1));
      setDataView(v);
      setDataViewForGraph(v);
      fetchDataForGraph({ faci: facilities.slice(0, 1), dataV: v });
    } else {
      setDataView(v);
      setDataViewForGraph(v);
      fetchDataForGraph({ dataV: v });
    }
  };

  type FetchDataForGraphParams = {
    dat?: Moment | null;
    shif?: string;
    faci?: string[];
    dataV?: string;
  };

  const fetchDataForGraph = ({
    dat,
    shif,
    faci,
    dataV,
  }: FetchDataForGraphParams) => {
    if (auth?.token) {
      setLoading(true);
      snackbar.close();
      postToServer({
        action: 'scheduling/GetDataForSchedulingGraph',
        params: {
          date:
            dat?.format(DateFormatServer.SHORT) ||
            date?.format(DateFormatServer.SHORT),
          shift: shif || shift,
          dataView: dataV || dataView,
          facilities: faci || facilities,
        },
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            const data = response.serverData as LooseObject;
            setDataForGraph(data);
          } else {
            snackbar.open(response.message);
            setDataForGraph(undefined);
          }
        }
      });
    }
  };

  const validDateStrings = validDates.map(i =>
    i.format(getDateFormat(org, 'short'))
  );
  const disableDates = (date: Moment) => {
    const currentDateString = date.format(getDateFormat(org, 'short'));
    const shouldDisable = !validDateStrings.includes(currentDateString);
    return shouldDisable;
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1} minHeight={`calc(100vh - ${190}px)`}>
        <Stack
          bgcolor={grey[100]}
          spacing={1}
          borderRight={1}
          borderColor={theme => theme.palette.divider}
        >
          <Stack p={CONTENT_PADDING}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography color={shift !== Shift.DAY ? 'grey.500' : ''}>
                Day Shift
              </Typography>
              <AntSwitch
                onChange={(e, v) => {
                  setShift(v ? Shift.NIGHT : Shift.DAY);
                  setShiftForGraph(v ? Shift.NIGHT : Shift.DAY);
                  fetchDataForGraph({ shif: v ? Shift.NIGHT : Shift.DAY });
                }}
                disabled
              />
              <Typography color={shift !== Shift.NIGHT ? 'grey.500' : ''}>
                Night Shift
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateCalendar
                loading={loadingDates}
                renderLoading={() => <DayCalendarSkeleton />}
                disableFuture
                value={date}
                onChange={(v: Moment | null) => {
                  setDate(v);
                  setDateForGraph(v);
                  fetchDataForGraph({ dat: v });
                }}
                shouldDisableDate={disableDates}
                slots={{
                  day: ({ day, ...rest }) => (
                    <PickersDay
                      day={day}
                      {...rest}
                      selected={
                        day.format(getDateFormat(org, 'short')) ===
                        date?.format(getDateFormat(org, 'short'))
                      }
                      sx={{
                        border: validDateStrings.includes(
                          day.format(getDateFormat(org, 'short'))
                        )
                          ? 1
                          : 0,
                        borderColor: theme => theme.palette.primary.main,
                      }}
                    />
                  ),
                }}
              />
            </LocalizationProvider>
          </Stack>
          <Divider />
          <Stack p={CONTENT_PADDING}>
            <Typography variant="h6">Facilities</Typography>
            <FormGroup>
              {loadingFacilities ? (
                <Skeleton variant="rounded" height={84} />
              ) : (
                allFacilities.map(item => (
                  <FormControlLabel
                    key={item}
                    control={<Checkbox checked={facilities.includes(item)} />}
                    // label={_.startCase(item)}
                    label="Site #1"
                    name={item}
                    onChange={(e, v) => {
                      onChangeFacilities(item, v);
                    }}
                  />
                ))
              )}
            </FormGroup>
          </Stack>
          <Divider />
          <Stack p={CONTENT_PADDING}>
            <Typography variant="h6">Data View</Typography>
            <RadioGroup
              aria-labelledby="radio-buttons-data-view"
              defaultValue={DataView.SIMPLE}
              name="radio-buttons-data-view"
              onChange={(e, v) => onChangeDataView(v as DataView)}
            >
              {Object.values(DataView).map(item => (
                <FormControlLabel
                  key={item}
                  value={item}
                  control={<Radio />}
                  label={_.startCase(item)}
                  disabled={
                    item === DataView.PROBABILISTIC ||
                    item === DataView.COMPARISON
                  }
                />
              ))}
            </RadioGroup>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Filter;
