import { Stack, Typography } from '@mui/material';

import { FormData, FormInputCategory } from '../../../utils/Types';
import { Form } from '../../../components';
import { HelperTextLogin } from '../Login/LoginForm';

type Props = {
  loading: boolean;
  handleSubmit: (formData: FormData) => void;
};

const ResetPasswordForm = ({ loading, handleSubmit }: Props) => {
  return (
    <Stack spacing={3} alignItems="center">
      <Stack spacing={1} alignItems="center" textAlign="center">
        <Typography variant="h4" fontWeight="bold" color="primary">
          Reset Your Password
        </Typography>
        <Typography color="grey.500">
          Enter your new password to continue!
        </Typography>
      </Stack>
      <Form
        loading={loading}
        onSubmit={data => {
          handleSubmit(data);
        }}
        buttonText="Submit"
        inputs={[
          {
            name: 'password',
            category: FormInputCategory.TEXT_INPUT,
            type: 'password',
            placeholder: 'Please enter your new password',
          },
          {
            name: 'passwordAgain',
            category: FormInputCategory.TEXT_INPUT,
            type: 'password',
            placeholder: 'Please enter your password again',
          },
        ]}
        itemSx={{
          mt: 1,
          borderRadius: 1,
          '&& .MuiInputBase-root': {
            backgroundColor: 'white',
          },
        }}
        buttonSx={{
          '&&.Mui-disabled': {
            backgroundColor: 'primary.dark',
            '&& .MuiLoadingButton-loadingIndicator': {
              color: 'grey.300',
            },
          },
        }}
      />
      <HelperTextLogin
        text="Remember your password?"
        to="/"
        linkTitle="Login"
      />
    </Stack>
  );
};

export default ResetPasswordForm;
