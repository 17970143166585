import { ExpandMore, Check } from '@mui/icons-material';
import {
  Stack,
  Typography,
  IconButton,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';

import { Auth, LooseObject, Org, User } from '../../utils/Types';
import { SnackbarContext, ThemeOveridesContext } from '../../utils/Contexts';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectAuth } from '../../redux/reducers/authSlice';
import { isNotEmpty, postToServer, signInToOrg } from '../../utils/Helper';
import Dialog from '../Dialog';
import Terms from '../../pages/auth/Login/Terms';

const SwichOrg = ({ org, user }: { org: Org; user: User }) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  // const [authActual, setAuthActural] = useState<Auth>();

  const [openDialogForAcceptTC, setOpenDialogForAcceptTC] = useState(false);
  const [currentOrgIdForAcceptTC, setCurrentOrgIdForAcceptTC] =
    useState<number>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const auth = useAppSelector(selectAuth);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const themeOverides = useContext(ThemeOveridesContext);

  const acceptTerms = async () => {
    setLoading(true);
    await postToServer({
      action: 'AcceptTerms',
      params: {
        orgId: currentOrgIdForAcceptTC,
        isAccepted: true,
      },
      token: auth?.token,
    }).then(response => {
      if (response.message.type === 'success' && response.serverData) {
        if ((response.serverData as LooseObject).isTCAccepted) {
          setAnchorEl(null);
          setOpenDialogForAcceptTC(false);
        }

        signInToOrg({
          orgId: currentOrgIdForAcceptTC,
          auth,
          themeOverides,
          dispatch,
          navigate,
          snackbar,
        });
        navigate('/loading');
      } else {
        snackbar.open(response.message);
      }
    });
    setLoading(false);
  };

  const IsUserAgreedTerms = async ({
    orgId,
    auth,
  }: {
    orgId?: number;
    auth?: Auth;
  }) => {
    let isUserAgreedTerms = false;

    if (isNotEmpty(orgId)) {
      if (auth?.token) {
        await postToServer({
          action: 'IsUserAgreedTerms',
          params: { orgId },
          token: auth?.token,
        }).then(response => {
          if (response.message.type === 'success' && response.serverData) {
            isUserAgreedTerms = (response.serverData as LooseObject)
              .isTCAccepted;
          }
        });
      }
    }
    return isUserAgreedTerms;
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography fontSize={18} color="background.default">
        {org?.name}
      </Typography>
      {(user?.orgs?.length || 0) > 1 && (
        <Stack>
          <IconButton
            onClick={event => setAnchorEl(event.currentTarget)}
            sx={{ width: 16, height: 16 }}
          >
            <ExpandMore sx={{ color: 'background.default' }} />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuList>
              {_.orderBy(user?.orgs, 'name')?.map(i => (
                <MenuItem
                  key={'org_' + i?.id}
                  onClick={async () => {
                    const isUserAgreedTerms = await IsUserAgreedTerms({
                      orgId: i?.id,
                      auth,
                    });

                    if (isUserAgreedTerms) {
                      signInToOrg({
                        orgId: i?.id,
                        auth,
                        themeOverides,
                        dispatch,
                        navigate,
                        snackbar,
                      });
                      navigate('/loading');
                    } else {
                      setCurrentOrgIdForAcceptTC(i?.id);
                      setOpenDialogForAcceptTC(true);
                    }
                  }}
                  disabled={i?.id === org?.id}
                >
                  {i?.id === org?.id && (
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                  )}
                  <ListItemText inset={i?.id !== org?.id}>
                    {i?.name}
                  </ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Popover>
          <Dialog open={openDialogForAcceptTC} maxWidth="lg">
            <Terms
              isUSA={true}
              onCancel={() => setOpenDialogForAcceptTC(false)}
              onConfirm={acceptTerms}
              loading={loading}
            />
          </Dialog>
        </Stack>
      )}
    </Stack>
  );
};

export default SwichOrg;
