import { Grid, Stack } from '@mui/material';
import { ChartType, QueryResultColumn } from '../../../utils/Types';
import { Chart } from '../../../components';
import { HISTOGRAM_Y_AXIS_TITLE } from '../../../utils/Constants';
import { showAllAttributesWhenHoverChart } from '../../../utils/Helper';

const Analysis = ({
  columns,
  rows,
}: {
  columns: QueryResultColumn[] | undefined;
  rows: any[];
}) => {
  return (
    <Stack bgcolor="white" minWidth="60vw" p={2}>
      <Grid container bgcolor="grey.50" spacing={1}>
        <Grid item xs={12} md={6}>
          <Chart
            type={ChartType.SCATTER_PLOT}
            title="GLQ vs. IMF"
            data={{
              xTitle: 'IMF',
              yTitle: 'GLQ',
              data: [
                {
                  x: rows.map(i => i['IMF']),
                  y: rows.map(i => i['GLQ']),
                  customdata: rows.map(i =>
                    showAllAttributesWhenHoverChart({
                      item: i,
                      excludeAttributes: ['AVGLON', 'AVGLAT', 'BARCODE'],
                    })
                  ),
                  hovertemplate: `%{customdata} <extra></extra>`,
                },
              ],
            }}
            maxHeight={400}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Chart
            type={ChartType.HISTOGRAM}
            title="Histogram of Body Weight"
            data={{
              xTitle: 'Body Weight',
              yTitle: HISTOGRAM_Y_AXIS_TITLE,
              // xAxisType: 'category',
              data: [
                {
                  x: rows.map(i => i['BODY_WEIGHT']),
                  type: 'histogram',
                  customdata: rows.map(i =>
                    showAllAttributesWhenHoverChart({
                      item: i,
                      excludeAttributes: ['AVGLON', 'AVGLAT', 'BARCODE'],
                    })
                  ),
                  hovertemplate: `%{customdata} <extra></extra>`,
                },
              ],
            }}
            maxHeight={400}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Analysis;
