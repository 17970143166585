import { FormInputCategory, LooseObject } from '../../../utils/Types';
import { Form, DialogWrapper } from '../../../components';

type Props = {
  onConfirm: (values: any) => void;
  onCancel: (values: any) => void;
  loading: boolean;
  item: LooseObject;
};

const Edit = ({ onConfirm, onCancel, loading, item }: Props) => (
  <DialogWrapper
    title="Edit Organisation"
    onCancel={onCancel}
    hideCancelButton
    hideConfirmButton
  >
    <Form
      loading={loading}
      onSubmit={onConfirm}
      buttonText="Submit"
      inputs={[
        {
          name: 'name',
          label: 'Name',
          category: FormInputCategory.TEXT_INPUT,
          defaultValue: item?.name,
        },
        {
          name: 'hostname',
          label: 'Hostname',
          category: FormInputCategory.TEXT_INPUT,
          defaultValue: item?.hostname,
        },
        {
          name: 'logo',
          label: 'Logo',
          category: FormInputCategory.TEXT_INPUT,
          defaultValue: item?.logo,
        },
        {
          name: 'theme',
          label: 'Theme',
          category: FormInputCategory.TEXT_INPUT,
          defaultValue: JSON.stringify(item?.theme),
        },
        {
          name: 'isWhiteLabel',
          label: 'A white labelled organisation (used for logo display)',
          defaultValue: item.isWhiteLabel,
          category: FormInputCategory.SWITCH,
        },
        {
          name: 'isUSA',
          label: 'A US Orgnisation (used for date format)',
          defaultValue: item.isUSA,
          category: FormInputCategory.SWITCH,
        },
        {
          name: 'isTCRequired',
          label: 'Require to accpet terms upon first login',
          defaultValue: item.isTCRequired,
          category: FormInputCategory.SWITCH,
        },
        {
          name: 'isValid',
          label: 'Active',
          defaultValue: item.isValid,
          category: FormInputCategory.SWITCH,
        },
      ]}
    />
  </DialogWrapper>
);

export default Edit;
