import * as React from 'react';
import {
  Toolbar,
  Typography,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Search } from '@mui/icons-material';

import { TableToolBarActionButtonCategory } from '../../utils/Types';

export interface ActionButton {
  category: TableToolBarActionButtonCategory;
  component: React.ReactNode;
}
interface EnhancedTableToolbarProps {
  numSelected: number;
  title?: string;
  sxTitle?: {};
  actionButtons?: ActionButton[];
  searchAttributes?: string[];
  sxSearchTextField?: {};
  handleSearch?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
}

const EnhancedTableToolbar = ({
  numSelected,
  title,
  sxTitle,
  actionButtons,
  searchAttributes,
  sxSearchTextField,
  handleSearch,
}: EnhancedTableToolbarProps) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: theme =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Stack
          direction="row"
          sx={{
            flex: '1 1 100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{ flex: '1 1 100%', ...sxTitle }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
          {searchAttributes && (
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              sx={sxSearchTextField}
            />
          )}
        </Stack>
      )}
      {actionButtons && (
        <Stack direction="row" spacing={1} ml={2}>
          {actionButtons.map((actionButton, index) => {
            let isDisplay = false;
            if (
              numSelected === 0 &&
              actionButton.category ===
                TableToolBarActionButtonCategory.NO_SELECT_ONLY
            ) {
              isDisplay = true;
            }

            if (
              numSelected === 1 &&
              actionButton.category ===
                TableToolBarActionButtonCategory.SINGLE_SELECT_ONLY
            ) {
              isDisplay = true;
            }

            if (
              numSelected >= 1 &&
              actionButton.category ===
                TableToolBarActionButtonCategory.MULTIPLE_SELECT
            ) {
              isDisplay = true;
            }

            if (
              actionButton.category === TableToolBarActionButtonCategory.ALWAYS
            ) {
              isDisplay = true;
            }

            return isDisplay ? (
              <div key={`actionButton${index}`}>{actionButton.component}</div>
            ) : null;
          })}
        </Stack>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
