import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import _ from 'lodash';

import { Auth, LooseObject, Org } from '../../../utils/Types';
import { DialogWrapper, Table } from '../../../components';
import { getDateFormat, isNotEmpty, postToServer } from '../../../utils/Helper';
import moment from 'moment';

const LoginLog = ({
  onCancel,
  auth,
  org,
  item,
  snackbar,
}: {
  onCancel: () => void;
  auth: Auth;
  org: Org;
  item: LooseObject;
  snackbar: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [loginLog, setLoginLog] = useState<LooseObject[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'administration/GetUserLoginLog',
        params: { id: item.id },
        token: auth.token,
      }).then(response => {
        if (response.statusCode !== 401) {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setLoginLog(_.orderBy(serverData, 'id', 'desc'));
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const columns = [
    {
      header: 'Login At',
      accessorFn: (row: any) =>
        moment(row.createdAt).format(getDateFormat(org, 'default')),
    },
    { header: 'Organisation', accessorFn: (row: any) => row.org?.name || '' },
    { accessorKey: 'ip', header: 'IP' },
    {
      header: 'Location',
      accessorFn: (row: any) => {
        const location = [row.city, row.region, row.countryShort];
        return location.filter(i => isNotEmpty(i)).join(', ');
      },
    },
  ];

  const rows = loginLog.map(i => ({
    ...i,
  }));

  return (
    <DialogWrapper
      title={`${item.firstName} ${item.lastName}'s Login Log`}
      onCancel={onCancel}
      hideCancelButton
    >
      <Stack>
        <Table
          columns={columns}
          data={rows}
          state={{ isLoading: loading }}
          enableColumnActions={false}
          layoutMode="grid"
          initialState={{
            density: 'compact',
            pagination: { pageSize: 20, pageIndex: 0 },
          }}
        />
      </Stack>
    </DialogWrapper>
  );
};

export default LoginLog;
