import { useContext, useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { LooseObject } from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { getUniqTraits, logout, postToServer } from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import TraitsHistogram from './TraitsHistogram';
import TraitsScatter from './TraitsScatter';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import TopMarblingCarcases from './TopMarblingCarcases';
import CarcasesHistogram from './CarcasesHistogram';
import { selectUser } from '../../../redux/reducers/userSlice';
import GraderHistogram from './GraderHistogram';
import ImfHistogram from './ImfHistogram';

export const JBS_OWNER_STRINGS = [
  'dinmore',
  'riverina',
  'jbsomaha',
  'beefcity',
  'longford',
  'scone',
];

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [summaries, setSummaries] = useState<LooseObject[]>([]);

  const [criteria, setCriteria] = useState<LooseObject>({});

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'camera/SummaryImages',
        params: {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            const serverData = (response.serverData as LooseObject[]).filter(
              i => {
                let flag = true;
                if (
                  user?.roleConfig?.photoDates &&
                  Array.isArray(user?.roleConfig?.photoDates) &&
                  user?.roleConfig?.photoDates.length > 0
                ) {
                  flag = user?.roleConfig?.photoDates.includes(i.photo_date);
                }
                return flag;
              }
            );

            if (org?.isUSA) {
              serverData.forEach(i => {
                i.traits?.forEach((t: any) => {
                  t.label = t.label?.replaceAll('AUS', 'BMS');
                });
              });
            }

            setSummaries(serverData);
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const allTraits = summaries?.map(i =>
    i.traits.map((j: any) => ({ key: j.key, label: j.label }))
  );

  const uniqTraits = getUniqTraits(allTraits);

  const flattenedSummaries: LooseObject[] = [];

  summaries.forEach(item => {
    const flattenedSummary = {
      photo_date: item.photo_date,
      number_of_images: item.number_of_images,
    };
    uniqTraits.forEach(i => {
      flattenedSummary[i.key] = item.traits.find(
        (j: any) => j.key === i.key
      )?.value;
    });
    flattenedSummaries.push(flattenedSummary);
  });

  const dataToDisplay = flattenedSummaries?.filter(i =>
    criteria.months ? criteria.months.includes(i.photo_date) : true
  );

  return (
    <Stack mt={2} ml={-2} mr={-3} mb={-3}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <CarcasesHistogram
            data={dataToDisplay}
            loading={loading}
            updateCriteria={v => setCriteria({ ...criteria, months: v.x })}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TraitsHistogram
            data={dataToDisplay}
            allTraits={uniqTraits}
            loading={loading}
            updateCriteria={v => setCriteria({ ...criteria, months: v.x })}
          />
        </Grid>
        <Grid item xs={12} lg={6} mt={1}>
          <TraitsScatter
            data={dataToDisplay}
            loading={loading}
            auth={auth}
            org={org}
          />
        </Grid>
        {auth?.orgOwner !== 'jbsomaha' && (
          <Grid item xs={12} lg={6} mt={1}>
            <TopMarblingCarcases
              data={flattenedSummaries}
              loading={loading}
              org={org}
              auth={auth}
              snackbar={snackbar}
            />
          </Grid>
        )}
        {auth?.orgOwner &&
          JBS_OWNER_STRINGS.includes(auth.orgOwner) &&
          (auth?.orgOwner === 'jbsomaha' ? (
            <Grid item xs={12} lg={6} mt={1}>
              <ImfHistogram
                data={flattenedSummaries}
                loading={loading}
                org={org}
                auth={auth}
                snackbar={snackbar}
              />
            </Grid>
          ) : (
            <Grid item xs={12} lg={6} mt={1}>
              <GraderHistogram
                data={flattenedSummaries}
                loading={loading}
                org={org}
                auth={auth}
                snackbar={snackbar}
              />
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};

export default Page;
