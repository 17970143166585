import { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import moment, { Moment } from 'moment';

import {
  Auth,
  ChartType,
  DateFormatServer,
  LooseObject,
  Org,
} from '../../../utils/Types';
import { NoDataView, Skeleton, Dialog, Chart } from '../../../components';
import {
  assignIds,
  compareServerDatesDesc,
  getDateFormat,
  getUniqTraits,
  postToServer,
} from '../../../utils/Helper';
import ImageItem from '../SummaryOfImages/ImageItem';
import ImageInfo from '../SummaryOfImages/ImageInfo';
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { HISTOGRAM_Y_AXIS_TITLE } from '../../../utils/Constants';
import { JBS_OWNER_STRINGS } from '.';

const TopMarblingCarcases = ({
  data,
  loading,
  minHeight,
  auth,
  org,
  snackbar,
}: {
  data: LooseObject[];
  loading: boolean;
  minHeight?: number;
  auth: Auth;
  org: Org;
  snackbar: any;
}) => {
  const allDateStrings = data
    ?.map(i => i.photo_date)
    ?.sort(
      (a: string, b: string) =>
        moment(b, DateFormatServer.SHORT).valueOf() -
        moment(a, DateFormatServer.SHORT).valueOf()
    );

  const [loadingImages, setLoadingImages] = useState(false);
  const [images, setImages] = useState<LooseObject[]>([]);
  const [imageItemWithUrl, setImageItemWithUrl] = useState<LooseObject>();
  const [uniqTraits, setUniqTraits] = useState<LooseObject[]>([]);
  const [currentTrait, setCurrentTrait] = useState<LooseObject | undefined>();
  const [openDialog, setOpenDialog] = useState(false);

  const [date, setDate] = useState<Moment | null>(moment());
  const [allImages, setAllImages] = useState<LooseObject[]>([]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    fetchData();

    let defaultDate = moment();

    if (allDateStrings?.[0]) {
      defaultDate = moment(allDateStrings?.[0], DateFormatServer.SHORT);
      setDate(defaultDate);

      loadAllImages(defaultDate);
    }
  }, [data]);

  useEffect(() => {
    getImageItemWithUrl();
    setLoadingImages(false);
  }, [currentTrait]);

  const fetchData = async () => {
    if (auth?.token) {
      setLoadingImages(true);
      const imagesFromServer: LooseObject[] = [];
      const lastDay =
        data && data.length > 1
          ? data
              .map(i => i.photo_date)
              .sort((a, b) =>
                compareServerDatesDesc({
                  a,
                  b,
                  dateFormat: DateFormatServer.SHORT,
                })
              )[0]
          : moment().format(DateFormatServer.SHORT);

      await postToServer({
        action: 'camera/ListOfImages',
        params: { dateFrom: lastDay, includeImage: true },
        token: auth.token,
      }).then(response => {
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            if (org?.isUSA) {
              serverData.forEach(i => {
                i.traits?.forEach((t: any) => {
                  t.label = t.label?.replaceAll('AUS', 'BMS');
                });
              });
            }
            imagesFromServer.push(...assignIds(serverData));
          }
        }
      });

      setImages(imagesFromServer.filter(i => i.photo_url)); // remove images with no url

      const allTraits = imagesFromServer
        .filter(i => i.photo_url)
        .map(i =>
          i.traits
            .filter((t: any) => t.value !== null)
            .map((j: any) => ({ key: j.key, label: j.label }))
        );

      const uniq = getUniqTraits(allTraits);
      setUniqTraits(uniq);

      if (uniq && uniq.length > 0) {
        setCurrentTrait(uniq?.[0]);
      }
    }
  };

  const loadAllImages = (forDate: Moment | null) => {
    if (forDate && auth?.token) {
      setLoadingImages(true);
      postToServer({
        action: 'camera/ListOfImages',
        params: {
          dateFrom: forDate.format(DateFormatServer.SHORT),
          includeImage: true,
        },
        token: auth.token,
      }).then(response => {
        setLoadingImages(false);
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];

            if (org?.isUSA) {
              serverData.forEach(i => {
                i.traits?.forEach((t: any) => {
                  t.label = t.label?.replaceAll('AUS', 'BMS');
                });
              });
            }

            const imagesFromServer: LooseObject[] = assignIds(serverData);

            if (imagesFromServer?.length > 0) {
              setAllImages(imagesFromServer);
            }
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const getImageItemWithUrl = async () => {
    const imagesFlattened: LooseObject[] = [];
    images.forEach(item => {
      const image = {
        barcode: item.barcode,
        photo_date: item.photo_date,
        photo_url: item.photo_url,
      };
      uniqTraits?.forEach(i => {
        image[i.key] = item.traits.find((j: any) => j.key === i.key)?.value;
      });

      imagesFlattened.push(image);
    });

    const highest = _.orderBy(
      imagesFlattened,
      o => o[currentTrait?.key] || '',
      'desc'
    )?.[0];

    const imageItem = images.find(i => i.barcode === highest.barcode);

    setImageItemWithUrl(imageItem);
  };

  const onChangeTrait = (event: SelectChangeEvent) => {
    setCurrentTrait(uniqTraits.find(i => i.key === event.target.value));
  };

  const disableDates = (date: Moment) => {
    const currentDateString = date.format(DateFormatServer.SHORT);
    const shouldDisable = !allDateStrings?.includes(currentDateString);
    return shouldDisable;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return auth?.orgOwner && JBS_OWNER_STRINGS.includes(auth.orgOwner) ? (
    <Chart
      type={ChartType.HISTOGRAM}
      title="JBS AUS Marbling VS AUS Marbling"
      headerBeforeLoading={
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">{`Show data for ${date?.format(
            getDateFormat(org, 'short')
          )}`}</Typography>
          <Stack>
            <Typography
              onClick={handleClick}
              variant="body2"
              sx={{
                textDecoration: 'underline',
                m: 1,
                ':hover': { color: 'grey.700', cursor: 'pointer' },
              }}
            >
              Change Date
            </Typography>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateCalendar
                  disableFuture
                  value={date}
                  onChange={(v: Moment | null) => {
                    setDate(v);
                    loadAllImages(v);
                  }}
                  shouldDisableDate={disableDates}
                  slots={{
                    day: ({ day, ...rest }) => (
                      <PickersDay
                        day={day}
                        {...rest}
                        selected={
                          day.format(getDateFormat(org, 'short')) ===
                          date?.format(getDateFormat(org, 'short'))
                        }
                        sx={{
                          border: allDateStrings.includes(
                            day.format(DateFormatServer.SHORT)
                          )
                            ? 1
                            : 0,
                          borderColor: theme => theme.palette.primary.main,
                        }}
                      />
                    ),
                  }}
                />
              </LocalizationProvider>
            </Popover>
          </Stack>
        </Stack>
      }
      data={{
        xTitle: 'Value',
        yTitle: HISTOGRAM_Y_AXIS_TITLE,
        data: [
          {
            x: allImages?.map(i =>
              Math.floor(
                i.traits?.find((j: any) => j.key === 'jbs_aus_mb')?.value
              )
            ),
            name: 'JBS AUS Marbling',
            nameX: 'JBS AUS Marbling',
            type: 'histogram',
          },
          {
            x: allImages?.map(
              i => i.traits?.find((j: any) => j.key === 'aus_marbling')?.value
            ),
            name: 'AUS Marbling',
            nameX: 'AUS Marbling',
            type: 'histogram',
          },
        ],
      }}
      loading={loading || loadingImages}
    />
  ) : (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Typography variant="h6" textAlign="center">
        {`Highest ${currentTrait?.label || 'Marbling'} Carcase for Last Day`}
      </Typography>
      {(loading || loadingImages) && <Stack width="100%" height={36} />}
      {loading || loadingImages ? (
        <Skeleton minHeight={minHeight} />
      ) : !imageItemWithUrl ? (
        <NoDataView minHeight={minHeight} />
      ) : (
        <Stack spacing={2}>
          <FormControl sx={{ mt: 1, bgcolor: 'white' }} size="small">
            <InputLabel>Select trait</InputLabel>
            <Select
              value={currentTrait?.key || ''}
              label="Select trait"
              onChange={onChangeTrait}
            >
              {uniqTraits.map(i => (
                <MenuItem key={i.key} value={i.key || ''}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack maxWidth={270}>
            <ImageItem
              item={imageItemWithUrl}
              org={org}
              hideReportImageIssue
              onClick={() => setOpenDialog(true)}
            />
            <Dialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'rgba(0,0,0,0.5)',
              }}
              fullScreen
              isTransparent
            >
              <ImageInfo
                org={org}
                onClose={() => setOpenDialog(false)}
                data={imageItemWithUrl}
                hideReportImageIssue
              />
            </Dialog>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default TopMarblingCarcases;
