import {
  ToggleButtonGroup,
  ToggleButton,
  SelectChangeEvent,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';

import { ChartType, DateFormatServer, LooseObject } from '../../../utils/Types';
import { Chart } from '../../../components';
import { DATE_RANGE, DATE_RANGE_KEYS } from './CarcasesHistogram';

const TraitsHistogram = ({
  data,
  allTraits,
  loading,
  updateCriteria,
}: {
  data: LooseObject[];
  allTraits: LooseObject[];
  loading: boolean;
  updateCriteria: (v: any) => void;
}) => {
  const [histogramTrait, setHistogramTrait] = useState<
    LooseObject | undefined
  >();

  const [dateRange, setDateRange] = useState<string>(DATE_RANGE_KEYS[3]);

  const handleChangeDateRange = (event: SelectChangeEvent) => {
    if (event?.target?.value) {
      setDateRange(event.target.value);
    }
  };

  const dataToDisplay = data.filter(i => {
    if (dateRange === 'all') {
      return true;
    }
    const datePoint = DATE_RANGE.find(d => d.key === dateRange)?.value;

    if (datePoint) {
      return (
        moment(i.photo_date, DateFormatServer.SHORT) >=
        moment().add(-datePoint.n, datePoint.u)
      );
    }

    return false;
  });

  useEffect(() => {
    setHistogramTrait(allTraits?.[0]);
  }, [allTraits]);

  const handleChangeHistogramTrait = (
    event: React.MouseEvent<HTMLElement>,
    v: string
  ) => {
    if (v) {
      setHistogramTrait(allTraits.find(i => i.key === v));
    }
  };

  return (
    <Chart
      type={ChartType.BAR_CHART}
      title={histogramTrait?.label || 'Trait'}
      headerBeforeLoading={
        <Stack
          direction="row"
          spacing={1}
          mt={1}
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          <FormControl size="small" sx={{ bgcolor: 'white', minWidth: 200 }}>
            <InputLabel>Date Range</InputLabel>
            <Select
              value={dateRange}
              label="Date Range"
              onChange={handleChangeDateRange}
            >
              {DATE_RANGE.map(i => (
                <MenuItem
                  key={'dateRange_' + i.key}
                  value={i.key || ''}
                  disabled={i.key === dateRange}
                >
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      }
      header={
        allTraits.length > 1 && (
          <ToggleButtonGroup
            value={histogramTrait?.key}
            exclusive
            onChange={handleChangeHistogramTrait}
            aria-label="View"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
            }}
            size="small"
          >
            {allTraits.map(i => (
              <ToggleButton key={'h_' + i.key} value={i.key}>
                {i.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )
      }
      data={{
        xTitle: 'Photo Date',
        yTitle: histogramTrait?.label,
        data: [
          {
            x: dataToDisplay.map(i => i.photo_date),
            y: dataToDisplay.map(i => i[histogramTrait?.key]),
          },
        ],
      }}
      loading={loading}
      updateFilter={updateCriteria}
      dragMode="select"
      style={{ height: allTraits.length > 1 ? 395 : 'auto' }}
    />
  );
};

export default TraitsHistogram;
