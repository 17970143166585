import { ContentCopy } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';

const CopyButton = ({ text, sx }: { text: string; sx?: {} }) => (
  <Tooltip title="Copy">
    <IconButton
      sx={sx}
      aria-label={'copy'}
      onClick={() => navigator.clipboard.writeText(text)}
    >
      <ContentCopy />
    </IconButton>
  </Tooltip>
);

export default CopyButton;
