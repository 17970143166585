import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import FilePlayer from 'react-player/file';

import { LooseObject } from '../../../../utils/Types';
import { VIDEO_SAS_TOKEN, WEBCAM_SAS_TOKEN } from '../../../../utils/Constants';
import { SmallButton } from '../../../../components';

const Videos = ({ scan }: { scan: LooseObject }) => {
  const [playing, setPlaying] = useState(true);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isSegmentationOn, setIsSegmentationOn] = useState(false);
  const [isRotate, setIsRotate] = useState(false);

  const playerRef1 = useRef<FilePlayer>(null);
  const playerRef2 = useRef<FilePlayer>(null);

  const speeds = [0.2, 0.5, 1, 1.5, 2, 3];
  const frameActions = ['Prev', 'Next'];
  const frameInterval = 0.1;

  const SpeedButton = ({ speed }: { speed: number }) => (
    <IconButton
      size="small"
      onClick={() => setPlaybackRate(speed)}
      sx={{
        width: 35,
        height: 30,
        color: 'primary.light',
        border: 0.5,
        borderRadius: 1,
        bgcolor: speed === playbackRate ? 'primary.main' : 'white',
        '&:hover': {
          backgroundColor: speed === playbackRate ? 'primary.main' : 'grey.50',
        },
      }}
    >
      <Typography
        fontSize={14}
        color={speed === playbackRate ? 'white' : 'primary.main'}
      >{`x${speed}`}</Typography>
    </IconButton>
  );

  const FrameButton = ({ title }: { title: string }) => (
    <SmallButton
      variant="outlined"
      title={`${title} Frame`}
      onClick={() => {
        playerRef1.current?.seekTo(
          playerRef1.current?.getCurrentTime() +
            (title === 'Next' ? frameInterval : -frameInterval),
          'seconds'
        );
        playerRef2.current?.seekTo(
          playerRef2.current?.getCurrentTime() +
            (title === 'Next' ? frameInterval : -frameInterval),
          'seconds'
        );
      }}
      sx={{
        py: 0.2,
        px: 0.5,
        fontSize: 12,
        width: 80,
      }}
    />
  );

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} height={30}>
        <SmallButton
          title={playing ? 'Pause' : 'Play'}
          onClick={() => setPlaying(!playing)}
        />
        <Stack direction="row" spacing={0.5}>
          {speeds.map(i => (
            <SpeedButton key={`speedButton` + i} speed={i} />
          ))}
        </Stack>
        <Stack direction="row" spacing={0.5}>
          {frameActions.map(i => (
            <FrameButton key={`frameButton` + i} title={i} />
          ))}
        </Stack>
        <SmallButton
          variant={isSegmentationOn ? 'contained' : 'outlined'}
          title="Segmentation"
          onClick={() => setIsSegmentationOn(!isSegmentationOn)}
        />
        <SmallButton
          variant="outlined"
          title="To Start"
          onClick={() => {
            playerRef1.current?.seekTo(0, 'seconds');
            playerRef2.current?.seekTo(0, 'seconds');
          }}
          sx={{
            py: 0.2,
            px: 0.5,
            fontSize: 12,
            width: 50,
          }}
        />
        <SmallButton
          variant={isRotate ? 'contained' : 'outlined'}
          title="Rotate"
          onClick={() => setIsRotate(!isRotate)}
        />
      </Stack>
      <Grid container>
        <Grid item xs={4}>
          <Stack sx={{ transform: isRotate ? 'rotate(180deg)' : undefined }}>
            <FilePlayer
              ref={playerRef1}
              url={scan.WEBCAM_URL + WEBCAM_SAS_TOKEN}
              width="100%"
              height="100%"
              playing={playing}
              playbackRate={playbackRate}
              controls
              loop
            />
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <FilePlayer
            ref={playerRef2}
            url={scan.VIDEO_URL + VIDEO_SAS_TOKEN}
            width="100%"
            height="100%"
            playing={playing}
            playbackRate={playbackRate}
            controls
            loop
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Videos;
