import { LoadingButton } from '@mui/lab';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

export type ButtonProps = {
  loading?: boolean;
  title: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps<Theme>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};

const Button = ({
  loading,
  title,
  onClick,
  variant,
  size,
  sx,
  startIcon,
  endIcon,
}: ButtonProps) => (
  <LoadingButton
    variant={variant || 'contained'}
    onClick={onClick}
    size={size || 'medium'}
    loading={loading}
    sx={{ textTransform: 'none', ...sx }}
    startIcon={startIcon}
    endIcon={endIcon}
  >
    {title}
  </LoadingButton>
);
export default Button;
