import { useContext, useEffect, useState } from 'react';
import { Dialog, IconButton, Stack, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Add } from '@mui/icons-material';

import { SmallButton, Table } from '../../../components';
import { FormData } from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { getDateFormat, logout, postToServer } from '../../../utils/Helper';
import { LooseObject } from '../../../utils/Types';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import TermsForm from './TermsForm';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [allTerms, setAllTerms] = useState<LooseObject[]>([]);
  const [tableRows, setTableRows] = useState<LooseObject[]>([]);
  const [currentItem, setCurrentItem] = useState<LooseObject>({});
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { accessorKey: 'title', header: 'Title' },
    { accessorKey: 'version', header: 'Version' },
    { accessorKey: 'country', header: 'Country' },
    { accessorKey: 'createdAt', header: 'Created At', sortingFn: 'datetime' },
    { accessorKey: 'updatedAt', header: 'Updated At', sortingFn: 'datetime' },
    { accessorKey: 'actions', header: 'Actions' },
  ];

  useEffect(() => {
    const rows = allTerms.map(i => ({
      ...i,
      createdAt: moment(i.createdAt).format(getDateFormat(org, 'default')),
      updatedAt: moment(i.updatedAt).format(getDateFormat(org, 'default')),
      actions: (
        <Stack spacing={1}>
          <SmallButton
            variant="outlined"
            title="Edit"
            onClick={() => {
              setCurrentItem(i);
              setOpenDialogEdit(true);
            }}
          />
        </Stack>
      ),
    }));
    setTableRows(rows);
  }, [allTerms]);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'administration/GetTerms',
        params: {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            setAllTerms(response.serverData as LooseObject[]);
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const handleAdd = ({ title, country, version }: FormData) => {
    if (title && country && version) {
      setLoading(true);
      if (auth?.token) {
        const params = { title, country, version };
        postToServer({
          action: 'administration/AddTerms',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogAdd(false);
              setAllTerms(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setLoading(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const handleEdit = ({ title, country, version }: FormData) => {
    if (currentItem.id && title && country && version) {
      setLoading(true);
      if (auth?.token) {
        const params = {
          id: currentItem.id,
          title,
          country,
          version,
        };
        postToServer({
          action: 'administration/EditTerms',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogEdit(false);
              setAllTerms(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setLoading(false);
        logout({ dispatch, navigate });
      }
    }
  };

  return (
    <Stack>
      <Stack alignItems="flex-end">
        <Tooltip arrow title="Create New Terms">
          <IconButton onClick={() => setOpenDialogAdd(true)} size="large">
            <Add color="primary" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Table
        columns={columns}
        data={tableRows}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnActions={false}
        layoutMode="grid"
        state={{ isLoading: loading }}
      />
      <Dialog open={openDialogAdd}>
        <TermsForm
          title="Add New Terms"
          onCancel={() => setOpenDialogAdd(false)}
          onConfirm={handleAdd}
          loading={loading}
        />
      </Dialog>
      <Dialog open={openDialogEdit}>
        <TermsForm
          title="Edit Terms"
          onCancel={() => setOpenDialogEdit(false)}
          onConfirm={handleEdit}
          loading={loading}
          item={currentItem}
        />
      </Dialog>
    </Stack>
  );
};

export default Page;
