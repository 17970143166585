import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChevronLeft,
  ChevronRight,
  FilterAltOutlined,
} from '@mui/icons-material';
import moment from 'moment';

import { SnackbarContext } from '../../../../utils/Contexts';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { selectOrg } from '../../../../redux/reducers/orgSlice';
import { selectAuth } from '../../../../redux/reducers/authSlice';
import {
  getDateFormat,
  logout,
  optimiseQueryResult,
  postToServer,
} from '../../../../utils/Helper';
import { QUERY_DATA_SOURCE_ENDPOINT } from '../../../../utils/Constants';
import {
  DateFormatServer,
  LooseObject,
  QueryResult,
  QueryType,
} from '../../../../utils/Types';
import Filter from './Filter';
import {
  Dialog,
  DialogWrapperInfo,
  NoDataView,
  SearchBar,
  SmallButton,
} from '../../../../components';
import Videos from './Videos';
import TableView from './TableView';
import ScanNavButton from './ScanNavButton';
import ScanTable from './ScanTable';
import Analysis from './Analysis';
import _ from 'lodash';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [location, setLocation] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [scans, setScans] = useState<QueryResult>();
  const [scansToDisplay, setScansToDisplay] = useState<LooseObject[]>();
  const [currentScanIndex, setCurrentScanIndex] = useState<number>(0);
  const [openDialogTable, setOpenDialogTable] = useState(false);
  const [openDialogAnalysis, setOpenDialogAnalysis] = useState(false);
  const [keywords, setKeywords] = useState('');

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let toDisplay = scans?.rows
      ?.filter(i => i.LOCATION === location && i.SCAN_DATE === date)
      ?.filter(i =>
        keywords
          ? !!i.ANIMAL_ID?.replaceAll(' ', '')
              ?.toLowerCase()
              ?.includes(keywords.replaceAll(' ', '').toLowerCase())
          : true
      );

    if (
      location === 'mesquite' &&
      moment(date, DateFormatServer.SHORT) >
        moment('2024-03-28', DateFormatServer.SHORT)
    ) {
      toDisplay = _.sortBy(toDisplay, 'SCAN_DATETIME');
    }
    setScansToDisplay(toDisplay);
  }, [location, date, scans, keywords]);

  const fetchData = async () => {
    if (auth?.token) {
      setLoading(true);
      await postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: 'meqinternal_live',
          type: QueryType.ORDINARY_QUERY,
          view: 'SCAN_REVIEW_PORTAL',
          sqlConditions: '1 = 1',
        },
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = optimiseQueryResult(
              response.serverData as QueryResult
            );
            setScans(serverData);
          } else {
            snackbar.open(response.message);
          }
        }
      });
      setLoading(false);
    }
  };

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        display={{ xs: 'flex', md: 'none' }}
        px={2}
        py={1}
        bgcolor="grey.100"
      >
        <Stack direction="row" color="grey.700">
          <FilterAltOutlined />
          <Typography>Filters</Typography>
        </Stack>
        <IconButton
          onClick={() => setShowFilter(!showFilter)}
          sx={{
            borderRadius: 1,
            bgcolor: 'grey.400',
            width: 26,
            height: 26,
            ':hover': {
              bgcolor: 'grey.300',
            },
          }}
        >
          {showFilter ? (
            <ChevronLeft sx={{ color: 'white' }} />
          ) : (
            <ChevronRight sx={{ color: 'white' }} />
          )}
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        minHeight={`calc(100vh - ${190}px)`}
        overflow="auto"
      >
        <Stack display={{ xs: showFilter ? 'flex' : 'none', md: 'flex' }}>
          <Filter
            loading={loading}
            data={scans?.rows}
            changeLocation={setLocation}
            changeDate={setDate}
            org={org}
          />
        </Stack>
        <Stack spacing={1}>
          <Stack direction="row" spacing={3} alignItems="center">
            <Stack direction="row" spacing={2}>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight="bold">Location:</Typography>
                <Typography>{location || 'Not Selected'}</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight="bold">Date:</Typography>
                <Typography>
                  {date
                    ? moment(date, DateFormatServer.SHORT).format(
                        getDateFormat(org, 'short')
                      )
                    : 'Not Selected'}
                </Typography>
              </Stack>
              {scansToDisplay && scansToDisplay.length > 0 && (
                <Stack direction="row" spacing={1}>
                  <Typography fontWeight="bold">Current:</Typography>
                  <Typography>
                    {`${currentScanIndex + 1}/${scansToDisplay.length}`}
                  </Typography>
                </Stack>
              )}
            </Stack>
            {location &&
              date &&
              scansToDisplay &&
              scansToDisplay.length > 0 && (
                <Stack direction="row" alignItems="center" spacing={2}>
                  <SmallButton
                    variant="outlined"
                    title="Table"
                    sx={{ height: 25 }}
                    onClick={() => setOpenDialogTable(true)}
                  />
                  <SmallButton
                    variant="outlined"
                    title="Analysis"
                    sx={{ height: 25 }}
                    onClick={() => setOpenDialogAnalysis(true)}
                  />
                  <Stack direction="row">
                    <ScanNavButton
                      title="Prev"
                      onClick={() => setCurrentScanIndex(prev => prev - 1)}
                      disabled={currentScanIndex === 0}
                    />
                    <ScanNavButton
                      title="Next"
                      onClick={() => setCurrentScanIndex(prev => prev + 1)}
                      disabled={currentScanIndex === scansToDisplay.length - 1}
                    />
                  </Stack>
                  <Dialog
                    open={openDialogTable}
                    onClose={() => setOpenDialogTable(false)}
                    isTransparent
                  >
                    <DialogWrapperInfo
                      onClose={() => setOpenDialogTable(false)}
                    >
                      <ScanTable
                        columns={scans?.columns}
                        rows={scansToDisplay}
                      />
                    </DialogWrapperInfo>
                  </Dialog>
                  <Dialog
                    open={openDialogAnalysis}
                    onClose={() => setOpenDialogAnalysis(false)}
                    isTransparent
                  >
                    <DialogWrapperInfo
                      onClose={() => setOpenDialogAnalysis(false)}
                    >
                      <Analysis
                        columns={scans?.columns}
                        rows={scansToDisplay}
                      />
                    </DialogWrapperInfo>
                  </Dialog>
                </Stack>
              )}
          </Stack>
          {location && date && (
            <Stack maxWidth={400}>
              <SearchBar
                onChange={v => setKeywords(v.target.value)}
                placeholder="Search Animal ID"
                height={44}
              />
            </Stack>
          )}
          {location && date ? (
            scansToDisplay && scansToDisplay?.length > 0 ? (
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Videos scan={scansToDisplay[currentScanIndex]} />
                </Grid>
                <Grid item xs={12} p={0.5}>
                  <TableView scan={scansToDisplay[currentScanIndex]} />
                </Grid>
              </Grid>
            ) : (
              <NoDataView />
            )
          ) : (
            <Typography>Please choose a location and date to start.</Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Page;
