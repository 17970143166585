import { Stack } from '@mui/material';
import { useState } from 'react';
import { Moment } from 'moment';
import _ from 'lodash';

import {
  Auth,
  ChartPoint,
  ChartType,
  DateFormat,
  LooseObject,
  Org,
} from '../../../utils/Types';
import {
  Chart,
  Dialog,
  DialogWrapperInfo,
  Skeleton,
} from '../../../components';
import DeepdiveFrames from './DeepdiveFrames';

export enum Trait {
  EYE_MUSCLE = 'EYE_MUSCLE',
  RIB_FAT = 'RIB_FAT',
}

export enum Type {
  FRAMES = 'frames',
  BARCODES = 'barcodes',
}

export const MIN_HEIGHT = 300;
export const MIN_WIDTH = 600;

export type DepthCameraComponentProps = {
  data?: LooseObject;
  date: Moment | null;
  trait: Trait;
  type: Type;
  facility?: string;
  loading: boolean;
  auth: Auth;
  org: Org;
  snackbar: any;
  isAdjusted?: boolean;
};

const GraphArea = ({
  data,
  date,
  trait,
  type,
  facility,
  loading,
  auth,
  org,
  snackbar,
  isAdjusted,
}: DepthCameraComponentProps) => {
  const [currentItem, setCurrentItem] = useState<LooseObject>();
  const [openDialog, setOpenDialog] = useState(false);

  const getMinV = (delta: number) => {
    let minV =
      data?.data && data.data.length > 0
        ? Math.min(
            Math.min(...data?.data.map((i: LooseObject) => i.x)),
            Math.min(...data?.data.map((i: LooseObject) => i.y))
          ) -
          delta * 2
        : 0;
    if (typeof minV === 'number') {
      minV = Number(minV.toFixed(2));
    }
    return minV;
  };

  const getMaxV = (delta: number) => {
    let maxV =
      data?.data && data.data.length > 0
        ? Math.max(
            Math.max(...data?.data.map((i: LooseObject) => i.y)),
            Math.max(...data?.data.map((i: LooseObject) => i.x))
          ) +
          delta * 2
        : 0;
    if (typeof maxV === 'number') {
      maxV = Number(maxV.toFixed(2));
    }
    return maxV;
  };

  const delta1 = trait === Trait.EYE_MUSCLE ? 1 : 0.1;
  const delta2 = trait === Trait.EYE_MUSCLE ? 1.5 : 0.2;

  const minV1 = getMinV(delta1);
  const maxV1 = getMaxV(delta1);

  const minV2 = getMinV(delta2);
  const maxV2 = getMaxV(delta2);

  const handleClickPoint = async ({ x, y }: ChartPoint) => {
    const item = data?.data.find(
      (i: LooseObject) =>
        (i.x === x || i.x === x?.toString()) &&
        (i.y === y || i.y === y?.toString())
    );

    if (item) {
      setCurrentItem(item);
      setOpenDialog(true);
    }
  };

  return (
    <Stack sx={{ minWidth: { xs: MIN_WIDTH * 0.7, md: MIN_WIDTH } }}>
      <Stack>
        {loading ? (
          <Skeleton />
        ) : (
          <Chart
            type={ChartType.SCATTER_PLOT}
            title={
              `${date?.format(DateFormat.SHORT)} - ${_.startCase(
                facility
              )} - ${trait} - ${_.startCase(type)}` +
              (isAdjusted ? ' - Adjusted' : '')
            }
            data={{
              xTitle: data?.columns.x,
              yTitle: data?.columns.y,
              data: [
                {
                  x: data?.data
                    .filter((i: LooseObject) => i.status)
                    .map((i: LooseObject) => i.x),
                  y: data?.data
                    .filter((i: LooseObject) => i.status)
                    .map((i: LooseObject) => i.y),
                  customdata: data?.data
                    .filter((i: LooseObject) => i.status)
                    .map((i: LooseObject) => i.barcode),
                  hovertemplate: `<i>%{xaxis.title.text}</i>: %{x} <br /><i>%{yaxis.title.text}</i>: %{y} <br /><i>barcode</i>: %{customdata} <extra></extra>`,
                  showlegend: false,
                  line: {
                    color: 'green',
                  },
                },
                {
                  x: data?.data
                    .filter((i: LooseObject) => !i.status)
                    .map((i: LooseObject) => i.x),
                  y: data?.data
                    .filter((i: LooseObject) => !i.status)
                    .map((i: LooseObject) => i.y),
                  customdata: data?.data
                    .filter((i: LooseObject) => !i.status)
                    .map((i: LooseObject) => i.barcode),
                  hovertemplate: `<i>%{xaxis.title.text}</i>: %{x} <br /><i>%{yaxis.title.text}</i>: %{y} <br /><i>barcode</i>: %{customdata} <extra></extra>`,
                  showlegend: false,
                  line: {
                    color: 'red',
                  },
                },
                {
                  x: [minV1 + delta1, maxV1 + delta1],
                  y: [minV1, maxV1],
                  mode: 'lines',
                  line: {
                    dash: 'dash',
                    color: 'orange',
                  },
                  hovertemplate: '',
                  showlegend: false,
                },
                {
                  x: [minV1 - delta1, maxV1 - delta1],
                  y: [minV1, maxV1],
                  mode: 'lines',
                  line: {
                    dash: 'dash',
                    color: 'orange',
                  },
                  hovertemplate: '',
                  showlegend: false,
                },
                {
                  x: [minV1, maxV1],
                  y: [minV1, maxV1],
                  mode: 'lines',
                  line: {
                    color: 'black',
                  },
                  hovertemplate: '',
                  showlegend: false,
                },
                {
                  x: [minV2 + delta2, maxV2 + delta2],
                  y: [minV2, maxV2],
                  mode: 'lines',
                  line: {
                    dash: 'dot',
                    color: 'red',
                  },
                  hovertemplate: '',
                  showlegend: false,
                },
                {
                  x: [minV2 - delta2, maxV2 - delta2],
                  y: [minV2, maxV2],
                  mode: 'lines',
                  line: {
                    dash: 'dot',
                    color: 'red',
                  },
                  hovertemplate: '',
                  showlegend: false,
                },
              ],
            }}
            loading={loading}
            backdrop={
              <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="xl"
                isTransparent
              >
                <DialogWrapperInfo onClose={() => setOpenDialog(false)}>
                  <DeepdiveFrames
                    item={currentItem}
                    date={date}
                    trait={trait}
                    type={type}
                    facility={facility}
                    loading={loading}
                    auth={auth}
                    org={org}
                    snackbar={snackbar}
                  />
                </DialogWrapperInfo>
              </Dialog>
            }
            handleClick={handleClickPoint}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default GraphArea;
