import { TextField, Stack } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';

import { FormInputItem, LooseObject } from '../../utils/Types';

export type TextInputProps = {
  label?: string;
  name?: string;
  type?: string;
  item: FormInputItem;
  textFieldProps: LooseObject;
  values: {};
  handleChange: {
    /** Classic React change handler, keyed by input name */
    (e: React.ChangeEvent<any>): void;
    /** Preact-like linkState. Will return a handleChange function.  */
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    /** Classic React blur handler, keyed by input name */
    (e: React.FocusEvent<any>): void;
    /** Preact-like linkState. Will return a handleBlur function. */
    <T = string | any>(fieldOrEvent: T): T extends string
      ? (e: any) => void
      : void;
  };
  touched: FormikTouched<{}>;
  errors: FormikErrors<{}>;
  inputProps?: {};
};

const TextInput = ({
  label,
  name,
  type,
  item,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  inputProps,
  textFieldProps,
}: TextInputProps) => (
  <Stack alignItems="flex-start" width="100%">
    <TextField
      {...textFieldProps}
      label={label || item.label}
      placeholder={item.placeholder}
      name={name || item.name}
      value={values[name || item.name]}
      type={type || item.type} // can be password, search, number
      onWheel={e => (e.target as HTMLElement).blur()}
      InputProps={inputProps || item.inputProps}
      onChange={v => {
        handleChange(v);
        item.exposeValue?.(v.target.value);
      }}
      onBlur={handleBlur}
      helperText={
        touched[name || item.name]
          ? errors[name || item.name]
          : item.helperText || ''
      }
      error={errors[name || item.name] && touched[name || item.name]}
      required={item.required}
      multiline={item.multiline}
      minRows={3}
      maxRows={7}
      size={item.size}
    />
  </Stack>
);

export default TextInput;
