import { Stack } from '@mui/material';
import moment from 'moment';

import { DialogWrapper, ItemDetails, Button } from '../../../components';
import { DataSource, Org } from '../../../utils/Types';
import { getDateFormat, isNotEmpty } from '../../../utils/Helper';

const DataSourceDetails = ({
  dataSource,
  onCancel,
  org,
}: {
  dataSource: DataSource;
  onCancel: () => void;
  org: Org;
}) => {
  const details: { label: any; value: any }[] = [
    {
      label: 'Name',
      value: dataSource.label,
    },
    {
      label: 'Created At',
      value: moment(dataSource.query?.createdAt).format(
        getDateFormat(org, 'default')
      ),
    },
    {
      label: 'Updated At',
      value: moment(dataSource.query?.updatedAt).format(
        getDateFormat(org, 'default')
      ),
    },
    {
      label: 'Data View',
      value: dataSource.query?.view,
    },
    {
      label: 'Conditions',
      value:
        dataSource.query?.sqlConditions &&
        JSON.stringify(dataSource.query?.sqlConditions) !== '"(1 = 1)"'
          ? dataSource.query?.sqlConditions
          : 'NONE',
    },
    {
      label: 'Columns Interested In',
      value: isNotEmpty(dataSource.query?.columnsInterested)
        ? dataSource.query?.columnsInterested.join(',\n')
        : 'ALL',
    },
  ];

  return (
    <DialogWrapper
      title="Data Source Information"
      onCancel={onCancel}
      hideCancelButton
    >
      <Stack>
        <ItemDetails data={details} />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={2}
        >
          <Button title="View Data" variant="outlined" onClick={() => {}} />
          <Button title="Edit" variant="outlined" onClick={() => {}} />
        </Stack>
      </Stack>
    </DialogWrapper>
  );
};

export default DataSourceDetails;
