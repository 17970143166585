import { Typography } from '@mui/material';
import {
  MRT_ColumnDef,
  MRT_RowData,
  MRT_TableOptions,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../redux/hooks';
import { selectOrg } from '../redux/reducers/orgSlice';
import { getDateFormat, isNotEmpty, isNumber } from '../utils/Helper';
import { Org, QueryResultColumn } from '../utils/Types';
import Skeleton from './Skeleton';

export const reSplitAlphaNumeric = /([0-9]+)/gm;

const numberFilter = (row: any, _columnIds: any, filterValue: any) => {
  const value = row.getValue(_columnIds);

  if (
    (isNotEmpty(filterValue[0]) || isNotEmpty(filterValue[1])) &&
    !isNumber(value)
  ) {
    return false;
  }

  if (
    filterValue[0] &&
    (!isNumber(filterValue[0]) || Number(filterValue[0]) > (value as number))
  ) {
    return false;
  }

  if (
    filterValue[1] &&
    (!isNumber(filterValue[1]) || Number(filterValue[1]) < (value as number))
  ) {
    return false;
  }
  return true;
};

const compareDateOnly = (aStr: string, bStr: string, org: Org) => {
  if (aStr === null || aStr === undefined) {
    return -1;
  }

  if (bStr === null || bStr === undefined) {
    return 1;
  }

  const dateA = moment(aStr, getDateFormat(org, 'short'));
  const dateB = moment(bStr, getDateFormat(org, 'short'));
  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

const compareDatetime = (aStr: string, bStr: string, org: Org) => {
  if (aStr === null || aStr === undefined) {
    return -1;
  }

  if (bStr === null || bStr === undefined) {
    return 1;
  }

  const dateA = moment(aStr, getDateFormat(org, 'default'));
  const dateB = moment(bStr, getDateFormat(org, 'default'));
  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

const Table = <TData extends MRT_RowData>({
  title,
  columns,
  columnDataTypes,
  renderTopToolbarCustomActions,
  muiTableBodyProps,
  muiTableHeadCellProps,
  muiTableBodyRowProps,
  muiTableBodyCellProps,
  defaultColumn,
  ...rest
}: MRT_TableOptions<TData> & {
  title?: string;
  columnDataTypes?: QueryResultColumn[];
}) => {
  const [optimisedColumns, setOptimisedColumns] = useState<
    MRT_ColumnDef<TData, any>[]
  >([]);

  const org = useAppSelector(selectOrg);

  useEffect(() => {
    const columnsWithMoreOptions = columns.map(i => {
      const i_withMoreOptions = { ...i }; // this is to make sure not overide the original options
      const columnDataType = columnDataTypes?.find(
        t => t.name === i.accessorKey
      );
      if (columnDataType?.type === 'number') {
        i_withMoreOptions.filterFn = 'number';
        i_withMoreOptions.filterVariant = 'range';
      }
      return { ...i_withMoreOptions, ...i };
    });

    setOptimisedColumns(columnsWithMoreOptions);
  }, [columns]);

  const table = useMaterialReactTable({
    columns: optimisedColumns,
    sortingFns: {
      dateOnly: (rowA: any, rowB: any, columnId: any): number =>
        compareDateOnly(
          rowA.getValue(columnId)?.toString().toLowerCase(),
          rowB.getValue(columnId)?.toString().toLowerCase(),
          org
        ),
      datetime: (rowA: any, rowB: any, columnId: any): number =>
        compareDatetime(
          rowA.getValue(columnId)?.toString().toLowerCase(),
          rowB.getValue(columnId)?.toString().toLowerCase(),
          org
        ),
    },
    filterFns: {
      number: numberFilter,
    },
    renderTopToolbarCustomActions:
      renderTopToolbarCustomActions ||
      (() => title && <Typography variant="h6">{title}</Typography>),
    renderEmptyRowsFallback: () => (
      <Typography style={{ textAlign: 'center', alignSelf: 'center' }}>
        No records to display
      </Typography>
    ),
    muiTableHeadCellProps: {
      sx: () => ({
        backgroundColor: 'grey.300',
      }),
      ...muiTableHeadCellProps,
    },
    muiTableBodyProps: {
      sx: () => ({
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: 'grey.100',
          },
      }),
      ...muiTableBodyProps,
    },
    muiTableBodyRowProps: props =>
      typeof muiTableBodyRowProps === 'function'
        ? {
            hover: false,
            ...muiTableBodyRowProps(props),
          }
        : {
            hover: false,
            ...muiTableBodyRowProps,
          },
    muiTableBodyCellProps: {
      sx: () => ({
        borderWidth: 0,
      }),
      ...muiTableBodyCellProps,
    },
    defaultColumn: defaultColumn || {
      minSize: 20,
      maxSize: 1000,
      size: 80,
    },
    displayColumnDefOptions: {
      'mrt-row-numbers': {
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: 'grey.300',
            width: 20,
          },
          ...muiTableHeadCellProps,
        },
        muiTableBodyCellProps: {
          sx: () => ({
            borderWidth: 0,
            width: 20,
          }),
          ...muiTableBodyCellProps,
        },
      },
    },
    ...rest,
  });

  return optimisedColumns.length === 0 ? (
    <Skeleton />
  ) : (
    <MaterialReactTable
      table={table} //only pass in table instead of all table options
    />
  );
};

export default Table;
