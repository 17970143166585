import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { CalendarMonth } from '@mui/icons-material';

import { DataTable, Form } from '../../../components';
import {
  TableRowProps,
  TableColumnProps,
  DateFormatServer,
  TableToolBarActionButtonCategory,
  FormData,
  FormInputCategory,
} from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import {
  compareServerDatesDesc,
  getDateFormat,
  getUniqTraits,
  isNotEmpty,
  logout,
  postToServer,
} from '../../../utils/Helper';
import { LooseObject } from '../../../utils/Types';
import ListOfImages from './ListOfImages';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import { MONTHS } from '../../../utils/Constants';
import ExportButton from './ExportButton';
import { selectUser } from '../../../redux/reducers/userSlice';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [summaries, setSummaries] = useState<LooseObject[]>([]);
  const [dateRange, setDateRange] = useState<{
    from: Moment | undefined;
    to: Moment | undefined;
  }>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const columns: TableColumnProps[] = [
    { id: 'id', label: 'ID', isSortable: true, hidden: true },
    {
      id: 'photoDate',
      label: 'Photo Date',
      isSortable: true,
      isDefalutOrderBy: true,
    },
    {
      id: 'numberOfImages',
      label: 'Number of Carcases',
      isSortable: true,
    },
  ];

  const allTraits = summaries?.map(i =>
    i.traits.map((j: any) => ({ key: j.key, label: j.label }))
  );

  const uniqTraits = getUniqTraits(allTraits);

  uniqTraits.forEach(i => {
    columns.push({
      id: i.key,
      label: i.label,
      isSortable: true,
      minWidth: 170,
    });
  });

  columns.push({
    id: 'actions',
    label: 'Actions',
    component: (item: TableRowProps) => (
      <ExportButton
        auth={auth}
        dispatch={dispatch}
        navigate={navigate}
        dateFrom={moment(item.row.id, getDateFormat(org, 'short')).format(
          DateFormatServer.SHORT
        )}
        org={org}
      />
    ),
  });

  const getSummariesInDateRange = (from: Moment, to: Moment) =>
    summaries.filter(i => {
      const photoDate = moment(i.photo_date, DateFormatServer.SHORT);
      return photoDate >= from && photoDate <= to;
    });

  let summariesToDisplay = summaries || [];

  if (dateRange?.from && dateRange?.to) {
    summariesToDisplay = getSummariesInDateRange(dateRange.from, dateRange.to);
  }

  const rows: TableRowProps[] = [];

  summariesToDisplay.forEach(item => {
    const row: TableRowProps = {
      id: moment(item.photo_date, DateFormatServer.SHORT).format(
        getDateFormat(org, 'short')
      ),
      photoDate: moment(item.photo_date, DateFormatServer.SHORT).format(
        getDateFormat(org, 'short')
      ),
      numberOfImages: item.number_of_images,
      actions: { isDependent: true },
    };
    uniqTraits.forEach(i => {
      row[i.key] = item.traits.find((j: any) => j.key === i.key)?.value;
    });
    rows.push(row);
  });

  const rowsToDisplay: TableRowProps[] = rows.filter(i => true);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'camera/SummaryImages',
        params: {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            if (
              Array.isArray(response.serverData) &&
              response.serverData.length > 0
            ) {
              let serverData = (response.serverData as LooseObject[])
                .filter(i => {
                  let flag = true;
                  if (
                    user?.roleConfig?.photoDates &&
                    Array.isArray(user?.roleConfig?.photoDates) &&
                    user?.roleConfig?.photoDates.length > 0
                  ) {
                    flag = user?.roleConfig?.photoDates.includes(i.photo_date);
                  }
                  return flag;
                })
                .sort((a, b) =>
                  compareServerDatesDesc({
                    a: a.photo_date,
                    b: b.photo_date,
                    dateFormat: DateFormatServer.SHORT,
                  })
                );

              if (org?.isUSA) {
                serverData.forEach(i => {
                  i.traits?.forEach((t: any) => {
                    t.label = t.label?.replaceAll('AUS', 'BMS');
                  });
                });
              }

              setSummaries(serverData);
            }
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const getListOfImages = (row: TableRowProps) => <ListOfImages data={row} />;

  const years: number[] = [];
  summaries.forEach(i => {
    const y = moment(i.photo_date, DateFormatServer.SHORT).year();
    if (!years.includes(y)) {
      years.push(y);
    }
  });

  const handleSubmit = ({
    year,
    month,
    photoDateFrom,
    photoDateTo,
  }: FormData) => {
    if (year && isNotEmpty(month)) {
      setDateRange({
        from: moment().set('year', year).set('month', month).startOf('month'),
        to: moment().set('year', year).set('month', month).endOf('month'),
      });
    }
    if (photoDateFrom && photoDateTo) {
      setDateRange({ from: photoDateFrom, to: photoDateTo });
    }
    handleClose();
  };

  return (
    <Stack mt={2}>
      {dateRange?.from && dateRange?.to && (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            variant="h6"
            fontSize={16}
          >{`Show images between ${dateRange.from.format(
            getDateFormat(org, 'short')
          )} and ${dateRange.to.format(
            getDateFormat(org, 'short')
          )}`}</Typography>
          <Button onClick={() => setDateRange(undefined)}>Clear</Button>
        </Stack>
      )}
      <DataTable
        dateFormat={getDateFormat(org, 'short')}
        loading={loading}
        title="Summary of Images by Photo Date"
        columns={columns}
        rows={rowsToDisplay}
        searchAttributes={['id']}
        collapsedComponent={getListOfImages}
        defaultRowsPerPage={25}
        actionButtons={[
          {
            category: TableToolBarActionButtonCategory.ALWAYS,
            component: (
              <Stack>
                <Tooltip title="Filter by Photo Date">
                  <IconButton onClick={handleClick}>
                    <CalendarMonth />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Stack m={2} spacing={1} maxWidth={340}>
                    <Form
                      fullWidth
                      loading={loading}
                      onSubmit={handleSubmit}
                      buttonText="Submit"
                      inputs={[
                        {
                          name: 'filterPhotosByMonth',
                          category: FormInputCategory.COMPONENT,
                          component: (
                            <Typography variant="h6" my={1}>
                              Filter photos by month
                            </Typography>
                          ),
                        },
                        {
                          name: 'year',
                          label: 'Year',
                          category: FormInputCategory.SELECT,
                          options: years.map(i => ({
                            label: i.toString(),
                            value: i,
                          })),
                        },
                        {
                          name: 'month',
                          label: 'Month',
                          category: FormInputCategory.SELECT,
                          options: MONTHS,
                        },
                        {
                          name: 'divider',
                          category: FormInputCategory.COMPONENT,
                          component: <Divider>Or</Divider>,
                        },
                        {
                          name: 'findPhotosInADateRange',
                          category: FormInputCategory.COMPONENT,
                          component: (
                            <Typography variant="h6" my={1}>
                              Find photos in a date range
                            </Typography>
                          ),
                        },
                        {
                          name: 'photoDate',
                          category: FormInputCategory.DATE_RANGE_PICKER,
                          disableFuture: true,
                          format: getDateFormat(org, 'short'),
                        },
                      ]}
                      maxWidth="sm"
                      itemSx={{ size: 'small', mb: 1 }}
                    />
                  </Stack>
                </Popover>
              </Stack>
            ),
          },
          {
            category: TableToolBarActionButtonCategory.ALWAYS,
            component:
              summariesToDisplay.length > 0 ? (
                <ExportButton
                  auth={auth}
                  dispatch={dispatch}
                  navigate={navigate}
                  dateFrom={
                    dateRange?.from?.format(DateFormatServer.SHORT) ||
                    summariesToDisplay[0].photo_date
                  }
                  dateTo={
                    dateRange?.to?.format(DateFormatServer.SHORT) ||
                    summariesToDisplay[summariesToDisplay.length - 1].photo_date
                  }
                  org={org}
                />
              ) : (
                <Stack />
              ),
          },
        ]}
      />
    </Stack>
  );
};

export default Page;
