import { useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { ChartType, DateFormatServer, LooseObject } from '../../../utils/Types';
import { Chart } from '../../../components';

type DatePoint = { n: number; u: 'days' | 'months' };

export const DATE_RANGE_KEYS = [
  '7-d',
  '14-d',
  '28-d',
  '1-m',
  '2-m',
  '4-m',
  '6-m',
  '12-m',
];
export const DATE_RANGE = DATE_RANGE_KEYS.map(i => {
  const n = Number(i.split('-')[0]);
  const u = i.split('-')[1] === 'd' ? 'days' : 'months';
  let datePoint: DatePoint = { n, u };
  return {
    key: i,
    label: `${n} ${_.startCase(n === 1 ? u.substring(0, u.length - 1) : u)}`,
    value: datePoint,
  };
});
DATE_RANGE.push({
  key: 'all',
  label: 'All',
  value: { n: Number.MAX_VALUE, u: 'days' },
});

const NotProbedHistogram = ({
  data,
  loading,
  updateCriteria,
}: {
  data: LooseObject[];
  loading: boolean;
  updateCriteria: (v: any) => void;
}) => {
  const [dateRange, setDateRange] = useState<string>(DATE_RANGE_KEYS[3]);

  const handleChangeDateRange = (event: SelectChangeEvent) => {
    if (event?.target?.value) {
      setDateRange(event.target.value);
    }
  };

  const dataToDisplay = data.filter(i => {
    if (dateRange === 'all') {
      return true;
    }
    const datePoint = DATE_RANGE.find(d => d.key === dateRange)?.value;

    if (datePoint) {
      return (
        moment(i.SLAUGHTER_DATE, DateFormatServer.SHORT) >=
        moment().add(-datePoint.n, datePoint.u)
      );
    }

    return false;
  });

  return (
    <Chart
      type={ChartType.BAR_CHART}
      title="% Not Probed by Date"
      headerBeforeLoading={
        <Stack
          direction="row"
          spacing={1}
          mt={1}
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          <FormControl size="small" sx={{ bgcolor: 'white', minWidth: 200 }}>
            <InputLabel>Date Range</InputLabel>
            <Select
              value={dateRange}
              label="Date Range"
              onChange={handleChangeDateRange}
            >
              {DATE_RANGE.map(i => (
                <MenuItem
                  key={'dateRange_' + i.key}
                  value={i.key || ''}
                  disabled={i.key === dateRange}
                >
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      }
      data={{
        xTitle: 'Slaughter Date',
        yTitle: '% Not Probed',
        data: [
          {
            x: dataToDisplay.map(i => i.SLAUGHTER_DATE),
            y: dataToDisplay.map(i => i.PERCENT_OF_NOT_PROBED),
            // hovertemplate: `<i>%{xaxis.title.text}</i>: %{label} <br /><i>%{yaxis.title.text}</i>: %{value}% <extra></extra>`,
          },
        ],
        yAxisRange: [0, 100],
      }}
      loading={loading}
      updateFilter={updateCriteria}
      dragMode="select"
    />
  );
};

export default NotProbedHistogram;
