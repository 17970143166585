import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { DataPoolItem } from '../../utils/Types';

export interface DataPoolState {
  value: DataPoolItem[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: DataPoolState = {
  value: [],
  status: 'idle',
};

export const dataPoolSlice = createSlice({
  name: 'dataPool',
  initialState,
  reducers: {
    setDataPool: (state, action) => {
      state.value = action.payload.payload;
    },
  },
});

export const { setDataPool } = dataPoolSlice.actions;

export const selectDataPool = (state: RootState) => state.dataPool.value;

export default dataPoolSlice.reducer;
