import { East, West } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

const ScanNavButton = ({
  title,
  onClick,
  disabled,
}: {
  title: string;
  onClick: () => void;
  disabled: boolean;
}) => (
  <Tooltip title={`${title} Scan`}>
    <Box>
      <IconButton size="small" onClick={onClick} disabled={disabled}>
        {title === 'Prev' ? <West /> : <East />}
      </IconButton>
    </Box>
  </Tooltip>
);

export default ScanNavButton;
