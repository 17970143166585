import { Chip } from '@mui/material';
import { Status as StatusType } from '../utils/Types';

interface props {
  status: StatusType;
}

const Status = ({ status }: props) => (
  <Chip
    variant="outlined"
    size="small"
    label={status}
    color={status === StatusType.SUSPENDED ? 'warning' : 'primary'}
  />
);

export default Status;
