import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';

import AppleStore from '../../../assets/images/apple-store.png';
import GooglePlay from '../../../assets/images/google-play.png';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { SnackbarContext } from '../../../utils/Contexts';
import { postToServer, logout, asyEncrypt } from '../../../utils/Helper';
import { LooseObject, User } from '../../../utils/Types';
import { setUser } from '../../../redux/reducers/userSlice';
import { CopyButton, OtpInput } from '../../../components';
import { RSA_PUBLIC_KEY } from '../../../utils/Constants';
import PhoneBg from '../../../assets/images/phone-bg.png';

type Props = {
  user: User;
  onCallback: (is2FA: boolean) => void;
};

const Set2FA = ({ user, onCallback }: Props) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [opt, setOpt] = React.useState<LooseObject>();
  const [otpToken, setOtpToken] = useState('');

  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    generateOptAuthSecret();
  }, []);

  const generateOptAuthSecret = () => {
    if (auth?.token) {
      postToServer({
        action: 'GenerateOptAuthSecret',
        params: {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            setOpt(response.serverData as LooseObject);
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleEnable = async () => {
    if (otpToken && otpToken.length === 6) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'SetOptAuth',
          params: {
            secret: await asyEncrypt({
              text: opt?.secret,
              pubKey: RSA_PUBLIC_KEY,
            }),
            otpToken,
          },
          token: auth.token,
        }).then(response => {
          setLoading(false);
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            if (response.message.type === 'success' && response.serverData) {
              // save user to current browser
              const updatedUser = { ...user, is2FA: true };
              localStorage.setItem('user', JSON.stringify(updatedUser));
              dispatch(
                setUser({
                  type: 'user/set',
                  payload: updatedUser,
                })
              );
              onCallback(true);
            }
            snackbar.open(response.message);
          }
        });
      }
    }
  };

  const steps = [
    {
      label: 'Download App',
      content: (
        <Stack alignItems="center">
          <Typography>
            Please download Google Authenticator to your mobile phone.
          </Typography>
          <Stack spacing={2} my={2}>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/app/google-authenticator/id388497605"
            >
              <img
                src={AppleStore}
                alt="Apple Store"
                width={isSmallScreen ? 150 : 270}
                height={isSmallScreen ? 50 : 90}
              />
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            >
              <img
                src={GooglePlay}
                alt="Google Play Store"
                width={isSmallScreen ? 150 : 270}
                height={isSmallScreen ? 50 : 90}
              />
            </Link>
          </Stack>
        </Stack>
      ),
    },
    {
      label: 'Scan QR Code',
      content: (
        <Stack alignItems="center">
          <Typography>
            Please launch your Google Authenticator app and scan this QR Code.
          </Typography>
          <Stack
            my={{ xs: 0, md: 1 }}
            spacing={{ xs: 1, md: 3 }}
            alignItems="center"
          >
            <Stack>
              <QRCodeSVG
                size={isSmallScreen ? 80 : 180}
                includeMargin={false}
                value={opt?.uri}
              />
            </Stack>
            <Stack alignItems="center">
              <Typography>Or enter this code manually into the app:</Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography fontSize={{ xs: 16, md: 18 }}>
                  {opt?.secret}
                </Typography>
                <CopyButton text={opt?.secret} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ),
    },
    {
      label: 'Enable 2FA',
      content: (
        <Stack alignItems="center">
          <Typography>
            Please enter the 6 digits showing in your Google Authenticator to
            enable 2FA.
          </Typography>
          <Stack my={2}>
            <OtpInput
              onChange={v => v && setOtpToken(v)}
              handleSubmit={handleEnable}
              isSmallScreen={isSmallScreen}
            />
          </Stack>
        </Stack>
      ),
    },
  ];

  return (
    <Stack alignItems="center">
      <Stack maxWidth="lg" alignItems="center">
        <Typography variant="h6" my={2} textAlign="center">
          Configure Your Two-factor Authentication
        </Typography>
        <Typography textAlign="center">
          Two-factor authentication (or 2-step authentication) is a security
          measure that adds a second layer of protection to verify online
          accounts. When you sign in, 2-Step verification helps make sure your
          personal information stays private, safe and secure.
        </Typography>
        <Stack mt={2}>
          <Stack
            sx={{
              backgroundImage: `url(${PhoneBg})`,
              width: { xs: 300, md: 480 },
              height: { xs: 430, md: 680 },
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              pt: { xs: 5, md: 10 },
              px: { xs: 1.1, md: 2 },
            }}
          >
            <Stepper activeStep={activeStep} sx={{ mx: { xs: 4, md: 8 } }}>
              {steps.map(step => (
                <Step key={step.label}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
            <Stack
              alignItems="center"
              mt={{ xs: 4, md: 6 }}
              height="100%"
              justifyContent="space-between"
            >
              <Stack alignItems="center">
                <Typography variant="h5">{steps[activeStep].label}</Typography>
                <Stack
                  alignItems="center"
                  mt={{ xs: 1, md: 2 }}
                  mx={2}
                  textAlign="center"
                >
                  {steps[activeStep].content}
                </Stack>
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                width="100%"
                ml={{ md: -0.5 }}
                mb={{ xs: 0.3, md: 0 }}
                height={{ xs: 36, md: 70 }}
              >
                <LoadingButton
                  variant="contained"
                  onClick={
                    activeStep === steps.length - 1 ? handleEnable : handleNext
                  }
                  sx={{
                    flex: 2,
                    borderRadius: 0,
                    fontSize: { xs: 16, md: 24 },
                  }}
                  loading={loading}
                >
                  {activeStep === steps.length - 1 ? 'Enable' : 'Continue'}
                </LoadingButton>
                {activeStep !== 0 && (
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      flex: 1,
                      borderRadius: 0,
                      fontSize: { xs: 16, md: 24 },
                      bgcolor: 'grey.300',
                    }}
                  >
                    Back
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Set2FA;
