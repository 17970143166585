import { DownloadForOfflineOutlined } from '@mui/icons-material';
import { Tooltip, IconButton, Stack, CircularProgress } from '@mui/material';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import {
  assignIds,
  delay,
  getDateFormat,
  getUniqTraits,
  logout,
  postToServer,
} from '../../../utils/Helper';
import {
  Auth,
  DateFormatServer,
  LooseObject,
  Org,
  Trait,
} from '../../../utils/Types';
import { SnackbarContext } from '../../../utils/Contexts';

const ExportButton = ({
  auth,
  dispatch,
  navigate,
  dateFrom,
  dateTo,
  org,
}: {
  auth: Auth;
  dispatch: any;
  navigate: any;
  dateFrom: string;
  dateTo?: string;
  org: Org;
}) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [csvFilename, setCsvFilename] = useState<string>('carcase_images.csv');
  const [allImages, setAllImages] = useState<LooseObject[]>([]);

  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  // make sure dateFrom < dateTo
  if (
    dateFrom &&
    dateTo &&
    moment(dateFrom, DateFormatServer.SHORT) >
      moment(dateTo, DateFormatServer.SHORT)
  ) {
    const temp = dateFrom;
    dateFrom = dateTo;
    dateTo = temp;
  }

  const downloadCsvFile = ({
    dateFrom,
    dateTo,
  }: {
    dateFrom: string;
    dateTo?: string;
  }) => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'camera/ListOfImages',
        params: { dateFrom, dateTo },
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            setCsvFilename(
              dateTo
                ? `carcase_images_${dateFrom}_to_${dateTo}.csv`
                : `carcase_images_${dateFrom}.csv`
            );

            const serverData = response.serverData as LooseObject[];
            if (org?.isUSA) {
              serverData.forEach(i => {
                i.traits?.forEach((t: any) => {
                  t.label = t.label?.replaceAll('AUS', 'BMS');
                  t.key = t.key?.replaceAll('aus', 'bms');
                });
              });
            }

            const images = assignIds(serverData);
            const allTraits = images.map(i =>
              i.traits.map((j: Trait) => ({ key: j.key, label: j.label }))
            );

            const uniqTraits = getUniqTraits(allTraits);

            const rows: LooseObject[] = [];
            // flatten images
            images.forEach(item => {
              const row: LooseObject = {
                barcode: item.barcode,
                photo_date: item.photo_date,
              };
              uniqTraits.forEach(i => {
                row[i.key] = item.traits.find(
                  (j: any) => j.key === i.key
                )?.value;
              });
              row.isBlurry = item.isBlurry;
              row.isBadImage = item.isBadImage;
              row.notes = item.notes;

              rows.push(row);
            });

            setAllImages(rows);
            await delay(1000);
            csvLink.current?.link.click();
          } else {
            snackbar.open(response.message);
          }

          setLoading(false);
        }
      });
    }
  };

  return (
    <Stack>
      <Tooltip
        placement="left"
        title={
          dateTo
            ? `Export data from ${moment(
                dateFrom,
                DateFormatServer.SHORT
              ).format(getDateFormat(org, 'short'))} to ${moment(
                dateTo,
                DateFormatServer.SHORT
              ).format(getDateFormat(org, 'short'))} as CSV file`
            : dateFrom
            ? `Export data as CSV file for ${moment(
                dateFrom,
                DateFormatServer.SHORT
              ).format(getDateFormat(org, 'short'))}`
            : 'Export data as CSV file'
        }
      >
        <IconButton
          onClick={() =>
            downloadCsvFile({
              dateFrom,
              dateTo,
            })
          }
          disabled={loading}
          sx={{ width: 40, height: 40 }}
        >
          {loading ? (
            <CircularProgress size={22} />
          ) : (
            <DownloadForOfflineOutlined />
          )}
        </IconButton>
      </Tooltip>
      <CSVLink
        data={allImages}
        filename={csvFilename}
        // headers={[{ key: 'photo_date', label: 'Photo Date' }]}
        ref={csvLink}
      ></CSVLink>
    </Stack>
  );
};

export default ExportButton;
