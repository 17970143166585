import { Grid, Stack } from '@mui/material';

import ItemDetailsRow, { ItemDetailsRowProps } from './ItemDetailsRow';

type Props = {
  data: ItemDetailsRowProps[];
  leftWidth?: number;
  px?: number;
};

const ItemDetails = ({ data, leftWidth, px }: Props) => (
  <Stack sx={{ px: px ?? 0 }}>
    <Grid container>
      {data.map((item, index) => (
        <ItemDetailsRow
          key={index}
          label={item.label}
          value={item.value}
          leftWidth={leftWidth}
        />
      ))}
    </Grid>
  </Stack>
);

export default ItemDetails;
