import { useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { Chart, Dialog, DialogWrapperInfo, Table } from '../../../components';
import {
  ChartPoint,
  ChartType,
  DateFormatServer,
  LooseObject,
} from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import {
  getDateFormat,
  isNotEmpty,
  logout,
  postToServer,
} from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import LotDetails from '../Yards/LotDetails';
import { selectOrg } from '../../../redux/reducers/orgSlice';

type ServerData = {
  lots: LooseObject[];
  averageUSDAScoreCompanyOverall: number | undefined;
  averageDailyIncreaseInUSDAScoreCompanyOverall: number | undefined;
};

export const DemoLocations = {
  Mesquite: 'Yard 1',
  Horton: 'Yard 2',
  Yellowstone: 'Yard 3',
  Bovina: 'Yard 4',
};

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [lotsWithLatestScan, setLotsWithLatestScan] = useState<ServerData>({
    lots: [],
    averageUSDAScoreCompanyOverall: undefined,
    averageDailyIncreaseInUSDAScoreCompanyOverall: undefined,
  });
  const [openDialogLotDetails, setOpenDialogLotDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>();

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'feedyard/GetLotsWithLatestScanAndShipments',
        params: {},
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as ServerData;
            setLotsWithLatestScan(serverData);
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  type DatePoint = { n: number; u: 'days' | 'months' };
  const groupLots = (datePoint: DatePoint) => {
    const group =
      lotsWithLatestScan.lots.filter(
        i =>
          isNotEmpty(i.AVG_DAYS_ON_FEED) && // make sure the lot was scanned
          i.SHIPPING_DATE &&
          moment(i.SHIPPING_DATE, DateFormatServer.SHORT) <
            moment().add(datePoint.n, datePoint.u)
      ) || [];

    const totalHeadcount = _.sumBy(
      group.filter(i => isNotEmpty(i.HEADCOUNT_YARDSHEET)),
      'HEADCOUNT_YARDSHEET'
    );
    const ultra = _.sumBy(
      group.filter(i => isNotEmpty(i.COUNT_OF_ULTRA)),
      'COUNT_OF_ULTRA'
    );

    const fiveStar = _.sumBy(
      group.filter(i => isNotEmpty(i.COUNT_OF_5_STAR)),
      'COUNT_OF_5_STAR'
    );
    const fourStar = _.sumBy(
      group.filter(i => isNotEmpty(i.COUNT_OF_4_STAR)),
      'COUNT_OF_4_STAR'
    );
    const choice = _.sumBy(
      group.filter(i => isNotEmpty(i.COUNT_OF_CHOICE)),
      'COUNT_OF_CHOICE'
    );
    const wagyu = _.sumBy(
      group.filter(i => isNotEmpty(i.COUNT_OF_WAGYU)),
      'COUNT_OF_WAGYU'
    );
    const groupWithNonEmptyUSDA = group.filter(i =>
      isNotEmpty(i.AVG_USDA_SCORE)
    );
    const usda =
      groupWithNonEmptyUSDA.length > 0
        ? Number.parseFloat(
            (
              _.sum(
                groupWithNonEmptyUSDA.map(
                  i => i.AVG_USDA_SCORE * i.HEADCOUNT_SCANNED
                )
              ) / _.sumBy(groupWithNonEmptyUSDA, 'HEADCOUNT_SCANNED')
            ).toFixed(2)
          )
        : null;

    return { totalHeadcount, ultra, fiveStar, fourStar, choice, wagyu, usda };
  };

  const d7 = groupLots({ n: 7, u: 'days' });
  const d14 = groupLots({ n: 14, u: 'days' });
  const d21 = groupLots({ n: 21, u: 'days' });
  const m1 = groupLots({ n: 1, u: 'months' });
  const m2 = groupLots({ n: 2, u: 'months' });
  const m3 = groupLots({ n: 3, u: 'months' });
  const m4 = groupLots({ n: 4, u: 'months' });
  const m5 = groupLots({ n: 5, u: 'months' });
  const m6 = groupLots({ n: 6, u: 'months' });

  const columns = [
    { accessorKey: 'label', header: 'Time Before Shipping', minSize: 200 },
    { accessorKey: 'd7', header: '7 Days', maxSize: 100 },
    { accessorKey: 'd14', header: '14 Days', maxSize: 100 },
    { accessorKey: 'd21', header: '21 Days', maxSize: 100 },
    { accessorKey: 'm1', header: '1 Month', maxSize: 100 },
    { accessorKey: 'm2', header: '2 Months', maxSize: 100 },
    { accessorKey: 'm3', header: '3 Months', maxSize: 100 },
    { accessorKey: 'm4', header: '4 Months', maxSize: 100 },
    { accessorKey: 'm5', header: '5 Months', maxSize: 100 },
    { accessorKey: 'm6', header: '6 Months', maxSize: 100 },
  ];

  const rows = [
    {
      label: 'Total Headcount Yardsheet',
      d7: d7.totalHeadcount,
      d14: d14.totalHeadcount,
      d21: d21.totalHeadcount,
      m1: m1.totalHeadcount,
      m2: m2.totalHeadcount,
      m3: m3.totalHeadcount,
      m4: m4.totalHeadcount,
      m5: m5.totalHeadcount,
      m6: m6.totalHeadcount,
    },
    {
      label: 'Average USDA Score',
      d7: d7.usda,
      d14: d14.usda,
      d21: d21.usda,
      m1: m1.usda,
      m2: m2.usda,
      m3: m3.usda,
      m4: m4.usda,
      m5: m5.usda,
      m6: m6.usda,
    },
  ];

  if (auth?.orgOwner === 'mishima') {
    rows.splice(
      rows.length - 1,
      0,
      ...[
        {
          label: 'Ultra',
          d7: d7.ultra,
          d14: d14.ultra,
          d21: d21.ultra,
          m1: m1.ultra,
          m2: m2.ultra,
          m3: m3.ultra,
          m4: m4.ultra,
          m5: m5.ultra,
          m6: m6.ultra,
        },
        {
          label: '5 Star',
          d7: d7.fiveStar,
          d14: d14.fiveStar,
          d21: d21.fiveStar,
          m1: m1.fiveStar,
          m2: m2.fiveStar,
          m3: m3.fiveStar,
          m4: m4.fiveStar,
          m5: m5.fiveStar,
          m6: m6.fiveStar,
        },
        {
          label: '4 Star',
          d7: d7.fourStar,
          d14: d14.fourStar,
          d21: d21.fourStar,
          m1: m1.fourStar,
          m2: m2.fourStar,
          m3: m3.fourStar,
          m4: m4.fourStar,
          m5: m5.fourStar,
          m6: m6.fourStar,
        },
        {
          label: 'Choice',
          d7: d7.choice,
          d14: d14.choice,
          d21: d21.choice,
          m1: m1.choice,
          m2: m2.choice,
          m3: m3.choice,
          m4: m4.choice,
          m5: m5.choice,
          m6: m6.choice,
        },
        {
          label: 'Wagyu',
          d7: d7.wagyu,
          d14: d14.wagyu,
          d21: d21.wagyu,
          m1: m1.wagyu,
          m2: m2.wagyu,
          m3: m3.wagyu,
          m4: m4.wagyu,
          m5: m5.wagyu,
          m6: m6.wagyu,
        },
      ]
    );
  }

  const lotsClosestToShipping30 = _.orderBy(
    lotsWithLatestScan.lots.filter(
      i =>
        i.SHIPPING_DATE &&
        moment(i.SHIPPING_DATE, DateFormatServer.SHORT) >=
          moment().startOf('day')
    ) || [],
    'SHIPPING_DATE',
    'asc'
  ).slice(0, 30);

  // const lotsClosestToShipping30WithUSDAandScannedCount =
  //   lotsClosestToShipping30.filter(
  //     i => isNotEmpty(i.AVG_USDA_SCORE) && isNotEmpty(i.HEADCOUNT_SCANNED)
  //   );
  // const usdaAverageClosestToShipping30 =
  //   lotsClosestToShipping30WithUSDAandScannedCount.length > 0
  //     ? Number.parseFloat(
  //         (
  //           _.sum(
  //             lotsClosestToShipping30WithUSDAandScannedCount.map(
  //               i => i.AVG_USDA_SCORE * i.HEADCOUNT_SCANNED
  //             )
  //           ) /
  //           _.sumBy(
  //             lotsClosestToShipping30WithUSDAandScannedCount,
  //             'HEADCOUNT_SCANNED'
  //           )
  //         ).toFixed(2)
  //       )
  //     : undefined;

  const handleClickPoint = async ({ x, y }: ChartPoint) => {
    const item = lotsClosestToShipping30.find(
      i => i.LOT === x && i.AVG_USDA_SCORE === y
    );
    if (item) {
      setCurrentItem(item);
      setOpenDialogLotDetails(true);
    }
  };

  return (
    <Stack spacing={2} pt={2}>
      <Chart
        type={ChartType.SCATTER_PLOT}
        title={
          <Stack justifyContent="center" alignItems="center">
            <Typography variant="h6" textAlign="center">
              Lots Closest To Shipping
            </Typography>
            <Typography textAlign="center">(USDA Score Required)</Typography>
          </Stack>
        }
        data={{
          xTitle: 'Lot',
          yTitle: 'Average USDA Score',
          xAxisType: 'category',
          data: [
            {
              x: lotsClosestToShipping30.map(i => i.LOT),
              y: lotsClosestToShipping30.map(i => i.AVG_USDA_SCORE),
              name: 'Lot',
              customdata: lotsClosestToShipping30.map(i =>
                auth?.orgOwner === 'mishima'
                  ? i.FEEDLOT
                  : DemoLocations[i.FEEDLOT]
              ),
              text: lotsClosestToShipping30.map(i =>
                i.SHIPPING_DATE
                  ? moment(i.SHIPPING_DATE, DateFormatServer.SHORT).format(
                      getDateFormat(org, 'short')
                    )
                  : ''
              ),
              hovertemplate: `<i>Feedlot</i>: %{customdata} <br /><i>%{xaxis.title.text}</i>: %{x} <br /><i>Shipping Date</i>: %{text} <br /><i>%{yaxis.title.text}</i>: %{y} <extra></extra>`,
            },
            // {
            //   x: lotsClosestToShipping30.map(i => i.LOT),
            //   y: Array.from(Array(lotsClosestToShipping30.length).keys()).map(
            //     () => lotsWithLatestScan.averageUSDAScoreCompanyOverall
            //   ),
            //   name: 'Company Overall Average',
            //   mode: 'lines',
            //   line: { dash: 'dash' },
            //   hovertemplate: `<i>Company Overall Average %{yaxis.title.text}</i>: %{y} <extra></extra>`,
            // },
            // {
            //   x: lotsClosestToShipping30.map(i => i.LOT),
            //   y: Array.from(Array(lotsClosestToShipping30.length).keys()).map(
            //     () => usdaAverageClosestToShipping30
            //   ),
            //   name: '30 Closest Average',
            //   mode: 'lines',
            //   line: { dash: 'dash' },
            //   hovertemplate: `<i>30 Closest Average %{yaxis.title.text}</i>: %{y} <extra></extra>`,
            // },
          ],
        }}
        loading={loading}
        handleClick={handleClickPoint}
      />
      <Table
        columns={columns}
        data={loading ? [] : rows}
        state={{ isLoading: loading }}
        enableSorting={false}
        enableColumnActions={false}
        enableRowActions={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        layoutMode="grid"
        initialState={{ density: 'compact' }}
      />
      <Dialog
        open={openDialogLotDetails}
        onClose={() => setOpenDialogLotDetails(false)}
        isTransparent
      >
        <DialogWrapperInfo onClose={() => setOpenDialogLotDetails(false)}>
          {currentItem && (
            <LotDetails
              lot={currentItem}
              loadLots={fetchData}
              averageDailyIncreaseInUSDAScoreCompanyOverall={
                lotsWithLatestScan.averageDailyIncreaseInUSDAScoreCompanyOverall
              }
              auth={auth}
              snackbar={snackbar}
              org={org}
            />
          )}
        </DialogWrapperInfo>
      </Dialog>
    </Stack>
  );
};

export default Page;
