import { Stack, Typography } from '@mui/material';
import _ from 'lodash';

import { NoDataView, Table } from '../../../../components';
import { QueryResultColumn } from '../../../../utils/Types';

const ScanTable = ({
  columns,
  rows,
}: {
  columns: QueryResultColumn[] | undefined;
  rows: any[];
}) => {
  const excludeColumns = ['VIDEO_URL'];

  if (!columns) {
    return <NoDataView bgcolor="grey.100" />;
  }

  const tableColumns: any[] = columns
    .filter(i => !excludeColumns.includes(i.name))
    .map(i => ({
      accessorKey: i.name,
      header: _.startCase(i.name),
    }));

  return (
    <Stack minHeight={200} minWidth="50vw" bgcolor="grey.50">
      <Table
        renderTopToolbarCustomActions={() => (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h6">{`List Of Scans (${rows.length} items in total)`}</Typography>
          </Stack>
        )}
        columns={tableColumns}
        columnDataTypes={columns}
        data={rows}
        enableColumnActions={false}
        enableHiding={false}
        enableColumnResizing
        enableDensityToggle={false}
        enableBottomToolbar={false}
        enablePagination={false}
        enableStickyHeader
        muiTableContainerProps={{ sx: { maxHeight: '80vh' } }}
        enableRowNumbers
        rowNumberDisplayMode="original"
        initialState={{
          density: 'compact',
          // pagination: { pageSize: 20, pageIndex: 0 },
        }}
        layoutMode="grid"
        defaultColumn={{
          minSize: 20,
          maxSize: 1000,
          size: 150,
        }}
      />
    </Stack>
  );
};

export default ScanTable;
