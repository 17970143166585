import { CircularProgress, Stack, Typography } from '@mui/material';

const Page = () => (
  <Stack height="100vh">
    <Stack flex={1} width="100vw" height={20}></Stack>
    <Stack
      flex={1}
      width="100vw"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Typography color="background.default" variant="h5">
        Please wait ...
      </Typography>
      <CircularProgress size={50} />
    </Stack>
    <Stack flex={2} width="100vw" height={20}></Stack>
  </Stack>
);

export default Page;
