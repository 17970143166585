import { Button } from '../../../components';

export type ButtonTabTitle = 'Tables' | 'Charts';

const ButtonTab = ({
  title,
  selected,
  onClick,
}: {
  title: ButtonTabTitle;
  selected: ButtonTabTitle;
  onClick: (v: ButtonTabTitle) => void;
}) => (
  <Button
    title={title}
    variant={selected === title ? 'contained' : 'outlined'}
    onClick={() => onClick(title)}
  />
);

export default ButtonTab;
