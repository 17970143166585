import * as Yup from 'yup';
import validator from 'validator';

import {
  LooseObject,
  MessageText,
  FormInputItem,
  PasswordLength,
} from '../../utils/Types';

const getValidateOptions = (fieldsWithContext: FormInputItem[]) => {
  const options: LooseObject = {};
  if (
    fieldsWithContext &&
    Array.isArray(fieldsWithContext) &&
    fieldsWithContext.length > 0
  ) {
    const fields = fieldsWithContext.map(
      item => !item.avoidValidation && item.name
    );
    if (fields.includes('orgId')) {
      options.orgId = Yup.number()
        .typeError(MessageText.NUMBER_ONLY)
        .required(MessageText.INPUT_ERROR_REQUIRED)
        .positive(MessageText.INPUT_ERROR_POSITIVE_NUMBER);
    }
    if (fields.includes('orgName')) {
      options.orgName = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes('name')) {
      options.name = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes('firstName')) {
      options.firstName = Yup.string().required(
        MessageText.INPUT_ERROR_REQUIRED
      );
    }
    if (fields.includes('lastName')) {
      options.lastName = Yup.string().required(
        MessageText.INPUT_ERROR_REQUIRED
      );
    }
    if (fields.includes('email')) {
      options.email = Yup.string()
        .required(MessageText.INPUT_ERROR_REQUIRED)
        .test('isValidEmail', MessageText.INPUT_ERROR_EMAIL, v =>
          validator.isEmail(v)
        );
    }
    if (fields.includes('password')) {
      options.password = Yup.string()
        .required(MessageText.INPUT_ERROR_REQUIRED)
        .min(
          PasswordLength.MIN,
          `At least ${PasswordLength.MIN} characters please`
        )
        .max(
          PasswordLength.MAX,
          `No more than ${PasswordLength.MAX} characters please`
        );
    }
    if (fields.includes('oldPassword')) {
      options.oldPassword = Yup.string()
        .required(MessageText.INPUT_ERROR_REQUIRED)
        .min(
          PasswordLength.MIN,
          `At least ${PasswordLength.MIN} characters please`
        )
        .max(
          PasswordLength.MAX,
          `No more than ${PasswordLength.MAX} characters please`
        );
    }
    if (fields.includes('passwordAgain')) {
      options.passwordAgain = Yup.string()
        .required(MessageText.INPUT_ERROR_REQUIRED)
        .min(
          PasswordLength.MIN,
          `At least ${PasswordLength.MIN} characters please`
        )
        .max(
          PasswordLength.MAX,
          `No more than ${PasswordLength.MAX} characters please`
        )
        .test('isPasswordMatch', 'Password does not match', function (value) {
          return this.parent.password === value;
        });
    }
    if (fields.includes('role')) {
      options.role = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes('owner')) {
      options.owner = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes('logo')) {
      options.logo = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes('theme')) {
      options.theme = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes('description')) {
      options.description = Yup.string().required(
        MessageText.INPUT_ERROR_REQUIRED
      );
    }
    if (fields.includes('notes')) {
      options.notes = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes('title')) {
      options.title = Yup.string().required(MessageText.INPUT_ERROR_REQUIRED);
    }
    if (fields.includes('itemType')) {
      options.itemType = Yup.string().required(
        MessageText.INPUT_ERROR_REQUIRED
      );
    }
    if (fields.includes('dataSourceKey')) {
      options.dataSourceKey = Yup.string().required(
        MessageText.INPUT_ERROR_REQUIRED
      );
    }
  }

  return options;
};

const getValidationSchema = (fields: FormInputItem[]) =>
  Yup.object().shape(getValidateOptions(fields));

export default getValidationSchema;
