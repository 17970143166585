import _ from 'lodash';
import { Moment } from 'moment';

import { ChartType, LooseObject, Org } from '../../../utils/Types';
import { Shift, DataView } from './GraphArea';
import { getDateFormat } from '../../../utils/Helper';
import { Chart } from '../../../components';
import { HISTOGRAM_Y_AXIS_TITLE } from '../../../utils/Constants';

type Props = {
  dataView: DataView;
  date?: Moment | null;
  shift?: Shift;
  facilities?: string[];
  data?: LooseObject;
  loading: boolean;
  org: Org;
};

const chartTraceColors = ['#464646', '#232378', '#D9D9D9'];

const View = ({
  dataView,
  date,
  shift,
  facilities,
  data,
  loading,
  org,
}: Props) => (
  <Chart
    type={ChartType.BAR_CHART}
    title={`${_.startCase(dataView)} View - ${
      (facilities?.length || 0) > 0
        ? // ? `[${facilities?.map(o => _.startCase(o)).join(' ')}] - `
          `[${facilities?.map(o => 'Site #1').join(' ')}] - `
        : ''
    }${date?.format(getDateFormat(org, 'short'))}`}
    data={{
      xTitle: 'Brands',
      yTitle: HISTOGRAM_Y_AXIS_TITLE,
      data: data?.histogram_data?.y_values.map((y: number, index: number) => ({
        x: data.histogram_data.x_labels,
        y,
        name: data.histogram_data.column_names[index],
        nameX: 'Brands',
      })),
    }}
    loading={loading}
    chartTraceColors={chartTraceColors}
    style={{ minHeight: '60vh' }}
  />
);

export default View;
