import { useEffect, useState } from 'react';
import validator from 'validator';
// import { InputAdornment } from '@mui/material';

import {
  Auth,
  DateFormatServer,
  FormInputCategory,
  FormInputItem,
  LooseObject,
  Org,
  User,
  UserRole,
} from '../../../utils/Types';
import {
  Form,
  DialogWrapper,
  // CopyButton,
  // ShowPasswordButton,
} from '../../../components';
import {
  compareServerDatesDesc,
  // getRandomPassword,
  getUserRoleNames,
  postToServer,
} from '../../../utils/Helper';
import { DEMO_ORGS } from '../../../utils/Constants';

type Props = {
  onConfirm: (values: any) => void;
  onCancel: (values: any) => void;
  auth: Auth;
  org?: Org;
  loading: boolean;
  isAdministration?: boolean;
};

const Add = ({
  onConfirm,
  onCancel,
  auth,
  org,
  loading,
  isAdministration,
}: Props) => {
  const [fetching, setFetching] = useState(false);

  const [target, setTarget] = useState<User>();
  const [summaries, setSummaries] = useState<LooseObject[]>([]);

  const [email, setEmail] = useState<string>();
  // const [newUserPassWord, setNewUserPassWord] = useState<string>(
  //   getRandomPassword()
  // );
  // const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    fetchData();
  }, [email]);

  useEffect(() => {
    fetchAvailableDatesInCamera();
  }, []);

  const fetchData = () => {
    if (email && validator.isEmail(email) && auth?.token) {
      setFetching(true);
      postToServer({
        action: 'GetUser',
        params: { email: email.toLowerCase() },
        token: auth.token,
      }).then(response => {
        setFetching(false);
        if (response.message.type === 'success' && response.serverData) {
          setTarget(response.serverData as User);
        } else {
          setTarget(null);
        }
      });
    }
  };

  const fetchAvailableDatesInCamera = () => {
    if (org && DEMO_ORGS.includes(org.owner) && auth?.token) {
      postToServer({
        action: 'camera/SummaryImages',
        params: {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            if (
              Array.isArray(response.serverData) &&
              response.serverData.length > 0
            ) {
              setSummaries(
                (response.serverData as LooseObject[]).sort((a, b) =>
                  compareServerDatesDesc({
                    a: a.photo_date,
                    b: b.photo_date,
                    dateFormat: DateFormatServer.SHORT,
                  })
                )
              );
            }
          }
        }
      });
    }
  };

  let inputs: FormInputItem[] = [
    {
      name: 'email',
      label: 'Email',
      category: FormInputCategory.TEXT_INPUT,
      exposeValue: value => setEmail(value),
    },
  ];

  const isNewUser = !(email && validator.isEmail(email) && target);

  if (isNewUser) {
    inputs = inputs.concat([
      {
        name: 'firstName',
        label: 'First Name',
        category: FormInputCategory.TEXT_INPUT,
      },
      {
        name: 'lastName',
        label: 'Last Name',
        category: FormInputCategory.TEXT_INPUT,
      },
      // {
      //   name: 'password',
      //   label: 'Password',
      //   category: FormInputCategory.TEXT_INPUT,
      //   type: showPassword ? 'text' : 'password',
      //   defaultValue: newUserPassWord,
      //   exposeValue: val => setNewUserPassWord(val),
      //   inputProps: {
      //     endAdornment: (
      //       <InputAdornment position="end">
      //         <CopyButton text={newUserPassWord} />
      //         <ShowPasswordButton
      //           onClick={() => setShowPassword(show => !show)}
      //         />
      //       </InputAdornment>
      //     ),
      //   },
      // },
      // {
      //   name: 'passwordAgain',
      //   label: 'Password Again',
      //   category: FormInputCategory.TEXT_INPUT,
      //   type: showPassword ? 'text' : 'password',
      //   defaultValue: newUserPassWord,
      // },
      // {
      //   name: 'isValid',
      //   label: 'Active',
      //   defaultValue: true,
      //   category: FormInputCategory.SWITCH,
      // },
      // {
      //   name: 'isSendEmail',
      //   label: 'Email login credentials to user',
      //   defaultValue: false,
      //   category: FormInputCategory.SWITCH,
      // },
    ]);
  }

  inputs.splice(inputs.length, 0, {
    name: 'role',
    label: 'Role',
    category: FormInputCategory.SELECT,
    options: getUserRoleNames({
      min: UserRole.ORGANISATION_USER,
      max: UserRole.ORGANISATION_ADMIN,
    }),
  });

  if (org && DEMO_ORGS.includes(org.owner)) {
    inputs.splice(inputs.length, 0, {
      name: 'photoDates',
      label: 'Photo Dates To Show In Camera',
      helperText: 'If empty, the user will see all dates available after login',
      category: FormInputCategory.SELECT,
      options: summaries.map(i => ({
        label: i.photo_date,
        value: i.photo_date,
      })),
      multiple: true,
      selectAll: true,
    });
  }

  return (
    <DialogWrapper
      title="Add a New User"
      onCancel={onCancel}
      hideCancelButton
      hideConfirmButton
    >
      <Form
        loading={loading || fetching}
        onSubmit={onConfirm}
        buttonText="Submit"
        inputs={inputs}
      />
    </DialogWrapper>
  );
};

export default Add;
