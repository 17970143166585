import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import _ from 'lodash';

import { LooseObject } from '../../../../utils/Types';

const TableView = ({ scan }: { scan: LooseObject }) => {
  const excludeColumns = ['VIDEO_URL', 'WEBCAM_URL'];

  return (
    <Table size="small">
      <TableBody>
        {Object.keys(scan)
          .filter(i => !excludeColumns.includes(i))
          .sort()
          .map(k => (
            <TableRow
              key={k}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {_.startCase(k)}
              </TableCell>
              <TableCell align="right">{scan[k]}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default TableView;
