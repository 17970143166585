import Button, { ButtonProps } from '.';

const SmallButton = (props: ButtonProps) => (
  <Button
    size="small"
    sx={{
      p: 0.2,
      fontSize: 12,
    }}
    {...props}
  />
);

export default SmallButton;
