import { useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { Moment } from 'moment';

import { ChartPoint, ChartType, LooseObject } from '../../../utils/Types';
import { Shift, DataView } from './GraphArea';
import { Chart, ToggleButton } from '../../../components';
import { CHART_BACKGROUND_COLOR } from '../../../utils/Constants';
import { getDateFormat } from '../../../utils/Helper';
import { useAppSelector } from '../../../redux/hooks';
import { selectOrg } from '../../../redux/reducers/orgSlice';

enum ViewType {
  PERCENTAGE = 'Percentage',
  VALUE = 'Counts',
}

type Props = {
  dataView: DataView;
  date?: Moment | null;
  shift?: Shift;
  facilities?: string[];
  data: LooseObject[];
  loading: boolean;
};

// const chartTraceColors = [
//   '#00812E',
//   '#7FC096',
//   '#464646',
//   '#7D7D7D',
//   '#E4E5E8',
//   '#008170',
//   '#4CA79B',
//   '#01554A',
//   '#013D33',
// ];

const chartTraceColors = [
  '#D4EEFF',
  '#A2E0FF',
  '#66D3FF',
  '#38BDF8',
  '#EAF2F8',
  '#ADC0DB',
  '#79A0C1',
  '#4D648D',
  '#232378',
];

const View = ({ dataView, date, shift, facilities, data, loading }: Props) => {
  const [viewType, setViewType] = useState(ViewType.PERCENTAGE);
  const [sortBy, setSortBy] = useState<string>('');
  const [selectedHotboxes, setSelectedHotboxes] = useState<string[]>([]);
  const [groupedData, setGroupedData] = useState<
    { brand_desc: any; count: number }[] | null
  >(null);

  const org = useAppSelector(selectOrg);

  const LABEL_PREFIX = 'Hotbox ';

  // const orderedLegend = [
  //   'HRI_Prime',
  //   'CAB_Choice',
  //   'SS_Choice',
  //   'AP_Choice',
  //   'RRA_Choice',
  //   'XL CH MS_Choice',
  //   'HRI_Choice',
  //   'HRI_Select',
  //   'RRA_Select',
  // ];
  const orderedLegend = [
    'Prime',
    'Cert. Angus',
    'Upp 2/3 Choice',
    'Choice 1',
    'Choice 2',
    'Choice 3',
    'Choice 4',
    'Select 1',
    'Select 2',
  ];
  data = _.sortBy(data, o => -orderedLegend.indexOf(o.brand_desc));

  const handleChangeViewType = (
    event: React.MouseEvent<HTMLElement>,
    newView: ViewType
  ) => {
    if (newView) {
      setViewType(newView);
    }
  };

  const handleSortByBrand = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);
  };

  const handleClickHotbox = ({ x }: ChartPoint) => {
    const newHotbox = x as string;
    if (selectedHotboxes.includes(newHotbox)) {
      setSelectedHotboxes(selectedHotboxes.filter(item => item !== newHotbox));
    } else {
      setSelectedHotboxes([...selectedHotboxes, newHotbox]);
    }
  };

  const handleGroup = () => {
    // brand by brand add the hotboxes together
    const selectedOriginalIndexes = selectedHotboxes.map(
      i => Number(i.substring(LABEL_PREFIX.length)) - 1
    );
    const groupedDataOfSelected = data.map((i, index) => {
      let count = 0;
      selectedOriginalIndexes.forEach(j => (count += data[index].counts[j]));
      return { brand_desc: i.brand_desc, count };
    });
    setGroupedData(groupedDataOfSelected);
  };

  // ------------- change viewType start -------------
  const totalsOfEachHotbox = new Array(data?.[0].counts.length).fill(0); // inital array with 0 to all elements
  for (let i = 0; i < data?.[0].counts.length; i++) {
    for (let j = 0; j < data.length; j++) {
      totalsOfEachHotbox[i] += data[j].counts[i];
    }
  }

  for (let i = 0; i < data.length; i++) {
    const percents = new Array(data?.[i].counts.length).fill(0);
    for (let j = 0; j < data?.[i].counts.length; j++) {
      percents[j] = ((data[i].counts[j] / totalsOfEachHotbox[j]) * 100).toFixed(
        2
      );
    }
    data[i].percents = percents;
  }
  // ------------- change viewType end -------------

  // ------------- sort by brand start -------------
  const defaultIndexes = Array.from(Array(data?.[0].counts.length).keys());

  const brand = data?.find(i => i.brand_desc === sortBy);
  const arrayToSort =
    viewType === ViewType.VALUE ? brand?.counts : brand?.percents;

  let sortedIndexes = defaultIndexes;
  if (arrayToSort) {
    // get original indexes after sort an array
    sortedIndexes = Array.from(arrayToSort.keys()).sort(
      (a: unknown, b: unknown): number =>
        arrayToSort[b as number] - arrayToSort[a as number]
    ) as number[];
  }
  const categoryarray = sortedIndexes.map(i => `${LABEL_PREFIX}${i + 1}`);
  // ------------- sort by brand end -------------

  // ------------- group start -------------

  const totalsOfEachBrand = data.map(i => ({
    brand_desc: i.brand_desc,
    count: _.sum(i.counts),
  }));

  const remaningOfEachBrand = totalsOfEachBrand.map(
    (i, index) => i.count - (groupedData?.[index].count || 0)
  );
  // ------------- group end -------------

  return (
    <Stack display="flex" alignItems="center">
      <Chart
        type={ChartType.BAR_CHART}
        title={`${_.startCase(dataView)} View - [${facilities
          // ?.map(o => _.startCase(o))
          ?.map(o => 'Site #1')
          .join(' ')}] - ${date?.format(
          getDateFormat(org, 'short')
        )} - ${viewType}`}
        header={
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            mt={2}
            px={1}
          >
            <FormControl sx={{ minWidth: 200 }} size="small">
              <InputLabel>Sort by brand</InputLabel>
              <Select
                value={sortBy}
                label="Sort by brand"
                onChange={handleSortByBrand}
              >
                <MenuItem value="">None</MenuItem>
                {data
                  .slice(0) // make a shallow copy of it then map of the reversed array
                  .reverse()
                  .map(i => (
                    <MenuItem key={i.brand_desc} value={i.brand_desc}>
                      {i.brand_desc}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <ToggleButtonGroup
              value={viewType}
              exclusive
              onChange={handleChangeViewType}
              aria-label="View"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
              size="small"
            >
              <ToggleButton value={ViewType.PERCENTAGE}>
                Percentage
              </ToggleButton>
              <ToggleButton value={ViewType.VALUE}>Counts</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        }
        data={{
          xTitle: 'Hotboxes',
          yTitle: `${
            viewType === ViewType.PERCENTAGE ? '%' : 'Counts'
          } of Grade`,
          data: data.map((item: LooseObject) => ({
            x: defaultIndexes.map((i: any) => `${LABEL_PREFIX}${i + 1}`),
            y: viewType === ViewType.PERCENTAGE ? item.percents : item.counts,
            name: item.brand_desc,
            nameX: 'Hotboxes',
            type: 'bar',
          })),
          xCategoryOrder: 'array',
          xCategoryArray: categoryarray,
        }}
        loading={loading}
        chartTraceColors={chartTraceColors}
        style={{ minHeight: '60vh' }}
        handleClick={v => handleClickHotbox(v)}
        barMode="stack"
        orientation="v"
        legendY={0.965}
      />
      {selectedHotboxes.length > 0 && (
        <Stack
          m={2}
          spacing={1}
          width="90%"
          bgcolor="#EEEEEE"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction="row"
            spacing={2}
            width="100%"
            p={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack spacing={1} width={300}>
              <Typography fontWeight="bold">Selected Hotboxes:</Typography>
              <Typography>{selectedHotboxes.join(', ')}</Typography>
            </Stack>
            {selectedHotboxes.length > 1 && (
              <Button
                variant="contained"
                sx={{ width: 20 }}
                onClick={handleGroup}
              >
                Group
              </Button>
            )}
          </Stack>
          {groupedData && (
            <Stack width="100%">
              <Divider sx={{ width: '100%', bgcolor: '#BBBEC5', mb: 3 }} />
              <Chart
                type={ChartType.BAR_CHART}
                title="Hot Boxes Selected vs Remaining"
                data={{
                  xTitle: 'Brands',
                  yTitle: 'Count',
                  data: [
                    {
                      x: groupedData
                        .slice(0)
                        .reverse()
                        .map(i => i.brand_desc),
                      y: groupedData
                        .slice(0)
                        .reverse()
                        .map(i => i.count),
                      name: 'Selected',
                      nameX: 'Brands',
                    },
                    {
                      x: totalsOfEachBrand
                        .slice(0)
                        .reverse()
                        .map(i => i.brand_desc),
                      y: remaningOfEachBrand.slice(0).reverse(),
                      name: 'Remaining',
                      nameX: 'Brands',
                    },
                  ],
                }}
                chartTraceColors={[...chartTraceColors].reverse()}
                bgcolor={CHART_BACKGROUND_COLOR}
              />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default View;
