import { Stack, Container } from '@mui/material';
import { ReactNode } from 'react';

import BgImg from '../assets/images/beef.jpg';

const PublicPageContainner = ({ children }: { children: ReactNode }) => (
  <Stack
    direction="row"
    sx={{
      height: '100vh',
      backgroundImage: `url(${BgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <Container maxWidth="sm" sx={{ bgcolor: 'black', minWidth: 360 }}>
      <Stack height="100vh" alignItems="center" justifyContent="center">
        <Stack sx={{ p: 3, width: '100%' }}>{children}</Stack>
      </Stack>
    </Container>
    <Stack
      display={{ xs: 'none', sm: 'flex' }}
      height="100vh"
      alignItems="center"
      justifyContent="center"
      width="100%"
      direction="row"
    >
      <Stack height="100vh" width="100%">
        <Stack height={20} width="100%" bgcolor="black" />
        <Stack height="100%" width="100%" />
        <Stack height={20} width="100%" bgcolor="black" />
      </Stack>
      <Stack height="100vh" width={20} bgcolor="black" />
    </Stack>
  </Stack>
);

export default PublicPageContainner;
