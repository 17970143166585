import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit, Delete, PersonAdd, LockReset } from '@mui/icons-material';
import {
  Stack,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  Typography,
} from '@mui/material';
import moment from 'moment';
import validator from 'validator';

import {
  LooseObject,
  TableColumnProps,
  TableRowProps,
  Status as StatusType,
  TableToolBarActionButtonCategory,
  FormData,
} from '../../../utils/Types';
import { DialogWrapper, Status, DataTable } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { selectUser } from '../../../redux/reducers/userSlice';
import {
  getDateFormat,
  getUserRoleName,
  logout,
  postToServer,
} from '../../../utils/Helper';
import AddUser from '../../management/UserList/AddUser';
import EditUser from '../../management/UserList/EditUser';
import DeleteUser from '../../management/UserList/DeleteUser';
import ResetUserPassword from '../../management/UserList/ResetUserPassword';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';

type Props = {
  onCancel: (values: any) => void;
  item: LooseObject;
};

const AsignUsers = ({ onCancel, item }: Props) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [sendingForm, setSendingForm] = useState(false);
  const [users, setUsers] = useState<LooseObject[]>([]);
  const [itemForDialog, setItemForDialog] = useState<LooseObject>({});
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogResetUserPassword, setOpenDialogResetUserPassword] =
    useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const org = useAppSelector(selectOrg);
  const user = useAppSelector(selectUser);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'management/GetUsers',
        params: { orgId: item.id },
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            setUsers(response.serverData as LooseObject[]);
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const handleAddUser = ({
    firstName,
    lastName,
    email,
    // password,
    role,
    // isValid,
    isSendEmail,
  }: FormData) => {
    if (email && validator.isEmail(email)) {
      setSendingForm(true);
      if (auth?.token) {
        const params = {
          orgId: item.id,
          firstName,
          lastName,
          email,
          // password,
          role,
          // isValid,
          isSendEmail,
          origin: window.location.protocol + '//' + window.location.host,
        };
        postToServer({
          action: 'management/AddUser',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setSendingForm(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogAdd(!openDialogAdd);
              setUsers(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setSendingForm(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const handleEditUser = ({ firstName, lastName, role, isValid }: FormData) => {
    if (itemForDialog.id && firstName && lastName) {
      setSendingForm(true);
      if (auth?.token) {
        const params = {
          orgId: item.id,
          id: itemForDialog.id,
          firstName,
          lastName,
          role,
          isValid,
        };
        postToServer({
          action: 'management/EditUser',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setSendingForm(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogEdit(!openDialogEdit);
              setUsers(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setSendingForm(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const handleResetUserPassword = ({ password }: FormData) => {
    if (itemForDialog.id && password) {
      setSendingForm(true);
      if (auth?.token) {
        const params = { orgId: item.id, id: itemForDialog.id, password };
        postToServer({
          action: 'management/ResetUserPassword',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setSendingForm(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogResetUserPassword(!openDialogResetUserPassword);
              setUsers(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setSendingForm(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const handleDeleteUser = () => {
    if (itemForDialog.id) {
      setSendingForm(true);
      if (auth?.token) {
        const params = { orgId: item.id, id: itemForDialog.id };
        postToServer({
          action: 'management/DeleteUser',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setSendingForm(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogDelete(!openDialogDelete);
              setUsers(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setSendingForm(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const columns: TableColumnProps[] = [
    { id: 'id', label: 'ID', hidden: true },
    {
      id: 'createdAt',
      label: 'Created At',
      isSortable: true,
      minWidth: 150,
    },
    {
      id: 'name',
      label: 'Name',
      isSortable: true,
      minWidth: 180,
    },
    {
      id: 'email',
      label: 'Email',
      isSortable: true,
    },
    {
      id: 'role',
      label: 'Role',
      minWidth: 180,
      component: (item: TableRowProps) => (
        <Typography>{getUserRoleName(item.params.role)}</Typography>
      ),
    },
    {
      id: 'status',
      label: 'Status',
      component: (item: TableRowProps) => (
        <Status status={item.params.status} />
      ),
    },
    {
      id: 'lastLogin',
      label: 'Last Login',
      isSortable: true,
      minWidth: 150,
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      component: (item: TableRowProps) =>
        item.row.id === user?.id ? (
          ''
        ) : (
          <Stack direction="row" spacing={1}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                color="info"
                onClick={() => {
                  setItemForDialog(item.row);
                  setOpenDialogEdit(true);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset Password">
              <IconButton
                aria-label="Reset password"
                color="info"
                onClick={() => {
                  setItemForDialog(item.row);
                  setOpenDialogResetUserPassword(true);
                }}
              >
                <LockReset />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setItemForDialog(item.row);
                  setOpenDialogDelete(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
    },
  ];

  const rows: TableRowProps[] = [];
  users.forEach(item => {
    const row: TableRowProps = {
      id: item.id,
      createdAt: moment(item.createdAt).format(getDateFormat(org, 'default')),
      name: `${item.firstName} ${item.lastName}`,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      role: {
        isDependent: true,
        params: {
          role: item.role,
        },
      },
      status: {
        isDependent: true,
        params: {
          status: item.isValid ? StatusType.ACTIVE : StatusType.SUSPENDED,
        },
      },
      lastLogin:
        item.lastLogin &&
        moment(item.lastLogin).format(getDateFormat(org, 'default')),
      actions: { isDependent: true },
      isValid: item.isValid,
    };
    rows.push(row);
  });

  const rowsToDisplay: TableRowProps[] = rows.filter(i => true);

  return (
    <DialogWrapper
      title={`Manage Users for ${item.name}`}
      onCancel={onCancel}
      hideCancelButton
      hideConfirmButton
    >
      <DataTable
        dateFormat={getDateFormat(org, 'default')}
        loading={loading}
        title="List of Users"
        columns={columns}
        rows={rowsToDisplay}
        searchAttributes={['name', 'email']}
        actionButtons={[
          {
            category: TableToolBarActionButtonCategory.NO_SELECT_ONLY,
            component: (
              <Stack>
                <Button
                  variant="outlined"
                  sx={{ minWidth: 200 }}
                  onClick={() => {
                    setOpenDialogAdd(true);
                  }}
                  startIcon={<PersonAdd />}
                >
                  Add a new user
                </Button>
                <Dialog open={openDialogAdd}>
                  <AddUser
                    onConfirm={handleAddUser}
                    onCancel={() => setOpenDialogAdd(!openDialogAdd)}
                    auth={auth}
                    loading={loading}
                    isAdministration
                  />
                </Dialog>
              </Stack>
            ),
          },
        ]}
      />
      <Dialog open={openDialogEdit}>
        <EditUser
          onCancel={() => setOpenDialogEdit(!openDialogEdit)}
          onConfirm={handleEditUser}
          loading={sendingForm}
          item={itemForDialog}
          isAdministration
        />
      </Dialog>
      <Dialog open={openDialogResetUserPassword}>
        <ResetUserPassword
          onCancel={() =>
            setOpenDialogResetUserPassword(!openDialogResetUserPassword)
          }
          onConfirm={handleResetUserPassword}
          loading={sendingForm}
          item={itemForDialog}
        />
      </Dialog>
      <Dialog open={openDialogDelete}>
        <DeleteUser
          onCancel={() => setOpenDialogDelete(!openDialogDelete)}
          onConfirm={handleDeleteUser}
          loading={sendingForm}
          item={itemForDialog}
        />
      </Dialog>
    </DialogWrapper>
  );
};

export default AsignUsers;
