import { Stack, Switch as MuiSwitch, FormControlLabel } from '@mui/material';

import { FormInputItem, LooseObject } from '../../utils/Types';
import React from 'react';

type Props = {
  item: FormInputItem;
  textFieldProps: LooseObject;
  values: {};
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  inputProps?: {};
};

const Switch = ({ item, values, setFieldValue, textFieldProps }: Props) => {
  const [checked, setChecked] = React.useState(values[item.name]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, v: any) => {
    setChecked(event.target.checked);
    setFieldValue(item.name, v);
    item.exposeValue?.(v);
  };

  return (
    <Stack key={item.name} alignItems="flex-start" width="100%">
      <FormControlLabel
        label={item.label}
        control={
          <MuiSwitch
            size={textFieldProps.size}
            checked={checked}
            value={values[item.name]}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        sx={textFieldProps.sx}
      />
    </Stack>
  );
};

export default Switch;
