import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { Auth } from '../../utils/Types';

export interface AuthState {
  value: Auth;
  status: 'idle' | 'loading' | 'failed';
}

let initialValue = {};
try {
  const initialValueString = localStorage.getItem('auth');
  if (initialValueString) {
    initialValue = JSON.parse(initialValueString);
  }
} catch (error: any) {
  console.log(error.message + ' in localStorage auth');
}

const initialState: AuthState = {
  value: initialValue as Auth,
  status: 'idle',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.value = action.payload.payload;
    },
  },
});

export const { setAuth } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth.value;

export default authSlice.reducer;
