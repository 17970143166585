import { useContext, useEffect, useState } from 'react';
import { Dialog, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { SmallButton, Table } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import {
  getDateFormat,
  getUserRoleName,
  logout,
  postToServer,
} from '../../../utils/Helper';
import { CONTENT_PADDING } from '../../../utils/Constants';
import { LooseObject } from '../../../utils/Types';
import LoginLog from './LoginLog';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState<LooseObject[]>([]);
  const [tableRows, setTableRows] = useState<LooseObject[]>([]);
  const [itemForDialog, setItemForDialog] = useState<LooseObject>({});
  const [openDialogLoginLog, setOpenDialogLoginLog] = useState(false);

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'administration/GetAllUsers',
        params: {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setUsers(
              _.orderBy(
                serverData.filter(i => i.orgs && i.orgs.length > 0),
                'id',
                'desc'
              )
            );
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const columns = [
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'email', header: 'Email' },
    { accessorKey: 'organisations', header: 'Organisations', minSize: 350 },
    { accessorKey: 'lastLogin', header: 'Last Login', sortingFn: 'datetime' },
    { accessorKey: 'createdAt', header: 'Created At', sortingFn: 'datetime' },
    { accessorKey: 'updatedAt', header: 'Updated At', sortingFn: 'datetime' },
    { accessorKey: 'actions', header: 'Actions' },
  ];

  useEffect(() => {
    const rows = users.map(i => ({
      ...i,
      name: `${i.firstName || ''} ${i.lastName || ''}`,
      organisations: (
        <Stack>
          {i.orgsWithRole &&
            _.sortBy(i.orgsWithRole, 'name').map((i: LooseObject) => (
              <Stack key={i.orgOwner} direction="row">
                <Typography variant="body2">{`${i.name} - ${getUserRoleName(
                  i.role
                )}`}</Typography>
                {!i.isValid && (
                  <Typography variant="body2" color="red">
                    (Suspended)
                  </Typography>
                )}
              </Stack>
            ))}
        </Stack>
      ),
      lastLogin: i.lastLogin
        ? moment(i.lastLogin).format(getDateFormat(org, 'default'))
        : undefined,
      createdAt: moment(i.createdAt).format(getDateFormat(org, 'default')),
      updatedAt: moment(i.updatedAt).format(getDateFormat(org, 'default')),
      actions: (
        <Stack spacing={1}>
          <SmallButton
            variant="outlined"
            title="Login Log"
            onClick={() => {
              setItemForDialog(i);
              setOpenDialogLoginLog(true);
            }}
          />
        </Stack>
      ),
    }));
    setTableRows(rows);
  }, [users]);

  return (
    <Stack pt={CONTENT_PADDING}>
      <Table
        renderTopToolbarCustomActions={() => (
          <Typography variant="h6">All Users in The System</Typography>
        )}
        columns={columns}
        data={tableRows}
        state={{ isLoading: loading }}
        enableColumnActions={false}
        layoutMode="grid"
      />
      <Dialog open={openDialogLoginLog} fullWidth>
        <LoginLog
          onCancel={() => setOpenDialogLoginLog(false)}
          auth={auth}
          org={org}
          item={itemForDialog}
          snackbar={snackbar}
        />
      </Dialog>
    </Stack>
  );
};

export default Page;
