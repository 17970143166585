import { useEffect, useState } from 'react';
// import _ from 'lodash';

import { Skeleton, Table } from '../../../components';
import { DepthCameraComponentProps } from './GraphArea';
import { DateFormatServer, LooseObject } from '../../../utils/Types';
import { postToServer } from '../../../utils/Helper';

const MetricResults = ({
  date,
  trait,
  type,
  facility,
  loading,
  auth,
  org,
  snackbar,
  isAdjusted,
}: DepthCameraComponentProps) => {
  const [data, setData] = useState<LooseObject[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    loadData();
  }, [facility, date, type, trait]);

  const loadData = () => {
    if (facility && date && type && trait) {
      if (auth?.token) {
        setLoadingData(true);
        postToServer({
          action: `machine-learning/depth-camera/${
            isAdjusted ? 'GetAdjustedMetricResults' : 'GetMetricResults'
          }`,
          params: {
            location: facility,
            date: date.format(DateFormatServer.SHORT),
            type,
            trait,
          },
          token: auth.token,
        }).then(response => {
          setLoadingData(false);
          if (!(response.message.type === 'success' && response.serverData)) {
            snackbar.open(response.message);
          }

          setData(
            response.serverData ? (response.serverData as LooseObject[]) : []
          );
        });
      }
    }
  };

  // let columns: MRT_ColumnDef[] = [];
  // if (data && data.length > 0) {
  //   columns = Object.keys(data[0]).map(k => ({
  //     accessorKey: k,
  //     header: _.startCase(k),
  //   }));
  // }

  const columns = [
    { accessorKey: 'metric', header: 'Metric' },
    { accessorKey: 'target', header: 'Target' },
    { accessorKey: 'result', header: 'Result' },
  ];

  return loading || loadingData ? (
    <Skeleton />
  ) : (
    <Table
      columns={columns}
      data={data}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      layoutMode="grid"
      initialState={{ density: 'compact' }}
    />
  );
};

export default MetricResults;
