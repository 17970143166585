import { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Popover,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import moment, { Moment } from 'moment';
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
  Auth,
  ChartType,
  DateFormatServer,
  LooseObject,
  Org,
  QueryResult,
  QueryType,
} from '../../../utils/Types';
import { Chart } from '../../../components';
import {
  delay,
  getDateFormat,
  optimiseQueryResult,
  postToServer,
} from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import {
  HISTOGRAM_Y_AXIS_TITLE,
  QUERY_DATA_SOURCE_ENDPOINT,
} from '../../../utils/Constants';

const ImfHistogram = ({
  data,
  loading,
  auth,
  org,
}: {
  data: LooseObject[];
  loading: boolean;
  auth: Auth;
  org: Org;
}) => {
  const snackbar = useContext(SnackbarContext);
  const [loadingCarcases, setLoadingCarcases] = useState(false);

  const allDateStrings = data
    ?.map(i => i.id)
    ?.sort(
      (a: string, b: string) =>
        moment(b, DateFormatServer.SHORT).valueOf() -
        moment(a, DateFormatServer.SHORT).valueOf()
    );

  type YAxis = 'Count' | 'Percent';
  const [yAxis, setYAxis] = useState<YAxis>('Percent');

  const [date, setDate] = useState<Moment | null>(moment());
  const [carcases, setCarcases] = useState<QueryResult>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    let defaultDate = moment();

    if (allDateStrings?.[0]) {
      defaultDate = moment(allDateStrings?.[0], DateFormatServer.SHORT);
      setDate(defaultDate);

      loadCarcases(defaultDate);
    }
  }, [data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadCarcases = async (forDate: Moment | null) => {
    if (forDate && auth?.token) {
      setLoadingCarcases(true);
      await delay(200);
      await postToServer({
        action: QUERY_DATA_SOURCE_ENDPOINT,
        params: {
          id: auth.orgOwner,
          type: QueryType.ORDINARY_QUERY,
          view: 'FREWS_INSIGHTS_CARCASE',
          sqlConditions: `SLAUGHTER_DATE = '${forDate.format(
            DateFormatServer.SHORT
          )}'`,
        },
        token: auth.token,
      }).then(response => {
        setLoadingCarcases(false);
        if (response.statusCode !== 401) {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as QueryResult;
            const optimisedServerData = optimiseQueryResult(serverData);

            setCarcases(optimisedServerData);
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const disableDates = (date: Moment) => {
    const currentDateString = date.format(DateFormatServer.SHORT);
    const shouldDisable = !allDateStrings?.includes(currentDateString);
    return shouldDisable;
  };

  const handleChangeYAxis = (
    event: React.MouseEvent<HTMLElement>,
    v: string
  ) => {
    if (v) {
      setYAxis(v as YAxis);
    }
  };

  const dataToDisplay = carcases?.rows
    ?.filter(i => i.MEQ_IMF)
    .map(i => i.MEQ_IMF);

  return (
    <Chart
      type={ChartType.HISTOGRAM}
      histnorm={yAxis === 'Count' ? '' : 'percent'}
      title={`IMF Histogram`}
      headerBeforeLoading={
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">{`Show data for ${date?.format(
            getDateFormat(org, 'short')
          )}`}</Typography>
          <Stack>
            <Typography
              onClick={handleClick}
              variant="body2"
              sx={{
                textDecoration: 'underline',
                m: 1,
                ':hover': { color: 'grey.700', cursor: 'pointer' },
              }}
            >
              Change Date
            </Typography>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateCalendar
                  disableFuture
                  value={date}
                  onChange={(v: Moment | null) => {
                    setDate(v);
                    loadCarcases(v);
                  }}
                  shouldDisableDate={disableDates}
                  slots={{
                    day: ({ day, ...rest }) => (
                      <PickersDay
                        day={day}
                        {...rest}
                        selected={
                          day.format(getDateFormat(org, 'short')) ===
                          date?.format(getDateFormat(org, 'short'))
                        }
                        sx={{
                          border: allDateStrings.includes(
                            day.format(DateFormatServer.SHORT)
                          )
                            ? 1
                            : 0,
                          borderColor: theme => theme.palette.primary.main,
                        }}
                      />
                    ),
                  }}
                />
              </LocalizationProvider>
            </Popover>
          </Stack>
        </Stack>
      }
      header={
        <ToggleButtonGroup
          value={yAxis}
          exclusive
          onChange={handleChangeYAxis}
          aria-label="View"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
          }}
          size="small"
        >
          <ToggleButton key={'category_count'} value="Count">
            Count
          </ToggleButton>
          <ToggleButton key={'category_percent'} value="Percent">
            Percent
          </ToggleButton>
        </ToggleButtonGroup>
      }
      data={{
        xTitle: 'MEQ IMF',
        yTitle: yAxis || HISTOGRAM_Y_AXIS_TITLE,
        data: [{ x: dataToDisplay }],
      }}
      loading={loading || loadingCarcases}
    />
  );
};

export default ImfHistogram;
