import { useContext, useEffect, useState } from 'react';
import { Dialog, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { FormData, LooseObject } from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { logout, postToServer } from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import {
  Skeleton,
  NoDataView,
  Table,
  DialogWrapper,
  SmallButton,
} from '../../../components';
import Insight from '../demo2/Insight';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import InsightForm from '../demo2/InsightForm';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [insights, setInsights] = useState<LooseObject[]>([]);
  const [availableQueries, setAvailableQueries] = useState<LooseObject[]>([]);
  const [currentItem, setCurrentItem] = useState<LooseObject>();
  const [openDialogView, setOpenDialogView] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchInsights();
    fetchAvailableQueries();
  }, []);

  const fetchInsights = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'insights2/GetSharedInsights',
        params: {},
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setInsights(serverData);
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  const fetchAvailableQueries = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'insights/GetQueries',
        params: {},
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setAvailableQueries(serverData);
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  const handleEdit = ({ id, title }: FormData) => {
    if (id && title) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights2/UpdateInsight',
          params: { id, title },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setInsights(response.serverData as LooseObject[]);
              setOpenDialogEdit(false);
            } else {
              snackbar.open(response.message);
            }
          }
        });
      }
    }
  };

  const handleRemove = (id: string | number) => {
    if (id) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights2/RemoveInsight',
          params: { id },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setInsights(response.serverData as LooseObject[]);
              setOpenDialogDelete(false);
            } else {
              snackbar.open(response.message);
            }
          }
        });
      }
    }
  };

  const columns = [
    { accessorKey: 'title', header: 'Title' },
    { accessorKey: 'actions', header: 'Actions' },
  ];

  const InsightsSection = ({
    insightsData,
  }: {
    insightsData: LooseObject[];
  }) => {
    const rows = insightsData.map(i => ({
      ...i,
      actions: (
        <Stack spacing={1} direction="row">
          <SmallButton
            variant="outlined"
            title="View"
            onClick={() => {
              setCurrentItem(i);
              setOpenDialogView(true);
            }}
          />
          {(i.sharedToEntireOrg?.isWritable ||
            (i.sharedToUsers?.length > 0 && i.sharedToUsers[0].isWritable)) && (
            <Stack direction="row" spacing={1}>
              <SmallButton
                variant="outlined"
                title="Edit"
                onClick={() => {
                  setCurrentItem(i);
                  setOpenDialogEdit(true);
                }}
              />
              <SmallButton
                variant="outlined"
                title="Delete"
                onClick={() => {
                  setCurrentItem(i);
                  setOpenDialogDelete(true);
                }}
              />
            </Stack>
          )}
        </Stack>
      ),
    }));

    return (
      <Stack spacing={2}>
        {insightsData.length > 0 ? (
          insightsData.map(insight => (
            <Stack key={`insight-${insight.id}`}>
              <Stack spacing={2}>
                <Table
                  columns={columns}
                  data={rows}
                  enableTopToolbar={false}
                  enableBottomToolbar={false}
                  enableColumnActions={false}
                  layoutMode="grid"
                />
              </Stack>
              <Dialog
                open={openDialogView}
                onClose={() => setOpenDialogView(false)}
                fullScreen
              >
                {currentItem && (
                  <Insight
                    initialInsight={currentItem}
                    updateInsights={value => setInsights(value)}
                    availableQueries={availableQueries}
                    onCancel={() => setOpenDialogView(false)}
                    isWritable
                    auth={auth}
                    org={org}
                  />
                )}
              </Dialog>
              <Dialog
                open={openDialogEdit}
                onClose={() => setOpenDialogEdit(false)}
                maxWidth="xl"
              >
                {currentItem && (
                  <InsightForm
                    title="Edit insight"
                    item={currentItem}
                    loading={loading}
                    onCancel={() => setOpenDialogEdit(false)}
                    handleSubmit={v => handleEdit({ id: currentItem.id, ...v })}
                  />
                )}
              </Dialog>
              <Dialog
                open={openDialogDelete}
                onClose={() => setOpenDialogDelete(false)}
                maxWidth="xl"
              >
                {currentItem && (
                  <DialogWrapper
                    title="Delete an insight"
                    onCancel={() => setOpenDialogDelete(false)}
                    onConfirm={() => handleRemove(currentItem.id)}
                  >
                    <Typography>{`Are you sure to delete ${currentItem.title}?`}</Typography>
                  </DialogWrapper>
                )}
              </Dialog>
            </Stack>
          ))
        ) : (
          <NoDataView />
        )}
      </Stack>
    );
  };

  return loading ? (
    <Skeleton />
  ) : (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h6">Shared by Users</Typography>
        <InsightsSection
          insightsData={insights.filter(i => !i.sharedToEntireOrg)}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h6">
          Shared Among the Entire Organisation
        </Typography>
        <InsightsSection
          insightsData={insights.filter(i => i.sharedToEntireOrg)}
        />
      </Stack>
    </Stack>
  );
};

export default Page;
