import {
  FormInputCategory,
  LooseObject,
  PluginName,
} from '../../../utils/Types';
import { Form, DialogWrapper } from '../../../components';

type Props = {
  onConfirm: (values: any) => void;
  onCancel: (values: any) => void;
  loading: boolean;
  item: LooseObject;
};

const AsignPlugins = ({ onConfirm, onCancel, loading, item }: Props) => (
  <DialogWrapper
    title={`Asign Plugins to ${item.name}`}
    onCancel={onCancel}
    hideCancelButton
    hideConfirmButton
  >
    <Form
      loading={loading}
      onSubmit={onConfirm}
      buttonText="Submit"
      inputs={[
        {
          name: 'plugins',
          label: 'Plugins',
          category: FormInputCategory.SELECT,
          options: Object.values(PluginName).map(o => ({
            label: o + (o === 'Feedyard' ? ` (Mishima)` : ''),
            value: o,
          })),
          multiple: true,
          defaultValue: item?.plugins.map((i: any) => i.name),
        },
      ]}
    />
  </DialogWrapper>
);

export default AsignPlugins;
