import {
  FormInputCategory,
  FormInputItem,
  LooseObject,
} from '../../../utils/Types';
import { Form, DialogWrapper } from '../../../components';

type Props = {
  onConfirm: (values: any) => void;
  onCancel: (values: any) => void;
  loading: boolean;
  item: LooseObject;
};

const Reset = ({ onConfirm, onCancel, loading, item }: Props) => {
  const inputs: FormInputItem[] = [
    {
      name: 'password',
      label: 'Password',
      category: FormInputCategory.TEXT_INPUT,
      type: 'password',
    },
  ];

  return (
    <DialogWrapper
      title={`Reset Password for ${item.firstName} ${item.lastName}`}
      onCancel={onCancel}
      hideCancelButton
      hideConfirmButton
    >
      <Form
        loading={loading}
        onSubmit={onConfirm}
        buttonText="Submit"
        inputs={inputs}
      />
    </DialogWrapper>
  );
};

export default Reset;
