import { FormInputCategory } from '../../../utils/Types';
import { Form, DialogWrapper } from '../../../components';

type Props = {
  onConfirm: (values: any) => void;
  onCancel: (values: any) => void;
  loading: boolean;
};

const Add = ({ onConfirm, onCancel, loading }: Props) => (
  <DialogWrapper
    title="Add a New Organisation"
    onCancel={onCancel}
    hideCancelButton
    hideConfirmButton
  >
    <Form
      loading={loading}
      onSubmit={onConfirm}
      buttonText="Submit"
      inputs={[
        {
          name: 'name',
          label: 'Name',
          category: FormInputCategory.TEXT_INPUT,
        },
        {
          name: 'owner',
          label: 'Owner',
          category: FormInputCategory.TEXT_INPUT,
        },
        {
          name: 'hostname',
          label: 'Hostname',
          category: FormInputCategory.TEXT_INPUT,
        },
        {
          name: 'logo',
          label: 'Logo',
          category: FormInputCategory.TEXT_INPUT,
        },
        {
          name: 'theme',
          label: 'Theme',
          category: FormInputCategory.TEXT_INPUT,
        },
        {
          name: 'isWhiteLabel',
          label: 'A white labelled organisation (used for logo display)',
          category: FormInputCategory.SWITCH,
        },
        {
          name: 'isUSA',
          label: 'A US Orgnisation (used for date format)',
          category: FormInputCategory.SWITCH,
        },
        {
          name: 'isTCRequired',
          label: 'Require to accpet terms upon first login',
          category: FormInputCategory.SWITCH,
        },
        {
          name: 'isValid',
          label: 'Active',
          defaultValue: true,
          category: FormInputCategory.SWITCH,
        },
      ]}
    />
  </DialogWrapper>
);

export default Add;
