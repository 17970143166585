import { useState } from 'react';
import {
  Box,
  CssBaseline,
  IconButton,
  Typography,
  Stack,
  Avatar,
  Divider,
} from '@mui/material';

import { selectOrg } from '../../redux/reducers/orgSlice';
import { selectUser } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/hooks';
import BarsSortIcon from '../../assets/icons/bars-sort.png';
import { CONTENT_PADDING } from '../../utils/Constants';
import Logo from './Logo';
import SwichOrg from './SwitchOrg';
import SideBar from './Sidebar';

export const SIDEBAR_WIDTH = 320;
export const MARGIN = 4;
export const MARGIN_MOBILE = 2;

interface Props {
  title: string;
  contentPadding?: number;
  children?: React.ReactNode;
}

const Nav = ({ title, contentPadding = CONTENT_PADDING, children }: Props) => {
  const [mobileSideBarOpen, setMobileSideBarOpen] = useState(false);
  const org = useAppSelector(selectOrg);
  const user = useAppSelector(selectUser);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        height: '100vh',
        bgcolor: 'text.primary',
      }}
    >
      <CssBaseline />
      <Stack display="flex" flex={1} direction="row" bgcolor="text.primary">
        <SideBar mobileOpen={mobileSideBarOpen} />

        <Stack
          sx={{
            width: '100%',
            height: '100%',
          }}
          pr={{ xs: MARGIN_MOBILE, md: MARGIN }}
          pl={{ xs: MARGIN_MOBILE, md: 0 }}
        >
          <Stack
            direction="row"
            spacing={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="text.primary"
          >
            <IconButton
              onClick={() => setMobileSideBarOpen(!mobileSideBarOpen)}
              sx={{ ml: -1, display: { md: 'none' } }}
            >
              <img src={BarsSortIcon} alt="Menu" />
            </IconButton>
            <Box display={{ xs: 'none', md: 'flex' }} />
            <Stack display={{ md: 'none' }}>
              <Logo maxWidth={180} maxHeight={40} img={org?.logo} />
            </Stack>
            <Stack
              height={{ xs: MARGIN_MOBILE * 40, md: MARGIN * 18 }}
              direction="row"
              spacing={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {(!org?.isWhiteLabel || (user?.orgs?.length || 0) > 1) && (
                <Stack
                  direction="row"
                  alignItems="center"
                  display={{ xs: 'none', md: 'flex' }}
                  spacing={3}
                  mr={2}
                >
                  <SwichOrg org={org} user={user} />
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ bgcolor: 'grey.700', height: 30 }}
                  />
                </Stack>
              )}

              <Avatar
                sx={{
                  width: { xs: MARGIN_MOBILE * 20, md: MARGIN * 10 },
                  height: { xs: MARGIN_MOBILE * 20, md: MARGIN * 10 },
                  bgcolor: { xs: 'background.default', md: 'primary.main' },
                }}
              >
                &nbsp;
              </Avatar>
              <Typography
                fontSize={18}
                display={{ xs: 'none', md: 'flex' }}
                color="background.default"
              >
                {`Hello, ${user?.firstName}`}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: '100%',
              height: '100%',
              bgcolor: 'white',
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              minWidth: {
                xs: `calc(100vw - ${MARGIN_MOBILE * 8 * 2}px)`,
                md: 0,
              },
              maxWidth: {
                xs: `calc(100vw - ${MARGIN_MOBILE * 8 * 2}px)`,
                md: `calc(100vw - ${SIDEBAR_WIDTH + MARGIN * 8}px)`,
              },
              p: contentPadding,
              overflow: 'auto',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              mx={contentPadding !== CONTENT_PADDING ? CONTENT_PADDING : 0}
              mt={contentPadding !== CONTENT_PADDING ? CONTENT_PADDING : 0}
              mb={CONTENT_PADDING}
            >
              {title}
            </Typography>
            <Divider sx={{ mx: -contentPadding }} />
            {children}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Nav;
