import { Grid, Box } from '@mui/material';
import { grey } from '@mui/material/colors';

export type ItemDetailsRowProps = {
  label: any;
  value: any;
  isEven?: boolean;
  leftWidth?: number;
};

const ItemDetailsRow = ({
  label,
  value,
  isEven,
  leftWidth = 4,
}: ItemDetailsRowProps) => {
  return (
    <Grid
      item
      xs={12}
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: isEven ? grey[100] : 'transparent',
      }}
    >
      <Grid
        item
        xs={leftWidth}
        // borderRight={1}
        borderColor={grey[300]}
        height="100%"
        display="flex"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
            p: 0.3,
            color: 'grey.700',
            fontSize: 15,
          }}
        >
          {label + ' :'}
        </Box>
      </Grid>
      <Grid item xs={12 - leftWidth} height="100%">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            p: 0.3,
            fontSize: 15,
          }}
        >
          {Array.isArray(value) ? (
            <Grid item container>
              {value.map((item, index) => (
                <ItemDetailsRow
                  key={label + index}
                  label={item.label}
                  value={item.value}
                  isEven={index % 2 === 0}
                />
              ))}
            </Grid>
          ) : (
            value
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ItemDetailsRow;
