import { useState } from 'react';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';

import {
  Dialog,
  DialogWrapper,
  Form,
  SmallButton,
  Table,
} from '../../../components';
import {
  FormInputCategory,
  LooseObject,
  SharedInsightScope,
} from '../../../utils/Types';

const Share = ({
  insight,
  loading,
  handleSharing,
  handleRetrieveSharing,
}: {
  insight: LooseObject;
  loading: boolean;
  handleSharing: (v: FormData) => void;
  handleRetrieveSharing: (v: string | number) => void;
}) => {
  const [anchorElShare, setAnchorElShare] = useState<null | HTMLElement>(null);
  const openShareMenu = Boolean(anchorElShare);
  const handleClickShare = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElShare(event.currentTarget);
  };
  const handleCloseShare = () => {
    setAnchorElShare(null);
  };

  const [openDialogForShareToAnotherUser, setOpenDialogForShareToAnotherUser] =
    useState(false);
  const [openDialogForShareToEntireOrg, setOpenDialogForShareToEntireOrg] =
    useState(false);
  const [openDialogForRetrieveSharing, setOpenDialogForRetrieveSharing] =
    useState(false);

  return (
    <Stack>
      <SmallButton
        variant="outlined"
        title="Share"
        onClick={handleClickShare}
      />
      <Menu
        anchorEl={anchorElShare}
        open={openShareMenu}
        onClose={handleCloseShare}
      >
        <MenuItem
          onClick={() => {
            setOpenDialogForShareToAnotherUser(true);
            handleCloseShare();
          }}
        >
          Share to another user
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDialogForShareToEntireOrg(true);
            handleCloseShare();
          }}
        >
          Share to entire organisation
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDialogForRetrieveSharing(true);
            handleCloseShare();
          }}
        >
          Retrieve existing sharings
        </MenuItem>
      </Menu>
      <Dialog open={openDialogForShareToAnotherUser}>
        <DialogWrapper
          title="Share to Another User"
          onCancel={() => setOpenDialogForShareToAnotherUser(false)}
          hideCancelButton
        >
          <Form
            loading={loading}
            onSubmit={data =>
              handleSharing({
                id: insight.id,
                scope: SharedInsightScope.TARGETED_USER,
                ...data,
              })
            }
            buttonText="Share"
            inputs={[
              {
                name: 'email',
                label: 'To User',
                category: FormInputCategory.TEXT_INPUT,
              },
              {
                name: 'isToUserWritable',
                label: 'Grant Write Permissions',
                defaultValue: false,
                category: FormInputCategory.SWITCH,
              },
            ]}
            maxWidth="xl"
          />
        </DialogWrapper>
      </Dialog>
      <Dialog open={openDialogForShareToEntireOrg}>
        <DialogWrapper
          title="Share to Entire Organisation"
          onCancel={() => setOpenDialogForShareToEntireOrg(false)}
          hideCancelButton
        >
          <Stack>
            <Typography>
              If you share the insight across the entire organisation, all users
              in your organisation will be able to access it.
            </Typography>
          </Stack>
          <Form
            loading={loading}
            onSubmit={data =>
              handleSharing({
                id: insight.id,
                scope: SharedInsightScope.ENTIRE_ORG,
                ...data,
              })
            }
            buttonText="Share"
            inputs={[
              {
                name: 'isEntireOrgWritable',
                label: 'Grant Write Permissions',
                defaultValue: false,
                category: FormInputCategory.SWITCH,
              },
            ]}
            maxWidth="xl"
          />
        </DialogWrapper>
      </Dialog>
      <Dialog open={openDialogForRetrieveSharing}>
        <DialogWrapper
          title="Retrive Existing Sharings"
          onCancel={() => setOpenDialogForRetrieveSharing(false)}
          hideCancelButton
        >
          <Stack spacing={3}>
            <Table
              columns={[
                {
                  accessorKey: 'toEntireOrg',
                  header: 'To Entire Organisation',
                },
                {
                  accessorKey: 'isWritable',
                  header: 'Write Permissions',
                },
                { accessorKey: 'actions', header: 'Actions' },
              ]}
              data={[
                {
                  toEntireOrg: insight.sharedToEntireOrg ? 'Yes' : 'No',
                  isWritable:
                    insight.sharedToEntireOrg &&
                    insight.sharedToEntireOrg.isWritable
                      ? 'Yes'
                      : 'No',
                  actions: insight.sharedToEntireOrg && (
                    <SmallButton
                      loading={loading}
                      title="Remove"
                      onClick={() =>
                        handleRetrieveSharing(
                          insight.sharedToEntireOrg.sharingId
                        )
                      }
                    />
                  ),
                },
              ]}
              enableTopToolbar={false}
              enableBottomToolbar={false}
              enableColumnActions={false}
              layoutMode="grid"
              initialState={{ density: 'compact' }}
            />
            <Table
              columns={[
                { accessorKey: 'toUser', header: 'To User' },
                {
                  accessorKey: 'isWritable',
                  header: 'Write Permissions',
                },
                { accessorKey: 'actions', header: 'Actions' },
              ]}
              data={
                insight.sharedToUsers
                  ? insight.sharedToUsers.map((i: any) => ({
                      toUser: i.email,
                      isWritable: i.isWritable ? 'Yes' : 'No',
                      actions: (
                        <SmallButton
                          loading={loading}
                          title="Remove"
                          onClick={() => handleRetrieveSharing(i.sharingId)}
                        />
                      ),
                    }))
                  : []
              }
              enableTopToolbar={false}
              enableBottomToolbar={false}
              enableColumnActions={false}
              layoutMode="grid"
              initialState={{ density: 'compact' }}
            />
          </Stack>
        </DialogWrapper>
      </Dialog>
    </Stack>
  );
};

export default Share;
