import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import type { Operator, OptionList, RuleGroupType } from 'react-querybuilder';
import { QueryBuilder, formatQuery, parseSQL } from 'react-querybuilder';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import 'react-querybuilder/dist/query-builder.css';

import { QueryResultColumn } from '../../../utils/Types';
import {
  getJsDataTypeFromServerDataType,
  getQueryBuilderInputTypeFromJsDataType,
  isNotEmpty,
} from '../../../utils/Helper';
import CustomValueEditor from './CustomValueEditor';

export const getQueryBuilderOperators = (type: string) => {
  let operators: OptionList<Operator<string>> = [];
  switch (type) {
    case 'number':
    case 'date':
    case 'datetime':
      operators = [
        { name: '=', label: '=' },
        { name: '!=', label: '!=' },
        { name: '<', label: '<' },
        { name: '>', label: '>' },
        { name: '<=', label: '<=' },
        { name: '>=', label: '>=' },
        { name: 'null', label: 'is null' },
        { name: 'notNull', label: 'is not null' },
        { name: 'in', label: 'in' },
        { name: 'notIn', label: 'not in' },
        { name: 'between', label: 'between' },
        { name: 'notBetween', label: 'not between' },
      ];
      break;
    case 'string':
      operators = [
        { name: '=', label: '=' },
        { name: '!=', label: '!=' },
        { name: 'contains', label: 'contains' },
        { name: 'beginsWith', label: 'begins with' },
        { name: 'endsWith', label: 'ends with' },
        { name: 'doesNotContain', label: 'does not contain' },
        { name: 'doesNotBeginWith', label: 'does not begin with' },
        { name: 'doesNotEndWith', label: 'does not end with' },
        { name: 'null', label: 'is null' },
        { name: 'notNull', label: 'is not null' },
        { name: 'in', label: 'in' },
        { name: 'notIn', label: 'not in' },
      ];
      break;
  }
  return operators;
};

const initialQuery: RuleGroupType = { combinator: 'and', rules: [] };

const QueryGenerator = ({
  initialSql,
  columns,
  onPreview,
  onQueryChange,
  loading,
}: {
  initialSql?: string;
  columns: QueryResultColumn[];
  onPreview?: (sql: string) => void;
  onQueryChange: (sql: string) => void;
  loading?: boolean;
}) => {
  const [query, setQuery] = useState(
    isNotEmpty(initialSql) ? parseSQL(initialSql!) : initialQuery
  );

  const fields =
    columns.map(i => ({
      name: i.name,
      label: i.name,
      inputType: getQueryBuilderInputTypeFromJsDataType(
        getJsDataTypeFromServerDataType(i.type)
      ),
      operators: getQueryBuilderOperators(
        getJsDataTypeFromServerDataType(i.type)
      ),
    })) || [];

  return (
    <Stack spacing={1}>
      <QueryBuilderMaterial>
        <QueryBuilder
          fields={fields}
          query={query}
          onQueryChange={q => {
            setQuery(q);
            onQueryChange(formatQuery(q, 'sql'));
          }}
          controlElements={{ valueEditor: CustomValueEditor }}
        />
      </QueryBuilderMaterial>
      {onPreview && (
        <Box sx={{ alignSelf: 'flex-end' }}>
          <LoadingButton
            onClick={() => onPreview(formatQuery(query, 'sql'))}
            disabled={loading}
            variant="outlined"
          >
            Preview
          </LoadingButton>
        </Box>
      )}
    </Stack>
  );
};

export default QueryGenerator;
