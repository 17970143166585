import {
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import InlineTextField from './InlineTextField';
import { useState, MouseEvent } from 'react';
import CircleIcon from '@mui/icons-material/Circle';

import { LooseObject, Org, User } from '../../../utils/Types';

const TraitList = ({
  item,
  user,
  org,
  onSubmitDataMaintainer,
  onSubmitMsa,
}: {
  item: LooseObject;
  user: User;
  org: Org;
  onSubmitDataMaintainer: (value: any) => void;
  onSubmitMsa: (value: any) => void;
}) => {
  const [msa, setMsa] = useState(item.msaUpdatedByUser);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" justifyContent="space-between" spacing={0.5}>
      <Stack>
        {item.traits?.slice(0, 2).map((i: any) => (
          <Stack direction="row" key={i.key} spacing={1} alignItems="center">
            <Typography variant="caption" fontWeight="bold">
              {i.label}:
            </Typography>
            {user?.isDataMaintainer ? (
              <InlineTextField
                item={item}
                traitKey={i.key}
                onSubmit={onSubmitDataMaintainer}
              />
            ) : (
              <Typography variant="caption">{i.value}</Typography>
            )}
            {org?.owner.includes('jbsomaha') && i.key === 'msa_marbling' && (
              <IconButton
                sx={{ width: 18, height: 18 }}
                onClick={() => {
                  setMsa(i.value);
                  onSubmitMsa(i.value);
                }}
              >
                <CircleIcon sx={{ color: 'success.light', fontSize: 14 }} />
              </IconButton>
            )}
          </Stack>
        ))}
        {item.traits?.length > 2 && (
          <Stack>
            <Typography variant="caption" onClick={handleClick}>
              ... show more
            </Typography>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Stack p={1}>
                {item.traits?.map((i: any) => (
                  <Stack
                    direction="row"
                    key={i.key}
                    spacing={1}
                    alignItems="center"
                  >
                    <Typography variant="caption" fontWeight="bold">
                      {i.label}:
                    </Typography>
                    {user?.isDataMaintainer ? (
                      <InlineTextField
                        item={item}
                        traitKey={i.key}
                        onSubmit={onSubmitDataMaintainer}
                      />
                    ) : (
                      <Typography variant="caption">{i.value}</Typography>
                    )}
                  </Stack>
                ))}
              </Stack>
            </Popover>
          </Stack>
        )}
      </Stack>

      {org?.owner.includes('jbsomaha') &&
        item.traits.find((i: any) => i.key === 'msa_marbling') && (
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                color: 'grey.300',
                '& fieldset': {
                  borderColor: 'grey.400',
                },
                '&:hover fieldset': {
                  borderColor: 'grey.200',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'grey.300',
                },
                '& .MuiInputBase-input::placeholder': {
                  color: 'grey.200',
                },
              },
              maxWidth: 70,
            }}
            name="msa_marbling"
            type="number"
            variant="outlined"
            label="MSA"
            InputLabelProps={{ style: { color: '#E0E0E0' } }}
            size="small"
            onChange={v => setMsa(v.target.value)}
            onBlur={v => onSubmitMsa(v.target.value)}
            value={msa}
            focused
          />
        )}
    </Stack>
  );
};

export default TraitList;
