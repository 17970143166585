import { IconButton, Paper, InputBase } from '@mui/material';
import { Search } from '@mui/icons-material';

type SearchBarProps = {
  onChange: (value: any) => any;
  height?: number;
  placeholder?: string;
};

const SearchBar = ({ onChange, height, placeholder }: SearchBarProps) => (
  <Paper
    sx={{
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: 120,
      height: height || { xs: 40, md: 60 },
    }}
  >
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      placeholder={placeholder || 'Search '}
      inputProps={{ 'aria-label': 'search' }}
      type="search"
      onChange={onChange}
    />

    <IconButton type="button" aria-label="search">
      <Search />
    </IconButton>
  </Paper>
);

export default SearchBar;
