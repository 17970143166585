import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Edit,
  Delete,
  PersonAddAlt,
  LockReset,
  MoreVert,
} from '@mui/icons-material';
import validator from 'validator';
import moment from 'moment';

import { DataTable } from '../../../components';
import {
  TableRowProps,
  TableColumnProps,
  FormData,
  TableToolBarActionButtonCategory,
  Status as StatusType,
} from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import { selectUser } from '../../../redux/reducers/userSlice';
import {
  getDateFormat,
  getUserRoleName,
  logout,
  postToServer,
} from '../../../utils/Helper';
import {
  CONTENT_PADDING,
  ORGS_HIDE_USERS_FROM_EACH_OTHER,
} from '../../../utils/Constants';
import { LooseObject } from '../../../utils/Types';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import AddUser from './AddUser';
import ResetUserPassword from './ResetUserPassword';
import { SnackbarContext } from '../../../utils/Contexts';
import { selectOrg } from '../../../redux/reducers/orgSlice';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [sendingForm, setSendingForm] = useState(false);
  const [users, setUsers] = useState<LooseObject[]>([]);
  const [itemForDialog, setItemForDialog] = useState<LooseObject>({});
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogResetUserPassword, setOpenDialogResetUserPassword] =
    useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const columns: TableColumnProps[] = [
    { id: 'id', label: 'ID', hidden: true },
    {
      id: 'name',
      label: 'Name',
      isSortable: true,
      minWidth: 180,
    },
    {
      id: 'email',
      label: 'Email',
      isSortable: true,
    },
    {
      id: 'role',
      label: 'Role',
      minWidth: 180,
      component: (item: TableRowProps) => (
        <Typography variant="body2">
          {getUserRoleName(item.params.role)}
        </Typography>
      ),
    },
    {
      id: 'status',
      label: 'Status',
      component: (item: TableRowProps) => (
        <Typography
          variant="body2"
          color={theme =>
            item.params.status === StatusType.SUSPENDED
              ? theme.palette.warning.light
              : ''
          }
        >
          {item.params.status}
        </Typography>
      ),
    },
    {
      id: 'lastLogin',
      label: 'Last Login',
      minWidth: 150,
      component: (value: any) =>
        value ? moment(value, getDateFormat(org, 'default')).fromNow() : '',
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      component: (item: TableRowProps) =>
        item.row.id === user?.id ? (
          ''
        ) : (
          <Stack direction="row" spacing={1}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setItemForDialog(item.row);
                  setOpenDialogEdit(true);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset Password">
              <IconButton
                aria-label="Reset password"
                onClick={() => {
                  setItemForDialog(item.row);
                  setOpenDialogResetUserPassword(true);
                }}
              >
                <LockReset />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setItemForDialog(item.row);
                  setOpenDialogDelete(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
    },
    {
      id: 'more',
      label: 'More',
      component: (item: TableRowProps) => (
        <Tooltip
          title={
            <Stack spacing={1}>
              {item.row.createdAt && (
                <Stack>
                  <Typography variant="caption">Created at</Typography>
                  <Typography variant="caption">
                    {item.row.createdAt}
                  </Typography>
                </Stack>
              )}
              {item.row.lastLogin && (
                <Stack>
                  <Typography variant="caption">Last login</Typography>
                  <Typography variant="caption">
                    {item.row.lastLogin}
                  </Typography>
                </Stack>
              )}
            </Stack>
          }
        >
          <IconButton>
            <MoreVert />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const rows: TableRowProps[] = [];
  users.forEach(item => {
    if (!item.email.includes('creativedhyani')) {
      const row: TableRowProps = {
        id: item.id,
        createdAt: moment(item.createdAt).format(getDateFormat(org, 'default')),
        name: `${item.firstName} ${item.lastName}`,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        role: {
          isDependent: true,
          params: {
            role: item.role,
          },
        },
        status: {
          isDependent: true,
          params: {
            status: item.isValid ? StatusType.ACTIVE : StatusType.SUSPENDED,
          },
        },
        roleConfig: item.roleConfig,
        lastLogin:
          item.lastLogin &&
          moment(item.lastLogin).format(getDateFormat(org, 'default')),
        actions: { isDependent: true },
        more: { isDependent: true },
        isValid: item.isValid,
      };
      if (!item.isValid) {
        row.color = '#FAF7EF';
      }
      rows.push(row);
    }
  });

  const rowsToDisplay: TableRowProps[] = rows.filter(i => true);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'management/GetUsers',
        params: {},
        token: auth.token,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          setLoading(false);
          if (response.message.type === 'success' && response.serverData) {
            let usersFromServer = response.serverData as LooseObject[];

            if (
              auth.orgOwner &&
              ORGS_HIDE_USERS_FROM_EACH_OTHER.includes(auth.orgOwner)
            ) {
              usersFromServer = usersFromServer.filter(i => i.id === auth.id);
            }

            setUsers(usersFromServer);
          } else {
            snackbar.open(response.message);
          }
        }
      });
    }
  };

  const handleAddUser = ({
    firstName,
    lastName,
    email,
    // password,
    role,
    photoDates,
    isValid,
    isSendEmail,
  }: FormData) => {
    if (email && validator.isEmail(email)) {
      setSendingForm(true);
      if (auth?.token) {
        const params = {
          firstName,
          lastName,
          email,
          // password,
          config: { photoDates },
          role,
          isValid,
          isSendEmail,
          origin: window.location.protocol + '//' + window.location.host,
        };
        postToServer({
          action: 'management/AddUser',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setSendingForm(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogAdd(!openDialogAdd);
              setUsers(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setSendingForm(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const handleEditUser = ({
    firstName,
    lastName,
    role,
    photoDates,
    isValid,
  }: FormData) => {
    if (itemForDialog.id && firstName && lastName) {
      setSendingForm(true);
      if (auth?.token) {
        const params = {
          id: itemForDialog.id,
          firstName,
          lastName,
          role,
          config: { photoDates },
          isValid,
        };
        postToServer({
          action: 'management/EditUser',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setSendingForm(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogEdit(!openDialogEdit);
              setUsers(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setSendingForm(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const handleResetUserPassword = ({ password }: FormData) => {
    if (itemForDialog.id && password) {
      setSendingForm(true);
      if (auth?.token) {
        const params = { id: itemForDialog.id, password };
        postToServer({
          action: 'management/ResetUserPassword',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setSendingForm(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogResetUserPassword(!openDialogResetUserPassword);
              setUsers(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setSendingForm(false);
        logout({ dispatch, navigate });
      }
    }
  };

  const handleDeleteUser = () => {
    if (itemForDialog.id) {
      setSendingForm(true);
      if (auth?.token) {
        const params = { id: itemForDialog.id };
        postToServer({
          action: 'management/DeleteUser',
          params,
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setSendingForm(false);
            if (response.message.type === 'success' && response.serverData) {
              setOpenDialogDelete(!openDialogDelete);
              setUsers(response.serverData as LooseObject[]);
            }
            snackbar.open(response.message);
          }
        });
      } else {
        setSendingForm(false);
        logout({ dispatch, navigate });
      }
    }
  };

  return (
    <Stack pt={CONTENT_PADDING}>
      <DataTable
        dateFormat={getDateFormat(org, 'default')}
        loading={loading}
        title="List of Users"
        columns={columns}
        rows={rowsToDisplay}
        searchAttributes={['name', 'email']}
        actionButtons={
          auth?.orgOwner &&
          ORGS_HIDE_USERS_FROM_EACH_OTHER.includes(auth.orgOwner)
            ? undefined
            : [
                {
                  category: TableToolBarActionButtonCategory.NO_SELECT_ONLY,
                  component: (
                    <Stack>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: 200 }}
                        onClick={() => setOpenDialogAdd(true)}
                        startIcon={<PersonAddAlt />}
                      >
                        Add a new user
                      </Button>
                      <Dialog open={openDialogAdd}>
                        <AddUser
                          onConfirm={handleAddUser}
                          onCancel={() => setOpenDialogAdd(!openDialogAdd)}
                          auth={auth}
                          org={org}
                          loading={sendingForm}
                        />
                      </Dialog>
                    </Stack>
                  ),
                },
              ]
        }
      />
      <Dialog open={openDialogEdit}>
        <EditUser
          onCancel={() => setOpenDialogEdit(!openDialogEdit)}
          onConfirm={handleEditUser}
          auth={auth}
          org={org}
          loading={sendingForm}
          item={itemForDialog}
        />
      </Dialog>
      <Dialog open={openDialogResetUserPassword}>
        <ResetUserPassword
          onCancel={() =>
            setOpenDialogResetUserPassword(!openDialogResetUserPassword)
          }
          onConfirm={handleResetUserPassword}
          loading={sendingForm}
          item={itemForDialog}
        />
      </Dialog>
      <Dialog open={openDialogDelete}>
        <DeleteUser
          onCancel={() => setOpenDialogDelete(!openDialogDelete)}
          onConfirm={handleDeleteUser}
          loading={sendingForm}
          item={itemForDialog}
        />
      </Dialog>
    </Stack>
  );
};

export default Page;
