import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Loading } from '..';
import { useContext, useEffect } from 'react';
import { delay, loadOrg, postToServer } from '../../utils/Helper';
import { User } from '../../utils/Types';
import { SnackbarContext, ThemeOveridesContext } from '../../utils/Contexts';
import { useAppDispatch } from '../../redux/hooks';
import { setAuth } from '../../redux/reducers/authSlice';
import { setUser } from '../../redux/reducers/userSlice';
import { NAV_ITEMS } from '../../utils/Constants';

const Page = () => {
  const snackbar = useContext(SnackbarContext);

  const themeOverides = useContext(ThemeOveridesContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orgId = Number(queryParams.get('orgId'));
  const accessToken = queryParams.get('accessToken');

  useEffect(() => {
    if (orgId && accessToken) {
      signInToOrgV2();
    }
  }, []);

  const signInToOrgV2 = async () => {
    await postToServer({
      action: 'SignInToOrgV2',
      params: { orgId, accessToken },
      token: '',
    }).then(async response => {
      if (response.message.type === 'success') {
        const user = response.serverData as User;

        ReactGA.set({ userId: user?.email });

        loadOrg({ orgId: user?.orgId, themeOverides, dispatch });

        // save auth to current browser
        localStorage.setItem('auth', JSON.stringify(response.auth));
        dispatch(
          setAuth({
            type: 'auth/set',
            payload: response.auth,
          })
        );

        // save user to current browser
        localStorage.setItem('user', JSON.stringify(response.serverData));
        dispatch(
          setUser({
            type: 'user/set',
            payload: response.serverData,
          })
        );

        const sortedPlugins = user?.plugins ? [...user?.plugins].sort() : null;

        // navigate to private pages
        // first avalable plugin or Account
        const toPage = sortedPlugins?.[0]
          ? NAV_ITEMS.find(i => i.category === sortedPlugins?.[0])
          : NAV_ITEMS.find(i => i.category === 'Account');

        navigate(toPage!.to);
      } else {
        snackbar.open(response.message);
        await delay(5000);
        // redirect back
        if (process.env.REACT_APP_INSIGHTS_V2) {
          window.location.href = process.env.REACT_APP_INSIGHTS_V2;
        }
      }
    });
  };

  return <Loading />;
};

export default Page;
