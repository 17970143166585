import _ from 'lodash';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';

import BasicTable from './BasicTable';
import {
  DateFormatServer,
  FormInputCategory,
  FormData,
  Org,
  Conditions,
} from '../utils/Types';
import Dialog from './Dialog';
import Form from './Form';
import DialogWrapper from './DialogWrapper';
import { getDateFormat, isNotEmpty } from '../utils/Helper';

const FilterConditions = ({
  conditions,
  onUpdate,
  onClear,
  onClearAll,
  org,
  hideTitle,
}: {
  conditions: Conditions;
  onUpdate: (v: FormData) => void;
  onClear: (k: string) => void;
  onClearAll: () => void;
  org: Org;
  hideTitle?: boolean;
}) => {
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [currentCondition, setCurrentCondition] = useState<{
    key: string;
    value: any;
    type: string;
  }>();
  const rows = Object.keys(conditions)
    .filter(i => conditions[i] && isNotEmpty(conditions[i]?.value))
    .map(key => ({
      id: key,
      attribute: _.startCase(key),
      condition: Array.isArray(conditions[key]!.value)
        ? conditions[key]!.value.join(', ')
        : `Between ${
            conditions[key]!.type === 'date' ||
            conditions[key]!.type === 'datetime'
              ? moment(
                  conditions[key]!.value.from,
                  DateFormatServer.SHORT
                ).format(getDateFormat(org, 'short'))
              : conditions[key]!.value.from
          } and ${
            conditions[key]!.type === 'date' ||
            conditions[key]!.type === 'datetime'
              ? moment(
                  conditions[key]!.value.to,
                  DateFormatServer.SHORT
                ).format(getDateFormat(org, 'short'))
              : conditions[key]!.value.to
          }`,
      actions: (
        <Stack direction="row" justifyContent="center">
          <Button
            size="small"
            onClick={() => {
              setCurrentCondition({
                key,
                value: conditions[key]!.value,
                type: conditions[key]!.type,
              });
              setOpenDialogUpdate(true);
            }}
          >
            Update
          </Button>
          <Button size="small" onClick={() => onClear(key)}>
            Clear
          </Button>
        </Stack>
      ),
    }));

  const handleUpdate = (v: FormData) => {
    if (v && currentCondition) {
      let shouldUpdate = true;
      if (!Array.isArray(currentCondition.value)) {
        if (isNotEmpty(v[`${v.key}From`]) && isNotEmpty(v[`${v.key}To`])) {
          if (
            currentCondition.type === 'date' ||
            currentCondition.type === 'datetime'
          ) {
            if (v[`${v.key}From`].isValid() && v[`${v.key}To`].isValid()) {
              v[`${v.key}From`] = v[`${v.key}From`].format(
                DateFormatServer.SHORT
              );
              v[`${v.key}To`] = v[`${v.key}To`].format(DateFormatServer.SHORT);
            } else {
              shouldUpdate = false;
            }
          }
        }
      }
      if (shouldUpdate) {
        onUpdate(v);
        setOpenDialogUpdate(false);
      }
    }
  };

  if (_.isEmpty(rows)) {
    return null;
  }

  return (
    <Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {!hideTitle && <Typography variant="h6">Filter Conditions</Typography>}
        {rows.length > 1 && (
          <Button size="small" onClick={onClearAll}>
            Clear All
          </Button>
        )}
      </Stack>
      <BasicTable
        columns={[
          { id: 'attribute', label: 'Attribute' },
          { id: 'condition', label: 'Condition' },
          { id: 'actions', label: 'Actions' },
        ]}
        rows={rows}
        hideHead
      />
      <Dialog
        open={openDialogUpdate}
        onClose={() => setOpenDialogUpdate(false)}
      >
        <DialogWrapper
          title={`Update Filter Condition ${
            currentCondition ? _.startCase(currentCondition.key) : ''
          }`}
          onCancel={() => setOpenDialogUpdate(false)}
          hideCancelButton
        >
          <Stack spacing={3}>
            {currentCondition && (
              <Form
                onSubmit={data =>
                  handleUpdate({
                    key: currentCondition.key,
                    type: currentCondition.type,
                    ...data,
                  })
                }
                buttonText="Update"
                inputs={[
                  {
                    name: currentCondition.key,
                    label: _.startCase(currentCondition.key),
                    category: Array.isArray(currentCondition.value)
                      ? FormInputCategory.TEXT_INPUT
                      : currentCondition.type === 'number'
                      ? FormInputCategory.NUMBER_RANGE_INPUT
                      : FormInputCategory.DATE_RANGE_PICKER,
                    defaultValue: Array.isArray(currentCondition.value)
                      ? currentCondition.value
                      : {
                          from:
                            currentCondition.type === 'date' ||
                            currentCondition.type === 'datetime'
                              ? moment(
                                  currentCondition.value.from,
                                  DateFormatServer.SHORT
                                )
                              : currentCondition.value.from,
                          to:
                            currentCondition.type === 'date' ||
                            currentCondition.type === 'datetime'
                              ? moment(
                                  currentCondition.value.to,
                                  DateFormatServer.SHORT
                                )
                              : currentCondition.value.to,
                        },
                    disableFuture: true,
                  },
                ]}
                maxWidth="xl"
              />
            )}
          </Stack>
        </DialogWrapper>
      </Dialog>
    </Stack>
  );
};

export default FilterConditions;
