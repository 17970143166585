import { Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Moment } from 'moment';

import FrameImage, { FrameImageProps } from './FrameImage';
import { Dialog, DialogWrapperInfo } from '../../../components';
import { Trait, Type } from './GraphArea';
import { LooseObject } from '../../../utils/Types';
import { isNotEmpty } from '../../../utils/Helper';

const FrameSourceOfTruth = ({
  data,
  date,
  trait,
  type,
  facility,
  barcode,
}: {
  data: LooseObject;
  date: Moment | null;
  trait: Trait;
  type: Type;
  facility?: string;
  barcode: string;
}) => {
  const [currentItem, setCurrentItem] = useState<FrameImageProps>();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Grid
      item
      container
      xs={12}
      bgcolor="grey.300"
      border={1}
      borderColor="grey.500"
      mb={1}
    >
      <Stack direction="row" spacing={2} justifyContent="center" width="100%">
        <Typography fontWeight="bold">Source Of Truth</Typography>
      </Stack>

      {isNotEmpty(data.source_of_truth_frames) ? (
        <Grid item container spacing={1}>
          {data.source_of_truth_frames.map((url: string, index: number) => (
            <Grid item xs={12} spacing={1} key={url}>
              <FrameImage
                url={url}
                title={`Source Of Truth #${index}`}
                onClick={image => {
                  setCurrentItem(image);
                  setOpenDialog(true);
                }}
                barcode={barcode}
                date={date}
                trait={trait}
                type={type}
                facility={facility}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Stack
          flex={1}
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', height: '100%' }}
        >
          <Typography>No image available.</Typography>
        </Stack>
      )}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        isTransparent
      >
        <DialogWrapperInfo
          onClose={() => setOpenDialog(false)}
          maxHeight="100vh"
        >
          {currentItem?.title && currentItem?.url && (
            <FrameImage
              url={currentItem.url}
              title={currentItem.title}
              height="90vh"
              barcode={barcode}
              date={date}
              trait={trait}
              type={type}
              facility={facility}
            />
          )}
        </DialogWrapperInfo>
      </Dialog>
    </Grid>
  );
};

export default FrameSourceOfTruth;
