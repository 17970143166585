import { Switch } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 56,
  height: 32,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 30,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(18px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'white',
      },
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: grey[100],
      },
      '& .MuiSwitch-thumb': {
        opacity: 1,
        backgroundColor: grey[300],
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 24,
    height: 24,
    borderRadius: 12,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    color: theme.palette.primary.main,
  },
  '& .MuiSwitch-track': {
    border: `1px solid #BBBEC5`,
    borderRadius: 32 / 2,
    opacity: 1,
    backgroundColor: 'white',
    boxSizing: 'border-box',
  },
}));

export default AntSwitch;
