import { useCallback, useEffect, useState } from 'react';
import {
  FormControl,
  IconButton,
  ImageList,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { North, South } from '@mui/icons-material';

import { DialogWrapper, Dialog } from '../../../components';
import { LooseObject, Org, User } from '../../../utils/Types';
import ImageItem from './ImageItem';
import ImageInfoWithNavi from './ImageInfoWithNavi';
import { getUniqTraits } from '../../../utils/Helper';

type Order = 'asc' | 'desc';

type Props = {
  user: User;
  org: Org;
  onCancel: (values: any) => void;
  date: string;
  data: LooseObject[];
  loadData: () => void;
  shouldReloadListOfImages?: (v: boolean) => void;
};

const AllImages = ({
  user,
  org,
  onCancel,
  date,
  data,
  loadData,
  shouldReloadListOfImages,
}: Props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>({});
  const [page, setPage] = useState(1);
  const [listHeight, setListHeight] = useState(0);
  const [sortBy, setSortBy] = useState<string>('');
  const [order, setOrder] = useState<Order>('desc');

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumScreen =
    useMediaQuery(theme.breakpoints.down('lg')) && !isSmallScreen;

  const measuredRef = useCallback((node: any) => {
    if (node !== null) {
      setListHeight(Math.ceil(node.getBoundingClientRect().height));
    }
  }, []);

  const numberOfItemsPerPage = 8;

  const dataWithPhotoUrl = data.filter(i => !_.isEmpty(i.photo_url));

  const allTraits = dataWithPhotoUrl.map(i =>
    i.traits
      .filter((t: any) => t.value !== null)
      .map((j: any) => ({ key: j.key, label: j.label }))
  );
  const uniqTraits = getUniqTraits(allTraits);

  useEffect(() => {
    if (uniqTraits.length > 0) {
      setSortBy(uniqTraits[0].key);
    }
  }, []);

  const dataWithPhotoUrlSorted = _.orderBy(
    dataWithPhotoUrl,
    o => o.traits.find((t: any) => t.key === sortBy)?.value || '',
    order
  );

  const dataToDisplay = dataWithPhotoUrlSorted.slice(
    0,
    page * numberOfItemsPerPage
  );

  const onScroll = (event: any) => {
    if (
      // load more when reach about 70% of screen height
      Math.floor(event.target.scrollTop + listHeight * 1.3) >=
        event.target.scrollHeight &&
      dataWithPhotoUrlSorted.length > page * numberOfItemsPerPage
    ) {
      setPage(page + 1);
    }
  };

  const handleSortBy = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);
  };

  return (
    <DialogWrapper
      title={`All Images for ${date}`}
      onCancel={onCancel}
      hideCancelButton
    >
      {dataWithPhotoUrl?.length > 0 && (
        <Stack width="100%" direction="row">
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Sort by</InputLabel>
            <Select
              value={sortBy}
              label="Sort by trait"
              onChange={handleSortBy}
            >
              {uniqTraits.map((i: any) => (
                <MenuItem key={i.key} value={i.key}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack direction="row" ml={2} alignItems="center">
            <IconButton
              onClick={() => setOrder('desc')}
              sx={{ width: 36, height: 36 }}
            >
              <South
                sx={{
                  fontSize: 26,
                  color: order === 'desc' ? 'grey.900' : 'grey.400',
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => setOrder('asc')}
              sx={{ width: 36, height: 36 }}
            >
              <North
                sx={{
                  fontSize: 26,
                  color: order === 'asc' ? 'grey.900' : 'grey.400',
                }}
              />
            </IconButton>
          </Stack>
        </Stack>
      )}

      {dataWithPhotoUrl.map(i => i.photo_url).length > 0 ? (
        <ImageList
          cols={isSmallScreen ? 2 : isMediumScreen ? 3 : 4}
          onScroll={onScroll}
          sx={{ height: '80vh', mt: 0 }} // fix height to have scroll
          ref={measuredRef}
          gap={12}
          tabIndex={0}
        >
          {dataToDisplay.map(
            item =>
              item.photo_url && (
                <ImageItem
                  user={user}
                  key={item.barcode}
                  item={item}
                  org={org}
                  onClick={itemWithUrl => {
                    setCurrentItem(itemWithUrl || item);
                    setOpenDialog(true);
                  }}
                  isImageUrlProvided
                  shouldReloadListOfImages={shouldReloadListOfImages}
                />
              )
          )}
        </ImageList>
      ) : (
        <Typography>No images</Typography>
      )}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          user?.isDataMaintainer && loadData();
        }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: 'rgba(0,0,0,0.5)',
        }}
        fullScreen
        isTransparent
      >
        <ImageInfoWithNavi
          user={user}
          org={org}
          onClose={() => {
            setOpenDialog(false);
            user?.isDataMaintainer && loadData();
          }}
          initialImage={currentItem}
          allImages={dataWithPhotoUrlSorted}
          isImageUrlProvided
        />
      </Dialog>
    </DialogWrapper>
  );
};

export default AllImages;
