import * as React from 'react';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import {
  TableRowProps,
  TableColumnProps,
  TableGroupColumnProps,
  TableRowId,
} from '../../utils/Types';

export type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  columns: TableColumnProps[];
  collapsedColumns?: string[]; // should not be used with collapsedComponent
  columnGroups?: TableGroupColumnProps[];
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TableRowProps
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: TableRowId;
  rowCount: number;
  isSelectable?: boolean;
  isSingleSelect?: boolean;
  borderColor?: string;
  showAllBorder?: boolean;
  collapsedComponent?: Function; // should not be used with collapsedColumns
  sxHead?: {};
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    columns,
    collapsedColumns,
    columnGroups,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
    isSelectable,
    isSingleSelect,
    borderColor,
    showAllBorder,
    collapsedComponent,
    sxHead,
  } = props;
  const createSortHandler =
    (property: keyof TableRowProps) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      {columnGroups && (
        <TableRow>
          {columnGroups.map((columnGroup, index) => (
            <TableCell
              key={`group${index}`}
              align="center"
              colSpan={columnGroup.colSpan}
              sx={{
                borderTop: 1,
                borderLeft: index !== 0 && showAllBorder ? 1 : 0,
                borderColor,
              }}
            >
              {columnGroup.label}
            </TableCell>
          ))}
        </TableRow>
      )}
      <TableRow
        sx={{
          '& th': {
            backgroundColor: '#D9D9D9',
          },
          ...sxHead,
        }}
      >
        {isSelectable && (
          <TableCell
            padding="checkbox"
            sx={{ borderRight: showAllBorder ? 1 : 0, borderColor }}
          >
            {!isSingleSelect && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            )}
          </TableCell>
        )}
        {(collapsedColumns || collapsedComponent) && <TableCell />}
        {columns.map(
          (column, index) =>
            (!collapsedColumns || !collapsedColumns.includes(column.id)) &&
            !column.hidden && (
              <TableCell
                key={column.id}
                align={column.align}
                padding={column.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === column.id ? order : false}
                sx={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  borderLeft: index !== 0 && showAllBorder ? 1 : 0,
                  borderColor,
                }}
              >
                {column.isSortable ? (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  column.label
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
