import { Stack, Typography, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import DatePicker, { DatePickerProps } from './DatePicker';

const DateRangePicker = ({
  item,
  values,
  setFieldValue,
  textFieldProps,
  format,
}: DatePickerProps) => (
  <Stack key={item.name} sx={textFieldProps.sx}>
    <Stack mb={1}>
      <Typography align="left">{item.label}</Typography>
    </Stack>
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="From"
            name={`${item.name}From`}
            item={item}
            values={values}
            maxDate={values[`${item.name}To`]}
            setFieldValue={setFieldValue}
            textFieldProps={textFieldProps}
            format={format}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="To"
            name={`${item.name}To`}
            item={item}
            values={values}
            minDate={values[`${item.name}From`]}
            setFieldValue={setFieldValue}
            textFieldProps={textFieldProps}
            format={format}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  </Stack>
);

export default DateRangePicker;
