import { useContext, useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { FormData, LooseObject } from '../../../utils/Types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/reducers/authSlice';
import {
  getDateFormat,
  isNotEmpty,
  logout,
  postToServer,
} from '../../../utils/Helper';
import { SnackbarContext } from '../../../utils/Contexts';
import {
  Skeleton,
  Dialog,
  Table,
  SmallButton,
  DialogWrapper,
} from '../../../components';
import { selectOrg } from '../../../redux/reducers/orgSlice';
import QueryResultDialog from './QueryResultDialog';
import DataSourceForm from './DataSourceForm';

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [dataSources, setDataSources] = useState<LooseObject[]>([]);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogView, setOpenDialogView] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>();

  const org = useAppSelector(selectOrg);
  const auth = useAppSelector(selectAuth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (auth?.token) {
      setLoading(true);
      postToServer({
        action: 'insights/GetQueries',
        params: {},
        token: auth.token,
      }).then(async response => {
        if (response.statusCode === 401) {
          logout({ dispatch, navigate });
        } else {
          if (response.message.type === 'success' && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setDataSources(serverData);
          } else {
            snackbar.open(response.message);
          }
          setLoading(false);
        }
      });
    }
  };

  const handleAdd = ({
    name,
    view,
    columnsInterested,
    sqlConditions,
    binds,
  }: FormData) => {
    if (name && view && sqlConditions) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights/AddQuery',
          params: { name, view, columnsInterested, sqlConditions, binds },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setDataSources(response.serverData as LooseObject[]);
              setOpenDialogAdd(false);
            } else {
              snackbar.open(response.message);
            }
          }
        });
      }
    }
  };

  const handleEdit = ({
    id,
    name,
    view,
    columnsInterested,
    sqlConditions,
    binds,
  }: FormData) => {
    if (id && name && view && sqlConditions) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights/EditQuery',
          params: { id, name, view, columnsInterested, sqlConditions, binds },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setDataSources(response.serverData as LooseObject[]);
              setOpenDialogEdit(false);
            } else {
              snackbar.open(response.message);
            }
          }
        });
      }
    }
  };

  const handleRemove = (id: number) => {
    if (id) {
      if (auth?.token) {
        setLoading(true);
        postToServer({
          action: 'insights/RemoveQuery',
          params: { id },
          token: auth.token,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, navigate });
          } else {
            setLoading(false);
            if (response.message.type === 'success' && response.serverData) {
              setDataSources(response.serverData as LooseObject[]);
              setOpenDialogDelete(false);
            } else {
              snackbar.open(response.message);
            }
          }
        });
      }
    }
  };

  const columns = [
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'view', header: 'Data View' },
    {
      header: 'Columns',
      accessorFn: (row: any) =>
        isNotEmpty(row.columnsInterested)
          ? row.columnsInterested.join(',\n')
          : 'ALL',
    },
    {
      header: 'Conditions',
      accessorFn: (row: any) =>
        row.sqlConditions && JSON.stringify(row.sqlConditions) !== '"(1 = 1)"'
          ? row.sqlConditions
          : 'NONE',
    },
    {
      header: 'Created At',
      accessorFn: (row: any) =>
        moment(row.createdAt).format(getDateFormat(org, 'default')),
    },
    {
      header: 'Updated At',
      accessorFn: (row: any) =>
        moment(row.updatedAt).format(getDateFormat(org, 'default')),
    },
    { accessorKey: 'actions', header: 'Actions' },
  ];

  const rows = dataSources.map(i => ({
    ...i,
    actions: (
      <Stack spacing={1}>
        <SmallButton
          variant="outlined"
          title="View Data"
          onClick={() => {
            setCurrentItem(i);
            setOpenDialogView(true);
          }}
        />
        <SmallButton
          variant="outlined"
          title="Edit"
          onClick={() => {
            setCurrentItem(i);
            setOpenDialogEdit(true);
          }}
        />
        <SmallButton
          variant="outlined"
          title="Delete"
          onClick={() => {
            setCurrentItem(i);
            setOpenDialogDelete(true);
          }}
        />
      </Stack>
    ),
  }));

  return loading ? (
    <Skeleton />
  ) : (
    <Stack>
      <Stack width="100%" alignItems="flex-end" my={2}>
        <Button variant="contained" onClick={() => setOpenDialogAdd(true)}>
          + Data Source
        </Button>
      </Stack>
      <Dialog
        open={openDialogAdd}
        onClose={() => setOpenDialogAdd(false)}
        maxWidth="xl"
      >
        <DataSourceForm
          title="Add a new data source"
          onCancel={() => setOpenDialogAdd(false)}
          handleSubmit={handleAdd}
        />
      </Dialog>
      <Stack spacing={2}>
        <Table
          columns={columns}
          data={rows}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          layoutMode="grid"
        />
      </Stack>
      <Dialog
        open={openDialogView}
        onClose={() => setOpenDialogView(false)}
        maxWidth="xl"
      >
        {currentItem && (
          <QueryResultDialog
            view={currentItem.view}
            columnsInterested={currentItem.columnsInterested}
            sqlConditions={currentItem.sqlConditions}
            onCancel={() => setOpenDialogView(false)}
            auth={auth}
            snackbar={snackbar}
          />
        )}
      </Dialog>
      <Dialog
        open={openDialogEdit}
        onClose={() => setOpenDialogEdit(false)}
        maxWidth="xl"
      >
        {currentItem && (
          <DataSourceForm
            title="Edit data source"
            item={currentItem}
            onCancel={() => setOpenDialogEdit(false)}
            handleSubmit={handleEdit}
          />
        )}
      </Dialog>
      <Dialog
        open={openDialogDelete}
        onClose={() => setOpenDialogDelete(false)}
        maxWidth="xl"
      >
        {currentItem && (
          <DialogWrapper
            title="Delete a data source"
            onCancel={() => setOpenDialogDelete(false)}
            onConfirm={() => handleRemove(currentItem.id)}
          >
            <Typography>{`Are you sure to delete ${currentItem.name}? All related insight items will be removed as well.`}</Typography>
          </DialogWrapper>
        )}
      </Dialog>
    </Stack>
  );
};

export default Page;
