import { Stack, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';

import { FormData, FormInputCategory } from '../../../utils/Types';
import { Form } from '../../../components';
import { Link } from 'react-router-dom';

export const HelperTextLogin = ({
  text,
  to,
  linkTitle,
  onClickLink,
}: {
  text: string;
  to: string;
  linkTitle?: string;
  onClickLink?: MouseEventHandler<HTMLAnchorElement>;
}) => (
  <Stack direction="row" spacing={1}>
    <Typography color="grey.500" variant="body2">
      {text}
    </Typography>
    <Link to={to} onClick={onClickLink}>
      <Typography
        color="grey.500"
        variant="body2"
        sx={{ textDecoration: 'underline' }}
      >
        {linkTitle || 'Click here'}
      </Typography>
    </Link>
  </Stack>
);

type Props = {
  loading: boolean;
  handleSubmit: (formData: FormData) => void;
};

const LoginForm = ({ loading, handleSubmit }: Props) => {
  return (
    <Stack spacing={3} alignItems="center">
      <Stack spacing={1} alignItems="center" textAlign="center">
        <Typography variant="h4" fontWeight="bold" color="primary">
          Welcome Back
        </Typography>
        <Typography color="grey.500">Sign in to continue!</Typography>
      </Stack>
      <Form
        loading={loading}
        onSubmit={data => {
          handleSubmit(data);
        }}
        buttonText="Login"
        inputs={[
          {
            name: 'email',
            category: FormInputCategory.TEXT_INPUT,
            placeholder: 'Please enter your email',
          },
          {
            name: 'password',
            category: FormInputCategory.TEXT_INPUT,
            type: 'password',
            placeholder: 'Please enter your password',
          },
        ]}
        itemSx={{
          mt: 1,
          borderRadius: 1,
          '&& .MuiInputBase-root': {
            backgroundColor: 'white',
          },
        }}
        buttonSx={{
          '&&.Mui-disabled': {
            backgroundColor: 'primary.dark',
            '&& .MuiLoadingButton-loadingIndicator': {
              color: 'grey.300',
            },
          },
        }}
      />
      <HelperTextLogin text="Forgot your password?" to="/forgot-password" />
    </Stack>
  );
};

export default LoginForm;
