import { IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import moment from 'moment';
import { useState } from 'react';

import {
  DataPoolItem,
  DateFormatServer,
  LooseObject,
  Org,
} from '../../../utils/Types';
import {
  getDateFormat,
  getFlatObjectArray,
  isNotEmpty,
} from '../../../utils/Helper';
import { Dialog, Table } from '../../../components';
import { filterData } from '../../../utils/Helper2';
import DataSourceDetails from './DataSourceDetails';

const InsightItemTable = ({
  loading,
  item,
  dataPoolItem,
  conditions,
  updateConditions,
  org,
}: {
  loading: boolean;
  item: LooseObject;
  dataPoolItem: DataPoolItem;
  conditions: LooseObject;
  updateConditions: (newConditions: LooseObject) => void;
  org: Org;
}) => {
  const [openDialogDataSource, setOpenDialogDataSource] = useState(false);

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Typography variant="h6" textAlign="center">
          {item.title}
        </Typography>
        {item.description && (
          <Tooltip title={item.description}>
            <IconButton sx={{ width: 20, height: 20 }}>
              <InfoOutlined sx={{ width: 20, height: 20 }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body2">Data Source: </Typography>
        <Link
          variant="body2"
          color="secondary"
          onClick={() => setOpenDialogDataSource(true)}
        >
          {dataPoolItem.dataSource.label}
        </Link>
        <Dialog
          open={openDialogDataSource}
          onClose={() => setOpenDialogDataSource(false)}
          maxWidth="sm"
        >
          <DataSourceDetails
            dataSource={dataPoolItem.dataSource}
            onCancel={() => setOpenDialogDataSource(false)}
            org={org}
          />
        </Dialog>
      </Stack>
      <Table
        //   renderTopToolbarCustomActions={() => (
        //     <Typography variant="h6">{item.title}</Typography>
        //   )}
        columns={dataPoolItem.attributes.map(i => ({
          accessorKey: i.key,
          header: i.label,
          size: 100,
        }))}
        data={getFlatObjectArray(
          filterData(dataPoolItem.data, conditions),
          dataPoolItem.attributes
        ).map(i => {
          dataPoolItem.attributes.forEach(a => {
            if (a.type === 'date') {
              i[a.key] = moment(i[a.key], DateFormatServer.SHORT).format(
                getDateFormat(org, 'short')
              );
            }
          });
          return i;
        })}
        initialState={{ density: 'compact' }}
        muiTablePaperProps={{ elevation: 0 }}
        state={{ isLoading: loading }}
        enableColumnFilters={false}
        filterFns={{
          filterFnForUsingUpdateConditions: (row, id, filterValue) => {
            let pass = true;
            const value = row.getValue(id);
            const attribute = dataPoolItem.attributes.find(i => i.key === id);
            if (attribute) {
              switch (attribute.type) {
                case 'number':
                  if (
                    isNotEmpty(filterValue[0]) &&
                    filterValue[0] > (value as number)
                  ) {
                    pass = false;
                  }
                  if (
                    isNotEmpty(filterValue[1]) &&
                    filterValue[1] < (value as number)
                  ) {
                    pass = false;
                  }
                  break;

                default:
                  pass = (value as string).includes(filterValue);
                  break;
              }
            }
            return pass;
          },
        }}
      />
    </Stack>
  );
};

export default InsightItemTable;
